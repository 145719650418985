<template>
  <div class="popup edit">
    <div class="popup-header">{{ $t("popup.PopupEditGroup.header") }}</div>
    <div class="divider"></div>
    <div class="popup-content">
      <div v-if="chat?.picture" class="profile-picture">
        <img :src="chat.picture" class="picture" />
      </div>
      <div class="option">
        <div class="label">
          {{ $t("popup.PopupEditGroup.optionPicture.label") }}
        </div>
        <div class="setting-container">
          <input type="text" :value="picture" @change="changePicture" />
          <!-- <button class="btn btn-secondary">
            {{ $t("popup.PopupEditGroup.optionPicture.btn") }}
          </button> -->
        </div>
      </div>
      <div class="option">
        <div class="label">
          {{ $t("popup.PopupEditGroup.optionName.label") }}
        </div>
        <div class="setting-container">
          <input type="text" :value="name" @change="changeName" />
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="popup-footer">
      <PopupCancelButton />
      <button class="btn btn-primary" @click="save">
        {{ $t("popup.PopupEditGroup.btnSave") }}
      </button>
    </div>
  </div>
</template>

<script>
import PopupCancelButton from "@/components/popup/PopupCancelButton.vue";
import { mapGetters } from "vuex";
import { ActionTypes, GetterTypes, MutationTypes, store } from "@/store";

export default {
  components: {
    PopupCancelButton,
  },
  data() {
    return {
      picture: "",
      name: "",
    };
  },
  computed: {
    ...mapGetters({
      chatId: GetterTypes.GET_SELECTED_CHAT_ID,
      chat: GetterTypes.GET_SELECTED_CHAT,
    }),
  },
  mounted() {
    const chat = store.getters.GET_SELECTED_CHAT;
    if (chat?.hasName) {
      this.name = chat.name;
    }
  },
  methods: {
    changeName(e) {
      this.name = e.target.value;
    },
    changePicture(e) {
      this.picture = e.target.value;
    },
    save() {
      store.dispatch(ActionTypes.UPDATE_GROUP_CHAT, {
        chatId: this.chatId,
        name: this.name,
        picture: this.picture,
      });
      store.commit(MutationTypes.HIDE_POPUP);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/popup/popup.scss";
@import "src/assets/scss/popup/popupDefault.scss";
</style>
