import { useComputedValue, useVisualViewport } from "@/composables";
import { searchUsersByNameCallbackAsync } from "@/services/maitrejaApi/search";
import { GetterTypes, MutationTypes } from "@/store";
import { Profile } from "@/store/auth/state";
import { Chat, ChatTypes } from "@/store/chats/types";
import { FriendUser, PhoneContact } from "@/store/users/state";
import { ChatTypeProvideObject } from "@/types/chat";
import { IconType } from "@/types/icons";
import { IListItem, ListItemTypeEnum } from "@/types/list";
import { IUserPhoneContactUser } from "@/types/users";
import { chatTypeInjectionKey } from "@/utils/chat/chatTypeInjectionKey";
import { getFullPhoneNumberString } from "@/utils/form";
import { ref, reactive, computed, watch, inject, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

interface Data {
  otherPeople: Profile[];
  selectedGroupMembers: IListItem[];
}

type member = {
  id: number;
};

export const useGetChangeChatMobileContainerProperties = (
  isNewChat: boolean,
) => {
  const root = ref<HTMLElement>();
  useVisualViewport(root);
  const route = useRoute();
  const router = useRouter();
  const chatId = route.params.chat_id;

  const data = reactive<Data>({
    otherPeople: [],
    selectedGroupMembers: [],
  });

  const injected = inject<ChatTypeProvideObject>(chatTypeInjectionKey, {
    type: ChatTypes.ONE_TO_ONE_CHAT,
  });

  const { getters, commit } = useStore();
  const friends = useComputedValue<FriendUser[]>(
    GetterTypes.GET_AUTH_FRIEND_ARRAY,
  );
  const phoneContacts = useComputedValue<PhoneContact[]>(
    GetterTypes.GET_PHONE_CONTACTS,
  );
  const phoneContactsUsers = useComputedValue<IUserPhoneContactUser[]>(
    GetterTypes.GET_PHONE_CONTACTS_USERS,
  );
  const accessToken = useComputedValue<string>(
    GetterTypes.GET_AUTH_ACCESS_TOKEN,
  );
  const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);
  const filter = useComputedValue<string>(GetterTypes.GET_CHATS_FILTER);
  const layoutHeight = useComputedValue<string>(GetterTypes.GET_LAYOUT_HEIGHT);
  const initialLoadCompleted = useComputedValue<boolean>(
    GetterTypes.GET_CHATS_INITIAL_LOAD,
  );

  onMounted(() => {
    if (injected.type === ChatTypes.ONE_TO_ONE_CHAT) {
      return;
    }

    addCurrentPartner();
  });

  watch(initialLoadCompleted, () => {
    addCurrentPartner();
  });

  const addCurrentPartner = () => {
    if (!isNewChat || !initialLoadCompleted) {
      return;
    }
    const chatId = route.params.chat_id as string;
    const chat: Chat | undefined = getters.GET_CONVERTED_CHAT_DETAIL(chatId);
    if (!chat?.partner) {
      return;
    }
    const partner = chat.partner;
    const { name, picture, id } = partner;
    const partnerListItem = {
      text: name,
      pictureSrc: picture,
      type: ListItemTypeEnum.Chat,
      itemId: id,
      isOnline: false,
    };

    data.selectedGroupMembers.push(partnerListItem);
  };

  const handleAddGroupMember = (item: IListItem) => {
    if (
      data.selectedGroupMembers.find((member) => member.itemId === item.itemId)
    ) {
      return;
    }
    data.selectedGroupMembers.push(item);
  };

  const handleRemoveGroupMember = (item: IListItem) => {
    data.selectedGroupMembers = data.selectedGroupMembers.filter(
      (m) => m.itemId !== item.itemId,
    );
  };

  const friendsListItemsArray = computed<IListItem[]>(() => {
    const arr = friends.value.map(({ name, picture, id, alias }) => {
      return {
        text: name,
        pictureSrc: picture,
        type: ListItemTypeEnum.Chat,
        itemId: id,
        isOnline: false,
        subtext: alias,
      };
    });

    return getFilteredArray(arr);
  });

  const phoneContactsRecommendListItemsArray = computed<IListItem[]>(() => {
    const arr: IListItem[] = phoneContacts.value.map(({ name, phone }, i) => {
      return {
        text: name,
        iconName: IconType.DEFAULT_SINGLE_GRAY,
        type: ListItemTypeEnum.Chat,
        itemId: i,
        isOnline: false,
        phoneNumber: phone,
        isNotInvited: true,
      };
    });

    return getFilteredArray(arr);
  });

  const phoneContactsUsingAppListItemsArray = computed<IListItem[]>(() => {
    const arr = phoneContactsUsers.value.map(
      ({ phoneNumber, countryCode, name, id, alias }) => {
        return {
          text: name,
          iconName: IconType.DEFAULT_SINGLE_GRAY,
          type: ListItemTypeEnum.Chat,
          itemId: id,
          isOnline: false,
          phoneNumber: getFullPhoneNumberString(countryCode, phoneNumber),
          subtext: alias,
        };
      },
    );
    return getFilteredArray(arr);
  });

  const otherPeopleListItemsArray = computed<IListItem[]>(() => {
    if (!filter.value) {
      return [];
    }
    const items = data.otherPeople
      .filter((user) => user.id !== authId.value)
      .map(({ name, picture, id }) => {
        return {
          text: name,
          pictureSrc: picture,
          type: ListItemTypeEnum.Chat,
          itemId: id,
          isOnline: false,
        };
      });

    const filtered = items
      .map((user) => {
        const isPhoneContact = phoneContactsUsingAppListItemsArray.value.some(
          (phoneContact) => phoneContact.itemId === user.itemId,
        );
        const isFriend = friendsListItemsArray.value.some(
          (phoneContact) => phoneContact.itemId === user.itemId,
        );
        if (!isPhoneContact && !isFriend) {
          return user;
        }
      })
      .filter(Boolean) as IListItem[];
    return getFilteredArray(filtered);
  });

  const getFilteredArray = (arr: IListItem[]): IListItem[] => {
    const filtered: IListItem[] = [];
    arr.forEach((it) => {
      const isSelected = data.selectedGroupMembers.find(
        (member) => member.itemId === it.itemId,
      );

      let isKept = false;
      if (injected.type === ChatTypes.ONE_TO_ONE_CHAT) isKept = true;
      if (injected.type === ChatTypes.GROUP_CHAT && !isSelected) isKept = true;
      if (injected.type === ChatTypes.GROUP_CHAT && !isNewChat) isKept = true;

      if (!isKept) return;

      if (isNewChat) {
        filtered.push(it);
        return filtered;
      }

      const chatDetail = getters.GET_CONVERTED_CHAT_DETAIL(chatId);

      const alreadyGroupMembers = chatDetail.members.map(
        (member: member) => member.id,
      );

      let updatedItemType = ListItemTypeEnum.Checkmark;
      if (isSelected) {
        updatedItemType = ListItemTypeEnum.Checkmarked;
      }
      if (alreadyGroupMembers.includes(it.itemId)) {
        updatedItemType = ListItemTypeEnum.CheckmarkedDisabled;
      }

      const updatedItem = {
        ...it,
        type: updatedItemType,
        isPictureDisplayed: true,
      };

      filtered.push(updatedItem);
    });

    return filtered;
  };

  const listsContainerHeight = computed<string>(() => {
    return `calc(${layoutHeight.value}px - 130px)`;
  });

  watch(filter, () => {
    searchUsersByNameCallbackAsync(
      filter.value,
      accessToken.value,
      setOtherPeople,
    );
  });

  const setOtherPeople = (users: Profile[]): void => {
    data.otherPeople = users;
  };

  const handleRedirect = async () => {
    switch (injected.type) {
      case ChatTypes.ONE_TO_ONE_CHAT:
        commit(MutationTypes.CLEAR_CHATS_FILTER);
        router.push("/");
        break;

      default:
        break;
    }
  };

  return {
    listsContainerHeight,
    friendsListItemsArray,
    otherPeopleListItemsArray,
    phoneContactsRecommendListItemsArray,
    phoneContactsUsingAppListItemsArray,
    handleRedirect,
    injected,
    data,
    handleAddGroupMember,
    handleRemoveGroupMember,
  };
};
