import store from "@/store";
import { ChatsGetterTypes } from "@/store/chats";
import { ChatBlockingStatusEnum } from "@/store/chats/types";

export const getIsBlockingTextShown = () => {
  const blockingStatus =
    store.getters[ChatsGetterTypes.GET_SELECTED_CHAT_BLOCKING_STATUS];
  const isDeleted =
    store.getters[ChatsGetterTypes.GET_SELECTED_CHAT_IS_DELETED];

  const isBlockingTextShown =
    (blockingStatus && blockingStatus !== ChatBlockingStatusEnum.None) ||
    isDeleted;

  return isBlockingTextShown;
};
