import { ChatTypes, MessageType, Message } from "@/store/chats/types";
import { getIsMessageTextType } from "./getIsMessageTextType";

export const showSenderName = (
  messages: Message[],
  index: number,
  authId: number,
) => {
  const currentMessage = messages[index];
  if (!getIsMessageTextType(currentMessage)) {
    return;
  }
  if (currentMessage.chatType === ChatTypes.ONE_TO_ONE_CHAT) return false;

  if (currentMessage.senderId === authId) return false;
  if (index === 0) return true;

  const previousMessageSenderId = messages[index - 1].senderId;
  const messageSenderId = messages[index].senderId;

  if (previousMessageSenderId === messageSenderId) return false;

  return true;
};
