import { HttpRequestMethodEnum } from "@/types/api";
import { IAuthResponseObject } from "@/types/auth";
import { request } from "../api";
import { storeAuthenticationData } from "./storeAuthenticationData";
import { config } from "@/config";

export const createNewUserAsync = async (
  name: string,
  email: string,
  password: string,
) => {
  const queryData = await request<IAuthResponseObject>(
    config.api.maitrejaApi.endpoints.registration,
    HttpRequestMethodEnum.POST,
    {
      client_id: process.env.VUE_APP_MAITREJA_API_KEY,
      client_secret: process.env.VUE_APP_MAITREJA_API_SECRET,
      name,
      email,
      pass: password,
    },
  );

  storeAuthenticationData(queryData);

  return queryData;
};
