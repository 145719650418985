import { JointLocalTrack } from "@/types/call";
import { LocalParticipant } from "twilio-video";
import { detachTrack } from "./detachTrack";

export const removeLocalParticipantTracks = (
  localParticipant: LocalParticipant,
  tracks: JointLocalTrack[],
) => {
  localParticipant.unpublishTracks(tracks);
  tracks.forEach((track) => {
    detachTrack(track);
  });
};
