import { format } from "date-fns";
import { getTodayMilliSeconds } from "./getTodayMilliSeconds";

export const convertDateToStringDate = (
  dateTime: number,
  i18n: any,
): string => {
  const currentDateTime = new Date().getTime();
  const date = new Date(dateTime);

  const todayMilliseconds = getTodayMilliSeconds(currentDateTime);
  const dayMilliseconds = 86400000;
  const diff = currentDateTime - dateTime;

  if (diff < todayMilliseconds) {
    return format(new Date(dateTime), "HH:mm");
  }
  if (diff < todayMilliseconds + dayMilliseconds) {
    return i18n("date.yesterday");
  }
  if (diff > todayMilliseconds + dayMilliseconds * 7) {
    return format(new Date(dateTime), "dd.MM.yyyy");
  }

  switch (date.getDay()) {
    case 0:
      return i18n("date.sunday");
    case 1:
      return i18n("date.monday");
    case 2:
      return i18n("date.tuesday");
    case 3:
      return i18n("date.wednesday");
    case 4:
      return i18n("date.thursday");
    case 5:
      return i18n("date.friday");
    case 6:
      return i18n("date.saturday");
  }

  return "";
};
