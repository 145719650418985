import { Chat } from "@/store/chats/types";
import { getPhoneContactUserAliasFromId } from "../users";

export const getFullChatName = (currentChat?: Chat): string => {
  let chatName = currentChat?.name ?? "";
  const partnerId = currentChat?.partner?.id;
  if (!partnerId) {
    return chatName;
  }

  const contactUserAlias = getPhoneContactUserAliasFromId(partnerId);

  if (contactUserAlias) {
    return (chatName += ` (~${contactUserAlias})`);
  }
  return chatName;
};
