import { config } from "@/config";
import { LangType, LangTypes, SignType, SignTypes } from "../../data/menu";

export interface MenuState {
  url: string;
  lang: LangType;
  sign: SignType;
  openDropdown: {
    create: boolean;
    options: boolean;
  };
}

export const menuState: MenuState = {
  url: config.api.maitrejaApi.appUrl,
  lang: LangTypes.CZ,
  sign: SignTypes.SIGN_IN,
  openDropdown: {
    create: false,
    options: false,
  },
};
