<template>
  <div
    class="attachment-image"
    :class="[
      getIsMyMessageClass(message, authId),
      { gallery: galleryMode, isShrinked },
    ]"
    @click="openImage"
  >
    <img loading="lazy" :style="appliedStyle" :src="attachment.url" />
    <Loader
      v-if="attachment?.uploadProgress !== undefined && !attachment?.uploaded"
      :progress="attachment.uploadProgress"
      :overlay="true"
    />
  </div>
</template>

<script setup lang="ts">
import Loader from "@/components/chat/chatContainer/Loader.vue";
import { ref, onMounted, watch } from "vue";
import { GetterTypes, MutationTypes, store } from "@/store";
import { LayoutBackgroundColorTypes } from "@/store/app/state";
import { useStore } from "vuex";
import { getIsMyMessageClass } from "@/utils/chat";
import { getImageAttachmentStyles } from "@/utils/attachment";
import { useComputedValue } from "@/composables";
import { Attachment, NonAutomaticMessage } from "@/store/chats/types";

interface Props {
  attachment: Attachment;
  isShrinked?: boolean;
  galleryMode?: boolean;
  message: NonAutomaticMessage;
}

const props = withDefaults(defineProps<Props>(), {
  isShrinked: false,
  galleryMode: false,
});

onMounted(() => {
  getAppliedStyle();
});

const { commit } = useStore();
const appliedStyle = ref<{ width: string; height: string } | null>(null);
const temporaryColorBottom = ref<string | null>(null);
const temporaryColorTop = ref<string | null>(null);
const src = useComputedValue<string | undefined>(
  GetterTypes.GET_FULL_SIZE_IMAGE,
);
const colorBottom = useComputedValue<LayoutBackgroundColorTypes>(
  GetterTypes.GET_LAYOUT_BG_COLOR_BOTTOM,
);
const colorTop = useComputedValue<LayoutBackgroundColorTypes>(
  GetterTypes.GET_LAYOUT_BG_COLOR_TOP,
);
const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);

const getAppliedStyle = async () => {
  const appliedStyles = await getImageAttachmentStyles({
    width: props.attachment?.width,
    height: props.attachment?.height,
    galleryMode: props.galleryMode,
    isShrinked: props.isShrinked,
    url: props.attachment?.url,
  });
  if (!appliedStyles) {
    return;
  }
  appliedStyle.value = appliedStyles;
};

watch(src, () => {
  updateImageSrc();
});
const openImage = () => {
  if (props.isShrinked || !props.attachment.url) {
    return;
  }
  temporaryColorBottom.value = colorBottom.value;
  temporaryColorTop.value = colorTop.value;
  store.commit(MutationTypes.SET_FULL_SIZE_IMAGE, props.attachment.url);
};

const updateImageSrc = () => {
  let resultColorBottom = temporaryColorBottom.value;
  let resultColorTop = temporaryColorTop.value;
  if (src.value) {
    resultColorBottom = LayoutBackgroundColorTypes.BLACK;
    resultColorTop = LayoutBackgroundColorTypes.BLACK;
  }

  commit(MutationTypes.SET_LAYOUT_BACKGROUND_COLOR_TOP, resultColorTop);
  commit(MutationTypes.SET_LAYOUT_BACKGROUND_COLOR_BOTTOM, resultColorBottom);
};
</script>
<style lang="scss" scoped>
@import "./ImageAttachment.scss";
</style>
