import { ChatsStates } from "@/store/chats/types";
import { IChatModifiedReferenceObject } from "@/types/chat";

export const getChatStatesFromReferenceArray = (
  chats: IChatModifiedReferenceObject[],
) => {
  const chatsObject: ChatsStates = chats.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.chatId]: cur,
    }),
    {},
  );
  return chatsObject;
};
