<template>
  <div
    class="verification-form"
    :class="{
      'verification-started': data.isVerificationStarted,
      'is-authenticated': isAuthenticated,
    }"
  >
    <div
      v-if="!isAuthenticated || data.isVerificationStarted"
      class="text-container"
    >
      <h1 class="title">{{ appliedTexts.title }}</h1>
      <p class="text">{{ appliedTexts.text }}</p>
    </div>
    <Form>
      <div
        class="verification-form-content"
        :class="{ 'verification-started': data.isVerificationStarted }"
      >
        <div v-if="!data.isVerificationStarted" class="verification-inputs">
          <CountryCodeSelect
            @update-country-code="handleUpdateCountryCode"
            @toggle-popup="handleTogglePopup"
          />
          <Input
            :model-value="data.phoneNumber"
            :name="FormFieldNameEnum.PhoneNumber"
            pattern="[0-9]{9}"
            placeholder="123 456 789"
            :type="FormFieldType.Tel"
            required
            :errors="data.errors"
            :is-checked="hasPhoneNumber"
            @update:model-value="updatePhoneNumber"
          />
        </div>
        <PartialInputsContainer
          v-else
          :strings-array="data.codesArray"
          @update-partial-inputs-container="updatePartialInput"
          @paste-to-partial-inputs="handlePasteToPartialInputs"
        />

        <GenericButton
          v-show="!data.isVerificationStarted"
          id="sign-in-button"
          :variant="GenericButtonVariantEnum.Primary"
          :text="appliedTexts.verifyButtonText"
          :is-loading="data.isLoading"
          @click="handleStartVerification"
        />
        <GenericButton
          v-if="data.isVerificationStarted"
          :variant="GenericButtonVariantEnum.Primary"
          :text="appliedTexts.confirmButtonText"
          :is-loading="data.isLoading"
          @click="handleConfirmCode"
        />
        <p
          v-if="data.isVerificationStarted"
          class="resend-code"
          @click="handleStartVerification"
        >
          {{ t("auth.buttons.resendVerificationSms") }}
        </p>
      </div>
    </Form>
  </div>
  <div id="sign-in" />
</template>

<script setup lang="ts">
import PartialInputsContainer from "@/components/forms/PartialInputsContainer/PartialInputsContainer.vue";
import CountryCodeSelect from "@/components/forms/CountryCodeSelect/CountryCodeSelect.vue";
import Form from "@/components/layouts/Form.vue";
import GenericButton from "@/components/buttons/GenericButton.vue";
import { useComputedValue } from "@/composables";
import { getIsAuthenticated } from "@/services/maitrejaApi";
import { GetterTypes } from "@/store";
import { Profile } from "@/store/auth/state";
import { IConnectPhoneData } from "@/types/auth";
import {
  FormFieldType,
  FormFieldNameEnum,
  LoginPhoneFormDataObjectType,
  FormFieldDataObject,
} from "@/types/form";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { IPartialInputEmitType } from "../PartialInput";
import { GenericButtonVariantEnum } from "@/types/button";
import Input from "../Input.vue";

interface PhoneVerificationFormEmits {
  (e: "togglePopup", arg: boolean): void;
}

interface Props {
  data: IConnectPhoneData & FormFieldDataObject<LoginPhoneFormDataObjectType>;
  handleUpdateCountryCode: (countryCode: string) => void;
  handleStartVerification: () => Promise<void>;
  handleConfirmCode: () => Promise<void>;
  updatePartialInput: (eventObject: IPartialInputEmitType) => void;
  handlePasteToPartialInputs: (e: ClipboardEvent) => void;
  updatePhoneNumber: (e: any) => void;
}
const props = defineProps<Props>();
const emit = defineEmits<PhoneVerificationFormEmits>();

const { t } = useI18n();
const isAuthenticated = getIsAuthenticated();
const profile = useComputedValue<Profile>(GetterTypes.GET_AUTH_PROFILE);
const hasPhoneNumber = computed<boolean>(() => {
  return Boolean(profile?.value.countryCode && profile?.value.phoneNumber);
});

const appliedTexts = computed(() => {
  let title = "auth.login";
  let text = "welcome.loginWithPhoneCode";
  let verifyButtonText = "auth.buttons.sendVerificationSms";
  let confirmButtonText = "auth.buttons.login";

  if (isAuthenticated && hasPhoneNumber) {
    verifyButtonText =
      "settings.connectPhoneNumberContent.connectNewPhoneNumber";
  }
  if (props.data.isVerificationStarted) {
    text = "welcome.confirmLoginWithPhoneCode";
  }
  if (isAuthenticated && props.data.isVerificationStarted) {
    title = "settings.connectPhoneNumberContent.confirmation.title";
    text = "settings.connectPhoneNumberContent.confirmation.text";
    confirmButtonText = "settings.connectPhoneNumberContent.savePhoneNumber";
  }

  return {
    title: t(title),
    text: t(text),
    verifyButtonText,
    confirmButtonText,
  };
});

const handleTogglePopup = (isPopupOpen: boolean) => {
  emit("togglePopup", isPopupOpen);
};
</script>

<style lang="scss" scoped>
@import "./PhoneVerificationForm.scss";
</style>
