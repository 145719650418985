import { CreateCachedMessageArgs } from "@/store/chats/types";
import { createCachedGroupChatMessage } from "./createCachedGroupChatMessage";
import { createCachedOneToOneChatMessage } from "./createCachedOneToOneChatMessage";

export const createCachedMessage = (
  memberIds: string[],
  args: CreateCachedMessageArgs,
) => {
  if (memberIds.length && memberIds.length >= 2) {
    return createCachedGroupChatMessage(args);
  }
  return createCachedOneToOneChatMessage(args);
};
