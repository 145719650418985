import { LocaleTypeEnum } from "@/types/localization";

export const constants = {
  CHAT_MOBILE_WIDTH: 850, // px
  DEBOUNCE_TIME: 250, // ms
  AFTER_FUNCTION_COUNT: 2,
  CHATS_PAGE_COUNT: 10,
  DEFAULT_LANG: LocaleTypeEnum.CS,
  CALLS_AVAILABLE_APP_VERSION: "2.1.0",
  PHONE_VERIFICATION_CODE_INPUTS_COUNT: 6,
  DEFAULT_VIDEO_SETTINGS: {
    height: 720,
    frameRate: 24,
    width: 1280,
  },
  SAFE_AREA_HEIGHT: 160,
  REACTIONS_SAFE_WIDTH: 116,
} as const;
