<template>
  <div v-if="popupType !== PopupTypes.WHAT_IS_NEW" class="popup-container">
    <PopupEditGroup v-if="popupType === PopupTypes.EDIT_GROUP" />
    <PopupEditGroupMembers v-if="popupType === PopupTypes.EDIT_GROUP_MEMBERS" />
    <PopupInfoDeleteChat v-if="popupType === PopupTypes.INFO_DELETE_CHAT" />
    <PopupInfoDeleteMessages
      v-if="popupType === PopupTypes.INFO_DELETE_MESSAGES"
    />
    <PopupInfoLeaveGroup v-if="popupType === PopupTypes.INFO_LEAVE_GROUP" />
    <PopupInfoMuteChat v-if="popupType === PopupTypes.INFO_MUTE_CHAT" />
    <PopupNotificationsSetting
      v-if="popupType === PopupTypes.NOTIFICATIONS_SETTING"
    />
    <PopupInfoDeleteUserAccount
      v-if="popupType === PopupTypes.INFO_DELETE_USER_ACCOUNT"
    />
    <PopupInfoReportUser v-if="popupType === PopupTypes.INFO_REPORT_USER" />
    <PopupInfoBlockUser v-if="popupType === PopupTypes.INFO_BLOCK_USER" />
    <PopupInfoUnblockUser v-if="popupType === PopupTypes.INFO_UNBLOCK_USER" />
  </div>
  <PopupWhatIsNew v-if="popupType === PopupTypes.WHAT_IS_NEW" />
</template>

<script>
import PopupEditGroup from "@/components/popup/PopupEditGroup.vue";
import PopupEditGroupMembers from "@/components/popup/PopupEditGroupMembers.vue";
import PopupNotificationsSetting from "@/components/popup/PopupNotificationsSetting.vue";
import PopupInfoDeleteChat from "@/components/popup/PopupInfoDeleteChat.vue";
import PopupInfoDeleteMessages from "@/components/popup/PopupInfoDeleteMessages.vue";
import PopupInfoLeaveGroup from "@/components/popup/PopupInfoLeaveGroup.vue";
import PopupInfoDeleteUserAccount from "@/components/popup/PopupInfoDeleteUserAccount.vue";
import PopupInfoBlockUser from "@/components/popup/PopupInfoBlockUser.vue";
import PopupInfoUnblockUser from "@/components/popup/PopupInfoUnblockUser.vue";
import PopupInfoReportUser from "@/components/popup/PopupInfoReportUser.vue";
import PopupInfoMuteChat from "@/components/popup/PopupInfoMuteChat.vue";
import PopupWhatIsNew from "@/components/popup/PopupWhatIsNew/PopupWhatIsNew.vue";
import { mapGetters } from "vuex";
import { GetterTypes } from "@/store";
import { PopupTypes } from "@/store/app/state";

export default {
  components: {
    PopupEditGroup,
    PopupEditGroupMembers,
    PopupNotificationsSetting,
    PopupInfoDeleteChat,
    PopupInfoDeleteMessages,
    PopupInfoLeaveGroup,
    PopupInfoLeaveGroup,
    PopupInfoDeleteUserAccount,
    PopupInfoBlockUser,
    PopupInfoUnblockUser,
    PopupInfoReportUser,
    PopupInfoMuteChat,
    PopupWhatIsNew,
  },
  data() {
    return {
      PopupTypes,
    };
  },
  computed: {
    ...mapGetters({
      popupType: GetterTypes.GET_POPUP_TYPE,
    }),
  },
  watch: {
    popupType() {
      this.changeDisplayMode();
    },
  },
  mounted() {
    this.changeDisplayMode();
  },
  methods: {
    changeDisplayMode() {
      if (!this.popupType) {
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/popup/popupContainer.scss";
</style>
