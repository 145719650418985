export const maitrejaApiConfig = {
  url: process.env.VUE_APP_MAITREJA_API_URL as string,
  appUrl: process.env.VUE_APP_MAITREJA_APP_URL as string,
  endpoints: {
    blockUser: "/blockuser",
    unblockUser: "/unblockuser",
    checkUserBlocking: "/controlblock",
    deleteUserAccount: "/closeaccount",
    searchUserById: "/searchuserbyid",
    reportUser: "/reportuser",
    searchUserByName: "/searchuser",
    login: "/login",
    loginByPhone: "/loginbyphone",
    friends: "/friends",
    registration: "/registration",
    token: "/token",
    checkToken: "/checktoken",
    updateUserLang: "/updateuserlang",
    updateUser: "/updateuser",
    updateUserPhone: "/updateuserphone",
    users: "/users",
    checkUsersByPhone: "/checkusersbyphone",
    updateFriendRequest: "/updatefriendship",
  },
} as const;
