import { HttpRequestContentTypeEnum, HttpRequestMethodEnum } from "@/types/api";
import {
  IUserPhoneContactUserResponseObject,
  IUserPhoneContactResponseObject,
  IPhoneContactRequestItemObject,
} from "@/types/users";
import { request } from "../api/request";
import { config } from "@/config";

export const getUsersByPhoneNumberAsync = async (
  accessToken: string,
  contactsRequestItemsArray: IPhoneContactRequestItemObject[],
): Promise<IUserPhoneContactUserResponseObject[]> => {
  try {
    const data = await request<IUserPhoneContactResponseObject>(
      config.api.maitrejaApi.endpoints.checkUsersByPhone,
      HttpRequestMethodEnum.POST,
      { contactsArray: contactsRequestItemsArray },
      accessToken,
      HttpRequestContentTypeEnum.JSON,
    );

    return data.users;
  } catch (error) {
    throw error;
  }
};
