import {
  CreateCachedAudioMessageArgs,
  MessageType,
  OneToOneRawAudioMessage,
} from "@/store/chats/types";
import { getCommonCachedMessageProperties } from "./getCommonCachedMessageProperties";

export const createCachedOneToOneAudioMessage = ({
  attachments,
  senderId,
  chatId,
}: CreateCachedAudioMessageArgs): OneToOneRawAudioMessage => {
  return {
    ...getCommonCachedMessageProperties({ chatId, senderId, attachments }),
    read: false,
    hide: !attachments.length,
    messageType: MessageType.Audio,
  };
};
