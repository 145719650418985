import { Profile } from "@/store/auth/state";
import { HttpRequestMethodEnum } from "@/types/api";
import { isStandaloneAppEnvironmentContainer } from "@/utils/app";
import { request } from "../api";
import { config } from "@/config";

export const searchUsersByNameCallbackAsync = async (
  searchUsername: string,
  accessToken: string,
  setUsers: any,
) => {
  if (!searchUsername) {
    setUsers([]);
    return;
  }

  if (!isStandaloneAppEnvironmentContainer && searchUsername.length <= 2) {
    return;
  }
  const query = await request(
    config.api.maitrejaApi.endpoints.searchUserByName,
    HttpRequestMethodEnum.POST,
    { search: searchUsername },
    accessToken,
  );

  const users: Profile[] = query?.users || [];
  setUsers(users);
};
