import i18n from "@/i18n";
import { ChatBlockingStatusEnum } from "@/store/chats/types";

interface IGetChatBlockingStringsArgs {
  isDeleted: boolean;
  blockingStatus: ChatBlockingStatusEnum;
  partnerName: string;
}

export const getChatBlockingStrings = ({
  isDeleted,
  blockingStatus,
  partnerName,
}: IGetChatBlockingStringsArgs): string[] => {
  const { t } = i18n.global;
  let strArray: string[] = [];

  if (isDeleted) {
    strArray.push(t("chat.blockingStatus.youCannotContinue"));
    strArray.push(`${t("chat.user")} ${t("chat.hasDeletedAccount")}.`);
    return strArray;
  }

  switch (blockingStatus) {
    case ChatBlockingStatusEnum.Them:
      strArray.push(
        `${t("chat.user")} ${partnerName} ${t(
          "chat.blockingStatus.hasBlockedYou",
        )}.`,
      );
      break;
    case ChatBlockingStatusEnum.Me:
      strArray.push(
        `${t("chat.blockingStatus.youHaveBlockedUser")} ${partnerName}.`,
      );
      break;
  }
  return strArray;
};
