import { getUrlFromFile } from "./getUrlFromFile";

export const getVideoFileDuration = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    const videoPlayer = document.createElement("video");
    videoPlayer.setAttribute("src", getUrlFromFile(file));
    videoPlayer.load();
    videoPlayer.addEventListener("error", (error) => {
      reject(`error when loading video file ${error}`);
    });
    videoPlayer.addEventListener("loadedmetadata", () => {
      const duration = Math.floor(videoPlayer.duration);
      resolve(duration);
    });
  });
};
