import { Profile } from "@/store/auth/state";
import {
  RawChat,
  GroupRawChat,
  RawAudioMessage,
  AudioMessage,
  OneToOneRawChat,
} from "@/store/chats/types";
import { User } from "@/store/users/state";
import { convertRawOneToOneAudioMessage } from "./convertRawOneToOneAudioMessage";
import { getIsOneToOneRawAudioMessage } from "./getIsOneToOneRawAudioMessage";
import { convertRawGroupAudioMessage } from "./convertRawGroupAudioMessage";

interface ConvertRawAudioMessageArgs {
  rawMessage: RawAudioMessage;
  rawChat: RawChat;
  auth: Profile;
  users?: User[];
  ignoreReplies: boolean;
}

export const convertRawAudioMessage = ({
  rawMessage,
  rawChat,
  auth,
  users,
  ignoreReplies,
}: ConvertRawAudioMessageArgs): AudioMessage => {
  if (getIsOneToOneRawAudioMessage(rawMessage, rawChat.id)) {
    const oneToOneRawChat = rawChat as OneToOneRawChat;
    return convertRawOneToOneAudioMessage({
      rawMessage,
      auth,
      users,
      rawChat: oneToOneRawChat,
ignoreReplies
    });
  }

  const groupChat = rawChat as GroupRawChat;

  return convertRawGroupAudioMessage({
    rawMessage,
    auth,
    users,
    rawChat: groupChat,
    ignoreReplies,
  });
};
