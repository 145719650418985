import store from "@/store";
import { getAccessTokenAsync } from "./getAccessTokenAsync";
import { AuthMutationTypes } from "@/store/auth";

export const renewAccessTokenAsync = async () => {
  const refreshToken = store.getters.GET_AUTH_REFRESH_TOKEN as string;
  let accessToken = store.getters.GET_AUTH_ACCESS_TOKEN as string;
  try {
    const authData = await getAccessTokenAsync(refreshToken);
    accessToken = authData.access_token;
    store.commit(AuthMutationTypes.SET_AUTH_ACCESS_TOKEN, accessToken);
  } catch (error) {}
  return accessToken;
};
