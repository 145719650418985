import { Track } from "twilio-video";
import { getIsAudioOrVideoTrack } from "./getIsAudioOrVideoTrack";
import { detachTrack } from "./detachTrack";

export const handleTrackUnsubscribed = (track: Track) => {
  if (!getIsAudioOrVideoTrack(track)) {
    return;
  }
  detachTrack(track);
};
