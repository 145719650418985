import { createMessageId } from "@/firebase/utils";
import firebase from "@/firebase/firebase";
import { Attachment } from "@/store/chats/types";

interface GetCommonCachedMessageProperties {
  chatId: string;
  senderId: number;
  attachments?: Attachment[];
}

export const getCommonCachedMessageProperties = ({
  chatId,
  senderId,
  attachments,
}: GetCommonCachedMessageProperties) => {
  return {
    id: createMessageId(chatId, senderId),
    sentAt: firebase.firestore.Timestamp.fromMillis(Date.now()),
    senderId,
    attachments,
    chatId,
  };
};
