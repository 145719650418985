<template>
  <div
    v-if="
      ringingCalls.length &&
      !isAppEnvironmentContainer &&
      layoutType === LayoutTypes.DESKTOP
    "
    class="ringingCallsContainer"
  >
    <RingingCallBanner
      v-for="call in ringingCalls"
      :key="call.chatId"
      v-bind="call"
    />
  </div>
</template>

<script setup lang="ts">
import { GetterTypes } from "@/store";
import { useComputedValue } from "@/composables";
import { FirestoreUserCall } from "@/store/auth/types";
import RingingCallBanner from "../RingingCallBanner/RingingCallBanner.vue";
import { isAppEnvironmentContainer } from "@/utils/app";
import { LayoutTypes } from "@/store/app/state";
const ringingCalls = useComputedValue<FirestoreUserCall[]>(
  GetterTypes.GET_RINGING_CALLS,
);
const layoutType = useComputedValue<LayoutTypes>(GetterTypes.GET_LAYOUT_TYPE);
</script>

<style lang="scss" scoped>
@import "./RingingCallsContainer.scss";
</style>
