import { HttpRequestMethodEnum } from "@/types/api";
import { TenorResultsObject } from "@/types/gifs";
import axios, { AxiosRequestConfig } from "axios";

export const tenorApiAxios = axios.create({
  baseURL: process.env.VUE_APP_TENOR_API_URL,
});

export const fetchTenorApi = async (
  path: string,
  params?: Record<string, string>,
  config?: AxiosRequestConfig<any>,
) => {
  return await tenorApiAxios<TenorResultsObject>(path, {
    method: HttpRequestMethodEnum.GET,
    ...config,
    params: { key: process.env.VUE_APP_TENOR_API_KEY, ...params },
  });
};
