import videojs from "video.js";
import Player from "video.js/dist/types/player";
import { onUnmounted, reactive, ref } from "vue";

interface GetVideoJsPlayerPropertiesProps {
  posterSrc?: string;
  hlsSrc: string;
  options?: Player["options"];
  elementId: string;
}

interface Data {
  elapsedTime: number;
}

export const useVideoJsPlayerProperties = () => {
  const data = reactive<Data>({ elapsedTime: 0 });
  const videoRef = ref<HTMLDivElement>();
  const playerRef = ref<Player | null>(null);

  const initializePlayer = ({
    posterSrc,
    hlsSrc,
    elementId,
    options,
  }: GetVideoJsPlayerPropertiesProps) => {
    const canBeInitialized = !playerRef.value && videoRef.value;
    if (!canBeInitialized) {
      return;
    }
    playerRef.value = videojs(elementId, {
      controls: true,
      fluid: true,
      sources: [{ src: hlsSrc, type: "application/x-mpegURL" }],
      poster: posterSrc,
      muted: false,
      playsinline: true,
      ...options,
    });
    playerRef.value.on("timeupdate", handleTimeUpdate);
  };

  onUnmounted(() => {
    const player = playerRef.value;
    if (player && !player.isDisposed()) {
      player.dispose();
      player.off("timeupdate", handleTimeUpdate);
      playerRef.value = null;
    }
  });

  const handleTimeUpdate = () => {
    const player = playerRef.value;
    if (!player) {
      return;
    }

    const time = Math.floor(player.currentTime() ?? 0);
    if (time === data.elapsedTime) {
      return;
    }
    data.elapsedTime = time;
  };

  const handleClick = () => {
    const player = playerRef.value;

    if (!player) {
      return;
    }

    if (!player.paused()) {
      player.pause();
      return;
    }
    player.play();
  };

  return {
    elapsedTime: data.elapsedTime,
    playerRef,
    videoRef,
    handleClick,
    initializePlayer,
  };
};
