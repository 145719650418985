<template>
  <ChatSettingsDetails v-bind="unblockUserProperties" />
</template>
<script setup lang="ts">
import { useGetChatSettingsUnblockUserProperties } from "./useGetChatSettingsUnblockUserProperties";
import ChatSettingsDetails from "@/components/chat/chatSettings/ChatSettingsDetails/ChatSettingsDetails.vue";

const { unblockUserProperties } = useGetChatSettingsUnblockUserProperties();
</script>
<style lang="scss" scoped></style>
