<template>
  <OnboardingContainer v-bind="notificationOnboardingProperties" />
</template>
<script setup lang="ts">
import OnboardingContainer from "@/components/onboarding/OnboardingContainer/OnboardingContainer.vue";
import { useGetOnboardingNotificationsProperties } from "./useGetOnboardingNotificationsProperties";

const { notificationOnboardingProperties } =
  useGetOnboardingNotificationsProperties();
</script>
<style lang="scss" scoped></style>
