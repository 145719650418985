<template>
  <PopupInfo
    title="popup.PopupInfoDeleteChat.title"
    label="popup.PopupInfoDeleteChat.label"
    btn-text="popup.PopupInfoDeleteChat.btnText"
    :action="clickAction"
  />
</template>

<script>
import PopupInfo from "@/components/popup/PopupInfo.vue";
import { ActionTypes, GetterTypes, MutationTypes, store } from "@/store";
import { mapGetters } from "vuex";

export default {
  components: { PopupInfo },
  computed: {
    ...mapGetters({
      authId: GetterTypes.GET_AUTH_ID,
      selectedChatId: GetterTypes.GET_SELECTED_CHAT_ID,
    }),
  },
  methods: {
    clickAction() {
      const chatId = this.selectedChatId ?? this.$route.params.chat_id;
      store.dispatch(ActionTypes.DELETE_CHAT, { chatId });
      store.commit(MutationTypes.HIDE_POPUP);
      this.$router.push("/");
    },
  },
};
</script>
