import firebase from "@/firebase/firebase";
import store, { ActionTypes } from "@/store";

export const getChatUnsubscribeSnapshotCallback = (chatId: string) => {
  return async (
    snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
  ) => {
    store.dispatch(ActionTypes.UPDATE_CHAT_DATA, { chatId, snapshot });
  };
};
