<template>
  <span
    v-if="message.isMine && selectedMessageIds"
    class="checkbox-wrapper hover"
  >
    <Icon
      :icon="
        selectedMessageIds.includes(message.id)
          ? IconType.CHECKBOX_ON
          : IconType.CHECKBOX_OFF
      "
      class="checkbox"
      @click="toggleSelectMessage(message.id)"
    />
  </span>
</template>

<script>
import { GetterTypes, MutationTypes, store } from "@/store";
import { mapGetters } from "vuex";
import { IconType } from "@/types/icons";
import Icon from "../icons/Icon/Icon.vue";

export default {
  components: { Icon },
  props: {
    message: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      IconType,
    };
  },
  computed: {
    ...mapGetters({
      authId: GetterTypes.GET_AUTH_ID,
      selectedMessageIds: GetterTypes.GET_SELECTED_MESSAGE_IDS,
    }),
  },
  methods: {
    toggleSelectMessage(messageId) {
      store.commit(MutationTypes.TOGGLE_SELECTED_MESSAGE_ID, { messageId });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/checkbox/selectMessageCheckbox.scss";
</style>
