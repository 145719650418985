import { ErrorHandlerSnackbarRequestObject } from "@/store/app/state";

export const loginEmailSnackbarErrorObject: ErrorHandlerSnackbarRequestObject =
  {
    "401": {
      title: "snackbar.errors.wrongCredentials.title",
      text: "snackbar.errors.wrongCredentials.text",
    },
    "406": {
      title: "snackbar.errors.accountWasClosedLogin.title",
      text: "snackbar.errors.accountWasClosedLogin.text",
      timeoutSeconds: 10,
    },
  };
