import { GetterTypes } from "@/store";
import { RoomData } from "@/types/call";
import { shallowReactive, onMounted, onUnmounted, watch } from "vue";
import { useComputedValue } from "./useComputedValue";
import { removeRoom, toggleCamera, toggleMicrophone } from "@/utils/call";
import _ from "lodash";
import { setInitialRoomData } from "@/utils/call/setInitialRoomData";

export const useGetCallRoomProperties = () => {
  const data = shallowReactive<RoomData>({
    room: null,
    localParticipant: null,
    dominantParticipant: null,
    participants: [],
  });

  const hasVideo = useComputedValue<boolean>(
    GetterTypes.GET_ONGOING_CALL_HAS_VIDEO,
  );
  const hasAudio = useComputedValue<boolean>(
    GetterTypes.GET_ONGOING_CALL_HAS_AUDIO,
  );
  const currentCallChatId = useComputedValue<string | null>(
    GetterTypes.GET_CURRENT_ONGOING_CALL_ID,
  );

  watch(hasVideo, () => {
    if (!data.room) {
      return;
    }
    toggleCamera(data.room.localParticipant, hasVideo.value);
  });
  watch(hasAudio, () => {
    if (!data.room) {
      return;
    }
    toggleMicrophone(data.room.localParticipant, hasAudio.value);
  });
  watch(currentCallChatId, () => {
    removeRoom(data);
    setInitialRoomData(data);
  });

  onMounted(() => {
    setInitialRoomData(data);
  });

  onUnmounted(() => {
    removeRoom(data);
    window.location.reload();
  });

  return { data };
};
