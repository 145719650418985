import i18n from "@/i18n";
import { handleSendContainerEmail } from "../api";
import { getReportErrorMessageBody } from "./getReportErrorMessageBody";

export const handleSendReportErrorEmail = () => {
  const subject = i18n.global.t("settings.reportErrorContent.messageFromApp");
  const messageBody = getReportErrorMessageBody();

  handleSendContainerEmail("support@mait.me", subject, messageBody);
};
