<template>
  <div></div>
</template>

<script>
import { store } from "@/store";

export default {};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/cards/optionsCard.scss";
</style>
