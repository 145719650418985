import { InfiniteScrollData, InfiniteScrollDirection } from "@/types/app";
import { nextTick, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useComputedValue } from "./useComputedValue";
import { GetterTypes } from "@/store";

const defaultData: InfiniteScrollData = {
  isLoadingMore: false,
  canLoadMore: true,
  listEndObserver: null,
  previousScrollHeightMinusScrollTop: 0,
};

export const useInfiniteScroll = (
  callbackFn: () => Promise<void>,
  direction: InfiniteScrollDirection = InfiniteScrollDirection.Up,
) => {
  const scrollContainerRef = ref<HTMLElement>();
  const sentinelRef = ref<HTMLElement>();
  const data = reactive<InfiniteScrollData>({ ...defaultData });
  const initialLoad = useComputedValue(GetterTypes.GET_CHATS_INITIAL_LOAD);

  onMounted(() => {
    handleInitialLoad();
  });

  watch(initialLoad, () => {
    handleInitialLoad();
  });
  const restoreDefaults = () => {
    Object.assign(data, { ...defaultData });
  };

  const handleInitialLoad = async () => {
    if (!initialLoad.value) {
      return;
    }
    restoreDefaults();
    await callbackFn();
    nextTick(() => {
      if (!scrollContainerRef.value) {
        return;
      }

      scrollContainerRef.value.scrollTop =
        direction === InfiniteScrollDirection.Up
          ? scrollContainerRef.value.scrollHeight
          : 0;

      nextTick(() => {
        initializeIntersectionObserver();
      });
    });
  };

  onUnmounted(() => {
    if (data.listEndObserver) {
      data.listEndObserver.disconnect();
    }
  });

  const recordScrollPosition = () => {
    if (!scrollContainerRef.value) {
      return;
    }

    if (direction === InfiniteScrollDirection.Down) {
      return;
    }

    data.previousScrollHeightMinusScrollTop =
      scrollContainerRef.value.scrollHeight -
      scrollContainerRef.value.scrollTop;
  };

  const restoreScrollPosition = () => {
    if (!scrollContainerRef.value) {
      return;
    }
    if (direction === InfiniteScrollDirection.Down) {
      return;
    }

    scrollContainerRef.value.scrollTop =
      scrollContainerRef.value.scrollHeight -
      data.previousScrollHeightMinusScrollTop;
  };

  const initializeIntersectionObserver = () => {
    const options =
      direction === InfiniteScrollDirection.Up
        ? {
            root: scrollContainerRef.value,
            rootMargin: "10px 0px 0px 0px",
          }
        : {
            root: scrollContainerRef.value,
          };
    data.listEndObserver = new IntersectionObserver(
      handleIntersectionObserverCallback,
      options,
    );

    data.listEndObserver.observe(sentinelRef.value as HTMLElement);
  };

  const handleIntersectionObserverCallback = ([
    entry,
  ]: IntersectionObserverEntry[]) => {
    if (entry.isIntersecting && data.canLoadMore && !data.isLoadingMore) {
      handleLoadMore();
    }
  };
  const handleLoadMore = async () => {
    try {
      data.isLoadingMore = true;
      recordScrollPosition();
      await callbackFn();
      data.isLoadingMore = false;
      nextTick(() => {
        restoreScrollPosition();
      });
    } catch (error) {
      data.canLoadMore = false;
      data.isLoadingMore = false;
    }
  };

  return { scrollContainerRef, sentinelRef, data };
};
