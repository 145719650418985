import { HttpRequestMethodEnum } from "@/types/api";
import { request } from "../api";
import { config } from "@/config";
import { IUpdateFriendRequestData } from "@/types/auth";

interface UpdateFriendRequestArgs extends IUpdateFriendRequestData {
  accessToken: string;
}

export const updateFriendRequest = async ({
  accessToken,
  ...data
}: UpdateFriendRequestArgs) => {
  try {
    const query = await request(
      config.api.maitrejaApi.endpoints.updateFriendRequest,
      HttpRequestMethodEnum.POST,
      data,
      accessToken,
    );

    return query;
  } catch (err) {
    // @ts-expect-error
    console.log(err.message);
  }
};
