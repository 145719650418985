import { useComputedValue } from "@/composables";
import { GetterTypes } from "@/store";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export const useGetChatSettingsCommonProperties = () => {
  const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);
  const selectedChatId = useComputedValue<string | undefined>(
    GetterTypes.GET_SELECTED_CHAT_ID,
  );
  const route = useRoute();
  const router = useRouter();
  const { dispatch, getters } = useStore();

  return { authId, selectedChatId, route, router, dispatch, getters };
};
