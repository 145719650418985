import { Message, TextMessage } from "@/store/chats/types";

const TWELVE_HOURS = 12 * 60 * 60 * 1000;

export const showMessageDate = (
  messages: Message[],
  currentMessageIndex: number,
): boolean => {
  if (currentMessageIndex < 1 || messages.length === 1) return true;
  if (currentMessageIndex > messages.length - 1) return false;

  const previousMessageSentAt = messages[currentMessageIndex - 1]?.sentAt;
  const currentMessageSentAt = messages[currentMessageIndex]?.sentAt;

  const timeDiff = currentMessageSentAt - previousMessageSentAt;

  if (timeDiff > TWELVE_HOURS) return true;

  return false;
};
