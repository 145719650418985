import { convertFirebaseDateToLocal } from "@/firebase/utils";
import { Profile } from "@/store/auth/state";
import { OneToOneRawChat, Chat, Message } from "@/store/chats/types";
import { User } from "@/store/users/state";
import { createChatNameFromMembers } from "./createChatNameFromMembers";
import { getChatTypeFromId } from "./getChatTypeFromId";
import i18n from "@/i18n";
import { CallType } from "@/types/webview";
import { IconType } from "@/types/icons";
import { getIconPath } from "../app/getIconPath";

export const convertRawOneToOneChat = (
  rawChat: OneToOneRawChat,
  auth: Profile,
  partner: User,
  latestMessage?: Message,
): Chat => {
  const members = [auth, partner] as User[];
  const nameFromMembers = createChatNameFromMembers(members, auth.id);
  let chatName = i18n.global.t("user.anonymousUser");
  if (partner?.name) {
    chatName = partner?.name;
  }
  if (partner?.isDeleted) {
    chatName = i18n.global.t("user.deletedUser");
  }

  const chat: Chat = {
    type: getChatTypeFromId(rawChat.id),
    id: rawChat.id,
    name: chatName,
    hasName: !!partner?.name,
    nameFromMembers,
    picture: partner?.picture ?? getIconPath(IconType.DEFAULT_SINGLE_GRAY),
    createdAt: convertFirebaseDateToLocal(rawChat?.createdAt),
    modifiedAt: convertFirebaseDateToLocal(rawChat?.createdAt),
    members,
    membersIds: [auth.id, partner.id],
    hidden: Boolean(rawChat?.hide),
    partner,
    latestMessage,
    blockingStatus: partner.blockingStatus,
    isDeleted: partner.isDeleted,
    activeCall: rawChat.activeCall,
    pendingCallType: rawChat.pendingCallType ?? CallType.Video,
  };

  return chat;
};
