<template>
  <ChatTextMessage
    v-if="getIsMessageTextType(message)"
    :index="index"
    :message="message"
    :visible-messages="visibleMessages"
    @reply-to-message="replyToMessage"
  />
  <ChatCallMessage
    v-else-if="getIsMessageCallType(message)"
    :index="index"
    :message="message"
    :visible-messages="visibleMessages"
  />
  <ChatAudioMessage
    v-else-if="getIsMessageAudioType(message)"
    :index="index"
    :message="message"
    :visible-messages="visibleMessages"
    @reply-to-message="replyToMessage"
  />
  <ChatFriendRequestMessage
    v-else
    :index="index"
    :message="message"
    :visible-messages="visibleMessages"
  />
</template>

<script setup lang="ts">
import ChatTextMessage from "@/components/chat/chatContainer/ChatTextMessage/ChatTextMessage.vue";
import ChatCallMessage from "@/components/chat/chatContainer/ChatCallMessage/ChatCallMessage.vue";
import {
  getIsMessageTextType,
  getIsMessageCallType,
  getIsMessageAudioType,
} from "@/utils/message";
import { Message } from "@/store/chats/types";
import ChatAudioMessage from "../ChatAudioMessage/ChatAudioMessage.vue";
import ChatFriendRequestMessage from "../ChatFriendRequestMessage/ChatFriendRequestMessage.vue";

interface ChatMessageProps {
  message: Message;
  index: number;
  visibleMessages: Message[];
}

interface Emits {
  (e: "replyToMessage", message: Message): void;
}

defineProps<ChatMessageProps>();
const emit = defineEmits<Emits>();

const replyToMessage = (message: Message) => {
  emit("replyToMessage", message);
};
</script>
