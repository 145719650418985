import { RoomData } from "@/types/call";
import { RemoteParticipant } from "twilio-video";
import { ShallowReactive } from "vue";
import { getFilteredParticipants } from "./getFilteredParticipants";

export const handleParticipantDisconnected = (
  participant: RemoteParticipant,
  data: ShallowReactive<RoomData>,
) => {
  const firstParticipant = data.participants.find(
    (part) => part.participant?.identity !== participant.identity,
  );
  const isDominant =
    data.dominantParticipant?.participant?.identity === participant.identity;
  if (isDominant && firstParticipant?.participant) {
    data.dominantParticipant = firstParticipant;
    data.participants = getFilteredParticipants(
      data.participants,
      firstParticipant.participant,
    );
    return;
  }
  data.participants = getFilteredParticipants(data.participants, participant);
  // stop listening for this participant
  participant.removeAllListeners();
};
