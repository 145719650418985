import { GetterTree } from "vuex";

import { RootState } from "@/store/state";
import { MenuState } from "./state";
import { getMenuData } from "../../data/menu";

export enum MenuGetterTypes {
  GET_URL = "GET_URL",
  GET_MENU_DATA = "GET_MENU_DATA",
  GET_OPEN_DROPDOWN_CREATE = "GET_OPEN_DROPDOWN_CREATE",
  GET_OPEN_DROPDOWN_OPTIONS = "GET_OPEN_DROPDOWN_OPTIONS",
}

export interface MenuGetters {
  [MenuGetterTypes.GET_URL](state: MenuState): string;
  [MenuGetterTypes.GET_MENU_DATA](state: MenuState): any;
  [MenuGetterTypes.GET_OPEN_DROPDOWN_CREATE](state: MenuState): boolean;
  [MenuGetterTypes.GET_OPEN_DROPDOWN_OPTIONS](state: MenuState): boolean;
}

export const menuGetters: GetterTree<MenuState, RootState> & MenuGetters = {
  [MenuGetterTypes.GET_URL]: (state) => state.url,
  [MenuGetterTypes.GET_MENU_DATA]: (state) =>
    getMenuData(state.lang, state.sign),
  [MenuGetterTypes.GET_OPEN_DROPDOWN_CREATE]: (state) =>
    state.openDropdown.create,
  [MenuGetterTypes.GET_OPEN_DROPDOWN_OPTIONS]: (state) =>
    state.openDropdown.options,
};
