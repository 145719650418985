import { createI18n } from "vue-i18n";
import { loadLocaleMessages } from "@/utils/api/loadLocaleMessages";
import { getCurrentLanguage } from "@/utils/api";
import { config } from "./config";

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: getCurrentLanguage(),
  fallbackLocale: config.constants.DEFAULT_LANG,
  messages: loadLocaleMessages(),
});

export default i18n;
