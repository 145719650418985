<template>
  <OnboardingContainer v-bind="deleteAccountProperties" />
</template>
<script setup lang="ts">
import OnboardingContainer from "@/components/onboarding/OnboardingContainer/OnboardingContainer.vue";
import { useGetSettingsDeleteAccountProperties } from "./useGetSettingsDeleteAccountProperties";

const { deleteAccountProperties } = useGetSettingsDeleteAccountProperties();
</script>
<style lang="scss" scoped></style>
