import { HttpRequestMethodEnum } from "@/types/api";
import { request } from "../api";
import { config } from "@/config";

export const searchWebsiteCallbackAsync = async (
  search: string,
  accessToken: string,
  setResults: any,
) => {
  if (!(search?.length > 2)) return;

  const query = await request(
    config.api.maitrejaApi.endpoints.searchUserByName,
    HttpRequestMethodEnum.POST,
    { search },
    accessToken,
  );

  const results = query?.results || [];
  setResults(results);
};
