import {
  CreateCachedTextMessageArgs,
  MessageType,
  OneToOneRawTextMessage,
} from "@/store/chats/types";
import { getIsMessageHidden } from ".";
import { getCommonCachedMessageProperties } from "./getCommonCachedMessageProperties";

export const createCachedOneToOneTextMessage = ({
  chatId,
  senderId,
  attachments,
  messageText,
}: CreateCachedTextMessageArgs): OneToOneRawTextMessage => {
  return {
    ...getCommonCachedMessageProperties({ chatId, senderId, attachments }),
    read: false,
    messageText,
    hide: getIsMessageHidden(messageText, attachments),
    messageType: MessageType.Text,
  };
};
