<template>
  <OnboardingContainer v-bind="transferContactsProperties" />
</template>
<script setup lang="ts">
import OnboardingContainer from "@/components/onboarding/OnboardingContainer/OnboardingContainer.vue";
import { useGetOnboardingTransferContactsProperties } from "./useGetOnboardingTransferContactsProperties";

const { transferContactsProperties } =
  useGetOnboardingTransferContactsProperties();
</script>
<style lang="scss" scoped></style>
