<template>
  <div class="attachment-other" :class="getIsMyMessageClass(message, authId)">
    <div class="right">
      <Loader
        v-if="attachment?.uploadProgress !== undefined && !attachment?.uploaded"
        :progress="attachment.uploadProgress"
      />
      <div v-else class="img-container">
        <Icon :icon="IconType.FILE" />
      </div>
    </div>
    <div class="left">
      <a class="link" target="_blank" :href="attachment?.url">{{
        shortenedFileName || t("chat.attachment")
      }}</a>
      <span class="size">{{ getFileSize(attachment?.size) }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import Loader from "@/components/chat/chatContainer/Loader.vue";
import { getFileSize } from "@/utils/attachment";
import { getShortenedString } from "@/utils/modifiers";
import { Attachment, Message } from "@/store/chats/types";

import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { getIsMyMessageClass } from "@/utils/chat";
import { useComputedValue } from "@/composables";
import { GetterTypes } from "@/store";
import Icon from "@/components/icons/Icon/Icon.vue";
import { IconType } from "@/types/icons";

interface Props {
  attachment: Attachment;
  message: Message;
}

const props = defineProps<Props>();
const { t } = useI18n();

const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);
const shortenedFileName = computed<string>(() => {
  return getShortenedString(props.attachment.filename, 20);
});
</script>
<style lang="scss" scoped>
@import "./OtherAttachment.scss";
</style>
