import { MutationTypes } from "@/store";
import { LayoutBackgroundColorTypes } from "@/store/app/state";
import { ICountryCodeItem } from "@/types/countryCodes";
import { getTranslatedCountryCodesArray } from "@/utils/form";
import { reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";
import {
  CountryCodeSelectData,
  CountryCodeSelectEmits,
} from "./CountryCodeSelectTypes";

export const useGetCountryCodeSelectProperties = (
  emit: CountryCodeSelectEmits,
) => {
  const translatedSortedCountryCodes = getTranslatedCountryCodesArray();
  const { commit } = useStore();
  const data = reactive<CountryCodeSelectData>({
    isPopupOpen: false,
    selectedCountryCode: "420",
    filteredCountryCodesArray: translatedSortedCountryCodes,
  });
  const fullCountryCode = computed(() => `+${data.selectedCountryCode}`);

  const toggleDropdown = () => {
    data.isPopupOpen = !data.isPopupOpen;
    data.filteredCountryCodesArray = translatedSortedCountryCodes;
    let resultColorTop = LayoutBackgroundColorTypes.WHITE;
    if (data.isPopupOpen) {
      resultColorTop = LayoutBackgroundColorTypes.BLACK_NON_TRANSPARENT;
    }

    commit(MutationTypes.SET_LAYOUT_BACKGROUND_COLOR_TOP, resultColorTop);
    emit("togglePopup", data.isPopupOpen);
  };
  const handleUpdateFilter = (filter: string) => {
    data.filteredCountryCodesArray = translatedSortedCountryCodes.filter(
      ({ countryName, countryCode }) => {
        const filterLowerCase = filter.toLowerCase();

        return (
          countryName.toLowerCase().includes(filterLowerCase) ||
          countryCode.toLowerCase().includes(filterLowerCase)
        );
      },
    );
  };

  onMounted(() => {
    handleEmitUpdateCountryCode();
  });

  const handleEmitUpdateCountryCode = () => {
    emit("updateCountryCode", fullCountryCode.value);
  };

  const handleClick = (item: ICountryCodeItem) => {
    data.selectedCountryCode = item.countryCode;
    handleEmitUpdateCountryCode();
    toggleDropdown();
  };

  return { data, toggleDropdown, handleUpdateFilter, handleClick };
};
