import { Attachment, AttachmentTypes } from "@/store/chats/types";

export const getImageAttachments = (
  attachments: Attachment[] | undefined,
): Attachment[] | undefined => {
  if (!attachments) return undefined;
  const imageAttachments = attachments.filter(
    (att) => att.type === AttachmentTypes.IMAGE,
  );
  if (imageAttachments.length === 0) return undefined;
  return imageAttachments;
};
