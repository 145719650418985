import { MenuMutationType } from "./mutationState";
import { MenuMutationTypes } from "./mutationTypes";

export const menuMutations: MenuMutationType = {
  [MenuMutationTypes.UPDATE_URL](state, url) {
    if (state.url === url) return;
    state.url = url;
  },
  [MenuMutationTypes.SET_OPEN_DROPDOWN_CREATE](state, open) {
    state.openDropdown.create = open;
  },
  [MenuMutationTypes.SET_OPEN_DROPDOWN_OPTIONS](state, open) {
    state.openDropdown.options = open;
  },
};
