import { IconType } from "@/types/icons";
import { ListItemTextColorTypeEnum } from "@/types/list";

export const getIconName = (
  textColorType?: ListItemTextColorTypeEnum,
): IconType => {
  let name = "FORWARD";
  if (textColorType && textColorType !== ListItemTextColorTypeEnum.BLACK) {
    name += `_${textColorType}`;
  }
  return IconType[name as keyof typeof IconType];
};
