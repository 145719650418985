import appComponents from "./appComponents";
import { createApp } from "vue";
import appUse from "./appUse";
import App from "./App.vue";
import * as Sentry from "@sentry/vue";

const app = createApp(App);

appUse(app);
appComponents(app);

if (process.env.VUE_APP_APP_ENVIRONMENT !== "local") {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN_URL,
    environment: process.env.VUE_APP_APP_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: [
      // Error generated by a bug in auto-fill library from browser
      // https://github.com/getsentry/sentry/issues/5267
      /Blocked a frame with origin/,
    ],
  });
}

app.mount("#app");
