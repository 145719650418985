import { ActionTypes } from "@/store";
import { ButtonObject } from "@/types/button";
import { ChatSettingsScreenEnum } from "@/types/chat";
import { computed } from "vue";
import { ChatSettingsDetailsPropsObject } from "../ChatSettingsDetails";
import { useGetChatSettingsCommonProperties } from "../useGetChatSettingsCommonProperties";
import { deleteChatWarningPoints } from "./deleteChatWarningPoints";

export const useGetChatSettingsDeleteChatProperties = () => {
  const { selectedChatId, route, router, dispatch } =
    useGetChatSettingsCommonProperties();
  const deleteChatProperties = computed<ChatSettingsDetailsPropsObject>(() => {
    return {
      type: ChatSettingsScreenEnum.DeleteChat,
      primaryButton: primaryButton.value,
      imageName: "deleteChat.svg",
      warningPoints: deleteChatWarningPoints,
    };
  });
  const primaryButton = computed<ButtonObject>(() => {
    return {
      handleClick: handlePrimaryButtonClick,
    };
  });

  const handlePrimaryButtonClick = async () => {
    const chatId = selectedChatId.value ?? (route.params.chat_id as string);
    dispatch(ActionTypes.DELETE_CHAT, { chatId });
    router.push("/");
  };

  return { deleteChatProperties };
};
