<template>
  <div :id="id" class="reactions-modal">
    <div v-click-outside="closeModal" class="reactions-modal-inner">
      <div class="reactions-modal-content">
        <div class="reactions-modal-top">
          <div />
          <span class="reactions-modal-title">{{
            $t("chat.chatContainer.messages.reactions.reactions")
          }}</span>
          <div class="reactions-modal-close" @click="closeModal">
            <Icon :icon="IconType.CROSS_GREY_CIRCLE" />
          </div>
        </div>
        <div class="reactions-modal-list">
          <MessageReactionsModalItem
            v-for="item in reactionsArray"
            :key="item.user.id"
            v-bind="item"
            :message-id="message.id"
            @close-modal="closeModal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AudioMessage, TextMessage } from "@/store/chats/types";
import { useComputedValue } from "@/composables";
import { GetterTypes } from "@/store";
import { computed } from "vue";
import { IconType } from "@/types/icons";
import Icon from "@/components/icons/Icon/Icon.vue";
import { findUserById } from "@/utils/users";
import { Profile } from "@/store/auth/state";
import { ReactionsItem } from "@/types/chat";
import MessageReactionsModalItem from "../MessageReactionsModalItem/MessageReactionsModalItem.vue";

interface ChatMessageReactionsModalProps {
  message: TextMessage | AudioMessage;
  id: string;
}

interface Emits {
  (e: "closeReactionsModal"): void;
}

const emit = defineEmits<Emits>();

const props = defineProps<ChatMessageReactionsModalProps>();
const profile = useComputedValue<Profile>(GetterTypes.GET_AUTH_PROFILE);

const reactionsArray = computed(() => {
  const items = props.message.reactions?.items;
  if (!items) {
    return [];
  }
  return Object.entries(items)
    .map(([userId, emoji]) => {
      const numericalUserId = Number(userId);
      const isMine = numericalUserId === profile.value.id;
      const user = isMine ? profile.value : findUserById(Number(userId));
      if (!user) {
        return;
      }
      return {
        user: { id: numericalUserId, name: user.name, picture: user.picture },
        emoji,
        isMine: isMine,
      };
    })
    .filter(Boolean) as ReactionsItem[];
});

const closeModal = () => {
  emit("closeReactionsModal");
};
</script>

<style lang="scss">
@import "./ChatMessageReactionsModal.scss";
</style>
