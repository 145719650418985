import { getUsersByPhoneNumberAsync } from "@/services/maitrejaApi";
import store, { MutationTypes } from "@/store";
import { PhoneContact } from "@/store/users/state";
import { getPhoneContactPhoneNumberProperties } from "./getPhoneContactPhoneNumberProperties";
import { getPhoneContactRequestItemObjectsArray } from "./getPhoneContactRequestItemObjectsArray";
import { getUpdatePhoneContactsProperties } from "./getUpdatePhoneContactsProperties";
import {
  IPhoneContactRequestItemObject,
  IUserPhoneContactUserResponseObject,
} from "@/types/users";

export const handleUpdatePhoneContacts = async () => {
  const accessToken = store.getters.GET_AUTH_ACCESS_TOKEN;
  if (!accessToken) {
    return;
  }
  const phoneContacts: PhoneContact[] = store.getters.GET_PHONE_CONTACTS;
  const regionCode: string = store.getters.GET_APP_DETAILS_REGION_CODE;
  const profile = store.getters.GET_AUTH_PROFILE;
  const filteredPhoneContacts = phoneContacts.filter(({ phone }) => {
    const { countryCode, phoneNumber } = getPhoneContactPhoneNumberProperties(
      phone,
      regionCode,
    );
    const isMe =
      countryCode === profile?.countryCode &&
      phoneNumber === profile?.phoneNumber;

    return !isMe;
  });
  const contactsRequestItemsArray: IPhoneContactRequestItemObject[] =
    getPhoneContactRequestItemObjectsArray(filteredPhoneContacts, regionCode);

  let contactUsers: IUserPhoneContactUserResponseObject[] = [];
  try {
    if (contactsRequestItemsArray.length) {
      contactUsers = await getUsersByPhoneNumberAsync(
        accessToken,
        contactsRequestItemsArray,
      );
    }
  } catch (error) {
    console.log({ error });
  }

  const { updatedPhoneContactUsers, updatedFriends, updatedPhoneContacts } =
    getUpdatePhoneContactsProperties(contactUsers, filteredPhoneContacts);

  store.commit(MutationTypes.SET_PHONE_CONTACTS, updatedPhoneContacts);
  store.commit(
    MutationTypes.SET_PHONE_CONTACTS_USERS,
    updatedPhoneContactUsers,
  );
  store.commit(MutationTypes.SET_AUTH_FRIENDS, updatedFriends);
};
