import { Profile } from "@/store/auth/state";
import {
  RawTextMessage,
  RawChat,
  GroupRawChat,
  Message,
  OneToOneRawChat,
} from "@/store/chats/types";
import { convertRawGroupTextMessage } from "./convertRawGroupTextMessage";
import { convertRawOneToOneTextMessage } from "./convertRawOneToOneTextMessage";
import { getIsOneToOneRawTextMessage } from "./getIsOneToOneRawTextMessage";
import { User } from "@/store/users/state";

interface ConvertRawTextMessageArgs {
  rawMessage: RawTextMessage;
  rawChat: RawChat;
  auth: Profile;
  users?: User[];
  ignoreReplies: boolean;
}

export const convertRawTextMessage = ({
  rawMessage,
  rawChat,
  auth,
  users,
  ignoreReplies,
}: ConvertRawTextMessageArgs): Message => {
  if (getIsOneToOneRawTextMessage(rawMessage, rawChat.id)) {
    const oneToOneRawChat = rawChat as OneToOneRawChat;
    return convertRawOneToOneTextMessage({
      rawMessage,
      auth,
      users,
      rawChat: oneToOneRawChat,
      ignoreReplies,
    });
  }

  const groupChat = rawChat as GroupRawChat;
  return convertRawGroupTextMessage({
    rawMessage,
    auth,
    users,
    rawChat: groupChat,
    ignoreReplies,
  });
};
