import { RoomParticipant } from "@/types/call";
import { Participant } from "twilio-video";

export const getFilteredParticipants = (
  participants: RoomParticipant[],
  targetParticipant: Participant,
) =>
  participants.filter(
    (par) => par.participant?.identity !== targetParticipant.identity,
  );
