import { HttpRequestMethodEnum } from "@/types/api";
import { IUpdateUserLangResponseObject } from "@/types/auth";
import { LocaleTypeEnum } from "@/types/localization";
import { request } from "../api";
import { storeRefreshToken } from "./storeRefreshToken";
import { Profile } from "@/store/auth/state";
import store, { GetterTypes } from "@/store";
import { config } from "@/config";

export const getLanguageChangeUpdatedRefreshTokenAsync = async (
  locale: LocaleTypeEnum,
  refreshToken: string,
) => {
  try {
    const query = await request<IUpdateUserLangResponseObject>(
      config.api.maitrejaApi.endpoints.updateUserLang,
      HttpRequestMethodEnum.POST,
      {
        lang: locale,
      },
      refreshToken,
    );

    const updatedRefreshToken = query?.refresh_token;
    const profile: Profile | null = store.getters[GetterTypes.GET_AUTH_PROFILE];
    if (!profile) {
      return;
    }

    const { id, name, picture } = profile;

    storeRefreshToken({
      refreshToken: updatedRefreshToken,
      userId: id,
      userName: name,
      userPictureUrl: picture,
    });
  } catch (error) {
    throw error;
  }
};
