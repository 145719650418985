import { chatApiAxios } from "@/plugins";
import { HttpRequestMethodEnum } from "@/types/api";

interface LeaveChat {
  chatId: string;
  firebaseIdToken: string;
}

export const leaveChatBeAsync = async ({
  chatId,
  firebaseIdToken,
}: LeaveChat) => {
  const headers = {
    Authorization: `Bearer ${firebaseIdToken}`,
  };
  const res = await chatApiAxios("chat/" + chatId + "/leave", {
    method: HttpRequestMethodEnum.DELETE,
    headers,
  });
  return res.data;
};
