import store, { MutationTypes } from "@/store";
import { LayoutBackgroundColorTypes } from "@/store/app/state";

export const updateBottomBgColor = (isBlockingTextShown: boolean): void => {
  let bottomBgColor = LayoutBackgroundColorTypes.WHITE;
  if (isBlockingTextShown) {
    bottomBgColor = LayoutBackgroundColorTypes.ORANGE;
  }
  store.commit(MutationTypes.SET_LAYOUT_BACKGROUND_COLOR_BOTTOM, bottomBgColor);
};
