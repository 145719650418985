export const removeVideoSrc = (elementId: string) => {
  const videoContainer = document.getElementById(elementId);
  const video = videoContainer?.querySelector("video");

  if (!video) {
    return;
  }
  video.pause();
  video.srcObject = null;
};
