import { AllActionTypes } from "@/store/actionTypes";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useGetChangeChatMobileContainerProperties } from "./useGetChangeChatMembersProperties";

export const useGetEditChatMobileContainerProperties = () => {
  const { dispatch } = useStore();
  const router = useRouter();
  const route = useRoute();
  const chatId = route.params.chat_id;
  const {
    listsContainerHeight,
    friendsListItemsArray,
    otherPeopleListItemsArray,
    phoneContactsUsingAppListItemsArray,
    handleRedirect,
    injected,
    data,
    handleAddGroupMember,
    handleRemoveGroupMember,
  } = useGetChangeChatMobileContainerProperties(false);

  const handleSaveAddedMembers = async () => {
    const addMemberIds = data.selectedGroupMembers.map((m) => m.itemId);

    dispatch(AllActionTypes.UPDATE_GROUP_CHAT_MEMBERS, {
      chatId,
      addMemberIds,
    });

    router.push(`/${chatId}`);
  };

  return {
    listsContainerHeight,
    friendsListItemsArray,
    otherPeopleListItemsArray,
    phoneContactsUsingAppListItemsArray,
    handleRedirect,
    injected,
    data,
    handleAddGroupMember,
    handleRemoveGroupMember,
    handleSaveAddedMembers,
  };
};
