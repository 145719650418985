import store from "@/store";
import { FriendUser } from "@/store/users/state";
import { IUserPhoneContactUserResponseObject } from "@/types/users";

export const getExtendedFriends = (
  contactUsers: IUserPhoneContactUserResponseObject[],
) => {
  const friendsArray: FriendUser[] = store.getters.GET_AUTH_FRIEND_ARRAY;
  const extendedFriends: FriendUser[] = friendsArray.map((friend) => {
    const contactUser = contactUsers.find(({ id }) => id === friend.id);
    return {
      ...friend,
      alias: contactUser?.name,
    };
  });

  return extendedFriends;
};
