import { ChatsState } from "@/store/chats/state";
import { IChatModifiedReferenceObject } from "@/types/chat";
import { addChatsShareSuggestions } from "./addChatsShareSuggestions";
import { getChatStatesFromReferenceArray } from "./getChatStatesFromReferenceArray";

export const addChatsToState = (
  state: ChatsState,
  chats: IChatModifiedReferenceObject[],
) => {
  addChatsShareSuggestions(chats);
  state.chats = { ...state.chats, ...getChatStatesFromReferenceArray(chats) };
  state.chatIds = [...state.chatIds, ...chats.map((chat) => chat.chatId)];
};
