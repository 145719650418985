<template>
  <div class="initialScreen">
    <div :id="videoPreviewId" ref="videoRef" class="videoRef tracks" />
    <div class="overlay" />
    <div class="avatar">
      <CallAvatar
        :size="ProfileAvatarSizeEnum.ExtraBig"
        :src="currentCall.displayChatPicture"
        :is-connected="false"
      />
      <span class="participantName">{{ participantText }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProfileAvatarSizeEnum } from "@/types/avatar";
import CallAvatar from "../CallAvatar/CallAvatar.vue";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useComputedValue } from "@/composables";
import { GetterTypes } from "@/store";
import { IOngoingCall } from "@/store/auth/types";
import { LocalVideoTrack, createLocalVideoTrack } from "twilio-video";
import { shallowRef } from "vue";
import {
  attachTrack,
  stopMediaPreview,
  togglePreviewCamera,
} from "@/utils/call";
import { config } from "@/config";

const { t } = useI18n();
const videoRef = ref<HTMLDivElement | null>(null);
const currentCall = useComputedValue<IOngoingCall>(
  GetterTypes.GET_CURRENT_ONGOING_CALL,
);
const participantText = computed(
  () => `${t("call.establishingCall")} ${currentCall.value.displayChatName}`,
);
const videoTrack = shallowRef<LocalVideoTrack | null>();
const videoPreviewId = "videoPreview";
const hasVideo = useComputedValue<boolean>(
  GetterTypes.GET_ONGOING_CALL_HAS_VIDEO,
);

const addVideoTrack = async () => {
  const track = await createLocalVideoTrack(
    config.constants.DEFAULT_VIDEO_SETTINGS,
  );
  videoTrack.value = track;
  attachTrack(track, videoPreviewId, true);
};

onMounted(() => {
  addVideoTrack();
});

onBeforeUnmount(() => {
  removeCameraPreview();
});

const removeCameraPreview = async () => {
  if (!videoTrack.value) {
    return;
  }

  stopMediaPreview(videoTrack.value);
  videoTrack.value = null;
};

watch(hasVideo, () => {
  if (!videoTrack.value) {
    return;
  }
  togglePreviewCamera(videoTrack.value, videoPreviewId, hasVideo.value);
});
</script>

<style lang="scss">
@import "./InitialCallScreen.scss";
</style>
