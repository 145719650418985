import { AuthMutationTypes } from "./mutationTypes";
import { AuthMutationType } from "./mutationState";

export const authMutations: AuthMutationType = {
  [AuthMutationTypes.SET_AUTH_PROFILE](state, profile) {
    state.profile = profile;
  },
  [AuthMutationTypes.UPDATE_AUTH_PROFILE](state, profile) {
    state.profile = {
      ...state.profile,
      ...profile,
    };
  },
  [AuthMutationTypes.SET_AUTH_ACCESS_TOKEN](state, accessToken) {
    state.accessToken = accessToken;
  },
  [AuthMutationTypes.SET_AUTH_REFRESH_TOKEN](state, refreshToken) {
    state.refreshToken = refreshToken;
  },
  [AuthMutationTypes.SET_AUTH_FIREBASE_ID_TOKEN](state, firebaseIdToken) {
    state.firebaseIdToken = firebaseIdToken;
  },
  [AuthMutationTypes.SET_RINGING_CALLS](state, ringingCalls = []) {
    state.ringingCalls = ringingCalls;
  },
  [AuthMutationTypes.SET_ONGOING_CALL](state, ongoingCall) {
    state.ongoingCalls = ongoingCall;
  },
  [AuthMutationTypes.ADD_ONGOING_CALL](state, ongoingCall) {
    const alreadyExists = state.ongoingCalls.calls.find(
      (call) => call.chatId === ongoingCall.chatId,
    );
    if (alreadyExists) {
      return;
    }
    state.ongoingCalls.calls.push(ongoingCall);
  },
  [AuthMutationTypes.TOGGLE_ONGOING_CALL_VIDEO](state, { value, callId }) {
    const newOngoingCalls = state.ongoingCalls.calls.map((call) => {
      if (call.chatId === callId) {
        return { ...call, hasVideo: value ?? !call.hasVideo };
      }
      return call;
    });
    state.ongoingCalls.calls = newOngoingCalls;
  },
  [AuthMutationTypes.TOGGLE_ONGOING_CALL_AUDIO](state, { value, callId }) {
    const newOngoingCalls = state.ongoingCalls.calls.map((call) => {
      if (call.chatId === callId) {
        return { ...call, hasAudio: value ?? !call.hasAudio };
      }
      return call;
    });
    state.ongoingCalls.calls = newOngoingCalls;
  },
  [AuthMutationTypes.REMOVE_ONGOING_CALL](state, chatId) {
    state.ongoingCalls.calls = state.ongoingCalls.calls.filter(
      (call) => call.chatId !== chatId,
    );
  },
  [AuthMutationTypes.REMOVE_RINGING_CALL](state, chatId) {
    state.ringingCalls = state.ringingCalls.filter(
      (call) => call.chatId !== chatId,
    );
  },
  [AuthMutationTypes.SET_CURRENT_ONGOING_CALL_ID](state, chatId) {
    state.ongoingCalls.currentCallId = chatId;
  },
};
