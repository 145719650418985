import { useComputedValue } from "@/composables/useComputedValue";
import { GetterTypes } from "@/store";
import {
  ChatState,
  Chat,
  ChatBlockingStatusEnum,
  Message,
} from "@/store/chats/types";
import { VideoDisplayStatus } from "@/types/chat";
import { getChatBlockingStrings } from "@/utils/chat";
import { convertDateToStringDate } from "@/utils/date";
import { getIsMessageTextType } from "@/utils/message";
import { useElementSize } from "@vueuse/core";
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";

interface ChatContentProps {
  messages: Message[];
  blockingStatus?: ChatBlockingStatusEnum;
  isDeleted?: boolean;
  userName?: string;
}

export const useGetChatContentValues = (props: ChatContentProps) => {
  const el = ref<HTMLElement>();
  const bottomAnchorRef = ref<HTMLElement>();
  const { height } = useElementSize(el);
  const { t } = useI18n();
  const initialLoad = useComputedValue(GetterTypes.GET_CHATS_INITIAL_LOAD);
  const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);
  const chat = useComputedValue<ChatState | undefined>(
    GetterTypes.GET_SELECTED_CHAT_STATE,
  );
  const chatDetail = useComputedValue<Chat | undefined>(
    GetterTypes.GET_SELECTED_CHAT,
  );
  const deletingMessages = useComputedValue<string[]>(
    GetterTypes.GET_SELECTED_MESSAGE_IDS,
  );
  const blockingStatusSelected = useComputedValue<ChatBlockingStatusEnum>(
    GetterTypes.GET_SELECTED_CHAT_BLOCKING_STATUS,
  );
  const isDeletedSelected = useComputedValue<boolean>(
    GetterTypes.GET_SELECTED_CHAT_IS_DELETED,
  );
  const blockingStatus = computed<ChatBlockingStatusEnum>(() => {
    if (props.blockingStatus) {
      return props.blockingStatus;
    }
    return blockingStatusSelected.value;
  });
  const isDeleted = computed<boolean>(() => {
    if (props.isDeleted) {
      return props.isDeleted;
    }
    return isDeletedSelected.value;
  });
  const partnerName = computed<string>(() => {
    if (props.userName) {
      return props.userName;
    }
    return chatDetail.value?.name ?? "";
  });
  const visibleMessages = computed<Message[]>(() => {
    return props.messages.filter((mess) => !mess.hidden);
  });
  const isBlockingTextShown = computed<boolean>(() => {
    return (
      blockingStatus.value !== ChatBlockingStatusEnum.None || isDeleted.value
    );
  });
  const lastConvertedMessage = computed(
    () => props.messages[props.messages.length - 1],
  );
  const isSomeAttachmentUploading = computed(() => {
    const lastMess = lastConvertedMessage.value;
    if (!lastMess || !getIsMessageTextType(lastMess)) {
      return false;
    }
    return lastMess.attachments?.some(
      (att) =>
        att.isUploading && att.displayStatus === VideoDisplayStatus.Uploading,
    );
  });
  const convertedDateString = computed<string>(() => {
    const lastMessage = lastConvertedMessage.value;
    if (!lastMessage || getIsMessageTextType(lastMessage)) {
      return convertDateToStringDate(
        lastMessage.readBy?.[0]?.readAt as number,
        t,
      );
    }
    return "";
  });
  const lastMessageId = computed(() => {
    if (!chat.value?.messageIds || !chat.value?.messageIds.length) {
      return null;
    }
    return chat.value?.messageIds[chat.value?.messageIds.length - 1];
  });
  const lastMessage = computed(() => {
    if (!lastMessageId.value || !chat.value) {
      return null;
    }
    return chat.value.messages[lastMessageId.value];
  });
  const firstMessage = computed(() => {
    const firstMessageId = chat.value?.messageIds[0];
    if (!firstMessageId) {
      return null;
    }
    return chat.value.messages[firstMessageId];
  });
  const chatId = computed(() => chat.value?.rawChat?.id);
  const blockingStrings = computed<string[]>(() =>
    getChatBlockingStrings({
      isDeleted: isDeleted.value,
      blockingStatus: blockingStatus.value,
      partnerName: partnerName.value,
    }),
  );

  return {
    visibleMessages,
    isBlockingTextShown,
    authId,
    deletingMessages,
    convertedDateString,
    blockingStrings,
    el,
    bottomAnchorRef,
    chatId,
    firstMessage,
    lastMessageId,
    lastMessage,
    isSomeAttachmentUploading,
    initialLoad,
    height,
    lastConvertedMessage,
    chatDetail,
  };
};
