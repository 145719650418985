import { LocaleTypeEnum } from "@/types/localization";
import Cookies from "js-cookie";

export const getLanguage = (): LocaleTypeEnum => {
  let lang: string | null | undefined = new URL(location.href).searchParams.get(
    "lang",
  );

  if (lang) return lang as LocaleTypeEnum;

  lang = Cookies.get("lang");
  if (lang) return lang as LocaleTypeEnum;

  return LocaleTypeEnum.CS;
};
