import { HttpRequestMethodEnum } from "@/types/api";
import { IAuthResponseObject } from "@/types/auth";
import { request } from "../api";
import { storeAuthenticationData } from "./storeAuthenticationData";
import { config } from "@/config";

export const getRefreshTokenAsync = async (email: string, password: string) => {
  try {
    const authData = await request<IAuthResponseObject>(
      config.api.maitrejaApi.endpoints.login,
      HttpRequestMethodEnum.POST,
      {
        client_id: process.env.VUE_APP_MAITREJA_API_KEY,
        client_secret: process.env.VUE_APP_MAITREJA_API_SECRET,
        email: email,
        pass: password,
      },
    );

    storeAuthenticationData(authData);
  } catch (error) {
    throw error;
  }
};
