export enum AuthMutationTypes {
  SET_AUTH_PROFILE = "SET_AUTH_PROFILE",
  SET_AUTH_ACCESS_TOKEN = "SET_AUTH_ACCESS_TOKEN",
  SET_AUTH_REFRESH_TOKEN = "SET_AUTH_REFRESH_TOKEN",
  SET_AUTH_FIREBASE_ID_TOKEN = "SET_AUTH_FIREBASE_ID_TOKEN",
  UPDATE_AUTH_PROFILE = "UPDATE_AUTH_PROFILE",
  SET_RINGING_CALLS = "SET_RINGING_CALLS",
  REMOVE_RINGING_CALL = "REMOVE_RINGING_CALL",
  SET_ONGOING_CALL = "SET_ONGOING_CALL",
  SET_CURRENT_ONGOING_CALL_ID = "SET_CURRENT_ONGOING_CALL_ID",
  ADD_ONGOING_CALL = "ADD_ONGOING_CALL",
  REMOVE_ONGOING_CALL = "REMOVE_ONGOING_CALL",
  TOGGLE_ONGOING_CALL_AUDIO = "TOGGLE_ONGOING_CALL_AUDIO",
  TOGGLE_ONGOING_CALL_VIDEO = "TOGGLE_ONGOING_CALL_VIDEO",
}
