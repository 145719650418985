import { convertFirebaseDateToLocal } from "@/firebase/utils";
import { Profile } from "@/store/auth/state";
import {
  GroupRawTextMessage,
  TextMessage,
  ChatTypes,
  MessageType,
} from "@/store/chats/types";
import { User } from "@/store/users/state";
import { parseMessageText } from ".";
import { getDisplayGroupMessage } from "../chat/getDisplayGroupMessage";
import { getGroupMessageReadBy } from "../chat/getGroupMessageReadBy";
import { getImageAttachments } from "../chat/getImageAttachments";
import { getOthersAttachments } from "../chat/getOthersAttachments";
import { getReadGroupMessage } from "../chat/getReadGroupMessage";
import { getSenderName } from "../chat/getSenderName";
import { getVideoAttachments } from "../chat/getVideoAttachments";
import { getVisibleAttachments } from "./getVisibleAttachments";
import { findUserById } from "../users";

interface ConvertRawGroupTextMessageArgs {
  rawMessage: GroupRawTextMessage;
  memberIds: number[];
  auth: Profile;
  users?: User[];
}

export const convertRawGroupTextMessage = ({
  rawMessage,
  memberIds,
  auth,
  users,
}: ConvertRawGroupTextMessageArgs): TextMessage => {
  const members: User[] = memberIds
    .map((memberId) => {
      const user = findUserById(memberId, users);
      return user;
    })
    .filter(Boolean) as User[];
  const { senderId, messageText, attachments, hide, sentAt, id, reactions } =
    rawMessage;
  const senderName = getSenderName(senderId, auth.id, members);
  const messageTextWithSenderName = senderName + ": " + messageText;
  const visibleAttachments = getVisibleAttachments(attachments);
  const hasAttachment = Boolean(
    visibleAttachments && visibleAttachments.length,
  );
  const hasMessageText = !!messageText;
  const hidden = Boolean(hide) && !(hasMessageText || hasAttachment);

  return {
    chatType: ChatTypes.GROUP_CHAT,
    id,
    senderId,
    senderName,
    isMine: auth.id === senderId,
    messageText,
    displayMessageText: getDisplayGroupMessage(rawMessage, senderName),
    messageTextWithSenderName,
    parseMessageText: parseMessageText(messageText),
    sentAt: convertFirebaseDateToLocal(sentAt),
    hasAttachment,
    hidden,
    hasMessageText,
    readBy: getGroupMessageReadBy(rawMessage),
    read: getReadGroupMessage(rawMessage, auth),
    attachments: visibleAttachments,
    allAttachments: attachments,
    imageAttachments: getImageAttachments(visibleAttachments),
    videoAttachments: getVideoAttachments(visibleAttachments),
    othersAttachments: getOthersAttachments(visibleAttachments),
    type: MessageType.Text,
    reactions,
  };
};
