interface GetImageSizeArgs {
  width?: number;
  height?: number;
  galleryMode?: boolean;
}

const MAX_DIMENSIONS = 350;

export const getImageSize = ({
  width,
  height,
  galleryMode = false,
}: GetImageSizeArgs) => {
  if (galleryMode || !width || !height) return;

  let biggestDimension = Math.max(width, height);
  let smallestDimension = Math.min(width, height);

  const ratio = smallestDimension / biggestDimension;
  if (biggestDimension > MAX_DIMENSIONS) {
    biggestDimension = MAX_DIMENSIONS;
  }
  smallestDimension = biggestDimension * ratio;

  const appliedWidth = width > height ? biggestDimension : smallestDimension;
  const appliedHeight = width > height ? smallestDimension : biggestDimension;

  return {
    width: appliedWidth,
    height: appliedHeight,
  };
};
