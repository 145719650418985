import { config } from "@/config";
import { HttpRequestContentTypeEnum } from "@/types/api";
import axios from "axios";

export const maitrejaApiAxios = axios.create({
  baseURL: config.api.maitrejaApi.url,
  headers: {
    "Content-Type": HttpRequestContentTypeEnum.WWWFORM,
  },
});
