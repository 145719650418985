<template>
  <Icon
    class="icon"
    :icon="appliedIcon"
    @mouseover="toggleHover"
    @mouseout="toggleHover"
  />
</template>

<script setup lang="ts">
import Icon from "@/components/icons/Icon/Icon.vue";
import { IconType } from "@/types/icons";
import { computed, ref } from "vue";
interface Props {
  activeIcon: IconType;
  inactiveIcon?: IconType;
  hoverIcon?: IconType;
  isActive?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  inactiveIcon: undefined,
  hoverIcon: undefined,
  isActive: false,
});
const isHover = ref(false);
const toggleHover = () => {
  isHover.value = !isHover.value;
};

const appliedIcon = computed(() => {
  if (props.isActive) {
    return props.activeIcon;
  }
  if (isHover.value && props.hoverIcon) {
    return props.hoverIcon;
  }

  return props.inactiveIcon ?? props.activeIcon;
});
</script>

<style lang="scss" scoped>
@import "./SwitchIconButton.scss";
</style>
