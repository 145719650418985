import { LocalParticipant } from "twilio-video";
import { unpublishVideoTrack } from "./unpublishVideoTrack";
import { publishVideoTrack } from "./publishVideoTrack";

export const toggleCamera = async (
  localParticipant: LocalParticipant,
  hasVideo: boolean,
) => {
  if (!hasVideo) {
    unpublishVideoTrack(localParticipant);
    return;
  }

  publishVideoTrack(localParticipant);
};
