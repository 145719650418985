import { HttpRequestMethodEnum } from "@/types/api";
import { request } from "../api";
import { config } from "@/config";

export const deleteUserAsync = async (accessToken: string) => {
  try {
    const query = await request(
      config.api.maitrejaApi.endpoints.deleteUserAccount,
      HttpRequestMethodEnum.POST,
      undefined,
      accessToken,
    );

    return query;
  } catch (err) {
    // @ts-expect-error
    console.log(err.message);
  }
};
