export const copyTextToClipboard = async (text: string) => {
  if (!navigator.clipboard) {
    return;
  }
  try {
    await navigator.clipboard.writeText(text);
  } catch (error) {
    console.error("Async: Could not copy text: ", error);
  }
};
