<template>
  <div class="loading-container">
    <div class="loading-circle-container">
      <div class="circle light"></div>
      <div class="circle dark"></div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
@import "src/assets/scss/loading/loadingScreen.scss";
</style>
