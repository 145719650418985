import {
  OneToOneRawTextMessage,
  OneToOneRawAudioMessage,
  RawFriendRequestMessage,
} from "@/store/chats/types";
import { markMessageAsReadBeAsync } from "./markMessageAsReadBeAsync";

interface IMarkOneToOneMessageAsReadObject {
  message:
    | OneToOneRawTextMessage
    | OneToOneRawAudioMessage
    | RawFriendRequestMessage;
  firebaseIdToken: string;
}

export const markOneToOneMessageAsRead = async ({
  message,
  firebaseIdToken,
}: IMarkOneToOneMessageAsReadObject) => {
  if (message.read) {
    return;
  }
  await markMessageAsReadBeAsync({ messageId: message.id, firebaseIdToken });
};
