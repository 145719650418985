<template>
  <div class="ringingCallContainer">
    <div class="left">
      <img :src="user.picture" alt="profile picture" class="profilePicture" />
      <div class="description">
        <span class="name">{{ user.name }}</span>
        <span class="calling">{{ $t("call.isCallingYou") }}</span>
      </div>
    </div>
    <div class="right">
      <RingingCallIcon
        :action-type="ActionButtonType.Accept"
        @click="acceptCall"
      />
      <RingingCallIcon
        :action-type="ActionButtonType.Decline"
        @click="declineCall"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { UsersGetterTypes } from "@/store/users";
import { User } from "@/store/users/state";
import { computed } from "vue";
import { useStore } from "vuex";
import RingingCallIcon from "../RingingCallIcon/RingingCallIcon.vue";
import { ActionButtonType } from "@/types/button";
import { ActionTypes } from "@/store";

interface Props {
  chatId: string;
  callerId: number;
}

const props = defineProps<Props>();
const { getters, dispatch } = useStore();
const user = computed<User>(() =>
  getters[UsersGetterTypes.GET_USER](props.callerId),
);

const acceptCall = () => {
  dispatch(ActionTypes.ACCEPT_RINGING_CALL, props.chatId);
};

const declineCall = () => {
  dispatch(ActionTypes.DECLINE_RINGING_CALL, props.chatId);
};
</script>

<style lang="scss" scoped>
@import "./RingingCallBanner.scss";
</style>
