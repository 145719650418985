import Cookies from "js-cookie";

export const getRefreshToken = (): string | null => {
  let refreshToken = new URL(location.href).searchParams.get("token");

  if (refreshToken) return refreshToken;

  let cookieRefreshToken = Cookies.get("refresh_token");
  if (cookieRefreshToken) return cookieRefreshToken;

  let cookieToken = Cookies.get("token");
  if (cookieToken) return cookieToken;

  return null;
};
