import { ErrorHandlerSnackbarRequestObject } from "@/store/app/state";

export const registerSnackbarErrorObject: ErrorHandlerSnackbarRequestObject = {
  "401": {
    title: "snackbar.errors.userAlreadyExists.title",
    text: "snackbar.errors.userAlreadyExists.text",
  },
  "406": {
    title: "snackbar.errors.accountWasClosedRegister.title",
    text: "snackbar.errors.accountWasClosedRegister.text",
    timeoutSeconds: 10,
  },
};
