import {
  getPartnerIdFromChatId,
  convertFirebaseDateToLocal,
} from "@/firebase/utils";
import { Profile } from "@/store/auth/state";
import {
  OneToOneRawAudioMessage,
  OneToOneRawTextMessage,
  ReadBy,
} from "@/store/chats/types";

export const getOneToOneMessageReadBy = (
  message: OneToOneRawTextMessage | OneToOneRawAudioMessage,
  auth: Profile,
  chatId: string,
): ReadBy[] => {
  const partnerId = getPartnerIdFromChatId(auth.id, chatId);
  if (message?.read) {
    const readAt = convertFirebaseDateToLocal(message?.sentAt) || 0;
    return [
      {
        read: true,
        readAt,
        userId: message.senderId === auth.id ? partnerId : auth.id,
      },
    ];
  }
  return [];
};
