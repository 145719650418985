import { BridgeActionEnum } from "@/types/webview";
import { postWebviewMessage } from "@/utils/api";
import Cookies from "js-cookie";

export const logoutUser = () => {
  postWebviewMessage({
    action: BridgeActionEnum.Logout,
    refreshToken: Cookies.get("refresh_token"),
  });
  Cookies.remove("refresh_token", { path: "" });
  window.location.href = "/welcome";
};
