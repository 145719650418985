<template>
  <div class="initial-container">
    <ReportErrorIconButton v-if="!otherProps.data.isPopupOpen" />
    <MobileNavigationBar
      :icon-type="MobileNavigationBarIconType.Circle"
      :handle-redirect="handleRedirectBack"
    />
    <PhoneVerificationForm
      v-bind="otherProps"
      @toggle-popup="handleTogglePopup"
    />
    <div class="filler" />
  </div>
</template>

<script setup lang="ts">
import PhoneVerificationForm from "@/components/forms/PhoneVerificationForm/PhoneVerificationForm.vue";
import ReportErrorIconButton from "@/components/buttons/ReportErrorIconButton/ReportErrorIconButton.vue";
import MobileNavigationBar from "@/components/layouts/MobileNavigationBar/MobileNavigationBar.vue";
import { MobileNavigationBarIconType } from "@/types/app";
import { getRefreshTokenFromPhoneAsync } from "@/services/maitrejaApi/auth";
import { ActionTypes, MutationTypes } from "@/store";
import { ConnectPhoneHandler } from "@/types/auth";
import { handleOnboardingRedirect } from "@/utils/settings";
import { useStore } from "vuex";
import { useGetConnectPhoneProperties } from "@/composables";

const { commit, dispatch } = useStore();
const handleSubmit: ConnectPhoneHandler = async (data, idToken) => {
  commit(MutationTypes.SET_IS_LOADING, true);
  try {
    await getRefreshTokenFromPhoneAsync(
      idToken,
      data.countryCode,
      data.phoneNumber.replaceAll(" ", ""),
    );
    await dispatch(ActionTypes.LOGIN_AUTH);
    handleOnboardingRedirect();
  } catch (err) {
    throw err;
  }
};

const { handleRedirectBack, handleTogglePopup, ...otherProps } =
  useGetConnectPhoneProperties(handleSubmit);
</script>

<style lang="scss" scoped>
@import "src/assets/scss/form/initialFormLayout.scss";
</style>
