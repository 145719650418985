import { Message, MessageType, TextMessage } from "@/store/chats/types";

export const getIsMessageTextType = (
  message: Message,
): message is TextMessage => {
  const type = message.type;
  // to support legacy messages with no type
  if (!type) {
    return true;
  }
  return type === MessageType.Text;
};
