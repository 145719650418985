import { FormField, FormFieldDataObject } from "@/types/form";

export const getInitialFormData = <DataObjectType>(
  initialFields: FormField[],
): FormFieldDataObject<DataObjectType> => {
  const fields = {} as DataObjectType;
  const fieldsArray = initialFields.map(
    (f) => f.field,
  ) as (keyof DataObjectType)[];
  fieldsArray.forEach((key) => {
    // @ts-expect-error
    fields[key] = "";
  });

  return {
    ...fields,
    errors: fields,
  };
};
