import { CallMessage, ChatTypes, RawCallMessage } from "@/store/chats/types";
import { convertFirebaseDateToLocal, isOneToOneChat } from "@/firebase/utils";
import { parseDurationString } from "./parseDurationString";

export const convertRawCallMessage = (
  { messageType, id, sentAt, senderId, hide, status, duration }: RawCallMessage,
  chatId: string,
): CallMessage => {
  const chatType = isOneToOneChat(chatId)
    ? ChatTypes.ONE_TO_ONE_CHAT
    : ChatTypes.GROUP_CHAT;

  return {
    chatType,
    id,
    sentAt: convertFirebaseDateToLocal(sentAt),
    hidden: hide,
    senderId,
    type: messageType,
    status,
    duration: duration ? parseDurationString(duration) : "",
  };
};
