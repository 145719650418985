import { getImageDimensions } from "./getImageDimensions";
import { getImagePixels } from "./getImagePixels";
import { getImageSize } from "./getImageSize";

interface GetImageAttachmentStylesArgs {
  width?: number;
  height?: number;
  galleryMode?: boolean;
  url?: string;
  isShrinked?: boolean;
}

export const getImageAttachmentStyles = async ({
  width,
  height,
  galleryMode = false,
  url,
  isShrinked = false,
}: GetImageAttachmentStylesArgs) => {
  let sizes = getImageSize({
    width,
    height,
    galleryMode,
  });
  if (!sizes) {
    sizes = await getImageDimensions({
      url,
      galleryMode,
    });
  }

  if (!sizes) {
    return;
  }
  const { height: finalHeight, width: finalWidth } = sizes;

  return {
    width: getImagePixels({ size: finalWidth, isShrinked }),
    height: getImagePixels({ size: finalHeight, isShrinked }),
  };
};
