<template>
  <img :src="src" />
</template>

<script setup lang="ts">
import { IconType } from "@/types/icons";
import { getIconPath } from "@/utils/app/getIconPath";
import { computed } from "vue";

interface Props {
  icon: IconType;
}

const props = defineProps<Props>();
const src = computed(() => getIconPath(props.icon));
</script>
