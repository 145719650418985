import { intervalToDuration } from "date-fns";

export const parseDurationString = (secondsNo: number) => {
  const { hours, minutes, seconds } = intervalToDuration({
    start: 0,
    end: secondsNo * 1000,
  });

  const valuesArr = [minutes, seconds].map((val) => val ?? 0);
  if (hours) {
    valuesArr.unshift(hours);
  }
  const durationStr = valuesArr
    .map((num) => String(num).padStart(2, "0"))
    .join(":");

  return durationStr;
};
