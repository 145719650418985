import { ActionTypes } from "@/store";
import { ButtonObject } from "@/types/button";
import { ChatSettingsScreenEnum } from "@/types/chat";
import { computed } from "vue";
import { ChatSettingsDetailsPropsObject } from "../ChatSettingsDetails";
import { useGetChatSettingsCommonProperties } from "../useGetChatSettingsCommonProperties";
import { leaveGroupConversationWarningPoints } from "./leaveGroupConversationWarningPoints";

export const useGetChatSettingsLeaveGroupConversationProperties = () => {
  const { selectedChatId, route, router, dispatch } =
    useGetChatSettingsCommonProperties();

  const leaveGroupConversationProperties =
    computed<ChatSettingsDetailsPropsObject>(() => {
      return {
        type: ChatSettingsScreenEnum.LeaveGroupConversation,
        primaryButton: primaryButton.value,
        imageName: "leaveConversation.svg",
        warningPoints: leaveGroupConversationWarningPoints,
      };
    });
  const primaryButton = computed<ButtonObject>(() => {
    return {
      handleClick: handlePrimaryButtonClick,
    };
  });

  const handlePrimaryButtonClick = async (): Promise<void> => {
    const chatId = selectedChatId.value ?? (route.params.chat_id as string);
    await dispatch(ActionTypes.LEAVE_GROUP_CHAT, { chatId });

    router.push("/");
  };

  return { leaveGroupConversationProperties };
};
