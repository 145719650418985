import { IconType } from "@/types/icons";
import {
  IListItem,
  ListItemTypeEnum,
  ListItemTextColorTypeEnum,
} from "@/types/list";

export const deleteUserAccountArray: IListItem[] = [
  {
    text: "settings.personalDetailsContent.deleteAccount",
    iconName: IconType.TRASH_RED,
    type: ListItemTypeEnum.Link,
    textColorType: ListItemTextColorTypeEnum.RED,
    link: "/settings/delete-account",
  },
];
