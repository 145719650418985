import { PhoneNumberUtil, PhoneNumber } from "google-libphonenumber";
import { getParsedPhoneNumberString } from "./getParsedPhoneNumberString";

export const getPhoneStringPropertiesFromLocaleRegionCode = (
  phoneNo: string,
  regionCode: string,
) => {
  const phoneNumber = getParsedPhoneNumberString(phoneNo);
  const phoneNumberUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
  const value: PhoneNumber = phoneNumberUtil.parseAndKeepRawInput(
    phoneNo,
    regionCode,
  );
  const countryCode = `+${value.getCountryCode()}`;

  return { countryCode, phoneNumber };
};
