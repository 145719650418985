<template>
  <div
    class="message-content-container"
    :class="{
      isMine,
      isFriendRequestType,
      isPendingFriendRequest,
    }"
  >
    <BackdropModal :enabled="hasShadow">
      <div
        :id="elementId"
        ref="messageContainer"
        class="mess-content"
        :class="{ isFixed: hasShadow, isFriendRequestType, isMine }"
        :style="contentStyle"
      >
        <TransitionGroup v-if="isMobile" name="pop">
          <MessageEmojiReactionsPanel
            v-if="isControlsActive"
            :message-id="message.id"
            :is-mine="isMine"
            @close-panel="closeControls"
          />
          <div v-if="isControlsActive" class="message-reaction-controls">
            <div v-if="false" class="reaction-control-item">
              <span>{{
                $t("chat.chatContainer.messages.controls.reply")
              }}</span>
              <Icon :icon="IconType.REPLY" />
            </div>
            <div
              v-if="isTextMessage"
              class="reaction-control-item"
              @mousedown.left="copyMessageToClipboard"
            >
              <span>{{ $t("chat.chatContainer.messages.controls.copy") }}</span>
              <Icon :icon="IconType.COPY" />
            </div>
          </div>
        </TransitionGroup>
        <MessageWithReactions
          v-bind="messageWithReactionsProps"
          @open-modal="openReactionsModal"
        >
          <slot />
        </MessageWithReactions>
      </div>
    </BackdropModal>
    <div
      v-if="hasShadow && isMobile"
      class="mess-content"
      :class="{ isFriendRequestType }"
    >
      <MessageWithReactions v-bind="messageWithReactionsProps">
        <slot />
      </MessageWithReactions>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  getIsMessageFriendRequestType,
  getIsMessageTextType,
  getIsMyMessage,
} from "@/utils/message";
import { FriendRequestStatus, Message } from "@/store/chats/types";
import { useComputedValue } from "@/composables";
import { GetterTypes } from "@/store";
import { computed, onUnmounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { ChatsMutationTypes } from "@/store/chats";
import { IconType } from "@/types/icons";
import Icon from "@/components/icons/Icon/Icon.vue";
import { copyTextToClipboard } from "@/utils/app";
import BackdropModal from "@/components/modals/BackdropModal/BackdropModal.vue";
import MessageWithReactions from "../MessageWithReactions/MessageWithReactions.vue";
import MessageEmojiReactionsPanel from "../MessageEmojiReactionsPanel/MessageEmojiReactionsPanel.vue";
import { LayoutTypes } from "@/store/app/state";
import { convertNumberToPx } from "@/utils/modifiers";

interface ChatMessageReactionsProps {
  message: Message;
  isActive: boolean;
  hasShadow: boolean;
  isModalOpen: boolean;
  modalId: string;
  elementId: string;
  position: { top: number; left: number; width: number };
  isTouchEndEnabled: boolean;
}

interface Emits {
  (e: "hideShadow"): void;
  (e: "openModal"): void;
}

const emit = defineEmits<Emits>();

const { commit } = useStore();
const props = defineProps<ChatMessageReactionsProps>();
const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);
const isMine = computed(() => getIsMyMessage(props.message, authId.value));
const messageContainer = ref<HTMLDivElement>();
const isFriendRequestType = computed(() =>
  getIsMessageFriendRequestType(props.message),
);
const isPendingFriendRequest = computed(
  () =>
    getIsMessageFriendRequestType(props.message) &&
    props.message.status === FriendRequestStatus.Pending &&
    !props.message.isMine,
);
const isTextMessage = computed(() => getIsMessageTextType(props.message));
const isControlsActive = computed(() => props.isActive && !props.isModalOpen);
const messageReactionId = useComputedValue<string | null>(
  GetterTypes.GET_MESSAGE_REACTION_ID,
);
const isReacting = computed(() => !!messageReactionId.value);
const messageWithReactionsProps = computed(() => ({
  message: props.message,
  isMine: isMine.value,
  elementId: props.elementId,
}));
const layoutType = useComputedValue<LayoutTypes>(GetterTypes.GET_LAYOUT_TYPE);
const isMobile = computed(() => layoutType.value === LayoutTypes.MOBILE);

const contentStyle = computed(() => {
  const { top, left, width } = props.position;
  return {
    top: convertNumberToPx(top),
    left: convertNumberToPx(left),
    width: width ? convertNumberToPx(width) : undefined,
  };
});

watch(isReacting, (newVal) => {
  if (!newVal || !props.isActive) {
    return;
  }

  window.addEventListener("mousedown", onClickOutside, { capture: true });
});

onUnmounted(() => {
  clearClickOutsideEventListener();
});

const clearClickOutsideEventListener = () => {
  window.removeEventListener("mousedown", onClickOutside, { capture: true });
};
const getIsContainingElement = (
  element: HTMLDivElement,
  wrapper: HTMLElement | undefined | null,
) => Boolean(wrapper === element || wrapper?.contains(element));

const onClickOutside = (e: MouseEvent) => {
  const target = e.target as HTMLDivElement;
  // this is due to unability to pass container reference to the modal
  const modal = document.getElementById(props.modalId);

  if (
    getIsContainingElement(target, messageContainer.value) ||
    getIsContainingElement(target, modal)
  ) {
    return;
  }
  closeControls();
};

const clearReactionId = () => {
  commit(ChatsMutationTypes.SET_MESSAGE_REACTION_ID, {
    messageId: null,
  });
};

const closeControls = () => {
  console.log("closeControls", { isTouchEndEnabled: props.isTouchEndEnabled });

  if (!props.isTouchEndEnabled) {
    return;
  }
  clearReactionId();
  clearClickOutsideEventListener();
  emit("hideShadow");
};

const copyMessageToClipboard = () => {
  if (!getIsMessageTextType(props.message) || !props.isTouchEndEnabled) {
    return;
  }
  closeControls();
  copyTextToClipboard(props.message.messageText);
};

const openReactionsModal = () => {
  if (isControlsActive.value) {
    return;
  }
  emit("openModal");
};
</script>

<style lang="scss">
@import "./ChatMessageReactions.scss";
</style>
