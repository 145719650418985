import { AuthGetterTypes } from "./auth";
import { AppGetterTypes } from "./app";
import { ChatsGetterTypes } from "./chats";
import { UsersGetterTypes } from "./users";
import { MenuGetterTypes } from "./menu";

export const AllGetterTypes = {
  ...AuthGetterTypes,
  ...ChatsGetterTypes,
  ...UsersGetterTypes,
  ...AppGetterTypes,
  ...MenuGetterTypes,
};
