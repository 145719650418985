import {
  RawCallMessage,
  RawFriendRequestMessage,
  RawMessage,
} from "@/store/chats/types";
import { getIsRawMessageFriendRequestType } from "./getIsRawMessageFriendRequestType";
import { getIsRawMessageCallType } from "./getIsRawMessageCallType";

export const getIsRawMessageAutomaticType = (
  message: RawMessage,
): message is RawFriendRequestMessage | RawCallMessage =>
  getIsRawMessageFriendRequestType(message) || getIsRawMessageCallType(message);
