import { GetterTree } from "vuex";

import { RootState } from "@/store/state";
import { FriendUser, PhoneContact, User, UsersState, UserState } from "./state";
import { IUserPhoneContactUser } from "@/types/users";

export enum UsersGetterTypes {
  GET_USERS = "GET_USERS",
  GET_USER = "GET_USER",
  GET_AUTH_FRIEND_ARRAY = "GET_AUTH_FRIEND_ARRAY",
  GET_PHONE_CONTACTS = "GET_PHONE_CONTACTS",
  GET_PHONE_CONTACTS_USERS = "GET_PHONE_CONTACTS_USERS",
}

export interface UsersGetters {
  [UsersGetterTypes.GET_USERS](state: UsersState): UserState | undefined;
  [UsersGetterTypes.GET_USER](
    state: UsersState,
  ): (userId: number) => User | undefined;
  [UsersGetterTypes.GET_AUTH_FRIEND_ARRAY](state: UsersState): FriendUser[];
  [UsersGetterTypes.GET_PHONE_CONTACTS](state: UsersState): PhoneContact[];
  [UsersGetterTypes.GET_PHONE_CONTACTS_USERS](
    state: UsersState,
  ): IUserPhoneContactUser[];
}

export const usersGetters: GetterTree<UsersState, RootState> & UsersGetters = {
  [UsersGetterTypes.GET_USERS]: (state) => state.users,
  [UsersGetterTypes.GET_USER]: (state) => (userId: number) => {
    const user = state.users?.[userId];
    return user;
  },
  [UsersGetterTypes.GET_AUTH_FRIEND_ARRAY]: (state) => state.friends,
  [UsersGetterTypes.GET_PHONE_CONTACTS]: (state) => state.phoneContacts,
  [UsersGetterTypes.GET_PHONE_CONTACTS_USERS]: (state) =>
    Object.values(state.phoneContactsUsers),
};
