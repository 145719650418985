import { LocalVideoTrack } from "twilio-video";
import { attachTrack } from "./attachTrack";
import { stopMediaPreview } from "./stopMediaPreview";
import { config } from "@/config";

export const togglePreviewCamera = async (
  track: LocalVideoTrack,
  elementId: string,
  hasVideo: boolean,
) => {
  if (!hasVideo) {
    stopMediaPreview(track);
    return;
  }

  await track.restart(config.constants.DEFAULT_VIDEO_SETTINGS);
  attachTrack(track, elementId, true);
};
