import { HttpRequestMethodEnum } from "@/types/api";
import { IAuthResponseObject } from "@/types/auth";
import { request } from "../api";
import { storeAuthenticationData } from "./storeAuthenticationData";
import { config } from "@/config";

export const getRefreshTokenFromPhoneAsync = async (
  idToken: string,
  countryCode: string,
  phoneNumber: string,
) => {
  try {
    const authData = await request<IAuthResponseObject>(
      config.api.maitrejaApi.endpoints.loginByPhone,
      HttpRequestMethodEnum.POST,
      {
        client_id: process.env.VUE_APP_MAITREJA_API_KEY,
        client_secret: process.env.VUE_APP_MAITREJA_API_SECRET,
        id_token: idToken,
        country_code: countryCode,
        phone_number: phoneNumber,
      },
    );

    storeAuthenticationData(authData);
  } catch (error) {
    console.log({ error });

    throw error;
  }
};
