<template>
  <ChatSettingsDetails v-bind="deleteChatProperties" />
</template>
<script setup lang="ts">
import { useGetChatSettingsDeleteChatProperties } from "./useGetChatSettingsDeleteChatProperties";
import ChatSettingsDetails from "@/components/chat/chatSettings/ChatSettingsDetails/ChatSettingsDetails.vue";

const { deleteChatProperties } = useGetChatSettingsDeleteChatProperties();
</script>
<style lang="scss" scoped></style>
