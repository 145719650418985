<template>
  <div class="overall-wrapper">
    <div v-if="attachments.length > 0" class="attachments-wrapper">
      <div class="attachment">
        <input class="attachment-input" type="file" @change="addAttachment" />
        <Icon :icon="IconType.GALLERY_PLUS" class="btn-add"/>
      </div>
      <InputAttachmentPreview 
        v-for="attachment in attachments" :key="attachment.id" :attachment="attachment"
        :remove-attachment="removeAttachment" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Attachment } from "@/store/chats/types";
import { IconType } from "@/types/icons";
import InputAttachmentPreview from "./InputAttachmentPreview.vue"
import Icon from "@/components/icons/Icon/Icon.vue"

interface Props {
  attachments: Attachment[];
}
interface Emits {
  (e: "add-attachment", files: FileList): void;
  (e: "remove-attachment", id: string | undefined): void;
}

withDefaults(defineProps<Props>(), {
  attachments: () => [],
});

const emit = defineEmits<Emits>();

const addAttachment = (e: Event) => {
  const target = e.target as HTMLInputElement;
  const files = target.files;
  if (!files) {
    return;
  }
  emit("add-attachment", files);
};

const removeAttachment = (id?: string | undefined) => {
  if (!id) {
    return;
  }
  emit("remove-attachment", id);
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatContainer/inputAttachmentWithPreview.scss";
</style>
