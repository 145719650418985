import { store } from "@/store";
import { AuthGetterTypes } from "@/store/auth";

import { Track } from "twilio-video";
import { JointParticipant } from "@/types/call";
import { attachTrack } from "./attachTrack";

export const handleTrackSubscribed = (
  track: Track,
  participant: JointParticipant,
) => {
  const authId = store.getters[AuthGetterTypes.GET_AUTH_ID];
  const isLocal = participant.identity === String(authId);
  attachTrack(track, participant.sid, isLocal);
};
