import { LayoutBackgroundColorTypes } from "@/store/app/state";
import { BridgeDisplayModeEnum, BridgeActionEnum } from "@/types/webview";
import { getBackgroundColorHexCode } from "./getBackgroundColorHexCode";
import { postWebviewMessage } from "./postWebviewMessage";

export const handleChangeDisplayMode = (
  displayMode: BridgeDisplayModeEnum = BridgeDisplayModeEnum.Container,
  topBackgroundColorType: LayoutBackgroundColorTypes = LayoutBackgroundColorTypes.WHITE,
  bottomBackgroundColorType: LayoutBackgroundColorTypes = LayoutBackgroundColorTypes.WHITE,
) => {
  const requestObj = {
    action: BridgeActionEnum.ChangeDisplayMode,
    displayMode, // container, noSafeArea
    topColor: getBackgroundColorHexCode(topBackgroundColorType),
    bottomColor: getBackgroundColorHexCode(bottomBackgroundColorType),
  };

  postWebviewMessage(requestObj);
};
