export const getVersionNumber = (versionString: string): number => {
  if (!versionString) {
    return 0;
  }

  //Android version has additional info
  const parsedVersion = versionString.split(" ");

  return Number(parsedVersion[0].replaceAll(".", "").padEnd(3, "0"));
};
