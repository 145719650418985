import { config } from "@/config";

export const menuData = (userId: string) => {
  return {
    cz: {
      signIn: {
        pages: [
          {
            title: "Časová osa",
            ico: "/icons/home.svg",
            url: `${config.api.maitrejaApi.appUrl}/timeline`,
          },
          {
            title: "Články",
            ico: "/icons/groups.svg",
            url: `${config.api.maitrejaApi.appUrl}/blog`,
          },
          {
            title: "Skupiny",
            ico: "/icons/bull.svg",
            url: `${config.api.maitrejaApi.appUrl}/groups`,
          },
          {
            title: "Projekty",
            ico: "/icons/flag.svg",
            url: `${config.api.maitrejaApi.appUrl}/projects`,
          },
          {
            title: "Události",
            ico: "/icons/calendar.svg",
            url: `${config.api.maitrejaApi.appUrl}/events`,
          },
          {
            title: "Poklady",
            ico: "/icons/diamond.svg",
            url: `${config.api.maitrejaApi.appUrl}/treasures`,
          },
        ],
        create: {
          title: "Vytvořit",
          subtitle: "Vytvořit",
          ico: "/icons/plus.svg",
          dropdown: [
            {
              "create-title": "Příspěvek",
              "create-info": "Sdílej příspěvek na časové ose.",
              "create-url": `${config.api.maitrejaApi.appUrl}/timeline`,
            },
            {
              "create-title": "Článek",
              "create-info": "Napiš článek a inspiruj ostatní.",
              "create-url": `${config.api.maitrejaApi.appUrl}/profil/user-${userId}/blog?fce=newarticle`,
            },
            {
              "create-title": "Skupinu",
              "create-info": "Propoj se s lidmi s podobnými zájmy.",
              "create-url": `${config.api.maitrejaApi.appUrl}/newgroup`,
            },
            {
              "create-title": "Projekt",
              "create-info":
                "Zrealizuj nápad. Potkej lidi, kteří ti s tím pomohou.",
              "create-url": `${config.api.maitrejaApi.appUrl}/newproject`,
            },
            {
              "create-title": "Událost",
              "create-info":
                "Uspořádej nápad. Potkej lidi, kteří ti s tím pomohou.",
              "create-url": `${config.api.maitrejaApi.appUrl}/newevent`,
            },
            {
              "create-title": "Poklad",
              "create-info":
                "Sdílej své žívotní poselství a usnadni cestu ostatním.",
              "create-url": `${config.api.maitrejaApi.appUrl}/newtreasure`,
            },
          ],
        },
        options: {
          title: "Možnosti",
          ico: "/icons/dots.svg",
          dropdown: [
            {
              title: "Nastavení",
              ico: "/icons/cog.svg",
              url: `${config.api.maitrejaApi.appUrl}/profil/user-${userId}/settings`,
            },
            {
              title: "Nový firemní profil",
              ico: "/icons/newMessage.svg",
              url: `${config.api.maitrejaApi.appUrl}/newprofile`,
            },
            {
              title: "Odhlásit",
              ico: "/icons/lock.svg",
              url: `${config.api.maitrejaApi.appUrl}/login?fce=logout`,
            },
          ],
        },
      },
      signInWithCompanyAccount: {},
      signOut: {},
    },
    sk: {},
    en: {},
  };
};

export enum LangTypes {
  CZ = "cz",
  SK = "sk",
  EN = "en",
}

export type LangType = LangTypes.CZ | LangTypes.SK | LangTypes.EN;

export enum SignTypes {
  SIGN_IN = "signIn",
  SIGN_OUT = "signOut",
  SIGN_IN_WITH_COMPANY_ACCOUNT = "signInWithCompanyAccount",
}

export type SignType =
  | SignTypes.SIGN_IN
  | SignTypes.SIGN_OUT
  | SignTypes.SIGN_IN_WITH_COMPANY_ACCOUNT;

export const getMenuData = (
  lang: LangType,
  sign: SignType,
  userId?: string,
) => {
  // @ts-expect-error
  return menuData(userId || "sign-out")[lang][sign];
};
