import { GetterTree } from "vuex";

import { RootState } from "@/store/state";
import {
  Chat,
  ChatBlockingStatusEnum,
  ChatState,
  ChatTypes,
  RawChat,
  RawMessage,
  Message,
} from "./types";
import { ChatGetterHandler, ChatsState } from "./state";
import { convertChatStateToArray, sortChatState } from "@/utils/chat";
import { convertRawMessage } from "@/utils/message";

export enum ChatsGetterTypes {
  GET_CHATS_STATE = "GET_CHATS_STATE",
  GET_CHAT = "GET_CHAT",
  GET_RAW_CHAT = "GET_RAW_CHAT",
  GET_CHATS_STATE_ARRAY = "GET_CHATS_STATE_ARRAY",
  GET_CONVERTED_CHATS_ARRAY_FILTERED = "GET_CONVERTED_CHATS_ARRAY_FILTERED",
  GET_CHATS_ARRAY = "GET_CHATS_ARRAY",
  GET_CHATS_INITIAL_LOAD = "GET_CHATS_INITIAL_LOAD",
  GET_CHATS_FILTER = "GET_CHATS_FILTER",
  GET_SELECTED_CHAT = "GET_SELECTED_CHAT",
  GET_SELECTED_CHAT_STATE = "GET_SELECTED_CHAT_STATE",
  GET_SELECTED_CHAT_MESSAGES_ARRAY = "GET_SELECTED_CHAT_MESSAGES_ARRAY",
  GET_CHAT_MESSAGES_ARRAY = "GET_CHAT_MESSAGES_ARRAY",
  GET_SELECTED_CHAT_ID = "GET_SELECTED_CHAT_ID",
  GET_SELECTED_MESSAGE_IDS = "GET_SELECTED_MESSAGE_IDS",
  GET_CHAT_BLOCKING_STATUS = "GET_CHAT_BLOCKING_STATUS",
  GET_SELECTED_CHAT_BLOCKING_STATUS = "GET_SELECTED_CHAT_BLOCKING_STATUS",
  GET_CONVERTED_CHAT_DETAIL = "GET_CONVERTED_CHAT_DETAIL",
  GET_CHAT_IS_DELETED = "GET_CHAT_IS_DELETED",
  GET_SELECTED_CHAT_IS_DELETED = "GET_SELECTED_CHAT_IS_DELETED",
  GET_SHARED_MESSAGE = "GET_SHARED_MESSAGE",
  GET_MESSAGE_REACTION_ID = "GET_MESSAGE_REACTION_ID",
}

export interface ChatsGetters {
  [ChatsGetterTypes.GET_CHAT](state: ChatsState): ChatGetterHandler;
  [ChatsGetterTypes.GET_CHATS_STATE](state: ChatsState): ChatsState;
  [ChatsGetterTypes.GET_RAW_CHAT](
    state: ChatsState,
  ): (chatId: string) => RawChat;
  [ChatsGetterTypes.GET_CONVERTED_CHAT_DETAIL](
    state: ChatsState,
  ): (chatId: string) => Chat;
  [ChatsGetterTypes.GET_CHATS_INITIAL_LOAD](state: ChatsState): boolean;
  [ChatsGetterTypes.GET_CHATS_STATE_ARRAY](state: ChatsState): ChatState[];
  [ChatsGetterTypes.GET_CONVERTED_CHATS_ARRAY_FILTERED](
    state: ChatsState,
    getters: ChatsGetters,
  ): Chat[];
  [ChatsGetterTypes.GET_CHATS_ARRAY](state: ChatsState): Chat[];
  [ChatsGetterTypes.GET_CHATS_FILTER](state: ChatsState): string;
  [ChatsGetterTypes.GET_SELECTED_CHAT_STATE](
    state: ChatsState,
  ): ChatState | undefined;
  [ChatsGetterTypes.GET_SELECTED_CHAT](state: ChatsState): Chat | undefined;
  [ChatsGetterTypes.GET_SELECTED_CHAT_MESSAGES_ARRAY](
    state: ChatsState,
  ): Message[];
  [ChatsGetterTypes.GET_CHAT_MESSAGES_ARRAY](
    state: ChatsState,
    getters: ChatsGetters,
  ): (chatId: string) => Message[];
  [ChatsGetterTypes.GET_SELECTED_CHAT_ID](
    state: ChatsState,
  ): string | undefined;
  [ChatsGetterTypes.GET_SELECTED_MESSAGE_IDS](
    state: ChatsState,
  ): string[] | null;
  [ChatsGetterTypes.GET_SELECTED_CHAT_BLOCKING_STATUS](
    state: ChatsState,
  ): ChatBlockingStatusEnum | undefined;
  [ChatsGetterTypes.GET_CHAT_BLOCKING_STATUS](
    state: ChatsState,
  ): (chatId: string) => ChatBlockingStatusEnum | undefined;
  [ChatsGetterTypes.GET_CHAT_IS_DELETED](
    state: ChatsState,
  ): (chatId: string) => boolean;
  [ChatsGetterTypes.GET_SELECTED_CHAT_IS_DELETED](state: ChatsState): boolean;
  [ChatsGetterTypes.GET_SHARED_MESSAGE](state: ChatsState): string | null;
}

export const chatsGetters: GetterTree<ChatsState, RootState> = {
  [ChatsGetterTypes.GET_CHATS_STATE]: (state) => {
    return state;
  },
  [ChatsGetterTypes.GET_CHAT]: (state) => (chatId: string) => {
    if (!chatId) return null;
    return state.chats?.[chatId];
  },
  [ChatsGetterTypes.GET_RAW_CHAT]: (_, getters) => (chatId: string) => {
    const chatState: ChatState | null = getters.GET_CHAT(chatId);
    return chatState?.rawChat;
  },
  [ChatsGetterTypes.GET_CONVERTED_CHAT_DETAIL]:
    (state, getters) => (chatId: string) => {
      if (!chatId || chatId === ChatTypes.NEW_CHAT_TYPE) return;
      const rawChat: RawChat = getters.GET_RAW_CHAT(chatId);
      if (!rawChat) {
        return;
      }
      return state.chats[chatId]?.convertedChat;
    },
  [ChatsGetterTypes.GET_CHATS_INITIAL_LOAD]: (state) => {
    return state.initialLoadCompleted;
  },
  [ChatsGetterTypes.GET_CHATS_STATE_ARRAY]: (state) => {
    const chatsStateArray = convertChatStateToArray(state.chats);
    return [...chatsStateArray.sort(sortChatState)];
  },
  [ChatsGetterTypes.GET_CONVERTED_CHATS_ARRAY_FILTERED]: (state, getters) => {
    const unFilteredChats: ChatState[] = getters.GET_CHATS_STATE_ARRAY;
    const filter = state.chatsFilter.toLowerCase();
    return unFilteredChats
      .map((chatState) => {
        const convertedChat: Chat = getters.GET_CONVERTED_CHAT_DETAIL(
          chatState.rawChat?.id,
        );
        return convertedChat;
      })
      .filter((chat) => chat?.name?.toLowerCase().includes(filter));
  },
  [ChatsGetterTypes.GET_CHATS_ARRAY]: (_, getters) => {
    const chatsStateArray: ChatState[] = getters.GET_CHATS_STATE_ARRAY;

    return chatsStateArray.map((chatState) => {
      const convertedChat: Chat = getters.GET_CONVERTED_CHAT_DETAIL(
        chatState.rawChat?.id,
      );
      return convertedChat;
    });
  },
  [ChatsGetterTypes.GET_CHATS_FILTER]: (state) => {
    return state.chatsFilter;
  },
  [ChatsGetterTypes.GET_SELECTED_CHAT_STATE]: (state) => {
    if (!state.selectedChat || state.selectedChat === ChatTypes.NEW_CHAT_TYPE)
      return;

    return state.chats[state.selectedChat];
  },
  [ChatsGetterTypes.GET_SELECTED_CHAT]: (state, getters) => {
    if (!state.selectedChat || state.selectedChat === ChatTypes.NEW_CHAT_TYPE)
      return;

    const rawChat = state.chats?.[state.selectedChat]?.rawChat;
    if (!rawChat) {
      return;
    }
    const convertedChat: Chat = getters.GET_CONVERTED_CHAT_DETAIL(rawChat.id);
    return convertedChat;
  },
  [ChatsGetterTypes.GET_SELECTED_CHAT_MESSAGES_ARRAY]: (state, getters) => {
    if (!state.selectedChat || state.selectedChat === ChatTypes.NEW_CHAT_TYPE)
      return [];
    const messagesArray = getters.GET_CHAT_MESSAGES_ARRAY(state.selectedChat);
    return messagesArray;
  },
  [ChatsGetterTypes.GET_CHAT_MESSAGES_ARRAY]:
    (state, getters) => (chatId: string) => {
      if (!chatId) return [];

      const rawChat: RawChat = getters.GET_RAW_CHAT(chatId);
      const chatState: ChatState = getters.GET_CHAT(chatId);
      if (!rawChat || !chatState.messageIds) {
        return [];
      }
      const uniqueMessageIds = [...new Set(chatState.messageIds)];
      const rawMessages: RawMessage[] = uniqueMessageIds.map(
        (id) => state.chats[chatId].messages[id],
      );

      return rawMessages.map((rawMessage) =>
        convertRawMessage({ rawMessage, rawChat, ignoreReplies: false }),
      );
    },
  [ChatsGetterTypes.GET_SELECTED_CHAT_ID]: (state) => {
    return state.selectedChat;
  },
  [ChatsGetterTypes.GET_SELECTED_MESSAGE_IDS]: (state) => {
    return state?.selectedMessageIds || null;
  },
  [ChatsGetterTypes.GET_SELECTED_CHAT_BLOCKING_STATUS]: (state, getters) => {
    const blockingStatus = getters.GET_CHAT_BLOCKING_STATUS(state.selectedChat);

    return blockingStatus;
  },
  [ChatsGetterTypes.GET_CHAT_BLOCKING_STATUS]:
    (_, getters) => (chatId: string) => {
      const convertedChat: Chat = getters.GET_CONVERTED_CHAT_DETAIL(chatId);
      if (!convertedChat) {
        return;
      }

      return convertedChat?.blockingStatus;
    },
  [ChatsGetterTypes.GET_CHAT_IS_DELETED]: (_, getters) => (chatId: string) => {
    const convertedChat: Chat = getters.GET_CONVERTED_CHAT_DETAIL(chatId);
    if (!convertedChat) {
      return;
    }

    return convertedChat?.isDeleted;
  },
  [ChatsGetterTypes.GET_SELECTED_CHAT_IS_DELETED]: (state, getters) => {
    const isDeleted = getters.GET_CHAT_IS_DELETED(state.selectedChat);
    return isDeleted;
  },
  [ChatsGetterTypes.GET_SHARED_MESSAGE]: (state) => {
    return state.sharedMessage;
  },
  [ChatsGetterTypes.GET_MESSAGE_REACTION_ID]: (state) => {
    return state.messageReactionId;
  },
};
