import {
  MessageType,
  RawFriendRequestMessage,
  RawMessage,
} from "@/store/chats/types";

export const getIsRawMessageFriendRequestType = (
  message: RawMessage,
): message is RawFriendRequestMessage =>
  message.messageType === MessageType.FriendRequest;
