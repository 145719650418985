import store from "@/store";
import { Profile } from "@/store/auth/state";
import { IListItem, ListItemTypeEnum } from "@/types/list";
import {
  isAppEnvironmentAndroid,
  isStandaloneAppEnvironmentContainer,
} from "../app";
import { handleSendReportErrorEmail } from "./handleSendReportErrorEmail";
import { IconType } from "@/types/icons";

export const getSettingsMenuItems = (): IListItem[] => {
  const profile: Profile | null = store.getters.GET_AUTH_PROFILE;

  return [
    {
      text: "settings.personalDetails",
      iconName: IconType.COG,
      link: "/settings/personal-details",
      type: ListItemTypeEnum.Link,
      isHidden: !profile?.email,
    },
    {
      text: "settings.connectPhoneNumber",
      iconName: IconType.PHONE_SMART,
      link: "/settings/connect-phone-number",
      type: ListItemTypeEnum.Link,
      isHidden: !isStandaloneAppEnvironmentContainer,
    },
    {
      text: "settings.notifications",
      iconName: IconType.RING_BLACK,
      link: "/settings/notifications",
      type: ListItemTypeEnum.Link,
      isHidden: !isStandaloneAppEnvironmentContainer,
    },
    {
      text: "settings.transferContacts",
      iconName: IconType.CALLS_BLACK,
      link: "/settings/transfer-contacts",
      type: ListItemTypeEnum.Link,
      // disabled for Android due to problems with app review
      isHidden: !isStandaloneAppEnvironmentContainer || isAppEnvironmentAndroid,
    },
    // {
    //   text: "settings.profilePicture",
    //   iconName: IconType.FACE_ID,
    //   link: "/settings/profile-picture",
    //   type: ListItemTypeEnum.Link,
    // },
    {
      text: "settings.changeLanguage",
      iconName: IconType.LANGUAGE,
      link: "/settings/language",
      type: ListItemTypeEnum.Link,
      isHidden: !isStandaloneAppEnvironmentContainer,
    },
    {
      text: "settings.reportError",
      iconName: IconType.ERROR,
      type: ListItemTypeEnum.Link,
      isHidden: !isStandaloneAppEnvironmentContainer,
      onClick: handleSendReportErrorEmail,
    },
    {
      text: "settings.termsAndConditions.title",
      iconName: IconType.MESSAGE_DOUBLE_BLACK,
      link: "/settings/terms",
      type: ListItemTypeEnum.Link,
      isHidden: !isStandaloneAppEnvironmentContainer,
    },
    {
      text: "settings.logout",
      iconName: IconType.LOGOUT,
      link: "/logout",
      type: ListItemTypeEnum.Link,
    },
  ];
};
