<template>
  <div class="buttons">
    <button @click="cancelDeletingMessages()">
      {{ $t("buttons.TopBarDeletingMessagesButtons.cancelDelete") }}
    </button>
    <button class="active" @click="deleteMessages()">
      {{ $t("buttons.TopBarDeletingMessagesButtons.delete") }}
    </button>
  </div>
</template>

<script>
import { ActionTypes, MutationTypes, store } from "@/store";

export default {
  methods: {
    deleteMessages() {
      const messageIds = store.getters.GET_SELECTED_MESSAGE_IDS;

      store.dispatch(ActionTypes.DELETE_MESSAGES, { messageIds });
      store.commit(MutationTypes.SET_SELECTING_MESSAGES, { selecting: true });
    },
    cancelDeletingMessages() {
      store.commit(MutationTypes.SET_SELECTING_MESSAGES, { selecting: false });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/buttons/topBarDeletingMessagesButtons.scss";
</style>
