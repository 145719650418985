import { HttpRequestMethodEnum } from "@/types/api";
import { request } from "../api";
import { config } from "@/config";

export const unblockUserAsync = async (
  accessToken: string,
  partnerId: number,
) => {
  try {
    const query = await request(
      config.api.maitrejaApi.endpoints.unblockUser,
      HttpRequestMethodEnum.POST,
      { id: partnerId },
      accessToken,
    );

    return query;
  } catch (err) {
    // @ts-expect-error
    console.log(err.message);
  }
};
