<template>
  <div class="chats-preview-container">
    <MobileNavigationBar
      title="share.title"
      action-title="settings.chat.createGroupChat.createGroup"
      :handle-redirect="() => $router.push('/')"
    />
    <ChatSidebarSearchBar />
    <div ref="scrollContainerRef" class="messages-wrapper">
      <ChatPreviewNew v-if="isNewChat && layoutType === LayoutTypes.DESKTOP" />
      <ChatsPreviewLoadingContainer v-if="!initialLoad" />
      <ChatPreview
        v-for="(chat, index) in visibleChats"
        :key="chat.id"
        :index="index"
        :chat="chat"
      />
      <ChatsPreviewLoadingContainer v-if="data.isLoadingMore" />
      <div ref="sentinelRef" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { GetterTypes, MutationTypes } from "@/store";
import { Chat, ChatState } from "@/store/chats/types";
import { LayoutTypes } from "@/store/app/state";
import { useComputedValue, useInfiniteScroll } from "@/composables";
import { useRoute } from "vue-router";
import { computed } from "vue";
import { useStore } from "vuex";
import {
  getChatOnSnapshotListenersObject,
  getFirebaseChatDataAsync,
  getIsChatPreviewVisible,
} from "@/utils/chat";
import { getRawMessagesDetails } from "@/utils/message";
import { IChatModifiedReferenceObject } from "@/types/chat";
import { InfiniteScrollDirection } from "@/types/app";
import { convertRawChat } from "@/utils/chat/convertRawChat";
import { ChatsState } from "@/store/chats/state";
import { getDifferenceArray } from "@/utils/modifiers/getDifferenceArray";
import ChatPreviewNew from "../chat/chatSidebar/ChatPreviewNew.vue";
import ChatsPreviewLoadingContainer from "../chat/chatSidebar/ChatsPreviewLoadingContainer.vue";
import ChatPreview from "../chat/chatSidebar/ChatPreview.vue";
import ChatSidebarSearchBar from "../chat/chatSidebar/ChatSidebarSearchBar.vue";
import MobileNavigationBar from "../layouts/MobileNavigationBar/MobileNavigationBar.vue";
import MessageSenderComponent from "@/mocks/MessageSenderComponent.vue";

const route = useRoute();
const chats = useComputedValue<Chat[]>(
  GetterTypes.GET_CONVERTED_CHATS_ARRAY_FILTERED,
);
const chatsState = useComputedValue<ChatsState>(GetterTypes.GET_CHATS_STATE);
const initialLoad = useComputedValue<boolean>(
  GetterTypes.GET_CHATS_INITIAL_LOAD,
);
const layoutType = useComputedValue(GetterTypes.GET_LAYOUT_TYPE);
const allChatsCount = computed(() => {
  const { allChatIds } = chatsState.value;

  return allChatIds.length;
});
const isNewChat = computed<boolean>(() => route.path === "/new");
const { getters, commit } = useStore();
const lastChat = computed(() => {
  if (!chats.value.length) {
    return;
  }
  const lastChatId = chats.value[chats.value.length - 1].id;
  const chatState: ChatState = getters[GetterTypes.GET_CHAT](lastChatId);
  return chatState;
});
const visibleChats = computed(() =>
  chats.value.filter(getIsChatPreviewVisible),
);

const handleLoadMoreChats = async () => {
  if (!lastChat.value || !initialLoad.value) {
    return;
  }
  const { remainingChatIds, chatIds } = chatsState.value;
  if (allChatsCount.value <= chats.value.length) {
    data.canLoadMore = false;
    return;
  }

  try {
    const chats = await getFirebaseChatDataAsync({
      chatIds: getDifferenceArray(remainingChatIds, chatIds),
    });

    const modifiedChats: IChatModifiedReferenceObject[] = chats.map(
      (rawChat) => {
        const { rawMessages, ...rawMessagesObj } =
          getRawMessagesDetails(rawChat);
        const chatListenersObject = getChatOnSnapshotListenersObject(
          chats.map((chat) => chat.id),
        );
        return {
          ...rawMessagesObj,
          rawChat,
          chatId: rawChat.id,
          isVisible: Boolean(rawChat && rawChat.lastMessage),
          convertedChat: convertRawChat(rawChat),
          chatUnsubscribe: chatListenersObject[rawChat.id].unsubcribe,
        };
      },
    );

    if (modifiedChats.length === 0) {
      data.canLoadMore = false;
      return;
    }
    commit(MutationTypes.APPEND_CHATS, modifiedChats);
  } catch (error) {
    throw error;
  }
};

const { scrollContainerRef, sentinelRef, data } = useInfiniteScroll(
  handleLoadMoreChats,
  InfiniteScrollDirection.Down,
);
</script>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatSidebar/chatsPreviewContainer.scss";
</style>
