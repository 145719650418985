import { config } from "@/config";
import { request } from "@/services";
import { Profile } from "@/store/auth/state";
import { HttpRequestMethodEnum } from "@/types/api";

export const getAuthFriendsAsync = async (
  accessToken: string,
): Promise<Profile[] | undefined> => {
  try {
    const query = await request(
      config.api.maitrejaApi.endpoints.friends,
      HttpRequestMethodEnum.GET,
      undefined,
      accessToken,
    );

    const friends: Profile[] = query.data?.friends || [];
    return friends;
  } catch (e) {
    console.log(e);
  }
};
