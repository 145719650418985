import store from "@/store";
import { AuthGetterTypes } from "@/store/auth";
import { joinRoom } from "./joinRoom";
import { setInitialDominantParticipant } from "./setInitialDominantParticipant";
import { RoomData } from "@/types/call";
import { ShallowReactive } from "vue";

export const setInitialRoomData = (data: ShallowReactive<RoomData>) => {
  const ongoingCurrentCall =
    store.getters[AuthGetterTypes.GET_CURRENT_ONGOING_CALL_ID];
  if (!ongoingCurrentCall) {
    return;
  }
  data.localParticipant = null;
  setInitialDominantParticipant(data);
  joinRoom(data);
};
