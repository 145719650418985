<template>
  <div class="chat-sidebar-options">
    <div class="wrapper-title">
      <ProfileImage
        v-if="
          layoutType === LayoutTypes.MOBILE &&
          isStandaloneAppEnvironmentContainer
        "
        :id="profile.id"
        :image="profile.picture"
        :size="36"
        redirect-link="/settings"
      />
      <h4>
        {{ $t("chat.chatSidebar.ChatSidebarTopBar.title") }}
      </h4>
      <div class="icons">
        <Icon :icon="appliedIcon" class="icon" @click="handleOpenNewChat" />
      </div>
    </div>
    <ChatSidebarSearchBar />
  </div>
</template>

<script setup lang="ts">
import ProfileImage from "@/components/images/ProfileImage.vue";
import ChatSidebarSearchBar from "@/components/chat/chatSidebar/ChatSidebarSearchBar.vue";
import { GetterTypes, MutationTypes, store } from "@/store";
import { LayoutTypes } from "@/store/app/state";
import { isStandaloneAppEnvironmentContainer } from "@/utils/app";
import { useComputedValue } from "@/composables";
import { Profile } from "@/store/auth/state";
import { useRouter } from "vue-router";
import { computed } from "vue";
import Icon from "@/components/icons/Icon/Icon.vue";
import { IconType } from "@/types/icons";

const profile = useComputedValue<Profile>(GetterTypes.GET_AUTH_PROFILE);
const layoutType = useComputedValue<LayoutTypes>(GetterTypes.GET_LAYOUT_TYPE);
const router = useRouter();

const handleOpenNewChat = () => {
  store.commit(MutationTypes.REMOVE_SELECTED_CHAT);
  router.push("/new");
};

const appliedIcon = computed(() => {
  let iconName = "NEW_MESSAGE";
  if (layoutType.value === LayoutTypes.MOBILE) {
    iconName += "_ORANGE";
  }

  return IconType[iconName as keyof typeof IconType];
});
</script>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatSidebar/chatSidebarTopBar.scss";
</style>
 
