<template>
  <div
    class="message-with-reactions"
    @mouseenter="showDesktopControls"
    @mouseleave="hideDesktopControls"
  >
    <Transition v-if="!isMobile && !isMessageAutomatic" name="pop">
      <div
        v-if="isDesktopControlsVisible"
        class="desktop-reactions-panel"
        :class="{ isMine }"
      >
        <div class="icon-wrapper" @mouseleave="hideActiveIcon">
          <div class="emoji-icon-inner" @mouseenter="showActiveIcon">
            <Transition name="pop">
              <MessageEmojiReactionsPanel
                v-if="isEmojiActive"
                :style="styleObj"
                :message-id="message.id"
                :is-mine="isMine"
                @close-panel="hideDesktopControls"
              />
            </Transition>
            <Icon :icon="appliedIcon" />
          </div>
        </div>
      </div>
    </Transition>
    <slot />
  </div>
  <div
    v-if="
      !getIsAutomaticMessageType(message) &&
      message.reactions?.visibleItems?.length
    "
    class="current-emoji-reactions"
    :class="{ isMine }"
    @click="openReactionsModal"
  >
    <EmojiItem
      v-for="(emoji, ind) in message.reactions.visibleItems"
      :key="ind"
      :size="isMobile ? 12 : 20"
      :emoji="emoji"
    />
    <span v-if="message.reactions.isCountVisible" class="reactions-count">{{
      message.reactions.count
    }}</span>
  </div>
</template>

<script setup lang="ts">
import EmojiItem from "@/components/cards/EmojiItem/EmojiItem.vue";
import { getIsAutomaticMessageType } from "@/utils/message";
import { Message } from "@/store/chats/types";
import { computed, HTMLAttributes, ref } from "vue";
import { IconType } from "@/types/icons";
import Icon from "@/components/icons/Icon/Icon.vue";
import MessageEmojiReactionsPanel from "../MessageEmojiReactionsPanel/MessageEmojiReactionsPanel.vue";
import { GetterTypes } from "@/store";
import { useComputedValue } from "@/composables";
import { LayoutTypes } from "@/store/app/state";
import { config } from "@/config";

interface ChatMessageReactionsProps {
  message: Message;
  isMine: boolean;
  elementId: string;
}

interface Emits {
  (e: "openModal"): void;
}

const emit = defineEmits<Emits>();

const props = defineProps<ChatMessageReactionsProps>();
const isEmojiActive = ref(false);
const isDesktopControlsVisible = ref(false);
const layoutType = useComputedValue<LayoutTypes>(GetterTypes.GET_LAYOUT_TYPE);
const isMobile = computed(() => layoutType.value === LayoutTypes.MOBILE);
const isMessageAutomatic = computed(() =>
  getIsAutomaticMessageType(props.message),
);
const transformStyle = ref("");
const styleObj = computed<HTMLAttributes["style"]>(() => ({
  transform: transformStyle.value,
}));

const appliedIcon = computed(() => {
  if (isEmojiActive.value) {
    return IconType.EMOJI_BLACK;
  }

  return IconType.EMOJI_GRAY;
});
const openReactionsModal = () => {
  emit("openModal");
};

const toggleDesktopControls = (newValue: boolean) => {
  if (isMobile.value) {
    return;
  }
  isDesktopControlsVisible.value = newValue;
};

const showDesktopControls = () => {
  toggleDesktopControls(true);
};
const hideDesktopControls = () => {
  toggleDesktopControls(false);
  hideActiveIcon();
};

const getTransformStyle = (width: number) => {
  let addition: number = width + 16;
  if (props.isMine) {
    addition = -addition;
  }

  return `translateX(calc(-50% + ${addition}px))`;
};

const safeTransformReactionsPanel = () => {
  const contentEl = document.getElementById(props.elementId);
  if (!contentEl) {
    return;
  }
  const { width } = contentEl.getBoundingClientRect();
  // if the width of reactions panel does not overflow the chat wrapper, do nothing
  if (width >= config.constants.REACTIONS_SAFE_WIDTH) {
    return;
  }
  transformStyle.value = getTransformStyle(width);
};

const showActiveIcon = () => {
  toggleActiveIcon(true);
  safeTransformReactionsPanel();
};

const hideActiveIcon = () => {
  toggleActiveIcon(false);
};

const toggleActiveIcon = (newValue: boolean) => {
  isEmojiActive.value = newValue;
};
</script>

<style lang="scss">
@import "./MessageWithReactions.scss";
</style>
