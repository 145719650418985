import { convertFirebaseDateToLocal } from "@/firebase/utils";
import { Profile } from "@/store/auth/state";
import {
  GroupRawChat,
  Chat,
  ChatTypes,
  Message,
  ChatBlockingStatusEnum,
} from "@/store/chats/types";
import { User } from "@/store/users/state";
import { createChatNameFromMembers } from "./createChatNameFromMembers";
import { CallType } from "@/types/webview";
import { IconType } from "@/types/icons";
import { getIconPath } from "../app/getIconPath";

export const convertRawGroupChat = (
  rawChat: GroupRawChat,
  auth: Profile,
  members: User[],
  latestMessage?: Message,
): Chat => {
  const nameFromMembers = createChatNameFromMembers(members, auth.id);

  return {
    type: ChatTypes.GROUP_CHAT,
    id: rawChat.id,
    name: rawChat?.name || nameFromMembers,
    hasName: !!rawChat?.name,
    picture: rawChat?.picture || getIconPath(IconType.DEFAULT_GROUP_GRAY),
    createdAt: convertFirebaseDateToLocal(rawChat.createdAt),
    createdBy: rawChat.createdBy,
    modifiedAt: convertFirebaseDateToLocal(rawChat.modifiedAt),
    membersIds: rawChat.members,
    members,
    nameFromMembers,
    hidden: Boolean(rawChat?.hide),
    isOwner: auth.id === rawChat.createdBy,
    latestMessage,
    activeCall: rawChat.activeCall,
    blockingStatus: ChatBlockingStatusEnum.None,
    isDeleted: false,
    pendingCallType: rawChat.pendingCallType ?? CallType.Video,
  };
};
