<template>
  <div class="emojis-option" :class="{ isActive }" @click="toggleSwitchType">
    <Icon :icon="appliedIcon" alt="emoji-icon" class="switch-icon" />
  </div>
</template>

<script setup lang="ts">
import Icon from "@/components/icons/Icon/Icon.vue";
import { IconType } from "@/types/icons";
import { SwitchType } from "@/types/cards";
import { computed } from "vue";

interface Props {
  activeSwitchType: SwitchType;
  switchType: SwitchType;
}
interface Emits {
  (e: "toggle-switch-type", switchType: SwitchType): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const isActive = computed(() => props.activeSwitchType === props.switchType);

const appliedIcon = computed(() => {
  if (props.switchType === SwitchType.Gif && isActive.value) {
    return IconType.GIF_BLACK;
  }
  if (props.switchType === SwitchType.Gif) {
    return IconType.GIF_GRAY;
  }

  if (isActive.value) {
    return IconType.EMOJI_BLACK;
  }

  return IconType.EMOJI_GRAY;
});

const toggleSwitchType = () => {
  emit("toggle-switch-type", props.switchType);
};
</script>

<style lang="scss" scoped>
@import "./EmojisAndGiphsSwitch.scss";
</style>
