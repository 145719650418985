import { getFormattedTime } from "@/utils/modifiers";
import { AudioRecordingData } from "../ChatInputBar/ChatInputBarTypes";

const defaultRecordingData: AudioRecordingData = {
  recordingFile: null,
  isRecording: false,
  isPaused: false,
  totalTime: getFormattedTime(0),
  currentTime: getFormattedTime(0),
  isFinished: false,
  hasPlayed: false,
  shouldClear: false,
};

export const getDefaultRecordingData = (duration?: string) => {
  const data = { ...defaultRecordingData };
  if (duration) {
    data.totalTime = duration;
  }

  return data;
};
