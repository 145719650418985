import { JointParticipant, RoomData } from "@/types/call";
import { ShallowReactive } from "vue";
import { convertParticipantToRoomParticipant } from "./convertParticipantToRoomParticipant";

export const setDominantParticipant = (
  participant: JointParticipant | null,
  data: ShallowReactive<RoomData>,
) => {
  if (!participant) {
    data.dominantParticipant = null;
    return;
  }

  data.dominantParticipant = convertParticipantToRoomParticipant(participant);
};
