<template>
  <SwitchIconButton
    :active-icon="IconType.CONTROL_HANG_UP"
    :hover-icon="IconType.CONTROL_HANG_UP_HOVER"
    @click="handleHangUp"
  />
</template>

<script setup lang="ts">
import SwitchIconButton from "@/components/buttons/SwitchIconButton/SwitchIconButton.vue";
import { AuthMutationTypes } from "@/store/auth";
import { IconType } from "@/types/icons";
import { Room } from "twilio-video";
import { useStore } from "vuex";

interface Props {
  room: Room | null;
}
const props = withDefaults(defineProps<Props>(), {
  room: null,
});

const { commit } = useStore();

const handleHangUp = () => {
  if (!props.room) {
    return;
  }
  commit(AuthMutationTypes.SET_CURRENT_ONGOING_CALL_ID, null);
};
</script>

<style lang="scss" scoped></style>
