import { Dimensions } from "@/store/chats/types";

export const getVideoDimensions = (url: string): Promise<Dimensions> => {
  return new Promise((resolve) => {
    const video = document.createElement("video");
    video.addEventListener("loadedmetadata", function () {
      const height = this.videoHeight;
      const width = this.videoWidth;
      resolve({ height, width });
    });

    video.preload = "metadata";
    video.src = url;
  });
};
