import { RoomData } from "@/types/call";
import { ShallowReactive } from "vue";
import { handleParticipantConnected } from "./handleParticipantConnected";
import { handleParticipantDisconnected } from "./handleParticipantDisconnected";
import { handleDominantSpeakerChanged } from "./handleDominantSpeakerChanged";
import { disconnectFromCurrentRoom } from "./disconnectFromCurrentRoom";

export const addRoomListeners = (data: ShallowReactive<RoomData>) => {
  if (!data.room) {
    return;
  }

  data.room.on("participantConnected", (participant) => {
    handleParticipantConnected(participant, data);
  });
  data.room.on("participantDisconnected", (participant) => {
    handleParticipantDisconnected(participant, data);
  });
  data.room.on("dominantSpeakerChanged", (participant) => {
    handleDominantSpeakerChanged(participant, data);
  });
  data.room.once("disconnected", () => {
    if (!data.room) {
      return;
    }
    disconnectFromCurrentRoom(data);
  });
  window.addEventListener("pagehide", () => data.room?.disconnect());
  window.addEventListener("beforeunload", () => data.room?.disconnect());
};
