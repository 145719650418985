import { checkUserBlockingAsync } from "@/services/maitrejaApi";
import store from "@/store";
import { ChatBlockingStatusEnum } from "@/store/chats/types";

export const getIsUserBlockedAsync = async (partnerId: number) => {
  if (!partnerId) {
    throw new Error("partner not defined");
  }

  try {
    const accessToken = store.getters.GET_AUTH_ACCESS_TOKEN;
    if (!accessToken) {
      throw new Error("Access token not defined");
    }

    const blockingStatus: ChatBlockingStatusEnum = await checkUserBlockingAsync(
      accessToken,
      partnerId,
    );
    return blockingStatus;

    // @ts-expect-error
  } catch (err: Error) {
    console.log(err.message);
    throw err;
  }
};
