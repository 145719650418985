import whatIsNewObj from "@/versions/what-is-new.json";
import { IWhatIsNewObject, IWhatIsNewPoint } from "@/types/app";
import { getNewestAppVersionString } from "./getNewestAppVersionString";

export const getNewestAppVersionPointsArray = (): IWhatIsNewPoint[] => {
  const theNewestVersion = getNewestAppVersionString();
  const object = whatIsNewObj as IWhatIsNewObject;
  const newestAppVersionPointsArray = object[theNewestVersion];

  return newestAppVersionPointsArray;
};
