<template>
  <NewChatMobileContainer />
</template>
<script setup lang="ts">
import NewChatMobileContainer from "@/components/chat/chatContainer/NewChatMobileContainer/NewChatMobileContainer.vue";
import { ChatTypes } from "@/store/chats/types";
import { chatTypeInjectionKey } from "@/utils/chat/chatTypeInjectionKey";
import { provide } from "vue";

provide(chatTypeInjectionKey, {
  type: ChatTypes.ONE_TO_ONE_CHAT,
});
</script>
<style lang="scss" scoped></style>
