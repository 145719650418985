import store, { ActionTypes } from "@/store";
import { RawMessage } from "@/store/chats/types";
import { getIsRawMessageTextType } from "@/utils/message";
import { canUserMarkMessageAsRead } from "./canUserMarkMessageAsRead";

interface IMarkMessageAsReadArgs {
  lastMessage: RawMessage | null;
  chatId: string | undefined;
}

export const markMessageAsRead = ({
  lastMessage,
  chatId,
}: IMarkMessageAsReadArgs): void => {
  if (!lastMessage || !chatId) {
    return;
  }

  if (
    !getIsRawMessageTextType(lastMessage) ||
    !canUserMarkMessageAsRead({ lastMessage, chatId })
  ) {
    return;
  }

  store.dispatch(ActionTypes.MARK_MESSAGE_AS_READ, {
    messageId: lastMessage.id,
    chatId,
  });
};
