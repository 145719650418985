<template>
  <div ref="root" class="chat-wrapper" :class="{ isNoMembers }" :style="style">
    <ChatTopBarNewChat
      ref="searchBar"
      :members="data.members"
      @add-member="addMember"
      @remove-member="removeMember"
    />
    <ChatContent
      :messages="data.messages"
      :is-deleted="data.isDeleted"
      :blocking-status="data.blockingStatus"
      :user-name="data.userName"
    />
    <ChatInputBar
      v-if="!isBlockingTextShown"
      ref="inputBar"
      :members="data.members"
      :already-exists-chat-id="data.chatId"
      :new-chat="true"
    />
  </div>
</template>

<script setup lang="ts">
import ChatContent from "@/components/chat/chatContainer/ChatContent/ChatContent.vue";
import ChatTopBarNewChat from "../ChatTopBarNewChat/ChatTopBarNewChat.vue";
import ChatInputBar from "@/components/chat/chatContainer/ChatInputBar/ChatInputBar.vue";
import { useGetNewChatContainerProperties } from "./useGetNewChatContainerProperties";

const {
  isNoMembers,
  style,
  data,
  addMember,
  removeMember,
  isBlockingTextShown,
  inputBar,
} = useGetNewChatContainerProperties();
</script>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatContainer/chatContainer.scss";
</style>
