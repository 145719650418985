<template>
  <div class="gifs-selection">
    <SearchBar :placeholder="t('forms.searchTenor')" @search="searchGifs" />
    <div class="gifs-container">
      <GifItem
        v-for="{ description, url } in searchData"
        :key="url"
        :description="description"
        :url="url"
        @insert-gif="insertGif"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import SearchBar from "@/components/forms/SearchBar/SearchBar.vue";
import GifItem from "../GifItem/GifItem.vue";
import { fetchTenorApi } from "@/plugins";
import { TenorDisplayObject, TenorResultsObject } from "@/types/gifs";
import { onMounted, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";

interface Emits {
  (e: "insert-gif", url: string): void;
}

const emit = defineEmits<Emits>();
const searchData = ref<TenorDisplayObject[]>([]);
const featuredData = ref<TenorDisplayObject[]>([]);
const { locale, t } = useI18n();

const insertGif = (url: string) => {
  emit("insert-gif", url);
};

const getTenorDisplayObjects = (
  data: TenorResultsObject,
): TenorDisplayObject[] =>
  data.results.map(({ content_description, media_formats }) => ({
    description: content_description,
    url: media_formats.gif.url,
  }));

const fetchFeaturedGifs = async () => {
  const { data } = await fetchTenorApi("featured", { locale: locale.value });
  saveData(data, featuredData);
  saveData(data, searchData);
};

const saveData = (data: TenorResultsObject, ref: Ref<TenorDisplayObject[]>) => {
  const results = getTenorDisplayObjects(data);
  ref.value = results;
};

const searchGifs = async (searchText: string) => {
  if (!searchText) {
    searchData.value = { ...featuredData.value };
    return;
  }
  const { data } = await fetchTenorApi("search", {
    q: searchText,
    locale: locale.value,
  });
  saveData(data, searchData);
};

onMounted(() => {
  fetchFeaturedGifs();
});
</script>

<style lang="scss" scoped>
@import "./GifsSelection.scss";
</style>
