import { chatApiAxios } from "@/plugins";
import { UpdateChatMembersArgs } from "@/store/chats/types";
import { HttpRequestMethodEnum } from "@/types/api";

export const updateChatMembersBeAsync = async ({
  chatId,
  addMemberIds,
  removeMemberIds,
  firebaseIdToken,
}: UpdateChatMembersArgs) => {
  const headers = {
    Authorization: `Bearer ${firebaseIdToken}`,
  };
  const res = await chatApiAxios("chat/" + chatId + "/members", {
    method: HttpRequestMethodEnum.PATCH,
    headers,
    data: {
      addMemberIds,
      removeMemberIds,
    },
  });
  return res.data;
};
