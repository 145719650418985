export const getParsedPhoneNumberString = (phoneNumber: string): string => {
  const formattedPhoneNumberArray = phoneNumber.split("");
  let formattedPhoneNumber = "";
  formattedPhoneNumberArray.forEach((char) => {
    if (/^[0-9]*$/.test(char)) {
      formattedPhoneNumber += char;
    }
  });

  return formattedPhoneNumber;
};
