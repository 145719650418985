import store, { ActionTypes } from "@/store";

export const addLatestChatListener = (
  storeChatIds: string[],
  firebaseChatIds: string[],
) => {
  const lastChatId: string | undefined =
    firebaseChatIds[firebaseChatIds.length - 1];
  const isChatRendered = storeChatIds.find((chatId) => chatId === lastChatId);

  if (isChatRendered) {
    return;
  }
  store.dispatch(ActionTypes.ADD_CHAT_LISTENER, { chatId: lastChatId });
};
