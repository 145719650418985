import { useComputedValue } from "@/composables/useComputedValue";
import { useVisualViewport } from "@/composables/useVisualViewport";
import store, { GetterTypes } from "@/store";
import { Profile } from "@/store/auth/state";
import { ChatBlockingStatusEnum, Message } from "@/store/chats/types";
import { isAppEnvironmentContainer } from "@/utils/app";
import { getIsUserBlockedAsync as getIsUserBlockedAsync } from "@/utils/auth";
import { createSingleChatId } from "@/utils/chat";
import {
  ref,
  ComponentPublicInstance,
  reactive,
  computed,
  watch,
  Ref,
  nextTick,
} from "vue";
import ChatInputBarComponent from "../ChatInputBar/ChatInputBar.vue";
import ChatTopBarNewChatComponent from "../ChatTopBarNewChat/ChatTopBarNewChat.vue";
import { getUserByIdAsync } from "@/services/maitrejaApi";

interface Data {
  members: Profile[];
  messages: Message[];
  newChat: boolean;
  chatId: string | null;
  blockingStatus: ChatBlockingStatusEnum;
  isDeleted: boolean;
  userName: string;
}

export const useGetNewChatContainerProperties = () => {
  const root = ref<HTMLElement>();
  const inputBar = ref<ComponentPublicInstance<typeof ChatInputBarComponent>>();
  const searchBar =
    ref<ComponentPublicInstance<typeof ChatTopBarNewChatComponent>>();
  const { style } = useVisualViewport(root);

  const data = reactive<Data>({
    members: [],
    messages: [],
    newChat: true,
    chatId: null,
    blockingStatus: ChatBlockingStatusEnum.None,
    isDeleted: false,
    userName: "",
  });

  const authId = useComputedValue<number | null>(GetterTypes.GET_AUTH_ID);
  const accessToken = useComputedValue<string>(
    GetterTypes.GET_AUTH_ACCESS_TOKEN,
  );
  const isBlockingTextShown = computed<boolean>(() => {
    return Boolean(
      (data.blockingStatus &&
        data.blockingStatus !== ChatBlockingStatusEnum.None) ||
        data.isDeleted,
    );
  });
  const isNoMembers = computed<boolean>(() => {
    return isAppEnvironmentContainer && !data.members.length;
  });
  const membersLength = computed<number>(() => {
    return data.members.length;
  });

  watch(membersLength, () => {
    updateUserStatuses();
  });

  const updateUserStatuses = async () => {
    if (membersLength.value !== 1) {
      data.blockingStatus = ChatBlockingStatusEnum.None;
      return;
    }
    const partnerId = data.members[0].id;
    try {
      const [blockingStatus, user] = await Promise.all([
        getIsUserBlockedAsync(partnerId),
        getUserByIdAsync(accessToken.value, partnerId),
      ]);
      data.blockingStatus = blockingStatus;
      if (user) {
        data.isDeleted = user.isDeleted;
        data.userName = user.name;
      }
    } catch (error) {
      throw error;
    }
  };

  const addMember = (newMember: Profile) => {
    const alreadyIn = data.members.some((member) => member.id === newMember.id);

    if (alreadyIn) return;
    data.members.push(newMember);

    checkIfUserHaveAlreadyConversation();
  };

  const removeMember = (removeMember: Profile) => {
    data.members = data.members.filter(
      (member) => member.id !== removeMember.id,
    );
    checkIfUserHaveAlreadyConversation();
  };
  const focusTextInput = () => {
    focusInput(inputBar, "textarea");
  };
  const focusSearchbar = () => {
    focusInput(searchBar);
  };

  const focusInput = (
    inputRef: Ref<ComponentPublicInstance<any>>,
    type = "input",
  ) => {
    nextTick(() => {
      const inputNode = inputRef.value?.$el.querySelector(type);

      if (inputNode) {
        inputNode.focus({ preventScroll: true });
      }
    });
  };

  const checkIfUserHaveAlreadyConversation = () => {
    if (data.members.length !== 1) {
      data.messages = [];
      data.chatId = null;
      focusSearchbar();
      return;
    }
    const chatId = createSingleChatId(
      String(authId.value),
      data.members[0].id.toString(),
    );

    const chatState = store.getters.GET_CHAT(chatId);
    data.messages = [];
    data.chatId = null;

    if (chatState?.messageIds.length) {
      const messages = store.getters.GET_CHAT_MESSAGES_ARRAY(chatId);
      data.messages = messages;
      data.chatId = chatId;
    }

    focusTextInput();
  };

  return {
    isNoMembers,
    style,
    data,
    addMember,
    removeMember,
    isBlockingTextShown,
    inputBar,
  };
};
