import { NonAutomaticMessage } from "@/store/chats/types";
import { getMessageContentElementId } from "./getMessageContentElementId";

export const skipToReplyMessage = (replyToMessage: NonAutomaticMessage) => {
  const messageContentEl = document.getElementById(
    getMessageContentElementId(replyToMessage.id),
  );
  if (!messageContentEl) {
    return;
  }

  messageContentEl.scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "nearest",
  });
};
