import { AppEnvironmentEnum } from "@/store/app/state";
import { isAppEnvironmentAndroid } from "./isAppEnvironmentAndroid";
import { isAppEnvironmentIos } from "./isAppEnvironmentIos";

export const getApplicationEnvironment = (): AppEnvironmentEnum => {
  let applicationEnvironment: AppEnvironmentEnum = AppEnvironmentEnum.Web;
  if (isAppEnvironmentIos) {
    applicationEnvironment = AppEnvironmentEnum.IOS;
  }
  if (isAppEnvironmentAndroid) {
    applicationEnvironment = AppEnvironmentEnum.Android;
  }

  return applicationEnvironment;
};
