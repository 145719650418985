export const getTodayMilliSeconds = (dateTime: number): number => {
  const date = new Date(dateTime);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const milliseconds = date.getMilliseconds();

  let total = 0;
  total += hours * 3600000;
  total += minutes * 60000;
  total += seconds * 1000;
  total += milliseconds;

  return total;
};
