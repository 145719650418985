<template>
  <OnboardingTransferContacts />
</template>
<script setup lang="ts">
import OnboardingTransferContacts from "@/components/onboarding/OnboardingTransferContacts/OnboardingTransferContacts.vue";
import { OnboardingContainerTypeEnum } from "@/types/onboarding";
import { onboardingInjectionKey } from "@/utils/onboarding/onboardingInjectionKey";
import { provide } from "vue";

provide(onboardingInjectionKey, {
  type: OnboardingContainerTypeEnum.Settings,
});
</script>
<style lang="scss" scoped></style>
