<template>
  <div class="input-container">
    <InputLabel v-if="label" :name="name" :label="label" />
    <input
      :id="name"
      :ref="name"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      class="input"
      :value="appliedValue"
      :name="name"
      :type="type"
      :placeholder="placeholder ? $t(placeholder) : undefined"
      :required="required"
      :pattern="pattern ? pattern : undefined"
      @keydown="handleKeyDown"
      @input="handleInput($event as InputEvent)"
    />
    <Icon v-if="isChecked" :icon="IconType.CHECKMARK" class="is-checked" />
    <InputErrorMessage v-if="!hideErrorMessage" :error-message="error" />
  </div>
</template>

<script setup lang="ts">
import InputLabel from "@/components/forms/InputLabel.vue";
import InputErrorMessage from "@/components/forms/InputErrorMessage.vue";
import { useRestrictNumberInput } from "@/composables";

import {
  FormFieldNameEnum,
  FormFieldType,
  FormInputErrorMessageTypeEnum,
} from "@/types/form";
import { getFormattedThousandsString } from "@/utils/modifiers";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import Icon from "../icons/Icon/Icon.vue";
import { IconType } from "@/types/icons";

interface Props {
  modelValue: string;
  name: FormFieldNameEnum;
  placeholder?: string;
  pattern?: string;
  label?: string;
  type?: FormFieldType;
  required?: boolean;
  // errors?: ErrorsFieldObject["errors"];
  errors?: any;
  hideErrorMessage?: boolean;
  isChecked?: boolean;
}

interface Emits {
  (e: "update:modelValue", value: string): void;
}

const emit = defineEmits<Emits>();
const { t } = useI18n();

const props = withDefaults(defineProps<Props>(), {
  modelValue: "",
  name: FormFieldNameEnum.Name,
  placeholder: "",
  pattern: "",
  label: "",
  type: FormFieldType.Text,
  required: false,
  hideErrorMessage: false,
  errors: undefined,
  isChecked: false,
});

const error = computed<string>(() => {
  let err = FormInputErrorMessageTypeEnum.None;
  if (!props.errors) {
    return err;
  }

  const inputName = props.name;
  const currentError = props.errors[inputName];

  if (currentError) {
    return t(`auth.errors.${currentError}`);
  }

  return err;
});

const handleInputEmit = (event: Event): void => {
  const target = event.target as HTMLInputElement;
  emit("update:modelValue", target.value);
};

const appliedValue = computed<string>(() => {
  let value = props.modelValue;
  if (props.type === FormFieldType.Tel) {
    value = getFormattedThousandsString(value);
  }
  return value;
});

const { handleKeyDown, handleInput } = useRestrictNumberInput(
  props.type,
  handleInputEmit,
);
</script>
<style lang="scss" scoped>
@import "src/assets/scss/form/input.scss";
</style>
