<template>
  <main class="layout">
    <img src="/images/entryPassImage.png" />
    <div class="container">
      <h1>{{ $t("auth.restorePassword") }}</h1>
      <span class="secondary">{{
        $t("auth.restorePasswordInstructions")
      }}</span>
      <router-view></router-view>
      <router-link to="/login" class="text-center">{{
        $t("auth.buttons.backToLogin")
      }}</router-link>
    </div>
  </main>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/form/formViewLayout";
@import "src/assets/scss/form/formLayout.scss";
</style>
