<template>
    <div class="loader-container">
        <svg 
            id="vrstva_1"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 30 30"
            style="enable-background:new 0 0 30 30;"
            xml:space="preserve">
            <g id="loader">
                <circle id="circle" class="st0" cx="15" cy="15" r="13.5" />
                <circle id="spinner" class="st1"  cx="15" cy="15" r="13.5" />
            </g>
        </svg>
    </div>
</template>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatContainer/encodingLoader.scss";
</style>