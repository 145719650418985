import { chatApiAxios } from "@/plugins";
import { config } from "@/config";
import store, { MutationTypes } from "@/store";
import { HttpRequestMethodEnum } from "@/types/api";
import { IUploadFile } from "@/types/chat";
import { Attachment } from "@/store/chats/types";

export const uploadFileBeAsync = async (
  { chatId, messageId, firebaseIdToken, attachment }: IUploadFile,
  attachmentIdx: number,
) => {
  const headers = {
    Authorization: `Bearer ${firebaseIdToken}`,
    "Content-Type": "multipart/form-data",
  };

  const formData = new FormData();
  const file = attachment.compressedFile ?? attachment.file;
  formData.append("attachment", file);
  formData.append("chatId", chatId);
  formData.append("messageId", messageId);
  if (attachment.duration) {
    formData.append("duration", attachment.duration);
  }

  const res = await chatApiAxios<{ attachment: Attachment }>(
    config.api.chatApi.endpoints.message.uploadFile,
    {
      method: HttpRequestMethodEnum.POST,
      headers,
      data: formData,
      onUploadProgress: (e) => {
        if (!e.total) {
          return;
        }
        const progress = Math.floor((e.loaded * 100) / e.total);
        store.commit(MutationTypes.UPDATE_UPLOADING_MESSAGE_PROGRESS, {
          chatId,
          messageId,
          attachmentIdx,
          progress,
        });
      },
    },
  );

  return res.data.attachment;
};
