import { getAuthFriendsAsync } from "@/utils/api";
import { UsersActionType } from "./actionState";
import { UsersActionTypes } from "./actionTypes";
import { UsersMutationTypes } from "./mutationTypes";

export const usersActions: UsersActionType = {
  async [UsersActionTypes.FETCH_AUTH_FRIENDS]({ commit, getters }) {
    try {
      const accessToken = getters.GET_AUTH_ACCESS_TOKEN;
      if (!accessToken) return;

      // @ts-expect-error
      const friends: Users[] = await getAuthFriendsAsync(accessToken);

      commit(UsersMutationTypes.SET_AUTH_FRIENDS, friends);
    } catch (err) {
      console.log(err);
    }
  },
};
