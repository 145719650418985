import { IconType } from "@/types/icons";
import {
  IListItem,
  ListItemTypeEnum,
  ListItemTextColorTypeEnum,
} from "@/types/list";

export const getDeleteConversationArray = (chatId: string): IListItem[] => [
  {
    text: "settings.chat.deleteChat.title",
    iconName: IconType.TRASH_RED,
    type: ListItemTypeEnum.Link,
    textColorType: ListItemTextColorTypeEnum.RED,
    link: `delete_chat/${chatId}`,
  },
];
