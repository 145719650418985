<template>
  <div ref="mediaOutputRef" />
</template>

<script setup lang="ts">
import { useGetCallParticipantProperties } from "@/composables";
import { RoomParticipant } from "@/types/call";

interface Props {
  roomParticipant: RoomParticipant | null;
}
const props = defineProps<Props>();

const { mediaOutputRef } = useGetCallParticipantProperties(props);
</script>
