import store from "@/store";
import { Profile } from "@/store/auth/state";
import {
  CreateCachedMessageArgs,
  MessageType,
  OneToOneRawAudioMessage,
  OneToOneRawTextMessage,
} from "@/store/chats/types";
import { createCachedOneToOneTextMessage } from "./createCachedOneToOneTextMessage";
import { createCachedOneToOneAudioMessage } from "./createCachedOneToOneAudioMessage";

export const createCachedOneToOneChatMessage = ({
  messageText,
  attachments,
  chatId,
  messageType,
}: CreateCachedMessageArgs):
  | OneToOneRawTextMessage
  | OneToOneRawAudioMessage => {
  const auth = store.getters.GET_AUTH_PROFILE as Profile;
  const commonArgs = { attachments, senderId: auth.id, chatId };

  if (messageType === MessageType.Text) {
    return createCachedOneToOneTextMessage({
      ...commonArgs,
      messageText,
    });
  }

  return createCachedOneToOneAudioMessage(commonArgs);
};
