<template>
  <input
    :type="formFieldType"
    :value="value"
    class="partial-input"
    @input="handleInput($event as InputEvent)"
    @keydown="handleInputKeyDown"
    @paste="pasteToPartialInputs"
  />
</template>

<script setup lang="ts">
import { useRestrictNumberInput } from "@/composables";
import { FormFieldType } from "@/types/form";

interface Props {
  value: string;
  index: number;
}

interface IPartialInputEmitType {
  event: InputEvent;
  index: number;
}
interface IPartialKeyDownEmitType {
  event: KeyboardEvent;
  index: number;
}

interface Emits {
  (e: "updatePartialInput", arg: IPartialInputEmitType): void;
  (e: "handlePartialInputKeyDown", arg: IPartialKeyDownEmitType): void;
  (e: "pasteToPartialInputs", arg: ClipboardEvent): void;
}

const emit = defineEmits<Emits>();
const props = defineProps<Props>();
const formFieldType = FormFieldType.Tel;

const updatePartialInput = (e: InputEvent): void => {
  emit("updatePartialInput", { event: e, index: props.index });
};

const handleInputKeyDown = (e: KeyboardEvent): void => {
  handleKeyDown(e);
  emit("handlePartialInputKeyDown", { event: e, index: props.index });
};

const { handleKeyDown, handleInput } = useRestrictNumberInput(
  formFieldType,
  updatePartialInput,
);

const pasteToPartialInputs = (e: ClipboardEvent): void => {
  emit("pasteToPartialInputs", e);
};
</script>

<style lang="scss" scoped>
@import "./PartialInput.scss";
</style>
