import { BridgeMessageActionObject } from "@/types/webview";
import {
  isAppEnvironmentAndroid,
  isStandaloneIosAppEnvironmentContainer,
} from "../app";

export const postWebviewMessage = (actionObject: BridgeMessageActionObject) => {
  if (isAppEnvironmentAndroid) {
    // @ts-expect-error
    window.ReactObserver.postMessage(JSON.stringify(actionObject));
    return;
  }

  if (isStandaloneIosAppEnvironmentContainer) {
    // @ts-expect-error
    window.webkit.messageHandlers.ReactObserver.postMessage(actionObject);
  }
};
