import { PhoneContact } from "@/store/users/state";
import { getPhoneContactPhoneNumberProperties } from "./getPhoneContactPhoneNumberProperties";

export const getPhoneContactRequestItemObjectsArray = (
  phoneContacts: PhoneContact[],
  regionCode: string,
) => {
  const phoneContactRequestItemObjectsArray = phoneContacts.map(({ phone }) => {
    const { countryCode, phoneNumber } = getPhoneContactPhoneNumberProperties(
      phone,
      regionCode,
    );
    return { country_code: countryCode, phone_number: phoneNumber };
  });

  return phoneContactRequestItemObjectsArray;
};
