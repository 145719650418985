<template>
  <div
    v-if="showSenderName(visibleMessages, index, authId)"
    class="message-sender-name"
  >
    <p>
      {{ message.senderName }}
    </p>
    <p v-if="contactUserAlias" class="alias">
      {{ contactUserAliasText }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { showSenderName } from "@/utils/message";
import { AudioMessage, Message, TextMessage } from "@/store/chats/types";
import { useComputedValue } from "@/composables";
import { GetterTypes } from "@/store";
import { computed } from "vue";
import { getPhoneContactUserAliasFromId } from "@/utils/users";

interface ChatMessageSenderProps {
  message: TextMessage | AudioMessage;
  index: number;
  visibleMessages: Message[];
}

const props = defineProps<ChatMessageSenderProps>();
const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);
const contactUserAlias = computed<string | undefined>(() =>
  getPhoneContactUserAliasFromId(props.message.senderId),
);

const contactUserAliasText = computed<string>(
  () => `~${contactUserAlias.value}`,
);
</script>

<style lang="scss" scoped>
@import "./ChatMessageSender.scss";
</style>
