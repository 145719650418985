import { Attachment, AttachmentTypes } from "@/store/chats/types";
import randomstring from "randomstring";
import { getAttachmentType } from "./getAttachmentType";
import { getFileExtension } from "./getFileExtension";
import { getFileName } from "./getFileName";
import { getVideoDimensions } from "@/services/chatApi/message/getVideoDimensions";
import { VideoDisplayStatus } from "@/types/chat";

export const convertFilesToAttachments = async (
  files: File[],
): Promise<Attachment[]> => {
  const promises = Array.from(files).map(async (file) => {
    const type = getAttachmentType(file);
    const url = URL.createObjectURL(file);

    const attachment: Attachment = {
      id: randomstring.generate(16),
      size: file.size,
      name: getFileName(file.name),
      extname: getFileExtension(file.name),
      uploaded: false,
      filename: file.name,
      contentType: file.type,
      type,
      file,
      uploadProgress: 0,
      displayStatus: VideoDisplayStatus.Uploading,
    };
    if (type === AttachmentTypes.VIDEO) {
      return {
        ...attachment,
        dimensions: await getVideoDimensions(url),
      };
    }

    return { ...attachment, url };
  });

  return await Promise.all(promises);
};
