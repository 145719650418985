import { HttpRequestMethodEnum } from "@/types/api";
import { IAuthResponseObject } from "@/types/auth";
import { request } from "../api";
import { config } from "@/config";

export const getAccessTokenAsync = async (refreshToken: string) => {
  try {
    const authData = await request<IAuthResponseObject>(
      config.api.maitrejaApi.endpoints.token,
      HttpRequestMethodEnum.POST,
      {
        client_id: process.env.VUE_APP_MAITREJA_API_KEY,
        grant_type: "access_token",
        refresh_token: refreshToken,
      },
    );

    return authData;
  } catch (error) {
    throw error;
  }
};
