import {
  GroupRawAudioMessage,
  GroupRawTextMessage,
  ReadBy,
} from "@/store/chats/types";

export const getGroupMessageReadBy = (
  message: GroupRawTextMessage | GroupRawAudioMessage,
): ReadBy[] => {
  return (
    message?.readBy?.map((userId) => {
      return { userId, read: true };
    }) || []
  );
};
