import { chatApiAxios } from "@/plugins";
import store, { GetterTypes } from "@/store";
import { HttpRequestMethodEnum } from "@/types/api";
import { config } from "@/config";
import { IStartCallArgs } from "@/types/call";
import { CallType } from "@/types/webview";

export const getCallAccessTokenAsync = async ({
  chatId,
  userId,
  callType = CallType.Video,
}: IStartCallArgs) => {
  const accessToken = store.getters[GetterTypes.GET_AUTH_ACCESS_TOKEN];
  if (!accessToken) {
    return;
  }
  try {
    const res = await chatApiAxios(config.api.chatApi.endpoints.call.prompt, {
      method: HttpRequestMethodEnum.POST,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        room: chatId,
        identity: userId,
        type: callType,
      },
    });
    const twilioAccessToken: string = res.data;

    return twilioAccessToken;
  } catch (error) {
    console.log(error);
  }
};
