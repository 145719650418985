import store from "@/store";
import { AuthGetterTypes } from "@/store/auth";
import { UsersGetterTypes } from "@/store/users";
import { RoomData } from "@/types/call";
import { ShallowReactive } from "vue";

export const setInitialDominantParticipant = (
  data: ShallowReactive<RoomData>,
) => {
  const ongoingCurrentCall =
    store.getters[AuthGetterTypes.GET_CURRENT_ONGOING_CALL];
  if (!ongoingCurrentCall) {
    return;
  }
  const user = store.getters[UsersGetterTypes.GET_USER](
    ongoingCurrentCall.callerId,
  );
  if (!user) {
    return;
  }

  data.dominantParticipant = {
    participant: null,
    user,
    isConnected: false,
  };
};
