<template>
  <button v-if="layoutType === LayoutTypes.MOBILE" @click="returnBack">
    <Icon :icon="IconType.BACK" />
  </button>
</template>

<script setup lang="ts">
import { GetterTypes, MutationTypes } from "@/store";
import { LayoutTypes } from "@/store/app/state";
import { useComputedValue } from "@/composables";
import { useRoute, useRouter } from "vue-router";
import Icon from "@/components/icons/Icon/Icon.vue";
import { IconType } from "@/types/icons";
import { useStore } from "vuex";

const route = useRoute();
const router = useRouter();
const layoutType = useComputedValue<LayoutTypes>(GetterTypes.GET_LAYOUT_TYPE);
const { commit } = useStore();

const returnBack = () => {
  if (route.path === "/") {
    return;
  }

  commit(MutationTypes.CLEAR_CHATS_FILTER);
  router.replace("/");
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/buttons/mobileNavigationBackButton.scss";
</style>
