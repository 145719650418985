import { store } from "@/store";
import { Profile } from "@/store/auth/state";
import { IconType } from "@/types/icons";
import {
  IListItem,
  ListItemTypeEnum,
  ListItemTextBackgroundTypeEnum,
  ListItemTextColorTypeEnum,
} from "@/types/list";

export const getActivateAccountArray = (): IListItem[] => {
  const profile: Profile | null = store.getters.GET_AUTH_PROFILE;
  const isNonActiveAccount = Boolean(
    profile?.countryCode && profile.phoneNumber && !profile.email,
  );
  return [
    {
      text: "settings.activateAccount",
      iconName: IconType.STAR_WHITE,
      type: ListItemTypeEnum.Link,
      textColorType: ListItemTextColorTypeEnum.WHITE,
      backgroundColorType: ListItemTextBackgroundTypeEnum.ORANGE,
      link: "/settings/activate-account",
      isHidden: !isNonActiveAccount,
    },
  ];
};
