<template>
  <div class="icon-wrapper" @mouseleave="hideActiveIcon">
    <div
      class="icon-inner"
      :class="{ isIconActive }"
      @mouseenter="showActiveIcon"
    >
      <slot />
      <Icon :icon="appliedIcon" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { IconType } from "@/types/icons";
import Icon from "@/components/icons/Icon/Icon.vue";

type ControlsType = "emoji" | "reply";

interface DesktopReactionsControlsItemProps {
  type: ControlsType;
  activeIcon: IconType;
  inactiveIcon: IconType;
  isIconActive: boolean;
}

interface Emits {
  (e: "toggleActiveIcon", type: ControlsType, newValue: boolean): void;
  (e: "onActiveIconChange"): void;
}

const emit = defineEmits<Emits>();

const props = defineProps<DesktopReactionsControlsItemProps>();

const appliedIcon = computed(() => {
  if (props.isIconActive) {
    return props.activeIcon;
  }

  return props.inactiveIcon;
});

const showActiveIcon = () => {
  toggleActiveIcon(true);
  emit("onActiveIconChange");
};

const hideActiveIcon = () => {
  toggleActiveIcon(false);
};

const toggleActiveIcon = (newValue: boolean) => {
  emit("toggleActiveIcon", props.type, newValue);
};
</script>

<style lang="scss">
@import "./DesktopReactionsControlsItem.scss";
</style>
