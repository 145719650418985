import store from "@/store";
import { PhoneContact } from "@/store/users/state";
import {
  IUserPhoneContactUser,
  IUserPhoneContactUserResponseObject,
} from "@/types/users";
import { getPhoneContactPhoneNumberProperties } from "./getPhoneContactPhoneNumberProperties";

export const getPhoneContactUsers = (
  contactUsers: IUserPhoneContactUserResponseObject[],
  filteredPhoneContacts: PhoneContact[],
) => {
  const regionCode: string = store.getters.GET_APP_DETAILS_REGION_CODE;
  const phoneContactUsers: IUserPhoneContactUser[] = contactUsers.map(
    ({ name, picture, id, country_code, phone_number, url }) => {
      const { name: alias } = filteredPhoneContacts.find(({ phone }) => {
        const { countryCode, phoneNumber } =
          getPhoneContactPhoneNumberProperties(phone, regionCode);
        return countryCode === country_code && phoneNumber === phone_number;
      }) as PhoneContact;

      return {
        id,
        name,
        picture,
        countryCode: country_code,
        phoneNumber: phone_number,
        alias,
        url,
      };
    },
  );

  return phoneContactUsers;
};
