import { IChatSettingsWarningPoint } from "@/types/chat";
import { IconType } from "@/types/icons";

export const deleteChatWarningPoints: IChatSettingsWarningPoint[] = [
  {
    title: "settings.chat.deleteChat.deleteAllMessages.title",
    text: "settings.chat.deleteChat.deleteAllMessages.text",
    iconName: IconType.TRASH_GRAY,
  },
  {
    title: "settings.chat.deleteChat.photosAndFiles.title",
    text: "settings.chat.deleteChat.photosAndFiles.text",
    iconName: IconType.FILE_GRAY,
  },
];
