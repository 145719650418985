import {
  PhoneNumberUtil,
  PhoneNumber,
  PhoneNumberFormat,
} from "google-libphonenumber";
import { getFormattedThousandsString } from "./getFormattedThousandsString";

export const getPhoneNumberStringWithSpaces = (phoneString: string) => {
  const phoneNumberUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
  const value: PhoneNumber = phoneNumberUtil.parseAndKeepRawInput(phoneString);
  const phoneNumberStringWithDashes = phoneNumberUtil.format(
    value,
    PhoneNumberFormat.INTERNATIONAL,
  );

  const national = phoneNumberUtil.format(value, PhoneNumberFormat.NATIONAL);
  const strippedNational = national
    .replaceAll("(", "")
    .replaceAll(")", "")
    .replaceAll("-", " ");
  if (national === strippedNational) {
    const countryCode = `+${value.getCountryCode()}`;
    const formattedString = getFormattedThousandsString(national);
    return `${countryCode} ${formattedString}`;
  }

  return phoneNumberStringWithDashes.replaceAll("-", " ");
};
