import {
  FormField,
  FormType,
  FormFieldType,
  FormInputErrorMessageTypeEnum,
} from "@/types/form";
import { getIsEmailString } from "./getIsEmailString";

export const getIsFieldValid = (
  data: any,
  formField: FormField,
  formType: FormType,
) => {
  const fieldValue: string = data[formField.field];
  let errorMessageType = FormInputErrorMessageTypeEnum.None;

  switch (formField.type) {
    case FormFieldType.Password:
      if (fieldValue.length < 8 && formType === FormType.Register) {
        errorMessageType = FormInputErrorMessageTypeEnum.InvalidPasswordLength;
      }
      break;
    case FormFieldType.Email:
      const isEmail = getIsEmailString(fieldValue);
      if (!isEmail) {
        errorMessageType = FormInputErrorMessageTypeEnum.InvalidEmailFormat;
      }
      break;

    default:
      break;
  }

  if (fieldValue === "") {
    errorMessageType = FormInputErrorMessageTypeEnum.ThisFieldIsRequired;
  }

  data.errors = {
    ...data.errors,
    [formField.field]: errorMessageType,
  };

  const isFieldValid = errorMessageType === FormInputErrorMessageTypeEnum.None;

  return isFieldValid;
};
