import { Profile } from "@/store/auth/state";
import { GroupRawAudioMessage, GroupRawTextMessage } from "@/store/chats/types";

export const getReadGroupMessage = (
  message: GroupRawTextMessage | GroupRawAudioMessage,
  auth: Profile,
): boolean => {
  if (message.senderId === auth.id) return true;
  return Boolean(message.readBy?.includes(auth.id));
};
