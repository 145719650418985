import store, { ActionTypes } from "@/store";
import { getIsAttachmentForDeletion, getIsMessageTextType } from "../message";
import { Message } from "@/store/chats/types";

interface IShowUnsentAttachmentsSnackbarArgs {
  lastMessage: Message | undefined;
  chatId: string | undefined;
  messages: Message[];
}

export const showUnsentAttachmentsSnackbar = ({
  lastMessage,
  messages,
  chatId,
}: IShowUnsentAttachmentsSnackbarArgs) => {
  if (!chatId || !lastMessage || !getIsMessageTextType(lastMessage)) {
    return;
  }
  const lastMessageHasUnsentAttachments = lastMessage.allAttachments?.some(
    getIsAttachmentForDeletion,
  );

  if (!lastMessageHasUnsentAttachments || !lastMessage.isMine) {
    return;
  }

  store.dispatch(ActionTypes.DELETE_NOT_UPLOADED_ATTACHMENTS, {
    chatId,
    messages: messages.filter(getIsMessageTextType),
  });

  store.dispatch(ActionTypes.MOUNT_SNACKBAR, {
    title: "snackbar.errors.videoWasNotUploaded.title",
    text: "snackbar.errors.videoWasNotUploaded.text",
  });
};
