import router from "@/router";
import { isStandaloneAppEnvironmentContainer } from "../app";
import { getOnboardingPathsArray } from "./getOnboardingPathsArray";

export const handleOnboardingRedirect = () => {
  const onboardingPathsArray = getOnboardingPathsArray();
  const isWelcomeShown = onboardingPathsArray.length === 3;

  if (isStandaloneAppEnvironmentContainer && isWelcomeShown) {
    router.push("/register/welcome");
    return;
  }

  router.push("/");
};
