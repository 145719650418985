import { MutationTypes } from "@/store";
import { ButtonObject } from "@/types/button";
import { OnboardingContainerTypeEnum } from "@/types/onboarding";

import { onboardingInjectionKey } from "@/utils/onboarding/onboardingInjectionKey";
import { computed, provide } from "vue";
import { useRouter } from "vue-router";
import { OnboardingContainerPropsObject } from "@/components/onboarding/OnboardingContainer";
import { useStore } from "vuex";
import { PopupTypes } from "@/store/app/state";

export const useGetSettingsDeleteAccountProperties = () => {
  provide(onboardingInjectionKey, {
    type: OnboardingContainerTypeEnum.DeleteAccount,
  });

  const router = useRouter();
  const { commit } = useStore();

  const primaryButton = computed<ButtonObject>(() => {
    return {
      handleClick: handleRedirectBack,
      text: getTranslationString("primaryButtonText"),
    };
  });

  const secondaryButton = computed<ButtonObject>(() => {
    return {
      handleClick: handleSecondaryButtonClick,
      text: getTranslationString("secondaryButtonText"),
    };
  });

  const deleteAccountProperties = computed<OnboardingContainerPropsObject>(
    () => {
      return {
        title: getTranslationString("title"),
        text: getTranslationString("text"),
        primaryButton: primaryButton.value,
        secondaryButton: secondaryButton.value,
        imageName: "deleteAccount.svg",
        componentName: "deleteAccount",
      };
    },
  );

  const getTranslationString = (property: string): string => {
    return `settings.deleteAccountContent.${property}`;
  };

  const handleSecondaryButtonClick = (): void => {
    commit(MutationTypes.SHOW_POPUP, {
      popupType: PopupTypes.INFO_DELETE_USER_ACCOUNT,
    });
  };

  const handleRedirectBack = () => {
    router.back();
  };
  return { deleteAccountProperties };
};
