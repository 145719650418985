<template>
  <div ref="root" class="new-chat-container">
    <MobileNavigationBar
      title="chat.chatSidebar.NewChatPreview.title"
      :handle-redirect="
        injected.type === ChatTypes.ONE_TO_ONE_CHAT ? handleRedirect : undefined
      "
      :handle-action="handleCreateChat"
      action-title="settings.chat.createGroupChat.createGroup"
    />
    <div class="bottom">
      <div class="groupChat">
        <ChatSidebarSearchBar />
        <div class="groupChatInner">
          <div class="groupMembers">
            <div
              v-for="member in data.selectedGroupMembers"
              :key="member.itemId"
              class="member"
              @click="handleRemoveGroupMember(member)"
            >
              <ProfileAvatar
                :src="member.pictureSrc"
                :is-online="false"
                :size="ProfileAvatarSizeEnum.Medium"
                :is-delete="true"
              />
            </div>
          </div>
          <h4
            v-if="
              friendsListItemsArray.length ||
              otherPeopleListItemsArray.length ||
              phoneContactsRecommendListItemsArray.length
            "
          >
            {{ $t("settings.chat.createGroupChat.usersRecommendations") }}
          </h4>
        </div>
      </div>
      <div class="lists-container" :style="{ height: listsContainerHeight }">
        <NewChatListContainer
          :list-items-array="friendsListItemsArray"
          @add-member="handleAddGroupMember"
        />
        <NewChatListContainer
          title="chat.chatContainer.NewChatListContainer.yourContacts"
          :list-items-array="phoneContactsUsingAppListItemsArray"
          @add-member="handleAddGroupMember"
        />
        <NewChatListContainer
          title="chat.chatContainer.NewChatListContainer.otherPeople"
          :list-items-array="otherPeopleListItemsArray"
          @add-member="handleAddGroupMember"
        />
        <NewChatListContainer
          title="chat.chatContainer.NewChatListContainer.recommend"
          :list-items-array="phoneContactsRecommendListItemsArray"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import MobileNavigationBar from "@/components/layouts/MobileNavigationBar/MobileNavigationBar.vue";
import ProfileAvatar from "@/components/images/ProfileAvatar.vue";
import ChatSidebarSearchBar from "@/components/chat/chatSidebar/ChatSidebarSearchBar.vue";
import NewChatListContainer from "@/components/chat/chatContainer/NewChatListContainer/NewChatListContainer.vue";
import { ProfileAvatarSizeEnum } from "@/types/avatar";
import { ChatTypes } from "@/store/chats/types";
import { useGetNewChatMobileContainerProperties } from "./useGetNewChatMobileContainerProperties";

const {
  listsContainerHeight,
  friendsListItemsArray,
  otherPeopleListItemsArray,
  phoneContactsRecommendListItemsArray,
  phoneContactsUsingAppListItemsArray,
  handleRedirect,
  injected,
  data,
  handleAddGroupMember,
  handleRemoveGroupMember,
  handleCreateChat,
} = useGetNewChatMobileContainerProperties();
</script>
<style lang="scss" scoped>
@import "./newChatMobileContainer.scss";
</style>
