<template>
  <CallRoom v-if="!!ongoingCall" />
  <router-view v-else v-slot="{ Component }">
    <keep-alive max="1">
      <component :is="Component" v-if="!isAppLoading" :key="$route.path" />
    </keep-alive>
  </router-view>
  <LoadingScreen v-if="isAppLoading" />
  <PopupContainer v-if="!isAppLoading && popupType" />
  <Snackbar />
  <RingingCallsContainer />
</template>

<script setup lang="ts">
import LoadingScreen from "@/components/loading/LoadingScreen.vue";
import Snackbar from "@/components/snackbar/Snackbar.vue";
import PopupContainer from "@/components/popup/PopupContainer.vue";
import { useComputedValue, useLoadAppProperties } from "@/composables";
import RingingCallsContainer from "./components/call/RingingCallsContainer/RingingCallsContainer.vue";
import CallRoom from "./components/call/CallRoom/CallRoom.vue";
import { GetterTypes } from "./store";
import { IOngoingCall } from "./store/auth/types";

const { isAppLoading, popupType } = useLoadAppProperties();
const ongoingCall = useComputedValue<IOngoingCall>(
  GetterTypes.GET_CURRENT_ONGOING_CALL,
);
</script>

<style lang="scss">
@import "assets/scss/global";
</style>
