<template>
  <div class="top-bar">
    <div class="back" @click="handleClick">
      <Icon :icon="appliedIconName" class="icon" />
    </div>
    <h4 v-if="title" class="title">{{ appliedTitle }}</h4>
    <p v-if="handleAction" class="btn" @click="handleAction">
      {{ t(actionTitle) }}
    </p>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { MobileNavigationBarIconType } from "@/types/app";
import Icon from "@/components/icons/Icon/Icon.vue";
import { IconType } from "@/types/icons";
import { MobileNavigationProps } from "@/types/app";

const router = useRouter();
const { t } = useI18n();

const props = withDefaults(defineProps<MobileNavigationProps>(), {
  hideDivider: false,
  title: "",
  disableTitleTranslation: false,
  handleRedirect: undefined,
  handleAction: undefined,
  actionTitle: "",
  iconType: MobileNavigationBarIconType.Default,
});

const appliedIconName = computed(() => {
  switch (props.iconType) {
    case MobileNavigationBarIconType.Circle:
      return IconType.BACK_CIRCLE;

    default:
      return IconType.BACK;
  }
});

const appliedTitle = computed<string>(() =>
  props.disableTitleTranslation ? props.title : t(props.title),
);

const handleClick = () => {
  if (props.handleRedirect) {
    props.handleRedirect();
    return;
  }

  router.back();
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/layout/mobileNavigationBar";
</style>
