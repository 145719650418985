import { OneToOneRawTextMessage } from "@/store/chats/types";
import { defaultAttachmentString } from "../constants";

export const getDisplayOneToOneMessage = (
  message: OneToOneRawTextMessage,
): string => {
  if (message.messageText) return message.messageText;
  if (message.attachments?.length) {
    return defaultAttachmentString;
  }
  return "";
};
