import { GetterTree } from "vuex";

import { RootState } from "@/store/state";
import { Profile, AuthState } from "./state";
import {
  IOngoingCall,
  FirestoreRingingCall,
  OngoingCallsObject,
} from "./types";
import { getOngoingCallByChatId } from "@/utils/call";

export enum AuthGetterTypes {
  GET_AUTH_ID = "GET_AUTH_ID",
  GET_AUTH_PROFILE = "GET_AUTH_PROFILE",
  GET_AUTH_ACCESS_TOKEN = "GET_AUTH_ACCESS_TOKEN",
  GET_AUTH_REFRESH_TOKEN = "GET_AUTH_REFRESH_TOKEN",
  GET_AUTH_FIREBASE_ID_TOKEN = "GET_AUTH_FIREBASE_ID_TOKEN",
  GET_RINGING_CALLS = "GET_RINGING_CALLS",
  GET_ONGOING_CALL = "GET_ONGOING_CALL",
  GET_ONGOING_CALLS_OBJECT = "GET_ONGOING_CALLS_OBJECT",
  GET_CURRENT_ONGOING_CALL = "GET_CURRENT_ONGOING_CALL",
  GET_CURRENT_ONGOING_CALL_ID = "GET_CURRENT_ONGOING_CALL_ID",
  GET_ONGOING_CALL_HAS_VIDEO = "GET_ONGOING_CALL_HAS_VIDEO",
  GET_ONGOING_CALL_HAS_AUDIO = "GET_ONGOING_CALL_HAS_AUDIO",
}

export interface AuthGetters {
  [AuthGetterTypes.GET_AUTH_ID](state: AuthState): number;
  [AuthGetterTypes.GET_AUTH_PROFILE](state: AuthState): Profile;
  [AuthGetterTypes.GET_AUTH_ACCESS_TOKEN](state: AuthState): string | undefined;
  [AuthGetterTypes.GET_AUTH_REFRESH_TOKEN](
    state: AuthState,
  ): string | undefined;
  [AuthGetterTypes.GET_AUTH_FIREBASE_ID_TOKEN](
    state: AuthState,
  ): string | undefined;
  [AuthGetterTypes.GET_RINGING_CALLS](state: AuthState): FirestoreRingingCall[];
  [AuthGetterTypes.GET_ONGOING_CALLS_OBJECT](
    state: AuthState,
  ): OngoingCallsObject;
  [AuthGetterTypes.GET_ONGOING_CALL](
    state: AuthState,
    getters: AuthGetters,
  ): (chatId: string) => IOngoingCall | null;
  [AuthGetterTypes.GET_CURRENT_ONGOING_CALL](
    state: AuthState,
  ): IOngoingCall | null;
  [AuthGetterTypes.GET_CURRENT_ONGOING_CALL_ID](
    state: AuthState,
  ): string | null;
  [AuthGetterTypes.GET_ONGOING_CALL_HAS_VIDEO](
    state: AuthState,
    getters: AuthGetters,
  ): boolean;
  [AuthGetterTypes.GET_ONGOING_CALL_HAS_AUDIO](
    state: AuthState,
    getters: AuthGetters,
  ): boolean;
}

export const authGetters: GetterTree<AuthState, RootState> = {
  [AuthGetterTypes.GET_AUTH_ID]: (state) => state.profile.id,
  [AuthGetterTypes.GET_AUTH_PROFILE]: (state) => state.profile,
  [AuthGetterTypes.GET_AUTH_ACCESS_TOKEN]: (state) => state?.accessToken,
  [AuthGetterTypes.GET_AUTH_REFRESH_TOKEN]: (state) => state?.refreshToken,
  [AuthGetterTypes.GET_AUTH_FIREBASE_ID_TOKEN]: (state) =>
    state?.firebaseIdToken,
  [AuthGetterTypes.GET_RINGING_CALLS]: (state) => state.ringingCalls,
  [AuthGetterTypes.GET_CURRENT_ONGOING_CALL]: (state, getters) => {
    if (!state.ongoingCalls.currentCallId) {
      return null;
    }

    const currentCall = getters.GET_ONGOING_CALL(
      state.ongoingCalls.currentCallId,
    );

    return currentCall ?? null;
  },
  [AuthGetterTypes.GET_ONGOING_CALL]: (state) => (chatId: string) => {
    return getOngoingCallByChatId(chatId, state);
  },
  [AuthGetterTypes.GET_ONGOING_CALLS_OBJECT]: (state) => {
    return state.ongoingCalls;
  },
  [AuthGetterTypes.GET_CURRENT_ONGOING_CALL_ID]: (state) => {
    return state.ongoingCalls.currentCallId;
  },
  [AuthGetterTypes.GET_ONGOING_CALL_HAS_VIDEO]: (_, getters) => {
    const currentCall = getters[AuthGetterTypes.GET_CURRENT_ONGOING_CALL];
    return currentCall?.hasVideo ?? false;
  },
  [AuthGetterTypes.GET_ONGOING_CALL_HAS_AUDIO]: (_, getters) => {
    const currentCall = getters[AuthGetterTypes.GET_CURRENT_ONGOING_CALL];
    return currentCall?.hasAudio ?? false;
  },
};
