import {
  IOngoingCall,
  FirestoreRingingCall,
  OngoingCallsObject,
} from "./types";

export interface Profile {
  id: number;
  name: string;
  picture: string;
  countryCode: string;
  phoneNumber: string;
  email: string;
}

export type PhoneContactProfile = Omit<Profile, "email">;

export interface AuthState {
  profile: Profile;
  accessToken?: string;
  refreshToken?: string;
  firebaseIdToken?: string;
  ringingCalls: FirestoreRingingCall[];
  ongoingCalls: OngoingCallsObject;
}

const initialProfile: Profile = {
  id: 0,
  name: "",
  picture: "",
  countryCode: "",
  phoneNumber: "",
  email: "",
};

export const authState: AuthState = {
  profile: initialProfile,
  accessToken: "",
  refreshToken: "",
  firebaseIdToken: "",
  ringingCalls: [],
  ongoingCalls: { currentCallId: null, calls: [] },
};
