import { FormFieldType } from "@/types/form";
import { getIsAllowedNumberString } from "@/utils/form";
import { ref } from "vue";

export const useRestrictNumberInput = (
  formFieldType: FormFieldType,
  handleInputCallback: (event: InputEvent) => void,
) => {
  const isInputBlocked = ref(false);
  const isRestricted =
    formFieldType === FormFieldType.Number ||
    formFieldType === FormFieldType.Tel;

  const handleKeyDown = (event: KeyboardEvent) => {
    const isAllowed = getIsAllowedNumberString(event.key);

    if (isRestricted && !isAllowed) {
      isInputBlocked.value = true;
      return event.preventDefault();
    }
  };
  const handleInput = (event: InputEvent) => {
    if (isInputBlocked.value) {
      isInputBlocked.value = false;
      return;
    }
    handleInputCallback(event);
  };

  return { handleKeyDown, handleInput };
};
