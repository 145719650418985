<template>
  <Icon
    class="reportErrorIcon"
    :icon="IconType.ERROR_RED"
    @click="handleClick"
  />
</template>

<script setup lang="ts">
import Icon from "@/components/icons/Icon/Icon.vue";
import { IconType } from "@/types/icons";
import { handleSendReportErrorEmail } from "@/utils/settings";

const handleClick = () => {
  handleSendReportErrorEmail();
};
</script>

<style lang="scss" scoped>
@import "./ReportErrorIconButton.scss";
</style>
