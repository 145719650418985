import DOMPurify from "dompurify";
import linkifyHtml from "linkify-html";

export const parseMessageText = (text?: string): string => {
  if (!text) {
    return "";
  }

  const htmlString = linkifyHtml(text, {
    defaultProtocol: "https",
    target: "_blank",
  });

  return DOMPurify.sanitize(htmlString, {
    ALLOWED_TAGS: ["a"],
    ALLOWED_ATTR: ["href", "target"],
  });
};
