<template>
  <form class="form-layout" novalidate @submit.prevent="emitSubmitForm">
    <slot />
  </form>
</template>
<script setup lang="ts">
interface Emits {
  (e: "submitForm", arg: Event): void;
}

const emit = defineEmits<Emits>();
const emitSubmitForm = (e: Event) => {
  emit("submitForm", e);
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/form/formLayout";
</style>
