import { getNewestAppVersionNumber } from "./getNewestAppVersionNumber";

export const getNewestAppVersionString = (): string => {
  const theNewestVersionNumber = getNewestAppVersionNumber();
  const theNewestVersionString = Array.from<string>(
    String(theNewestVersionNumber),
  ).join(".");

  return theNewestVersionString;
};
