<template>
  <GenericButton
    :variant="variant"
    :text="appliedText"
    :on-click="handleCancel"
  />
</template>

<script setup lang="ts">
import GenericButton from "@/components/buttons/GenericButton.vue";
import { AllMutationTypes } from "@/store/mutationTypes";
import { GenericButtonVariantEnum } from "@/types/button";
import { computed } from "vue";
import { useStore } from "vuex";

interface Props {
  text?: string;
  variant?: GenericButtonVariantEnum;
}
const { commit } = useStore();

const props = withDefaults(defineProps<Props>(), {
  text: "",
  variant: GenericButtonVariantEnum.Secondary,
});

const appliedText = computed<string>(() => {
  const textProperty = props.text
    ? props.text
    : "popup.PopupCancelButton.btnText";

  return textProperty;
});

const handleCancel = () => {
  commit(AllMutationTypes.HIDE_POPUP, true);
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/popup/popup.scss";
@import "src/assets/scss/popup/popupDefault.scss";
</style>
