import { Module } from "vuex";
import { RootState } from "@/store/state";
import { appState, AppState } from "./state";
import { appActions } from "./actions";
import { appMutations } from "./mutations";
import { appGetters } from "./getters";

export const app: Module<AppState, RootState> = {
  state: appState,
  actions: appActions,
  getters: appGetters,
  mutations: appMutations,
};
