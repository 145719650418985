<template>
  <label :for="name" class="input-label">{{ $t(label) }}</label>
</template>

<script>
export default {
  props: {
    name: {
      required: true,
      type: String,
    },
    label: {
      required: true,
      type: String,
    },
  },
};
</script>
<style lang="scss">
@import "src/assets/scss/form/inputLabel.scss";
</style>
