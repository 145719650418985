import { JointLocalTrack } from "@/types/call";
import { LocalParticipant } from "twilio-video";
import { handleTrackSubscribed } from "./handleTrackSubscribed";

export const addLocalParticipantTrack = (
  localParticipant: LocalParticipant,
  localTrack: JointLocalTrack,
) => {
  localParticipant.publishTrack(localTrack);
  handleTrackSubscribed(localTrack, localParticipant);
};
