import { config } from "@/config";
import { ChatTypes } from "@/store/chats/types";
import { IconType } from "@/types/icons";
import { getIconPath } from "../app/getIconPath";

const MAITREJA_DEFAULT_CHAT_PICTURE = `${config.api.maitrejaApi.appUrl}/images/user/anonym.jpg`;

export const getChatPictureSrc = (
  pictureSrc: string,
  chatType: ChatTypes,
): string => {
  if (!pictureSrc || pictureSrc === MAITREJA_DEFAULT_CHAT_PICTURE)
    return chatType === ChatTypes.ONE_TO_ONE_CHAT
      ? getIconPath(IconType.DEFAULT_SINGLE_GRAY)
      : getIconPath(IconType.DEFAULT_GROUP_GRAY);

  return pictureSrc;
};
