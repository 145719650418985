import store, { MutationTypes } from "@/store";
import {
  OnboardingRouteItem,
  OnboardingRouteItemNameEnum,
} from "@/types/onboarding";
import { BridgeStatusEnum } from "@/types/webview";
import {
  isAppEnvironmentAndroid,
  isStandaloneAppEnvironmentContainer,
} from "../app";
import { onboardingRoutesArray } from "./onboardingRoutesArray";

export const getOnboardingPathsArray = (): OnboardingRouteItem[] => {
  let resultArray: OnboardingRouteItem[] = onboardingRoutesArray;
  const notificationsStatus: BridgeStatusEnum =
    store.getters.GET_NOTIFICATIONS_STATUS;
  const contactsStatus: BridgeStatusEnum =
    store.getters.GET_PHONE_CONTACTS_STATUS;

  if (!isStandaloneAppEnvironmentContainer) {
    return [];
  }

  if (notificationsStatus !== BridgeStatusEnum.Unused) {
    resultArray = resultArray.filter(
      (item) => item.name !== OnboardingRouteItemNameEnum.Notifications,
    );
  }
  if (
    contactsStatus !== BridgeStatusEnum.Unused ||
    // disabled for Android due to problems with app review
    isAppEnvironmentAndroid
  ) {
    resultArray = resultArray.filter(
      (item) => item.name !== OnboardingRouteItemNameEnum.TransferContacts,
    );
  }

  const frozenArray = Object.freeze(resultArray);

  store.commit(MutationTypes.SET_ONBOARDING_ROUTES, frozenArray);

  return resultArray;
};
