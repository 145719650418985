<template>
  <div
    class="chat-top-bar"
    :class="{
      webview: isStandaloneAppEnvironmentContainer,
      isBlurred,
    }"
  >
    <div class="chat-info">
      <MobileNavigationBackButton />
      <div class="chat-info-inner">
        <div
          v-if="!(deletingMessages && layout.width < 1200)"
          class="picture-wrapper"
          @click="showEditGroup"
        >
          <ProfileImage
            :id="chat.id"
            :image="chat.picture"
            :size="layout.type === LayoutTypes.MOBILE ? 36 : 48"
            :disable-redirect="isStandaloneAppEnvironmentContainer"
            @click.stop="openChatSettings"
          />
        </div>
        <div
          v-if="!(deletingMessages && layout.width < 1200)"
          class="chat-name-wrapper"
          @click="showEditGroup"
        >
          <p class="name">
            {{ appliedChatName }}
          </p>
          <template v-if="hasOngoingCall || isGroupChatMembersNameShown">
            <p v-if="hasOngoingCall" class="ongoingCall">
              {{ $t("call.status.ongoing") }}
            </p>
            <p v-if="isGroupChatMembersNameShown" class="members">
              {{ chat.nameFromMembers }}
            </p>
          </template>
        </div>
      </div>
    </div>
    <TopBarDeletingMessagesButtons v-if="deletingMessages" />
    <div class="chat-handlers">
      <template v-if="hasOngoingCall">
        <GenericButton class="joinButton" @click="joinCall">{{
          $t("call.join")
        }}</GenericButton>
      </template>
      <template v-else>
        <div v-if="isCallFunctionalityAvailable" class="call">
          <Icon
            :icon="IconType.CAM"
            alt="cam"
            class="icon"
            @click="startVideoCall"
          />
          <Icon
            :icon="IconType.PHONE"
            alt="phone"
            class="icon"
            @click="startAudioCall"
          />
        </div>
        <!-- <span class="divider"></span> -->
        <div class="settings">
          <OneToOneChatOptionsCard
            v-if="
              !isStandaloneAppEnvironmentContainer &&
              chat.type === ChatTypes.ONE_TO_ONE_CHAT
            "
          />
          <GroupChatOptionsCard
            v-if="
              !isStandaloneAppEnvironmentContainer &&
              chat.type === ChatTypes.GROUP_CHAT
            "
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import TopBarDeletingMessagesButtons from "@/components/buttons/TopBarDeletingMessagesButtons.vue";
import MobileNavigationBackButton from "@/components/buttons/MobileNavigationBackButton.vue";
import ProfileImage from "@/components/images/ProfileImage.vue";
import OneToOneChatOptionsCard from "@/components/cards/OneToOneChatOptionsCard.vue";
import GroupChatOptionsCard from "@/components/cards/GroupChatOptionsCard.vue";
import GenericButton from "@/components/buttons/GenericButton.vue";
import { useStore } from "vuex";
import { GetterTypes, MutationTypes } from "@/store";
import { Chat, ChatTypes } from "@/store/chats/types";
import { Layout, LayoutTypes, PopupTypes } from "@/store/app/state";

import {
  getVersionNumber,
  isAppEnvironmentContainer,
  isStandaloneAppEnvironmentContainer,
} from "@/utils/app";
import { useComputedValue } from "@/composables/useComputedValue";
import { useRouter } from "vue-router";
import { computed } from "vue";
import { getFullChatName } from "@/utils/chat";
import { startCallBeAsync } from "@/services/chatApi";
import {
  BridgeMessageAppDetailsResponseObject,
  CallType,
} from "@/types/webview";
import { config } from "@/config";
import { IconType } from "@/types/icons";
import Icon from "@/components/icons/Icon/Icon.vue";

const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);
const chat = useComputedValue<Chat>(GetterTypes.GET_SELECTED_CHAT);
const layout = useComputedValue<Layout>(GetterTypes.GET_LAYOUT);
const deletingMessages = useComputedValue<string[] | null>(
  GetterTypes.GET_SELECTED_MESSAGE_IDS,
);
const appDetails = useComputedValue<BridgeMessageAppDetailsResponseObject>(
  GetterTypes.GET_APP_DETAILS,
);
const chatId = computed(() => chat.value.id);
const { commit } = useStore();
const router = useRouter();
const layoutType = useComputedValue<LayoutTypes>(GetterTypes.GET_LAYOUT_TYPE);
const messageReactionId = useComputedValue<string | null>(
  GetterTypes.GET_MESSAGE_REACTION_ID,
);
const isBlurred = computed(() => !!messageReactionId.value);

const showEditGroup = () => {
  if (
    (chat.value && chat.value.type !== ChatTypes.GROUP_CHAT) ||
    isStandaloneAppEnvironmentContainer
  )
    return;

  commit(MutationTypes.SHOW_POPUP, {
    popupType: PopupTypes.EDIT_GROUP,
  });
};

const openChatSettings = () => {
  if (isStandaloneAppEnvironmentContainer) {
    router.push(`/settings/chat/${chat.value.id}`);
  }
};

const appliedChatName = computed<string>(() => {
  return getFullChatName(chat.value);
});

const getStartCallHandler = (callType: CallType) => async () => {
  await startCallBeAsync({
    chatId: chatId.value,
    userId: authId.value,
    callType,
    isDesktop: isDesktopCall.value,
  });
};

const startVideoCall = getStartCallHandler(CallType.Video);
const startAudioCall = getStartCallHandler(CallType.Audio);
const joinCall = getStartCallHandler(chat.value.pendingCallType);
const isDesktopCall = computed(
  () =>
    appDetails.value.currentAppVersion === "" &&
    !isAppEnvironmentContainer &&
    layoutType.value === LayoutTypes.DESKTOP,
);

const isCallFunctionalityAvailable = computed<boolean>(() => {
  if (isDesktopCall.value) {
    return true;
  }
  return (
    getVersionNumber(appDetails.value.currentAppVersion) >=
    getVersionNumber(config.constants.CALLS_AVAILABLE_APP_VERSION)
  );
});
const hasOngoingCall = computed<boolean>(
  () => !!chat.value.activeCall && isCallFunctionalityAvailable.value,
);
const isGroupChatMembersNameShown = computed<boolean>(
  () =>
    !hasOngoingCall.value &&
    chat.value.type === ChatTypes.GROUP_CHAT &&
    chat.value.hasName,
);
</script>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatContainer/chatTopBar.scss";
</style>
