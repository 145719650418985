import store from "@/store";
import { IUserPhoneContactUser } from "@/types/users";

export const getPhoneContactUserAliasFromId = (
  userId: number,
): string | undefined => {
  const phoneContactsUsers: IUserPhoneContactUser[] =
    store.getters.GET_PHONE_CONTACTS_USERS;
  const contactUser = phoneContactsUsers.find(
    (contact) => contact.id === userId,
  );

  return contactUser?.alias;
};
