import { CustomClickEvent } from "@/types/app";
import { onUnmounted, ref, watch } from "vue";

interface UseLongPressArgs {
  onLongPress: (event: CustomClickEvent) => void;
  onClick?: () => void;
  shouldPreventDefault?: boolean;
  delay?: number;
}

interface UseLongPressReturn {
  mousedown: (e: CustomClickEvent) => void;
  touchstart: (e: CustomClickEvent) => void;
  mouseup: (e: CustomClickEvent) => void;
  mouseleave: (e: CustomClickEvent) => void;
  touchend: (e: CustomClickEvent) => void;
}

export const useLongPress = ({
  onLongPress,
  onClick,
  shouldPreventDefault = true,
  delay = 300,
}: UseLongPressArgs): UseLongPressReturn => {
  const longPressTriggered = ref(false);
  const timeout = ref<NodeJS.Timeout>();
  const target = ref<HTMLElement | null>(null);
  const isLongPressEnabled = ref(true);
  const startEvent = ref<CustomClickEvent | null>(null);

  const start = (event: CustomClickEvent) => {
    isLongPressEnabled.value = true;
    if (shouldPreventDefault && event.target) {
      event.target.addEventListener("touchend", handleTouchEnd, {
        passive: false,
      });
      event.target.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });

      target.value = event.target;
      startEvent.value = event;
    }
    timeout.value = setTimeout(() => {
      longPressTriggered.value = true;
    }, delay);
  };

  const handleTouchMove = (touchEvent: TouchEvent) => {
    if (!isLongPressEnabled.value) {
      return;
    }
    isLongPressEnabled.value = false;
  };

  watch(longPressTriggered, (newValue) => {
    if (!newValue || !isLongPressEnabled.value || !startEvent.value) {
      return;
    }
    if (!isLongPressEnabled.value) {
      clearAll();
      return;
    }
    onLongPress(startEvent.value);
    clearAll();
  });

  const handleClick = (shouldTriggerClick = true) => {
    if (!shouldTriggerClick || longPressTriggered.value || !onClick) {
      return;
    }
    onClick();
  };
  const clear = (shouldTriggerClick = true) => {
    handleClearTimeout();
    handleClick(shouldTriggerClick);
    longPressTriggered.value = false;
    if (!shouldPreventDefault) {
      return;
    }
    clearEventListeners();
  };

  const handleClearTimeout = () => {
    if (!timeout.value) {
      return;
    }
    clearTimeout(timeout.value);
  };

  const clearEventListeners = () => {
    if (!target.value) {
      return;
    }
    target.value.removeEventListener("touchend", handleTouchEnd);
    target.value.removeEventListener("touchmove", handleTouchMove);
    target.value = null;
    startEvent.value = null;
  };

  const clearAll = () => {
    clearEventListeners();
    handleClearTimeout();
  };

  onUnmounted(() => {
    clearAll();
  });

  const handleTouchEnd = (event: TouchEvent) => {
    const shouldPreventDefault =
      event.touches.length < 2 && event.preventDefault;
    if (!shouldPreventDefault) {
      return;
    }
    event.preventDefault();
  };

  return {
    mousedown: (e) => start(e),
    touchstart: (e) => start(e),
    mouseup: () => clear(),
    mouseleave: () => clear(false),
    touchend: () => clear(),
  };
};
