import { User } from "@/store/users/state";
import { IChatModifiedReferenceObject } from "@/types/chat";
import { getChatOnSnapshotListenersObject } from "./getChatOnSnapshotListenersObject";
import { getExistingChatIds } from "./getExistingChatIds";
import { getGroupChatMembersAsync } from "./getGroupChatMembersAsync";
import { getFirebaseChatDataAsync } from "./getFirebaseChatDataAsync";
import { getOneToOneChatMembersAsync } from "./getOneToOneChatMembersAsync";
import { convertRawChat } from "./convertRawChat";
import { getRawMessagesDetails } from "../message";
import { isOneToOneChat } from "@/firebase/utils";

export const getInitialUsersAndChatsAsync = async (
  allChatIds: string[],
  accessToken: string,
) => {
  const oneToOneChatIds = allChatIds.filter(isOneToOneChat);
  const remainingGroupChatIds = allChatIds.filter(
    (chatId) => !isOneToOneChat(chatId),
  );
  const { oneToOneChatMembers, chatsToBeDeleted } =
    await getOneToOneChatMembersAsync(oneToOneChatIds, accessToken);
  const { remainingOneToOneChatIds } = getExistingChatIds(
    oneToOneChatIds,
    chatsToBeDeleted,
  );
  const unsortedRemainingChatIds = [
    ...remainingOneToOneChatIds,
    ...remainingGroupChatIds,
  ];

  const remainingChatIds = allChatIds
    .map((chatId) => {
      const isRemaining = unsortedRemainingChatIds.some(
        (cId) => cId === chatId,
      );
      if (!isRemaining) {
        return;
      }
      return chatId;
    })
    .filter(Boolean) as string[];

  const chats = await getFirebaseChatDataAsync({
    chatIds: remainingChatIds,
  });

  /*
  Note: we need to only get listeners for the currently available chat ids (not the ones we get later with lazy loading).
  If we would run the following line on all remainingChatIds, it would trigger downloading of all chats data.
  */
  const chatListenersObject = getChatOnSnapshotListenersObject(
    chats.map((chat) => chat.id),
  );
  const groupChatMembers = await getGroupChatMembersAsync(chats, accessToken);
  const users = [...groupChatMembers, ...oneToOneChatMembers].filter(
    (user) => user?.id !== undefined,
  ) as User[];

  const modifiedChats: IChatModifiedReferenceObject[] = chats.map((rawChat) => {
    const chatId = rawChat.id;
    const initialChat: IChatModifiedReferenceObject = {
      chatId,
      rawChat,
      messages: {},
      messageIds: [],
      convertedChat: convertRawChat(rawChat, users),
    };
    const { rawMessages, ...rawMessagesObj } = getRawMessagesDetails(rawChat);

    return {
      ...initialChat,
      ...rawMessagesObj,
      chatUnsubscribe: chatListenersObject[chatId]?.unsubcribe,
      rawChat,
      chatId,
      isVisible: Boolean(rawChat && rawChat.lastMessage),
    };
  });

  return {
    users,
    chats: modifiedChats,
    remainingChatIds,
  };
};
