<template>
  <main
    class="main-layout"
    :class="{
      webview: isStandaloneAppEnvironmentContainer,
      isReacting,
    }"
  >
    <slot />
  </main>
</template>

<script setup lang="ts">
import { useComputedValue } from "@/composables";
import { GetterTypes } from "@/store";
import { isStandaloneAppEnvironmentContainer } from "@/utils/app";
import { computed } from "vue";
const messageReactionId = useComputedValue<string | null>(
  GetterTypes.GET_MESSAGE_REACTION_ID,
);
const isReacting = computed(() => !!messageReactionId.value);
</script>

<style lang="scss" scoped>
@import "src/assets/scss/layout/mainLayout";
</style>
