import { chatApiAxios } from "@/plugins";
import { HttpRequestMethodEnum } from "@/types/api";

interface UpdateChat {
  chatId: string;
  name?: string;
  picture?: string;
  firebaseIdToken: string;
}

export const updateChatBeAsync = async ({
  chatId,
  name,
  picture,
  firebaseIdToken,
}: UpdateChat) => {
  const headers = {
    Authorization: `Bearer ${firebaseIdToken}`,
  };
  const res = await chatApiAxios("chat/" + chatId, {
    method: HttpRequestMethodEnum.PATCH,
    headers,
    data: {
      name,
      picture,
    },
  });
  return res.data;
};
