export enum HttpRequestContentTypeEnum {
  WWWFORM = "application/x-www-form-urlencoded",
  JSON = "application/json",
}

export enum HttpRequestMethodEnum {
  POST = "post",
  GET = "get",
  DELETE = "delete",
  PATCH = "patch",
}
