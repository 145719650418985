import router from "@/router";
import store from "@/store";
import {
  OnboardingRouteItem,
  OnboardingRouteItemNameEnum,
} from "@/types/onboarding";

export const handleOnboardingContinue = (
  routeName: OnboardingRouteItemNameEnum,
) => {
  const onboardingRoutes: readonly OnboardingRouteItem[] =
    store.getters.GET_ONBOARDING_ROUTES;

  const currentOrder = onboardingRoutes.findIndex(
    (item) => item.name === routeName,
  );

  const nextIndex = currentOrder + 1;
  if (!onboardingRoutes.length || onboardingRoutes.length < nextIndex + 1) {
    router.push("/");
    return;
  }
  const nextOnboardingRoute = onboardingRoutes[nextIndex];

  router.push(nextOnboardingRoute.path);
};
