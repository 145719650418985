import { ChatState } from "@/store/chats/types";
import { getSentByChatState } from "./getSentByChatState";

export const sortChatState = (chatA: ChatState, chatB: ChatState) => {
  const aSent = getSentByChatState(chatA);
  const bSent = getSentByChatState(chatB);

  if (!aSent || !bSent) {
    return 0;
  }

  if (aSent < bSent) return 1;
  if (aSent > bSent) return -1;
  return 0;
};
