import store from "@/store";
import { AuthGetterTypes } from "@/store/auth";
import { RoomParticipant } from "@/types/call";
import { Room } from "twilio-video";

export const getLocalParticipant = (room: Room): RoomParticipant => {
  const profile = store.getters[AuthGetterTypes.GET_AUTH_PROFILE];
  return {
    participant: room.localParticipant ?? null,
    user: profile,
    isConnected: true,
  };
};
