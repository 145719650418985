import { config } from "@/config";
import { IconType } from "@/types/icons";
import { IListItem, ListItemTypeEnum } from "@/types/list";
import { LocaleTypeEnum } from "@/types/localization";

export const getSettingsTermsMenuItems = (
  locale: LocaleTypeEnum,
): IListItem[] => {
  return [
    {
      text: "settings.termsAndConditions.privacyPolicy",
      iconName: IconType.FILE,
      type: ListItemTypeEnum.Link,
      onClick: openExternalLink("gdpr", locale),
    },
    {
      text: "settings.termsAndConditions.conditions",
      iconName: IconType.FILE,
      type: ListItemTypeEnum.Link,
      onClick: openExternalLink("conditions", locale),
    },
  ];
};

const urlPrefix = `${config.api.maitrejaApi.appUrl}/content`;
const openExternalLink = (suffix: string, locale: LocaleTypeEnum) => () => {
  window.open(`${urlPrefix}/${suffix}/${locale}`, "_blank");
};
