import { FormField, FormFieldDataObject, FormType } from "@/types/form";
import { getIsFieldValid } from "./getIsFieldValid";

export const getIsFormValid = <T>(
  data: FormFieldDataObject<T>,
  fields: FormField[],
  formType: FormType,
) => {
  const invalidFormFields: boolean[] = [];
  fields.forEach((formField) => {
    const isFieldValid = getIsFieldValid(data, formField, formType);
    if (!isFieldValid) {
      invalidFormFields.push(isFieldValid);
    }
  });

  return !invalidFormFields.length;
};
