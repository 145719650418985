import store from "@/store";
import { RawTextMessage } from "@/store/chats/types";
import { getIsOneToOneRawTextMessage } from "@/utils/message";

interface ICanUserMarkMessageAsReadArgs {
  lastMessage: RawTextMessage | null;
  chatId: string;
}

export const canUserMarkMessageAsRead = ({
  lastMessage,
  chatId,
}: ICanUserMarkMessageAsReadArgs) => {
  const authId = store.getters.GET_AUTH_ID as number;
  if (!chatId || !lastMessage) {
    return;
  }

  const isSender = lastMessage.senderId === authId;
  if (getIsOneToOneRawTextMessage(lastMessage, chatId)) {
    return !(lastMessage?.read || isSender);
  }

  const isRead = lastMessage?.readBy?.includes(authId);
  return !(isRead || isSender);
};
