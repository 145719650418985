import { Track } from "twilio-video";
import { getIsAudioOrVideoTrack } from "./getIsAudioOrVideoTrack";

export const detachTrack = (track: Track) => {
  if (!getIsAudioOrVideoTrack(track)) {
    return;
  }

  track.detach().forEach((element) => element.remove());
};
