import { LocalParticipant } from "twilio-video";
import { getIsAudioOrVideoTrack } from "./getIsAudioOrVideoTrack";
import { stopMediaPreview } from "./stopMediaPreview";

export const stopAllMediaTracks = (localParticipant: LocalParticipant) => {
  localParticipant.tracks.forEach((trackPublication) => {
    const track = trackPublication.track;
    localParticipant.unpublishTrack(track);
    trackPublication.unpublish();
    if (!getIsAudioOrVideoTrack(track)) {
      return;
    }
    stopMediaPreview(track);
  });
};
