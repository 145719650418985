<template>
  <SettingsDetails :gap="62" v-bind="{ ...props, title }">
    <div class="chat-settings-details">
      <div class="top">
        <ProfileAvatar
          v-if="imageSrc"
          :src="imageSrc"
          :size="ProfileAvatarSizeEnum.Big"
          :is-online="false"
        />
        <img
          v-else
          :src="appliedImageSrc"
          class="warning-point-image"
          :alt="appliedImageSrc.toLowerCase()"
        />
        <div class="warning-points">
          <ChatSettingsWarningPoint
            v-for="point in warningPoints"
            :key="point.title"
            v-bind="point"
          />
        </div>
      </div>
      <div class="buttons">
        <GenericButton
          :variant="GenericButtonVariantEnum.Primary"
          :text="primaryButtonText"
          :on-click="primaryButton.handleClick"
          disable-translation
        />
        <GenericButton
          :variant="GenericButtonVariantEnum.Secondary"
          :text="secondaryButtonText"
          :on-click="redirectBack"
        />
      </div>
    </div>
  </SettingsDetails>
</template>
<script setup lang="ts">
import ChatSettingsWarningPoint from "@/components/chat/chatSettings/ChatSettingsWarningPoint.vue";
import ProfileAvatar from "@/components/images/ProfileAvatar.vue";
import SettingsDetails from "@/components/settings/SettingsDetails/SettingsDetails.vue";
import GenericButton from "@/components/buttons/GenericButton.vue";
import { ProfileAvatarSizeEnum } from "@/types/avatar";
import {
  ChatSettingsScreenEnum,
  IChatSettingsWarningPoint,
} from "@/types/chat";
import { ButtonObject, GenericButtonVariantEnum } from "@/types/button";
import { useGetChatSettingsDetailsProperties } from "./useGetChatSettingsDetailsProperties";
import { MobileNavigationProps } from "@/types/app";

interface ChatSettingsDetailsPropsObject extends MobileNavigationProps {
  primaryButton: ButtonObject;
  imageName?: string;
  imageSrc?: string;
  type: ChatSettingsScreenEnum;
  warningPoints: IChatSettingsWarningPoint[];
}

const props = defineProps<ChatSettingsDetailsPropsObject>();

const {
  title,
  appliedImageSrc,
  primaryButtonText,
  secondaryButtonText,
  redirectBack,
} = useGetChatSettingsDetailsProperties(props);
</script>
<style lang="scss" scoped>
@import "src/assets/scss/chat/chatSettings/chatSettingsDetails";
</style>
