import { Attachment, AttachmentTypes } from "@/store/chats/types";

export const getVideoAttachments = (
  attachments: Attachment[] | undefined,
): Attachment[] | undefined => {
  if (!attachments) return undefined;
  const videoAttachments = attachments.filter(
    (att) => att.type === AttachmentTypes.VIDEO,
  );
  if (videoAttachments.length === 0) return undefined;
  return videoAttachments;
};
