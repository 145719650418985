import store, { MutationTypes } from "@/store";
import { Profile } from "@/store/auth/state";
import { IAuthResponseObject, IDecodedIdTokenObject } from "@/types/auth";
import { jwtDecode } from "jwt-decode";
import { storeRefreshToken } from "./storeRefreshToken";

export const storeAuthenticationData = (authData: IAuthResponseObject) => {
  const { refresh_token: refreshToken, id_token } = authData;
  if (!refreshToken) {
    return;
  }
  const { sub, picture, name, phone_number, country_code, email } =
    jwtDecode<IDecodedIdTokenObject>(id_token);
  const user: Profile = {
    id: sub,
    picture,
    name,
    countryCode: country_code,
    phoneNumber: phone_number,
    email,
  };

  store.commit(MutationTypes.SET_AUTH_PROFILE, user);
  storeRefreshToken({
    refreshToken,
    userId: user.id,
    userName: user.name,
    userPictureUrl: user.picture,
  });
};
