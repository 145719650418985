import { useComputedValue } from "@/composables";
import { GetterTypes } from "@/store";
import { ButtonObject } from "@/types/button";
import {
  OnboardingProvideObject,
  OnboardingContainerTypeEnum,
} from "@/types/onboarding";
import { BridgeStatusEnum } from "@/types/webview";
import { handleAllowAccessToContacts } from "@/utils/api";
import { onboardingInjectionKey } from "@/utils/onboarding/onboardingInjectionKey";
import { computed, inject, watch } from "vue";
import { useRouter } from "vue-router";
import { OnboardingContainerPropsObject } from "@/components/onboarding/OnboardingContainer";

export const useGetOnboardingTransferContactsProperties = () => {
  const injected = inject<OnboardingProvideObject>(onboardingInjectionKey, {
    type: OnboardingContainerTypeEnum.Settings,
  });

  const router = useRouter();
  const status = useComputedValue<BridgeStatusEnum>(
    GetterTypes.GET_PHONE_CONTACTS_STATUS,
  );

  const primaryButton = computed<ButtonObject>(() => {
    return {
      handleClick: handlePrimaryButtonClick,
      text: getTranslationString("primaryButtonText"),
    };
  });

  const secondaryButton = computed<ButtonObject>(() => {
    return {
      handleClick: handleRedirectBack,
      text: getTranslationString("secondaryButtonText"),
    };
  });

  const transferContactsProperties = computed<OnboardingContainerPropsObject>(
    () => {
      return {
        title: getTranslationString("title"),
        text: getTranslationString("text"),
        primaryButton: primaryButton.value,
        secondaryButton: secondaryButton.value,
        imageName: "phoneTransfer.jpg",
        componentName: "phoneTransfer",
      };
    },
  );

  watch(status, () => {
    router.push("/");
  });

  const getTranslationString = (property: string): string => {
    return `settings.transferContactsContent.switch.${status.value}.${property}`;
  };

  const handlePrimaryButtonClick = (): void => {
    if (
      injected.type === OnboardingContainerTypeEnum.Onboarding ||
      status.value === BridgeStatusEnum.Unused
    ) {
      handleAllowAccessToContacts();
      return;
    }
    handleRedirectBack();
  };

  const handleRedirectBack = () => {
    if (injected.type === OnboardingContainerTypeEnum.Onboarding) {
      router.push("/");
      return;
    }
    router.back();
  };
  return { transferContactsProperties };
};
