import { isOneToOneChat } from "@/firebase/utils";
import {
  NonAutomaticMessage,
  Attachment,
  RawTextMessage,
  OneToOneRawAudioMessage,
  GroupRawAudioMessage,
} from "@/store/chats/types";
import { getIsMessageTextType } from "./getIsMessageTextType";
import firebase from "@/firebase/firebase";

interface ConvertMessageToRawMessageArgs {
  replyMessage: NonAutomaticMessage;
  chatId: string;
  attachments: Attachment[];
}

export const convertNonAutomaticMessageToRawMessage = ({
  replyMessage,
  chatId,
  attachments,
}: ConvertMessageToRawMessageArgs) => {
  if (getIsMessageTextType(replyMessage)) {
    const { id, senderId, sentAt, senderName, type, messageText } =
      replyMessage;
    const replyTextMessage: RawTextMessage = {
      id,
      senderId,
      messageText,
      sentAt: firebase.firestore.Timestamp.fromMillis(sentAt),
      attachments,
      senderName,
      chatId,
      messageType: type,
    };
    return replyTextMessage;
  }

  const { id, sentAt, senderId, read, readBy, type } = replyMessage;
  const sentAtFirebase = firebase.firestore.Timestamp.fromMillis(sentAt);
  if (isOneToOneChat(chatId)) {
    const replyOneToOneRawAudioMessage: OneToOneRawAudioMessage = {
      id,
      sentAt: sentAtFirebase,
      senderId,
      read,
      attachments,
      messageType: type,
      chatId,
    };
    return replyOneToOneRawAudioMessage;
  }

  const replyGroupRawAudioMessage: GroupRawAudioMessage = {
    id,
    readBy: readBy
      ? readBy.map(({ userId }) => Number(userId)).filter(Boolean)
      : [],
    sentAt: sentAtFirebase,
    senderId,
    attachments,
    messageType: type,
    chatId,
  };

  return replyGroupRawAudioMessage;
};
