import store, { GetterTypes } from "@/store";
import { BridgeActionEnum, CallType } from "@/types/webview";
import { postWebviewMessage } from "@/utils/api";

interface IPostStartCallArgs {
  chatId: string;
  twilioAccessToken: string;
  callType: CallType;
}

export const postStartCallBridgeMessage = ({
  chatId,
  twilioAccessToken,
  callType,
}: IPostStartCallArgs) => {
  const chat = store.getters[GetterTypes.GET_SELECTED_CHAT];
  if (!chat) {
    return;
  }
  postWebviewMessage({
    action: BridgeActionEnum.StartCall,
    calleeDisplayName: chat.name,
    chatId,
    twilioAccessToken,
    callType,
    chatPictureUrl: chat.picture,
  });
};
