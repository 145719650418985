import _ from "lodash";
import { getChatUnsubscribeById } from "./getChatUnsubscribeById";

export const getChatOnSnapshotListenersArray = (chatIds: string[]) =>
  chatIds.map((chatId) => {
    return {
      unsubcribe: getChatUnsubscribeById(chatId),
      id: chatId,
    };
  });
