import { Attachment, AttachmentTypes } from "@/store/chats/types";
import { compressImage } from "./compressImage";

export const compressAttachmentAsync = async (
  attachments: Attachment[],
): Promise<Attachment[]> => {
  const response = attachments.map(async (attachment) => {
    let compressedFile = attachment.file;

    if (
      attachment.type === AttachmentTypes.IMAGE &&
      attachment.file &&
      // gifs should not be compressed, as that would make them static
      attachment.extname !== "gif"
    ) {
      compressedFile = await compressImage(attachment.file);
    }

    return {
      ...attachment,
      compressedFile,
    };
  });

  return await Promise.all(response);
};
