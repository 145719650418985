import { getCallAccessTokenAsync } from "@/services";
import { CallType } from "@/types/webview";
import { connect } from "twilio-video";
import { getRoomOptions } from "./getRoomOptions";

export interface GetNewRoomArgs {
  chatId: string;
  hasVideo: boolean;
  authId: number;
  callType: CallType;
}

export const getNewRoom = async ({
  chatId,
  hasVideo,
  authId,
  callType,
}: GetNewRoomArgs) => {
  const callAccessToken = await getCallAccessTokenAsync({
    chatId,
    userId: authId,
    callType,
  });
  if (!callAccessToken) {
    throw new Error("error fetching call token");
  }
  const room = await connect(callAccessToken, getRoomOptions(chatId, hasVideo));

  return room;
};
