<template>
  <div class="chat-sidebar-search-bar">
    <input
      :value="filter"
      :placeholder="t('chat.chatSidebar.SearchBar.placeholder')"
      @input="updateFilter"
    />
    <Icon :icon="IconType.MAGNIFY" class="icon" />
  </div>
</template>

<script setup lang="ts">
import Icon from "@/components/icons/Icon/Icon.vue";
import { MutationTypes, store, GetterTypes } from "@/store";
import { IconType } from "@/types/icons";
import { useComputedValue } from "@/composables";
import { useI18n } from "vue-i18n";
import { onMounted } from "vue";
import { useStore } from "vuex";

const { t } = useI18n();
const filter = useComputedValue<string>(GetterTypes.GET_CHATS_FILTER);
const { commit } = useStore();

onMounted(() => {
  commit(MutationTypes.CLEAR_CHATS_FILTER);
});

const updateFilter = (e: Event) => {
  const target = e.target as HTMLInputElement;
  store.commit(MutationTypes.SET_CHATS_FILTER, { filter: target.value });
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatSidebar/chatSidebarSearchBar.scss";
</style>
