<template>
  <div class="reactions-user" @click="removeReaction">
    <div class="reactions-user-left">
      <img class="reactions-user-picture" :src="user.picture" alt="person" />
      <div class="reactions-user-texts">
        <span class="reactions-user-name">
          {{ user.name }}
        </span>
        <span v-if="isMine" class="reactions-remove-reaction">{{
          $t("chat.chatContainer.messages.reactions.removeWithClick")
        }}</span>
      </div>
    </div>
    <div class>
      <EmojiItem :size="28" :emoji="emoji" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Chat } from "@/store/chats/types";
import { useComputedValue } from "@/composables";
import { ActionTypes, GetterTypes } from "@/store";
import { computed } from "vue";
import { useStore } from "vuex";
import { Profile } from "@/store/auth/state";
import EmojiItem from "@/components/cards/EmojiItem/EmojiItem.vue";
import { ReactionsItemUser } from "@/types/chat";

interface MessageReactionsModalItemProps {
  user: ReactionsItemUser;
  emoji: string;
  isMine: boolean;
  messageId: string;
}

interface Emits {
  (e: "closeModal"): void;
}

const emit = defineEmits<Emits>();
const profile = useComputedValue<Profile>(GetterTypes.GET_AUTH_PROFILE);
const { dispatch } = useStore();
const props = defineProps<MessageReactionsModalItemProps>();
const chat = useComputedValue<Chat>(GetterTypes.GET_SELECTED_CHAT);
const chatId = computed(() => chat.value.id);

const closeModal = () => {
  emit("closeModal");
};

const removeReaction = async () => {
  if (!props.isMine) {
    return;
  }

  closeModal();
  await dispatch(ActionTypes.REMOVE_MESSAGE_REACTION, {
    messageId: props.messageId,
    chatId: chatId.value,
  });
};
</script>

<style lang="scss">
@import "./MessageReactionsModalItem.scss";
</style>
