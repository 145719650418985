<template>
  <Form @submit-form="handleSubmit">
    <Input
      v-model="password"
      name="password"
      type="password"
      placeholder="auth.newPassword"
      required
    />
    <Input
      v-model="passwordCheck"
      name="passwordCheck"
      type="password"
      placeholder="auth.newPasswordAgain"
      required
    />
    <GenericButton
      :variant="GenericButtonVariantEnum.Primary"
      text="auth.buttons.restorePassword"
    />
  </Form>
</template>

<script>
import Form from "@/components/layouts/Form.vue";
import GenericButton from "@/components/buttons/GenericButton.vue";
import { GenericButtonVariantEnum } from "@/types/button";

export default {
  components: { GenericButton },
  data() {
    return {
      password: "",
      passwordCheck: "",
      GenericButtonVariantEnum,
    };
  },
  methods: {
    async handleSubmit() {},
  },
};
</script>

<style lang="scss" scoped></style>
