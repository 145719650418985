import { IChatSettingsWarningPoint } from "@/types/chat";
import { IconType } from "@/types/icons";

export const reportUserWarningPoints: IChatSettingsWarningPoint[] = [
  {
    title: "settings.chat.reportUser.foundInappropriateContent.title",
    text: "settings.chat.reportUser.foundInappropriateContent.text",
    iconName: IconType.REPORT_GRAY,
  },
  {
    title: "settings.chat.reportUser.weValueYourSafety.title",
    text: "settings.chat.reportUser.weValueYourSafety.text",
    iconName: IconType.BACK_GRAY,
  },
];
