<template>
  <PopupInfo
    title="popup.PopupInfoDeleteUserAccount.title"
    label="popup.PopupInfoDeleteUserAccount.label"
    btn-text="popup.PopupInfoDeleteUserAccount.btnText"
    cancel-btn-text="popup.PopupInfoDeleteUserAccount.cancelBtnText"
    :action="clickAction"
  />
</template>

<script setup lang="ts">
import PopupInfo from "@/components/popup/PopupInfo.vue";
import { ActionTypes, MutationTypes } from "@/store";
import { useStore } from "vuex";

const { dispatch, commit } = useStore();

const clickAction = async () => {
  await dispatch(ActionTypes.DELETE_USER);
  commit(MutationTypes.HIDE_POPUP);
};
</script>
