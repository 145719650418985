import { Profile } from "@/store/auth/state";
import {
  RawFriendRequestMessage,
  ChatTypes,
  FriendRequestMessage,
  MessageType,
} from "@/store/chats/types";
import { User } from "@/store/users/state";
import { findUserById } from "../users";
import { getSenderName } from "../chat";
import { convertFirebaseDateToLocal } from "@/firebase/utils";

interface ConvertRawFriendRequestMessageArgs {
  rawMessage: RawFriendRequestMessage;
  auth: Profile;
  users?: User[];
}

export const convertRawFriendRequestMessage = ({
  rawMessage,
  auth,
  users,
}: ConvertRawFriendRequestMessageArgs): FriendRequestMessage => {
  const { senderId, status, sentAt, id } = rawMessage;
  const partner = findUserById(senderId, users);
  const members = [partner].filter(Boolean) as User[];

  return {
    chatType: ChatTypes.ONE_TO_ONE_CHAT,
    id,
    senderId,
    senderName: getSenderName(senderId, auth.id, members),
    isMine: auth.id === senderId,
    sentAt: convertFirebaseDateToLocal(sentAt),
    hidden: false,
    type: MessageType.FriendRequest,
    status,
  };
};
