import { Attachment, AttachmentTypes } from "@/store/chats/types";

export const safeParseAttachment = (attachment: Attachment) => {
  if (attachment.type === AttachmentTypes.VIDEO) {
    return attachment;
  }

  const { url } = attachment;
  if (!url) {
    return attachment;
  }
  // url is in format http://storageUrl/v0/b/storageBucket/...
  // if storageBucket id is undefined due to historically incorrect settings of env variable on backend side, it must be replaced with an actual storackeBucket id
  const urlArray = url.split("/");
  // storageBucket string is 5th element of the splitted string array
  const storageBucketString = urlArray[5];
  // if it is not undefined, we do not have to replace it with anything else
  if (storageBucketString !== "undefined") {
    return attachment;
  }

  const parsedUrl = [
    ...urlArray.slice(0, 5),
    process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    ...urlArray.slice(6, urlArray.length),
  ].join("/");

  return { ...attachment, url: parsedUrl };
};
