import { isAppEnvironmentAndroid } from "./isAppEnvironmentAndroid";
import { isAppEnvironmentIos } from "./isAppEnvironmentIos";

export const getIsAppStandalone = (): boolean => {
  if (window.top !== window.self) {
    return false;
  }
  // @ts-expect-error
  if (isAppEnvironmentIos && window.webkit) {
    return true;
  }

  return isAppEnvironmentAndroid;
};
