export enum OnboardingContainerTypeEnum {
  Onboarding = "Onboarding",
  Settings = "Settings",
  ChatSettings = "ChatSettings",
  DeleteAccount = "DeleteAccount",
}

export interface OnboardingProvideObject {
  type: OnboardingContainerTypeEnum;
  routeName?: OnboardingRouteItemNameEnum;
}

export interface OnboardingRouteItem {
  name: string;
  path: string;
}

export enum OnboardingRouteItemNameEnum {
  Notifications = "notifications",
  TransferContacts = "transferContacts",
  Welcome = "welcome",
}
