import { getUserByIdAsync } from "@/services/maitrejaApi";
import store from "@/store";
import { GroupRawChat, RawChat } from "@/store/chats/types";
import { User } from "@/store/users/state";
import { getIsGroupRawChat } from "../message";

export const getGroupChatMembersAsync = async (
  rawChats: RawChat[],
  accessToken: string,
) => {
  const authId = store.getters.GET_AUTH_ID;
  let groupChatMembers: (User | undefined)[] = [];
  const filteredGroupChats = rawChats.filter(
    (rawChat) => rawChat && getIsGroupRawChat(rawChat),
  ) as GroupRawChat[];

  const groupChatsMembersIds: number[] = [
    ...new Set(
      filteredGroupChats
        .flatMap((rawChat) => rawChat.members)
        .filter((id) => id !== authId),
    ),
  ];

  const groupChatMembersPromises = groupChatsMembersIds.map(
    async (memberId) => {
      let member: User | undefined = store.getters.GET_USER(memberId);
      if (!member) {
        try {
          member = await getUserByIdAsync(accessToken, memberId);
        } catch (err) {
          console.log(err);
        }
      }

      return member;
    },
  );

  try {
    groupChatMembers = await Promise.all(groupChatMembersPromises);
  } catch (error) {
    console.log({ error });
  }

  return groupChatMembers;
};
