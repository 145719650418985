const whiteSpace = String.fromCharCode(160);

export const getFormattedThousandsString = (value: string): string => {
  const formattedValue = value.replaceAll(" ", "");
  if (!formattedValue) {
    return "";
  }

  return Number(formattedValue)
    .toLocaleString("cs-CZ")
    .replaceAll(whiteSpace, " ");
};
