import store, { GetterTypes } from "@/store";
import { AuthMutationTypes } from "@/store/auth";
import { ShallowReactive, markRaw } from "vue";
import { getNewRoom } from "./getNewRoom";
import { RoomData } from "@/types/call";
import { setInitialParticipantsData } from "./setInitialParticipantsData";
import { addRoomListeners } from "./addRoomListeners";

export const joinRoom = async (data: ShallowReactive<RoomData>) => {
  const ongoingCurrentCall =
    store.getters[GetterTypes.GET_CURRENT_ONGOING_CALL];
  const authId = store.getters[GetterTypes.GET_AUTH_ID];
  if (!ongoingCurrentCall) {
    return;
  }
  const { hasVideo, callType, chatId } = ongoingCurrentCall;
  try {
    const room = await getNewRoom({
      chatId,
      hasVideo,
      authId,
      callType,
    });
    data.room = markRaw(room);
    setInitialParticipantsData(room, data);
    addRoomListeners(data);
  } catch {
    store.commit(AuthMutationTypes.SET_CURRENT_ONGOING_CALL_ID, null);
  }
};
