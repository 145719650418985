export const getShortenedString = (str: string, charLimit: number) => {
  if (str.length <= charLimit) return str;

  const separator = "...";
  const charsToShow = charLimit - separator.length;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return (
    str.substring(0, frontChars) +
    separator +
    str.substring(str.length - backChars)
  );
};
