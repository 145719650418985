import { AnalyzeNewUserChatIds } from "@/store/chats/types";

export const analyzeNewUserChatIds = (
  newChatIds: string[],
  previousChatIds: string[],
): AnalyzeNewUserChatIds => {
  const sameIds = previousChatIds.filter((id) => newChatIds.includes(id));
  const newIds = newChatIds.filter((id) => !previousChatIds.includes(id));
  const removedIds = previousChatIds.filter((id) => !newChatIds.includes(id));

  return {
    sameIds,
    newIds,
    removedIds,
  };
};
