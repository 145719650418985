import store, { GetterTypes } from "@/store";
import { CallType } from "@/types/webview";
import { postStartCallBridgeMessage } from "./postStartCallBridgeMessage";
import { IStartCallArgs } from "@/types/call";
import { getCallAccessTokenAsync } from "./getCallAccessTokenAsync";
import { IOngoingCall } from "@/store/auth/types";
import { AuthMutationTypes } from "@/store/auth";
import { ChatsGetterTypes } from "@/store/chats";

export const startCallBeAsync = async ({
  chatId,
  userId,
  callType = CallType.Video,
  isDesktop = false,
}: IStartCallArgs) => {
  if (isDesktop) {
    const chat =
      store.getters[ChatsGetterTypes.GET_CONVERTED_CHAT_DETAIL](chatId);
    const newOngoingCall: IOngoingCall = {
      chatId,
      callerId: userId,
      hasVideo: callType === CallType.Video,
      hasAudio: true,
      callType,
      displayChatName: chat.name,
      displayChatPicture: chat.picture,
    };
    store.commit(AuthMutationTypes.ADD_ONGOING_CALL, newOngoingCall);
    store.commit(AuthMutationTypes.SET_CURRENT_ONGOING_CALL_ID, chatId);
    return;
  }
  const accessToken = store.getters[GetterTypes.GET_AUTH_ACCESS_TOKEN];
  if (!accessToken) {
    return;
  }
  try {
    const twilioAccessToken = await getCallAccessTokenAsync({
      chatId,
      userId,
      callType,
    });
    if (!twilioAccessToken) {
      return;
    }
    postStartCallBridgeMessage({ chatId, twilioAccessToken, callType });
  } catch (error) {
    console.log(error);
  }
};
