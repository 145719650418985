<template>
  <div
    ref="el"
    class="chat-content"
    :class="{ hasReplyMessage: !!replyMessage }"
    @click="handleBlurInput"
  >
    <div
      ref="scrollContainerRef"
      class="messages-wrapper"
      :class="{ isScrollDisabled }"
    >
      <div ref="sentinelRef" />
      <ChatMessage
        v-for="(message, index) in visibleMessages"
        :key="message.id"
        :index="index"
        :message="message"
        :visible-messages="visibleMessages"
        @reply-to-message="replyToMessage"
      />
      <span
        v-if="showReadAt(visibleMessages, visibleMessages.length - 1)"
        class="read-at"
        :class="deletingMessages ? 'padding-right' : ''"
      >
        {{ $t("chat.chatContainer.read") }}
        {{ convertedDateString }}
      </span>
      <div v-if="isBlockingTextShown" class="blocking-text">
        <p v-for="(str, i) in blockingStrings" :key="i">
          {{ str }}
        </p>
      </div>
      <div ref="bottomAnchorRef" />
    </div>
  </div>
</template>

<script setup lang="ts">
import ChatMessage from "@/components/chat/chatContainer/ChatMessage/ChatMessage.vue";
import { showReadAt } from "@/utils/message";
import { useGetChatContentProperties } from "./useGetChatContentProperties";
import { ChatBlockingStatusEnum, Message } from "@/store/chats/types";
import { inject } from "vue";
import { replyMessageInjectionKey } from "@/utils/chat";

interface ChatContentProps {
  messages: Message[];
  blockingStatus?: ChatBlockingStatusEnum;
  isDeleted?: boolean;
  userName?: string;
}

const props = withDefaults(defineProps<ChatContentProps>(), {
  messages: () => [],
  blockingStatus: undefined,
  isDeleted: undefined,
  userName: "",
});

interface Emits {
  (e: "replyToMessage", message: Message): void;
}

const emit = defineEmits<Emits>();
const replyToMessage = (message: Message) => {
  emit("replyToMessage", message);
};

const replyMessage = inject(replyMessageInjectionKey);

const {
  el,
  scrollContainerRef,
  handleBlurInput,
  visibleMessages,
  isBlockingTextShown,
  deletingMessages,
  convertedDateString,
  blockingStrings,
  sentinelRef,
  bottomAnchorRef,
  isScrollDisabled,
} = useGetChatContentProperties(props);
</script>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatContainer/chatContent.scss";
</style>
