<template>
  <PopupInfo
    title="popup.PopupInfoReportUser.title"
    label="popup.PopupInfoReportUser.label"
    btn-text="popup.PopupInfoReportUser.btnText"
    :action="clickAction"
  />
</template>

<script setup lang="ts">
import PopupInfo from "@/components/popup/PopupInfo.vue";
import { ActionTypes, GetterTypes, MutationTypes, store } from "@/store";
import { getPartnerIdFromChatId } from "@/firebase/utils";
import { useComputedValue } from "@/composables";
import { useRoute } from "vue-router";

const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);
const selectedChatId = useComputedValue<string>(
  GetterTypes.GET_SELECTED_CHAT_ID,
);
const route = useRoute();

const clickAction = async () => {
  const chatId = selectedChatId.value ?? route.params.chat_id;
  const partnerId = getPartnerIdFromChatId(authId.value, chatId);
  await store.dispatch(ActionTypes.REPORT_USER, partnerId);
  store.commit(MutationTypes.HIDE_POPUP);
};
</script>
