import { LocalParticipant, createLocalVideoTrack } from "twilio-video";
import { addLocalParticipantTrack } from "./addLocalParticipantTrack";
import { config } from "@/config";

export const publishVideoTrack = async (localParticipant: LocalParticipant) => {
  const localTrack = await createLocalVideoTrack(
    config.constants.DEFAULT_VIDEO_SETTINGS,
  );
  addLocalParticipantTrack(localParticipant, localTrack);
};
