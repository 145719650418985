import { IChatSettingsWarningPoint } from "@/types/chat";
import { IconType } from "@/types/icons";

export const blockUserWarningPoints: IChatSettingsWarningPoint[] = [
  {
    title: "settings.chat.blockUser.blockingMessages.title",
    text: "settings.chat.blockUser.blockingMessages.text",
    iconName: IconType.BLOCK_GRAY,
  },
  {
    title: "settings.chat.blockUser.blockCanBeReverted.title",
    text: "settings.chat.blockUser.blockCanBeReverted.text",
    iconName: IconType.BACK_GRAY,
  },
];
