<template>
  <div v-if="showMessageDate(visibleMessages, index)" class="message-date">
    {{ getMessageDate(message) }}
  </div>
  <div
    :id="messageElementId"
    class="message-wrapper"
    :class="{
      isBlocking: isBlockingTextShown && index === visibleMessages.length - 1,
      isActive: isControlsActive,
    }"
  >
    <div class="message" :class="getIsMyMessageClass(message, authId)">
      <div class="message-content-wrapper">
        <ChatMessageReactions
          :message="message"
          :is-active="isActive"
          :has-shadow="hasShadow"
          :is-modal-open="isReactionsModalOpen"
          :modal-id="reactionsModalId"
          :element-id="messageContentElementId"
          :position="positionObject"
          :is-touch-end-enabled="isTouchEndEnabled"
          @hide-shadow="hideShadow"
          @open-modal="openReactionsModal"
        >
          <div
            v-if="isMessageContentShown"
            class="message-content"
            :class="[getMessageClass(visibleMessages, index, authId)]"
            v-on="handlers"
          >
            <slot />
          </div>
        </ChatMessageReactions>
        <slot name="imageAttachment" />
        <slot name="videoPlayer" />
      </div>
      <slot name="checkboxSelectMessage" />
    </div>
  </div>
  <BackdropModal :enabled="isReactionsModalOpen">
    <ChatMessageReactionsModal
      v-if="isReactionsModalOpen && !getIsAutomaticMessageType(message)"
      :id="reactionsModalId"
      :message="message"
      @close-reactions-modal="closeReactionsModal"
    />
  </BackdropModal>
</template>

<script setup lang="ts">
import ChatMessageReactions from "../ChatMessageReactions/ChatMessageReactions.vue";
import {
  showMessageDate,
  getMessageClass,
  getMessageDate,
  getIsMessageTextType,
  getIsAutomaticMessageType,
} from "@/utils/message";
import { getIsMyMessageClass } from "@/utils/chat";
import { Message } from "@/store/chats/types";
import { useComputedValue, useLongPress } from "@/composables";
import { GetterTypes } from "@/store";
import { computed, onUnmounted, reactive, ref } from "vue";
import { getIsBlockingTextShown } from "@/utils/chat";
import { useStore } from "vuex";
import { ChatsMutationTypes } from "@/store/chats";
import { CustomClickEvent } from "@/types/app";
import { findParentNodeById, getSafeElementPosition } from "@/utils/app";
import ChatMessageReactionsModal from "../ChatMessageReactionsModal/ChatMessageReactionsModal.vue";
import BackdropModal from "@/components/modals/BackdropModal/BackdropModal.vue";
import { LayoutTypes } from "@/store/app/state";

interface ChatMessageContentProps {
  message: Message;
  index: number;
  visibleMessages: Message[];
}

const { commit } = useStore();
const props = defineProps<ChatMessageContentProps>();
const isBlockingTextShown = getIsBlockingTextShown();
const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);
const messageReactionId = useComputedValue<string | null>(
  GetterTypes.GET_MESSAGE_REACTION_ID,
);
const positionObject = reactive({
  top: 0,
  left: 0,
  width: 0,
});
const selectedMessageIds = useComputedValue<string[] | undefined>(
  GetterTypes.GET_SELECTED_MESSAGE_IDS,
);
const messageElementId = computed(() => `message_${props.message.id}`);
const messageContentElementId = computed(
  () => `message_content_${props.message.id}`,
);
const hasShadow = ref(false);
const isReactionsModalOpen = ref(false);
const isMessageAutomatic = computed(() =>
  getIsAutomaticMessageType(props.message),
);

const isActive = computed(
  () =>
    !isMessageAutomatic.value && messageReactionId.value === props.message.id,
);
const isControlsActive = computed(
  () => isActive.value && !isReactionsModalOpen.value,
);
const reactionsModalId = computed(() => `reactions-modal-${props.message.id}`);
const layoutType = useComputedValue<LayoutTypes>(GetterTypes.GET_LAYOUT_TYPE);
const isMobile = computed(() => layoutType.value === LayoutTypes.MOBILE);
const isTouchEndEnabled = ref(false);

const isMessageContentShown = computed<boolean>(() => {
  if (!getIsMessageTextType(props.message)) {
    return true;
  }

  return Boolean(
    props.message.messageText ||
      props.message?.othersAttachments ||
      props.message?.imageAttachments?.length === 1,
  );
});

const handlePosition = () => {
  const messageContentEl = document.getElementById(
    messageContentElementId.value,
  );
  if (!messageContentEl) {
    return;
  }

  const { top, left, width } = getSafeElementPosition(messageContentEl);

  positionObject.left = left;
  positionObject.top = top;
  positionObject.width = width;
  hasShadow.value = true;
};

const onLongPress = (event: CustomClickEvent) => {
  // if the user is in deleting messages mode, or if the message is automatic, reactions are not allowed
  if (selectedMessageIds.value || isMessageAutomatic.value || !isMobile.value) {
    return;
  }
  const target = event.target;
  commit(ChatsMutationTypes.SET_MESSAGE_REACTION_ID, {
    messageId: props.message.id,
  });

  const wrapper = findParentNodeById(target, messageElementId.value);
  if (!wrapper) {
    return;
  }

  disableScroll();
  handlePosition();
  disableTouchEnd();
};

const handleEnableTouchEnd = () => {
  console.log("handleEnableTouchEnd");
  isTouchEndEnabled.value = true;
};

const disableTouchEnd = () => {
  console.log("disableTouchEnd");
  isTouchEndEnabled.value = false;
  window.addEventListener("mouseup", handleEnableTouchEnd);
};

const clearDisableTouchEnd = () => {
  console.log("clearDisableTouchEnd");

  isTouchEndEnabled.value = true;
  window.removeEventListener("mouseup", handleEnableTouchEnd);
};

onUnmounted(() => {
  clearDisableTouchEnd();
});

const disableScroll = () => {
  document.body.style.overflow = "hidden";
};

const hideShadow = () => {
  positionObject.left = 0;
  positionObject.top = 0;
  hasShadow.value = false;
  clearDisableTouchEnd();
};

const handlers = useLongPress({ onLongPress });

const openReactionsModal = () => {
  commit(ChatsMutationTypes.SET_MESSAGE_REACTION_ID, {
    messageId: props.message.id,
  });
  isReactionsModalOpen.value = true;
};

const closeReactionsModal = () => {
  commit(ChatsMutationTypes.SET_MESSAGE_REACTION_ID, {
    messageId: null,
  });
  isReactionsModalOpen.value = false;
};
</script>

<style lang="scss">
@import "./ChatMessageContent.scss";
</style>
