import {
  FriendRequestMessage,
  Message,
  MessageType,
} from "@/store/chats/types";

export const getIsMessageFriendRequestType = (
  message: Message,
): message is FriendRequestMessage => {
  return message.type === MessageType.FriendRequest;
};
