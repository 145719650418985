import { IChatModifiedReferenceObject } from "@/types/chat";
import { postWebviewMessage } from "../api";
import { BridgeActionEnum } from "@/types/webview";

export const addChatsShareSuggestions = (
  chats: IChatModifiedReferenceObject[],
) => {
  const addedChats = chats.map(({ convertedChat }) => {
    const { id, name, picture } = convertedChat;
    return { id, name, picture };
  });

  postWebviewMessage({
    action: BridgeActionEnum.AddChatsShareSuggestions,
    userSuggestions: JSON.stringify(addedChats),
  });

  return addedChats;
};
