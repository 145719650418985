import whatIsNewObj from "@/versions/what-is-new.json";
import { getVersionNumber } from "./getVersionNumber";

export const getNewestAppVersionNumber = (): number => {
  const keysArray: string[] = Object.keys(whatIsNewObj);
  if (!keysArray.length) {
    return 0;
  }
  const numbersArray: number[] = keysArray.map((key) => getVersionNumber(key));
  return Math.max(...numbersArray);
};
