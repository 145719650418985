<template>
  <p v-if="errorMessage" class="errorMessage">{{ errorMessage }}</p>
</template>

<script>
export default {
  props: {
    errorMessage: {
      required: true,
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/form/inputErrorMessage.scss";
</style>
