<template>
  <OnboardingNotifications />
</template>
<script setup lang="ts">
import OnboardingNotifications from "@/components/onboarding/OnboardingNotifications/OnboardingNotifications.vue";
import { OnboardingContainerTypeEnum } from "@/types/onboarding";
import { onboardingInjectionKey } from "@/utils/onboarding/onboardingInjectionKey";
import { provide } from "vue";

provide(onboardingInjectionKey, {
  type: OnboardingContainerTypeEnum.Settings,
});
</script>
<style lang="scss" scoped>
@import "src/assets/scss/settings/settingsNotifications";
</style>
