<template>
  <img
    :key="url"
    :src="url"
    :alt="description"
    class="gif-image"
    @click="insertGif"
  />
</template>

<script setup lang="ts">
interface Emits {
  (e: "insert-gif", url: string): void;
}

interface Props {
  description: string;
  url: string;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const insertGif = () => {
  emit("insert-gif", props.url);
};
</script>

<style lang="scss" scoped>
@import "./GifItem.scss";
</style>
