import { chatApiAxios } from "@/plugins";
import { HttpRequestMethodEnum } from "@/types/api";

export const getFirebaseAuthTokenBeAsync = async ({
  refreshToken,
}: {
  refreshToken: string;
}) => {
  const headers = {
    Authorization: `Bearer ${refreshToken}`,
  };

  const res = await chatApiAxios("auth", {
    method: HttpRequestMethodEnum.GET,
    headers,
  });
  return res.data;
};
