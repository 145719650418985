import { IChatSettingsWarningPoint } from "@/types/chat";
import { IconType } from "@/types/icons";

export const leaveGroupConversationWarningPoints: IChatSettingsWarningPoint[] =
  [
    {
      title: "settings.chat.leaveGroupConversation.leavingConversation.title",
      text: "settings.chat.leaveGroupConversation.leavingConversation.text",
      iconName: IconType.LEAVE,
    },
    {
      title: "settings.chat.leaveGroupConversation.yourMessagesRemain.title",
      text: "settings.chat.leaveGroupConversation.yourMessagesRemain.text",
      iconName: IconType.MESSAGE_DOUBLE,
    },
  ];
