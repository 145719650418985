import store from "@/store";
import { User } from "@/store/users/state";

export const findUserById = (userId: number, users?: User[]) => {
  if (!users) {
    return store.getters.GET_USER(userId);
  }

  return users.find((user) => user?.id === userId) as User;
};
