import { GroupRawTextMessage } from "@/store/chats/types";
import { defaultAttachmentString } from "../constants";

export const getDisplayGroupMessage = (
  message: GroupRawTextMessage,
  senderName: string,
): string => {
  const firstSenderName = senderName?.split?.(" ")?.[0];
  if (message.messageText) {
    return firstSenderName + ": " + message.messageText;
  }
  if (message.attachments?.length) {
    return firstSenderName + ": " + defaultAttachmentString;
  }
  return "";
};
