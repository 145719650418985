import { RoomParticipant } from "@/types/call";
import { addParticipantListeners, addSubscribedTracks } from "@/utils/call";
import { computed, onMounted, ref } from "vue";
interface Props {
  roomParticipant: RoomParticipant | null;
}

export const useGetCallParticipantProperties = (props: Props) => {
  const mediaOutputRef = ref<HTMLDivElement | null>(null);
  const roomParticipant = computed(() => props.roomParticipant);

  onMounted(() => {
    addConnectedParticipant();
  });

  const addConnectedParticipant = async () => {
    const participant = roomParticipant.value?.participant;
    if (
      !roomParticipant.value?.isConnected ||
      !participant ||
      !mediaOutputRef.value
    ) {
      return;
    }
    addParticipantListeners(participant, mediaOutputRef);
    addSubscribedTracks(participant, mediaOutputRef);
  };

  return { mediaOutputRef };
};
