import store from "@/store";
import { HttpRequestMethodEnum } from "@/types/api";
import { IAuthResponseObject } from "@/types/auth";
import { request } from "../api";
import { config } from "@/config";

export const updateUserPhoneAsync = async (
  idToken: string,
  countryCode: string,
  phoneNumber: string,
) => {
  const accessToken = store.getters.GET_AUTH_ACCESS_TOKEN;
  try {
    await request<IAuthResponseObject>(
      config.api.maitrejaApi.endpoints.updateUserPhone,
      HttpRequestMethodEnum.POST,
      {
        id_token: idToken,
        country_code: countryCode,
        phone_number: phoneNumber,
      },
      accessToken,
    );
  } catch (error) {
    console.log({ error });

    throw error;
  }
};
