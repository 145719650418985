export interface ButtonObject {
  handleClick: () => void;
  text?: string;
}

export enum GenericButtonVariantEnum {
  Primary = "primary",
  Secondary = "secondary",
  Terciary = "terciary",
  Error = "error",
}

export enum ActionButtonType {
  Decline = "decline",
  Accept = "accept",
}
