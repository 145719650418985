import {
  convertFirebaseDateToLocal,
  getPartnerIdFromChatId,
} from "@/firebase/utils";
import { Profile } from "@/store/auth/state";
import {
  ChatTypes,
  MessageType,
  AudioMessage,
  OneToOneRawAudioMessage,
  OneToOneRawChat,
} from "@/store/chats/types";
import { User } from "@/store/users/state";
import { getOneToOneMessageReadBy } from "../chat/getOneToOneMessageReadBy";
import { getReadOneToOneMessage } from "../chat/getReadOneToOneMessage";
import { getSenderName } from "../chat/getSenderName";
import { getStatusIcon } from "../chat/getStatusIcon";
import { findUserById } from "../users";
import { getNonAutomaticReplyMessage } from "./getNonAutomaticReplyMessage";

interface ConvertRawOneToOneAudioMessageArgs {
  rawMessage: OneToOneRawAudioMessage;
  rawChat: OneToOneRawChat;
  auth: Profile;
  users?: User[];
  ignoreReplies: boolean;
}

export const convertRawOneToOneAudioMessage = ({
  rawMessage,
  rawChat,
  auth,
  users,
  ignoreReplies,
}: ConvertRawOneToOneAudioMessageArgs): AudioMessage => {
  const { id: chatId } = rawChat;
  const { senderId, attachments, hide, sentAt, id, reactions, replyToMessage } =
    rawMessage;
  const partnerId = getPartnerIdFromChatId(auth.id, chatId);
  const partner = findUserById(partnerId, users);
  const members = [partner].filter(Boolean) as User[];
  const hasAttachment = Boolean(attachments && attachments.length);
  const hidden = Boolean(hide) && !hasAttachment;

  return {
    chatType: ChatTypes.ONE_TO_ONE_CHAT,
    id,
    senderId,
    senderName: getSenderName(senderId, auth.id, members),
    isMine: auth.id === senderId,
    sentAt: convertFirebaseDateToLocal(sentAt),
    hidden,
    readBy: getOneToOneMessageReadBy(rawMessage, auth, chatId),
    read: getReadOneToOneMessage(rawMessage, auth),
    statusIcon: getStatusIcon(rawMessage, auth),
    attachments,
    type: MessageType.Audio,
    reactions,
    replyToMessage: getNonAutomaticReplyMessage({
      rawMessage: replyToMessage,
      rawChat,
      users,
      ignoreReplies,
    }),
  };
};
