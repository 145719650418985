import { RoomData } from "@/types/call";
import { RemoteParticipant } from "twilio-video";
import { ShallowReactive } from "vue";
import { convertParticipantToRoomParticipant } from "./convertParticipantToRoomParticipant";
import { getFilteredParticipants } from "./getFilteredParticipants";
import { getLocalParticipant } from "./getLocalParticipant";
import { setDominantParticipant } from "./setDominantParticipant";

export const handleParticipantConnected = (
  participant: RemoteParticipant,
  data: ShallowReactive<RoomData>,
) => {
  const alreadyExists =
    data.dominantParticipant?.participant?.identity === participant.identity ||
    data.participants.find(
      (par) => par.participant?.identity === participant.identity,
    );

  if (!data.room) {
    return;
  }
  if (alreadyExists) {
    return;
  }

  if (!data.localParticipant) {
    setDominantParticipant(participant, data);
    data.localParticipant = getLocalParticipant(data.room);
    return;
  }
  const newParticipants = [
    ...getFilteredParticipants(data.participants, participant),
  ];
  const newParticipant = convertParticipantToRoomParticipant(participant);
  if (newParticipant) {
    newParticipants.push(newParticipant);
  }
  data.participants = newParticipants;
};
