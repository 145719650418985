import { config } from "@/config";
import { chatApiAxios } from "@/plugins";
import { HttpRequestMethodEnum } from "@/types/api";
import { getRefreshToken } from "@/utils/api";

export const declineRingingCallAsync = async (chatId: string) => {
  const refreshToken = getRefreshToken();
  const headers = {
    Authorization: `Bearer ${refreshToken}`,
  };
  const res = await chatApiAxios(config.api.chatApi.endpoints.call.reject, {
    method: HttpRequestMethodEnum.POST,
    headers,
    data: {
      roomName: chatId,
    },
  });
  return res.data;
};
