<template>
  <div class="search-bar">
    <input
      :value="searchText"
      :placeholder="placeholder"
      @input="updateSearch"
    />
    <Icon :icon="IconType.MAGNIFY" class="icon" />
  </div>
</template>

<script setup lang="ts">
import Icon from "@/components/icons/Icon/Icon.vue";
import { config } from "@/config";
import { IconType } from "@/types/icons";
import _ from "lodash";
import { ref } from "vue";

interface Emits {
  (e: "search", searchText: string): void;
}
interface Props {
  placeholder?: string;
}

withDefaults(defineProps<Props>(), {
  placeholder: undefined,
});

const emit = defineEmits<Emits>();
const searchText = ref<string>();

const updateSearch = (e: Event) => {
  const target = e.target as HTMLInputElement;
  if (!target) {
    return;
  }
  const { value } = target;
  searchText.value = value;
  debounceSearch(value);
};

const emitSearch = (val: string) => {
  emit("search", val);
};

const debounceSearch = _.debounce(emitSearch, config.constants.DEBOUNCE_TIME);
</script>

<style lang="scss" scoped>
@import "./SearchBar.scss";
</style>
