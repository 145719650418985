import { useComputedValue, useVisualViewport } from "@/composables";
import { GetterTypes } from "@/store";
import { ChatBlockingStatusEnum, Chat, Message } from "@/store/chats/types";
import { ref, computed } from "vue";

export const useGetChatContentContainerProperties = () => {
  const root = ref<HTMLElement>();
  const { style } = useVisualViewport(root);
  const messages = useComputedValue<Message[]>(
    GetterTypes.GET_SELECTED_CHAT_MESSAGES_ARRAY,
  );
  const blockingStatus = useComputedValue<ChatBlockingStatusEnum | undefined>(
    GetterTypes.GET_SELECTED_CHAT_BLOCKING_STATUS,
  );
  const isDeleted = useComputedValue<boolean>(
    GetterTypes.GET_SELECTED_CHAT_IS_DELETED,
  );
  const isBlockingTextShown = computed(() => {
    return (
      (blockingStatus.value &&
        blockingStatus.value !== ChatBlockingStatusEnum.None) ||
      isDeleted.value
    );
  });
  const chat = useComputedValue<Chat>(GetterTypes.GET_SELECTED_CHAT);

  return {
    root,
    style,
    isBlockingTextShown,
    messages,
    members: chat.value.members,
  };
};
