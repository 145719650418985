import { chatApiAxios } from "@/plugins";
import { Attachment, MessageType } from "@/store/chats/types";
import { HttpRequestMethodEnum } from "@/types/api";

interface CreateChat {
  memberIds: string[];
  messageText?: string;
  firebaseIdToken: string;
  chatId: string;
  attachments?: Attachment[];
  messageId: string;
  messageType: MessageType;
}

export const createChatBeAsync = async ({
  memberIds,
  messageText,
  firebaseIdToken,
  chatId,
  attachments = [],
  messageId,
  messageType,
}: CreateChat) => {
  const headers = {
    Authorization: `Bearer ${firebaseIdToken}`,
  };
  const res = await chatApiAxios("chat", {
    method: HttpRequestMethodEnum.POST,
    headers,
    data: {
      memberIds,
      message: messageText,
      chatId,
      attachments,
      messageId,
      messageType,
    },
  });
  return res.data;
};
