import { PhoneNumberUtil, PhoneNumber } from "google-libphonenumber";

export const getPhoneNumberPropertiesFromFullString = (phoneString: string) => {
  const phoneNumberUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
  const value: PhoneNumber = phoneNumberUtil.parseAndKeepRawInput(phoneString);
  const countryCode: string = `+${value.getCountryCode()}`;
  const phoneNumber: string = value.getNationalNumber()
    ? String(value.getNationalNumber())
    : "";

  return { countryCode, phoneNumber };
};
