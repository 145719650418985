<template>
  <div class="initial-container">
    <ReportErrorIconButton />
    <MobileNavigationBar :icon-type="MobileNavigationBarIconType.Circle" />
    <div class="content">
      <div className="text-container">
        <h1 className="title">{{ t("auth.registration") }}</h1>
        <p class="text">{{ t("auth.enterRequirements") }}</p>
      </div>
      <Form @submit-form="handleSubmit">
        <div class="form-content">
          <div class="top">
            <div class="inputs">
              <Input
                v-model="data.name"
                :name="FormFieldNameEnum.Name"
                placeholder="auth.name"
                required
                :errors="data.errors"
              />
              <Input
                v-model="data.email"
                :name="FormFieldNameEnum.Email"
                placeholder="auth.email"
                required
                :errors="data.errors"
              />
              <Input
                v-model="data.password"
                :name="FormFieldNameEnum.Password"
                :type="FormFieldType.Password"
                placeholder="auth.password"
                required
                :errors="data.errors"
              />
            </div>
            <div class="bottom">
              <div class="checkbox-container">
                <Icon
                  :icon="checkboxSrc"
                  class="checkbox"
                  @click="toggleCheckbox"
                />
                <p>
                  {{ t("auth.businessConditions.iAgreeWith") }}
                  <a :href="`${maitrejaAppUrl}/content/gdpr`" target="_blank">{{
                    t("auth.businessConditions.gdpr")
                  }}</a>
                  {{ t("auth.businessConditions.and") }}
                  <a
                    :href="`${maitrejaAppUrl}/content/conditions`"
                    target="_blank"
                    >{{ t("auth.businessConditions.conditions") }}</a
                  >.
                </p>
              </div>
            </div>
          </div>
          <GenericButton
            :variant="GenericButtonVariantEnum.Primary"
            text="auth.buttons.register"
          />
        </div>
      </Form>
    </div>
    <div class="filler" />
  </div>
</template>

<script setup lang="ts">
import Form from "@/components/layouts/Form.vue";
import GenericButton from "@/components/buttons/GenericButton.vue";
import ReportErrorIconButton from "@/components/buttons/ReportErrorIconButton/ReportErrorIconButton.vue";
import MobileNavigationBar from "@/components/layouts/MobileNavigationBar/MobileNavigationBar.vue";
import { ActionTypes, MutationTypes } from "@/store";

import { handleOnboardingRedirect } from "@/utils/settings";
import {
  getInitialFormData,
  getIsFormValid,
  registerFormFields,
} from "@/utils/form";
import {
  FormFieldNameEnum,
  FormFieldType,
  FormType,
  RegisterFormDataObjectType,
} from "@/types/form";
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { MobileNavigationBarIconType } from "@/types/app";
import { handleMountErrorMessageSnackbar } from "@/utils/app";
import { registerSnackbarErrorObject } from "@/utils/auth";
import { GenericButtonVariantEnum } from "@/types/button";
import { config } from "@/config";
import { IconType } from "@/types/icons";
import Icon from "../icons/Icon/Icon.vue";
import Input from "../forms/Input.vue";

const { commit, dispatch } = useStore();
const { t } = useI18n();

const data = reactive(
  getInitialFormData<RegisterFormDataObjectType>(registerFormFields),
);
const agreedToTermsAndConditions = ref(false);
const checkboxSrc = computed<IconType>(() =>
  agreedToTermsAndConditions.value
    ? IconType.CHECKBOX_ON
    : IconType.CHECKBOX_OFF,
);

const maitrejaAppUrl = config.api.maitrejaApi.appUrl;

const toggleCheckbox = () => {
  agreedToTermsAndConditions.value = !agreedToTermsAndConditions.value;
};

const handleSubmit = async () => {
  const { errors, ...fields } = data;

  const isFormValid = getIsFormValid(
    data,
    registerFormFields,
    FormType.Register,
  );
  if (!isFormValid) {
    return;
  }

  if (!agreedToTermsAndConditions.value) {
    dispatch(ActionTypes.MOUNT_SNACKBAR, {
      title: "snackbar.errors.agreeToTermsAndConditions.title",
      text: "snackbar.errors.agreeToTermsAndConditions.text",
    });
    return;
  }

  commit(MutationTypes.SET_IS_LOADING, true);
  try {
    await dispatch(ActionTypes.REGISTER_AUTH, {
      name: data.name,
      email: data.email,
      password: data.password,
    });

    data.errors = fields;
    handleOnboardingRedirect();
  } catch (error) {
    handleMountErrorMessageSnackbar(error, registerSnackbarErrorObject);
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/form/initialFormLayout.scss";
@import "src/assets/scss/register/registerForm.scss";
</style>
