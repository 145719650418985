import store from "@/store";
import { AuthGetterTypes, AuthMutationTypes } from "@/store/auth";

export const closeTheOnlyRoom = () => {
  const ongoingCall = store.getters[AuthGetterTypes.GET_ONGOING_CALLS_OBJECT];
  const ongoingCallsLength = ongoingCall.calls.length;
  if (ongoingCallsLength > 1) {
    return;
  }
  store.commit(AuthMutationTypes.SET_CURRENT_ONGOING_CALL_ID, null);
};
