const MINIMUM_CHUNK_SIZE = 5 * 1024 * 1024; // Required a minimum chunk size of 5MB, here we use 50MB.
const CHUNK_SIZE_DIVIDER = 256 * 1024; // chunk size must be divisible by 256kb
const CHUNK_SHARES_COUNT = 8;
const MAXIMUM_CHUNK_SIZE = 10 * MINIMUM_CHUNK_SIZE;

export const getVideoChunkSize = (fileSize: number) => {
  const chunk = fileSize / CHUNK_SHARES_COUNT;
  if (fileSize <= MINIMUM_CHUNK_SIZE) {
    return fileSize;
  }

  const chunkSize = Math.ceil(chunk / CHUNK_SIZE_DIVIDER) * CHUNK_SIZE_DIVIDER;
  if (chunkSize <= MINIMUM_CHUNK_SIZE) {
    return MINIMUM_CHUNK_SIZE;
  }

  if (chunkSize > MAXIMUM_CHUNK_SIZE) {
    return MAXIMUM_CHUNK_SIZE;
  }

  return chunkSize;
};
