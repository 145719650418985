import { AppActionTypes } from "./app";
import { AuthActionTypes } from "./auth";
import { ChatsActionTypes } from "./chats";
import { UsersActionTypes } from "./users";
import { MenuActionTypes } from "./menu";

export const AllActionTypes = {
  ...AppActionTypes,
  ...AuthActionTypes,
  ...ChatsActionTypes,
  ...UsersActionTypes,
  ...MenuActionTypes,
};
