import { IListItem, ListItemTypeEnum } from "@/types/list";
import { getIsChatBlocked } from "./getIsChatBlocked";
import { getIsChatDeleted } from "./getIsChatDeleted";
import { IconType } from "@/types/icons";

export const getChatSettingsOneToOneChatMenuItems = (
  chatId: string,
): IListItem[] => [
  {
    text: "settings.chat.createGroupChat.title",
    iconName: IconType.GROUP,
    type: ListItemTypeEnum.Link,
    link: `create_group_chat/${chatId}`,
    isHidden: getIsChatDeleted(chatId),
  },
  {
    text: "settings.chat.blockUser.title",
    iconName: IconType.BLOCK,
    type: ListItemTypeEnum.Link,
    link: `block_user/${chatId}`,
    isHidden: !getIsChatBlocked(chatId) || getIsChatDeleted(chatId),
  },
  {
    text: "settings.chat.unblockUser.title",
    iconName: IconType.BLOCK,
    type: ListItemTypeEnum.Link,
    link: `unblock_user/${chatId}`,
    isHidden: getIsChatBlocked(chatId) || getIsChatDeleted(chatId),
  },
  {
    text: "settings.chat.reportUser.title",
    iconName: IconType.REPORT,
    type: ListItemTypeEnum.Link,
    link: `report_user/${chatId}`,
  },
];
