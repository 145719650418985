import { config } from "@/config";
import { request } from "../api";
import { HttpRequestMethodEnum } from "@/types/api";
import { renewAccessTokenAsync } from "./renewAccessTokenAsync";
import store from "@/store";

export const checkAccessTokenAsync = async () => {
  let accessToken = store.getters.GET_AUTH_ACCESS_TOKEN as string;
  try {
    await request(
      config.api.maitrejaApi.endpoints.checkToken,
      HttpRequestMethodEnum.GET,
      undefined,
      accessToken,
    );
  } catch (error) {
    accessToken = await renewAccessTokenAsync();
  }

  return accessToken;
};
