import { BridgeActionEnum } from "@/types/webview";
import { postWebviewMessage } from "./postWebviewMessage";
import { isAppEnvironmentAndroid } from "../app";

export const handleAllowAccessToContacts = (): void => {
  // disabled for Android due to problems with app review
  if (isAppEnvironmentAndroid) {
    return;
  }

  postWebviewMessage({
    action: BridgeActionEnum.GetContacts,
  });
};
