<template>
  <ChatPreviewLoading v-for="(item, i) in [...Array(7)]" :key="i" />
</template>

<script>
import ChatPreviewLoading from "./ChatPreviewLoading.vue";

export default {
  components: {
    ChatPreviewLoading,
  },
};
</script>
