import { chatApiAxios } from "@/plugins";
import { HttpRequestMethodEnum } from "@/types/api";
import { IMarkMessageAsReadObject } from "@/types/chat";

export const markMessageAsReadBeAsync = async ({
  messageId,
  firebaseIdToken,
}: IMarkMessageAsReadObject) => {
  const headers = {
    Authorization: `Bearer ${firebaseIdToken}`,
  };
  const res = await chatApiAxios("message/" + messageId + "/mark-as-read", {
    method: HttpRequestMethodEnum.PATCH,
    headers,
  });
  return res.data;
};
