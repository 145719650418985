import router from "./router";
import store from "./store";
import vueCustomElement from "vue-custom-element";
import i18n from "./i18n";

// @ts-expect-error
import vClickOutside from "click-outside-vue3";

export default (app: any) => {
  app.provide("$store", store);
  app.provide("$router", router);

  app.use(vueCustomElement);
  app.use(store);
  app.use(router);
  app.use(i18n);
  app.use(vClickOutside);
};
