<template>
  <ChatSettingsDetails v-bind="leaveGroupConversationProperties" />
</template>
<script setup lang="ts">
import { useGetChatSettingsLeaveGroupConversationProperties } from "./useGetChatSettingsLeaveGroupConversationProperties";
import ChatSettingsDetails from "@/components/chat/chatSettings/ChatSettingsDetails/ChatSettingsDetails.vue";

const { leaveGroupConversationProperties } =
  useGetChatSettingsLeaveGroupConversationProperties();
</script>
<style lang="scss" scoped></style>
