<template>
  <SwitchIconButton
    :active-icon="IconType.CONTROL_MICROPHONE_ACTIVE"
    :inactive-icon="IconType.CONTROL_MICROPHONE_INACTIVE"
    :is-active="hasAudio"
    @click="toggleHasAudio"
  />
</template>

<script setup lang="ts">
import SwitchIconButton from "@/components/buttons/SwitchIconButton/SwitchIconButton.vue";
import { useComputedValue } from "@/composables";
import { GetterTypes, MutationTypes } from "@/store";
import { IOngoingCall } from "@/store/auth/types";
import { IconType } from "@/types/icons";
import { useStore } from "vuex";

const { commit } = useStore();
const hasAudio = useComputedValue<boolean>(
  GetterTypes.GET_ONGOING_CALL_HAS_AUDIO,
);
const currentCall = useComputedValue<IOngoingCall>(
  GetterTypes.GET_CURRENT_ONGOING_CALL,
);

const toggleHasAudio = () => {
  commit(MutationTypes.TOGGLE_ONGOING_CALL_AUDIO, {
    value: !hasAudio.value,
    callId: currentCall.value.chatId,
  });
};
</script>
