import { AttachmentTypes } from "@/store/chats/types";

export const getAttachmentType = (file: File): AttachmentTypes => {
  const type = file.type;

  if (type.includes("image")) {
    return AttachmentTypes.IMAGE;
  }
  if (type.includes("video")) {
    return AttachmentTypes.VIDEO;
  }
  if (type.includes("audio")) {
    return AttachmentTypes.AUDIO;
  }
  return AttachmentTypes.OTHERS;
};
