import { ChatsMutationTypes } from "./mutationTypes";
import { ChatsMutationType } from "./mutationState";
import {
  ChatTypes,
  FirestoreDocumentChangeType,
  RawMessagesState,
} from "./types";
import { getIdPrefixedObjectFromArray } from "@/utils/modifiers";
import {
  combineUpdatedRawTextMessage,
  getIsRawMessageAutomaticType,
  getIsRawMessageCallType,
  getIsRawMessageTextType,
  safeParseRawMessageWithAttachments,
} from "@/utils/message";
import _ from "lodash";
import { VideoDisplayStatus } from "@/types/chat";
import { convertRawChat } from "@/utils/chat/convertRawChat";
import { addChatsToState, getReducedChatIds } from "@/utils/chat";

export const chatsMutations: ChatsMutationType = {
  [ChatsMutationTypes.REMOVE_CHAT](state, { chatId }) {
    if (!chatId) return;

    const chat = state.chats?.[chatId];
    if (!chat) {
      return;
    }

    state.allChatIds = getReducedChatIds(state.allChatIds, chatId);
    state.remainingChatIds = getReducedChatIds(state.remainingChatIds, chatId);
    state.chatIds = getReducedChatIds(state.chatIds, chatId);
    chat.chatUnsubscribe?.();
    chat.messagesUnsubscribe?.();

    delete state.chats[chatId];

    if (state.selectedChat === chatId) {
      state.selectedChat = null;
    }
  },
  [ChatsMutationTypes.SET_AUTH_CHATS_UNSUBSCRIBE](state, { unsubscribe }) {
    state.authChatsUnsubscribe = unsubscribe;
  },
  [ChatsMutationTypes.SET_CHAT_UNSUBSCRIBE](state, { chatId, unsubscribe }) {
    if (!chatId) return;

    if (state.chats[chatId]) {
      state.chats[chatId].chatUnsubscribe = unsubscribe;
      return;
    }
    state.chats[chatId] = {
      chatUnsubscribe: unsubscribe,
      messages: {},
      messageIds: [],
      chatId,
    };
  },
  [ChatsMutationTypes.SET_MESSAGES_UNSUBSCRIBE](
    state,
    { chatId, unsubscribe },
  ) {
    if (!chatId) {
      return;
    }

    if (state.chats[chatId]) {
      state.chats[chatId].messagesUnsubscribe = unsubscribe;
      return;
    }
    state.chats[chatId] = {
      messagesUnsubscribe: unsubscribe,
      messages: {},
      messageIds: [],
      chatId,
    };
  },
  [ChatsMutationTypes.SET_CHAT_DATA](state, { chatId, rawChat }) {
    if (!chatId) {
      return;
    }
    if (state?.futureSelectedChatId === chatId) {
      state.selectedChat = chatId;
      delete state.futureSelectedChatId;
    }
    if (!state.chats[chatId]) {
      state.chats[chatId] = { messages: {}, messageIds: [], chatId };
    }
    const chatState = state.chats[chatId];
    chatState.rawChat = rawChat;
    chatState.convertedChat = convertRawChat(rawChat);
    const lastMessage = rawChat.lastMessage;
    if (!chatState?.messages) {
      chatState.messages = {};
    }

    if (!state.chatIds.includes(chatId)) {
      state.chatIds.push(chatId);
    }
    if (!state.remainingChatIds.includes(chatId)) {
      state.remainingChatIds.push(chatId);
    }
    if (!state.allChatIds.includes(chatId)) {
      state.allChatIds.push(chatId);
    }

    if (!lastMessage || chatState.messageIds.includes(lastMessage.id)) {
      return;
    }
    chatState.messages[lastMessage.id] = lastMessage;
    chatState.messageIds.push(lastMessage.id);
  },
  [ChatsMutationTypes.SET_CHATS_FILTER](state, { filter }) {
    state.chatsFilter = filter;
  },
  [ChatsMutationTypes.CLEAR_CHATS_FILTER](state) {
    state.chatsFilter = "";
  },
  [ChatsMutationTypes.SET_CHATS](state, chats) {
    addChatsToState(state, chats);
    state.initialLoadCompleted = true;
  },
  [ChatsMutationTypes.SET_ALL_CHAT_IDS](
    state,
    { allChatIds, remainingChatIds },
  ) {
    state.allChatIds = allChatIds;
    state.remainingChatIds = remainingChatIds;
  },
  [ChatsMutationTypes.APPEND_CHATS](state, chats) {
    addChatsToState(state, chats);
  },
  [ChatsMutationTypes.PREPEND_MESSAGES](state, { messages, chatId }) {
    const parsedMessages = messages.map(safeParseRawMessageWithAttachments);
    const messagesObject: RawMessagesState =
      getIdPrefixedObjectFromArray(parsedMessages);
    if (!state.chats?.[chatId]) {
      return;
    }
    state.chats[chatId].messages = {
      ...state.chats[chatId].messages,
      ...messagesObject,
    };
    const newMessagesIds = messages.map((mess) => mess.id);
    const oldMessagesIds = state.chats[chatId].messageIds;

    if (!oldMessagesIds.length) {
      return;
    }
    const allMessageIds = [...newMessagesIds, ...oldMessagesIds];
    const uniqueMessageIds = [...new Set(allMessageIds)];
    state.chats[chatId].messageIds = uniqueMessageIds;
  },
  [ChatsMutationTypes.SET_MESSAGE_DATA](
    state,
    { changeType, rawMessage, chatId },
  ) {
    const messageId = rawMessage.id;
    const rawChat = state.chats[chatId]?.rawChat;
    const message = state.chats[chatId]?.messages?.[messageId];
    const isAlreadyExistingMessage = Boolean(
      state.chats[chatId]?.messages?.[messageId] &&
        state.chats[chatId].messageIds.includes(messageId),
    );

    if (!state.chats[chatId]) {
      return;
    }

    if (!message && getIsRawMessageAutomaticType(rawMessage)) {
      state.chats[chatId].messageIds.push(messageId);
      state.chats[chatId].messages[messageId] = rawMessage;
      return;
    }

    const parsedMessage = safeParseRawMessageWithAttachments(rawMessage);
    const isModified =
      (changeType === FirestoreDocumentChangeType.Added &&
        isAlreadyExistingMessage) ||
      changeType === FirestoreDocumentChangeType.Modified;
    const toBeAdded =
      changeType === FirestoreDocumentChangeType.Added &&
      rawChat &&
      !isAlreadyExistingMessage;
    const isNewEntry = toBeAdded || isModified;
    const isToBeAddedTextMessage =
      isNewEntry && !getIsRawMessageAutomaticType(parsedMessage);

    if (toBeAdded) {
      state.chats[chatId].messageIds.push(messageId);
    }
    if (!message && isToBeAddedTextMessage) {
      state.chats[chatId].messages[messageId] = parsedMessage;
      return;
    }

    if (isToBeAddedTextMessage && !getIsRawMessageAutomaticType(message)) {
      state.chats[chatId].messages[messageId] = combineUpdatedRawTextMessage(
        message,
        parsedMessage,
      );
      return;
    }

    if (isNewEntry && getIsRawMessageAutomaticType(rawMessage)) {
      state.chats[chatId].messages[messageId] = rawMessage;
      return;
    }

    if (changeType === FirestoreDocumentChangeType.Removed && rawChat) {
      delete state.chats[chatId].messages?.[messageId];
      state.chats[chatId].messageIds = state.chats[chatId].messageIds.filter(
        (id) => id !== messageId,
      );
    }
  },
  [ChatsMutationTypes.SET_UPLOADING_MESSAGE](
    state,
    { chatId, message: rawMessage },
  ) {
    const messageId = rawMessage.id;
    if (!state.chats[chatId]) {
      return;
    }

    const parsedMessage = safeParseRawMessageWithAttachments(rawMessage);
    state.chats[chatId].messageIds.push(messageId);
    if (!state.chats[chatId].messages) {
      state.chats[chatId].messages = { [messageId]: parsedMessage };
      return;
    }
    state.chats[chatId].messages[messageId] = parsedMessage;
    const rawChat = state.chats[chatId].rawChat;
    if (!rawChat) {
      return;
    }
    rawChat.lastMessage = parsedMessage;
  },
  [ChatsMutationTypes.REMOVE_UPLOADING_MESSAGE](state, { chatId, messageId }) {
    if (!state.chats[chatId] || !state.chats[chatId].messages[messageId]) {
      return;
    }
    state.chats[chatId].messageIds = state.chats[chatId].messageIds.filter(
      (id) => id !== messageId,
    );

    delete state.chats[chatId].messages[messageId];
  },
  [ChatsMutationTypes.UPDATE_UPLOADING_MESSAGE_PROGRESS](
    state,
    { chatId, messageId, attachmentIdx, progress },
  ) {
    const message = state.chats[chatId].messages?.[messageId];
    if (
      !getIsRawMessageTextType(message) ||
      !message.attachments ||
      !message.attachments[attachmentIdx]
    ) {
      return;
    }
    if (Number(message.attachments[attachmentIdx].uploadProgress) > progress) {
      return;
    }
    message.attachments[attachmentIdx].uploadProgress = progress;
    if (progress === 100) {
      message.attachments[attachmentIdx].uploaded = true;
      message.attachments[attachmentIdx].displayStatus =
        VideoDisplayStatus.Processing;
    }
  },
  [ChatsMutationTypes.UPDATE_ATTACHMENT_IS_UPLOADING](
    state,
    { chatId, messageId, attachmentIdx, isUploading },
  ) {
    const message = state.chats[chatId].messages?.[messageId];
    if (
      !getIsRawMessageTextType(message) ||
      !message.attachments ||
      !message.attachments[attachmentIdx]
    ) {
      return;
    }

    message.attachments[attachmentIdx].isUploading = isUploading;
  },
  [ChatsMutationTypes.UPDATE_MESSAGE_DISPLAY_STATUS](
    state,
    { chatId, messageId, attachmentIdx, displayStatus },
  ) {
    const message = state.chats[chatId].messages?.[messageId];
    if (
      !getIsRawMessageTextType(message) ||
      !message.attachments ||
      !message.attachments[attachmentIdx]
    ) {
      return;
    }
    message.attachments[attachmentIdx].displayStatus = displayStatus;
  },
  [ChatsMutationTypes.UPDATE_UPLOADING_MESSAGE_ATTACHMENT_CDN_ID](
    state,
    { cdnId, chatId, messageId, attachmentIdx },
  ) {
    const message = state.chats[chatId].messages?.[messageId];
    if (
      !getIsRawMessageTextType(message) ||
      !message.attachments ||
      !message.attachments[attachmentIdx]
    ) {
      return;
    }
    message.attachments[attachmentIdx].id = cdnId;
  },
  [ChatsMutationTypes.SET_SELECTED_CHAT](state, { chatId }) {
    if (chatId === state.selectedChat) return;

    if (!chatId) {
      delete state.selectedMessageIds;
      state.selectedChat = null;
      return;
    }

    delete state.selectedMessageIds;
    state.selectedChat = chatId;
  },
  [ChatsMutationTypes.REMOVE_SELECTED_CHAT](state) {
    state.selectedChat = null;
  },
  [ChatsMutationTypes.SET_FUTURE_SELECTED_CHAT_ID](state, { chatId }) {
    state.futureSelectedChatId = chatId;
  },
  [ChatsMutationTypes.REMOVE_FUTURE_SELECTED_CHAT_ID](state) {
    delete state.futureSelectedChatId;
  },
  [ChatsMutationTypes.TOGGLE_SELECTED_CHAT_NEW_CHAT](state) {
    if (state.selectedChat === ChatTypes.NEW_CHAT_TYPE) {
      state.selectedChat = null;
      return;
    }
    state.selectedChat = ChatTypes.NEW_CHAT_TYPE;
  },
  [ChatsMutationTypes.SET_SELECTING_MESSAGES](state, { selecting }) {
    if (!state.selectedMessageIds && selecting) {
      state.selectedMessageIds = [];
      return;
    }
    delete state.selectedMessageIds;
  },
  [ChatsMutationTypes.TOGGLE_SELECTED_MESSAGE_ID](state, { messageId }) {
    if (!state.selectedMessageIds) return;

    const includes = state.selectedMessageIds.some(
      (selectedMsgId) => selectedMsgId === messageId,
    );

    if (includes) {
      state.selectedMessageIds = state.selectedMessageIds.filter(
        (selectedMsgId) => selectedMsgId !== messageId,
      );
      return;
    }
    state.selectedMessageIds.push(messageId);
  },
  [ChatsMutationTypes.SET_SHARED_MESSAGE](state, { sharedMessage }) {
    state.sharedMessage = sharedMessage;
  },
  [ChatsMutationTypes.SET_MESSAGE_REACTION_ID](state, { messageId }) {
    state.messageReactionId = messageId;
  },
};
