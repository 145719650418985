import { RoomData } from "@/types/call";
import { RemoteParticipant } from "twilio-video";
import { ShallowReactive } from "vue";
import { getFilteredParticipants } from "./getFilteredParticipants";
import { getLocalParticipant } from "./getLocalParticipant";
import { setDominantParticipant } from "./setDominantParticipant";

export const handleDominantSpeakerChanged = (
  participant: RemoteParticipant | null,
  data: ShallowReactive<RoomData>,
) => {
  const oldDominantParticipant = data.dominantParticipant;
  const isSameParticipant =
    oldDominantParticipant?.participant?.identity === participant?.identity;
  if (!data.room || !participant || isSameParticipant) {
    return;
  }
  if (data.participants.length === 0) {
    data.dominantParticipant = getLocalParticipant(data.room);
    data.localParticipant = null;
    return;
  }

  let newParticipants = getFilteredParticipants(data.participants, participant);
  if (oldDominantParticipant) {
    newParticipants = [oldDominantParticipant, ...newParticipants];
  }

  setDominantParticipant(participant, data);
  data.participants = newParticipants;
};
