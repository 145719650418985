import {
  MessageType,
  CreateCachedAudioMessageArgs,
  GroupRawAudioMessage,
} from "@/store/chats/types";
import { getCommonCachedMessageProperties } from "./getCommonCachedMessageProperties";

export const createCachedGroupAudioMessage = ({
  chatId,
  senderId,
  attachments,
}: CreateCachedAudioMessageArgs): GroupRawAudioMessage => {
  return {
    ...getCommonCachedMessageProperties({ chatId, senderId, attachments }),
    readBy: [senderId],
    hide: !attachments.length,
    messageType: MessageType.Audio,
  };
};
