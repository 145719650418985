import { ChatTypes, Message } from "@/store/chats/types";
import { getIsMessageTextType } from "./getIsMessageTextType";

export const showReadAt = (messages: Message[], index: number): boolean => {
  if (messages[0]?.chatType === ChatTypes.GROUP_CHAT) return false;
  if (!messages.length) return false;
  if (messages.length - 1 !== index) return false;

  const currentMessage = messages[index];

  if (!getIsMessageTextType(currentMessage)) return false;
  if (!currentMessage?.isMine) return false;
  if (currentMessage.readBy?.[0]?.read) return true;

  return false;
};
