<template>
  <div class="callBackground">
    <img class="bgImage" src="/images/callBg.png" alt="call background" />
    <div class="blurredStripe" />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
@import "./CallRoomBackground.scss";
</style>
