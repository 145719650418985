import { config } from "@/config";
import { getLangFromRefreshToken } from "./getLangFromRefreshToken";
import { getRefreshToken } from "./getRefreshToken";

export const getCurrentLanguage = () => {
  const refreshToken = getRefreshToken();
  const lang = refreshToken
    ? getLangFromRefreshToken(refreshToken)
    : config.constants.DEFAULT_LANG;

  return lang;
};
