import { getLastChatsByDocIds } from "@/firebase/ref";
import firebase from "@/firebase/firebase";

interface GetInitialDataArgs {
  chatIds: string[];
  startAfter?: firebase.firestore.Timestamp;
  limit?: number;
}

export const getFirebaseChatDataAsync = async ({
  chatIds,
  ...commonArgs
}: GetInitialDataArgs) => {
  try {
    const chats = await getLastChatsByDocIds({
      chatIds,
      ...commonArgs,
    });

    return chats;
  } catch (error) {
    throw error;
  }
};
