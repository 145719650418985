const COLOR_WHITE = "#fff";
const COLOR_ORANGE = "#ffa474";
const COLOR_GREY = "#c0bcbc";
const COLOR_BLACK = "#000";

interface GetWaveSurferColorsArgs {
  isRecorder?: boolean;
  isMine?: boolean;
}

export const getWaveSurferColors = ({
  isRecorder,
  isMine,
}: GetWaveSurferColorsArgs) => {
  if (!isMine && !isRecorder) {
    return {
      waveColor: COLOR_GREY,
      progressColor: COLOR_BLACK,
    };
  }

  if (!isMine) {
    return {
      waveColor: COLOR_BLACK,
      progressColor: COLOR_BLACK,
    };
  }

  if (!isRecorder) {
    return {
      waveColor: COLOR_ORANGE,
      progressColor: COLOR_WHITE,
    };
  }

  return {
    waveColor: COLOR_WHITE,
    progressColor: COLOR_WHITE,
  };
};
