<template>
  <div class="initial-container">
    <ReportErrorIconButton />
    <MobileNavigationBar :icon-type="MobileNavigationBarIconType.Circle" />
    <div class="content">
      <div className="text-container">
        <h1 className="title">{{ t("auth.login") }}</h1>
        <p class="text">{{ t("welcome.loginWithCredentials") }}</p>
      </div>
      <Form @submit-form="handleSubmit">
        <div class="form-content">
          <Input
            v-model="data.email"
            :name="FormFieldNameEnum.Email"
            placeholder="auth.email"
            required
            :errors="data.errors"
          />
          <Input
            v-model="data.password"
            :name="FormFieldNameEnum.Password"
            :type="FormFieldType.Password"
            placeholder="auth.password"
            required
            :errors="data.errors"
          />
          <GenericButton
            :variant="GenericButtonVariantEnum.Primary"
            text="auth.buttons.login"
          />
        </div>
      </Form>
      <!-- hidden for MVP -->
      <!-- <p className="text">
        {{ $t("welcome.unsuccessfulLogin") }}
        <router-link to="/restore">{{
          $t("welcome.restorePassword")
        }}</router-link>
      </p> -->
    </div>
    <div class="filler" />
  </div>
</template>

<script setup lang="ts">
import Form from "@/components/layouts/Form.vue";
import GenericButton from "@/components/buttons/GenericButton.vue";
import ReportErrorIconButton from "@/components/buttons/ReportErrorIconButton/ReportErrorIconButton.vue";
import MobileNavigationBar from "@/components/layouts/MobileNavigationBar/MobileNavigationBar.vue";
import { ActionTypes, MutationTypes } from "@/store";
import { handleOnboardingRedirect } from "@/utils/settings";
import {
  getInitialFormData,
  getIsFormValid,
  loginFormFields,
} from "@/utils/form";
import {
  FormFieldDataObject,
  FormFieldNameEnum,
  FormFieldType,
  FormType,
  LoginFormDataObjectType,
} from "@/types/form";
import { reactive } from "vue";
import { useStore } from "vuex";
import { getRefreshTokenAsync } from "@/services/maitrejaApi/auth";
import { GenericButtonVariantEnum } from "@/types/button";
import { useI18n } from "vue-i18n";
import { MobileNavigationBarIconType } from "@/types/app";
import { handleMountErrorMessageSnackbar } from "@/utils/app";
import { loginEmailSnackbarErrorObject } from "@/utils/auth";
import Input from "../forms/Input.vue";

const { commit, dispatch } = useStore();
const { t } = useI18n();

const data = reactive<FormFieldDataObject<LoginFormDataObjectType>>(
  getInitialFormData<LoginFormDataObjectType>(loginFormFields),
);

const handleSubmit = async () => {
  const isFormValid = getIsFormValid<LoginFormDataObjectType>(
    data,
    loginFormFields,
    FormType.Login,
  );
  if (!isFormValid) {
    return;
  }

  try {
    commit(MutationTypes.SET_IS_LOADING, true);
    await getRefreshTokenAsync(data.email, data.password);
    await dispatch(ActionTypes.LOGIN_AUTH);
    data.errors = {
      password: "",
      email: "",
    };
    handleOnboardingRedirect();
  } catch (error) {
    handleMountErrorMessageSnackbar(error, loginEmailSnackbarErrorObject);
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/form/initialFormLayout.scss";
</style>
