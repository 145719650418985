<template>
  <div v-if="showMessageDate(visibleMessages, index)" class="message-date">
    {{ getMessageDate(message) }}
  </div>
  <div
    :id="messageElementId"
    class="message-wrapper"
    :class="{
      isBlocking: isBlockingTextShown && index === visibleMessages.length - 1,
      isActive: isControlsActive,
    }"
  >
    <div class="message" :class="getIsMyMessageClass(message, authId)">
      <div class="message-content-wrapper">
        <div class="message-content-wrapper-inner" :class="{ isMine }">
          <div
            v-if="!getIsAutomaticMessageType(message) && message.replyToMessage"
            class="message-reply"
          >
            <div class="message-reply-top">
              <Icon :icon="IconType.REPLY" />
              <span class="reply-title">
                {{ getMessageReplyTitle({ message, authId, isMine, t }) }}</span
              >
            </div>
            <div class="message-reply-bottom">
              <div class="reply-text-wrapper" @click="fetchPreviousMessages">
                <span v-if="textMessagePreviewText" class="reply-text">{{
                  textMessagePreviewText
                }}</span>
                <ImageAttachment
                  v-if="
                    getIsMessageTextType(message.replyToMessage) &&
                    message.replyToMessage?.imageAttachments?.length
                  "
                  :class="'replyImageAttachment'"
                  :attachment="message.replyToMessage.imageAttachments[0]"
                  :message="message"
                  :is-shrinked="true"
                />
              </div>
            </div>
          </div>
          <ChatMessageReactions
            :message="message"
            :is-active="isActive"
            :has-shadow="hasShadow"
            :is-modal-open="isReactionsModalOpen"
            :modal-id="reactionsModalId"
            :element-id="messageContentElementId"
            :position="positionObject"
            :is-touch-end-enabled="isTouchEndEnabled"
            @hide-shadow="hideShadow"
            @open-modal="openReactionsModal"
            @reply-to-message="replyToMessage"
          >
            <div
              v-if="isMessageContentShown"
              class="message-content"
              :class="[getMessageClass(visibleMessages, index, authId)]"
              v-on="handlers"
            >
              <slot />
            </div>
          </ChatMessageReactions>
        </div>
        <slot name="imageAttachment" />
        <slot name="videoPlayer" />
      </div>
      <slot name="checkboxSelectMessage" />
    </div>
  </div>
  <BackdropModal :enabled="isReactionsModalOpen">
    <ChatMessageReactionsModal
      v-if="isReactionsModalOpen && !getIsAutomaticMessageType(message)"
      :id="reactionsModalId"
      :message="message"
      @close-reactions-modal="closeReactionsModal"
    />
  </BackdropModal>
</template>

<script setup lang="ts">
import ChatMessageReactions from "../ChatMessageReactions/ChatMessageReactions.vue";
import { IconType } from "@/types/icons";
import Icon from "@/components/icons/Icon/Icon.vue";
import {
  showMessageDate,
  getMessageClass,
  getMessageDate,
  getIsMessageTextType,
  getIsAutomaticMessageType,
  getMessageReplyTitle,
  getMessageContentElementId,
  skipToReplyMessage,
} from "@/utils/message";
import { getIsMyMessageClass } from "@/utils/chat";
import { ChatState, Message } from "@/store/chats/types";
import { useComputedValue, useLongPress } from "@/composables";
import { GetterTypes, MutationTypes } from "@/store";
import { computed, nextTick, onUnmounted, reactive, ref } from "vue";
import { getIsBlockingTextShown } from "@/utils/chat";
import { useStore } from "vuex";
import { ChatsMutationTypes } from "@/store/chats";
import { CustomClickEvent } from "@/types/app";
import { findParentNodeById, getSafeElementPosition } from "@/utils/app";
import ChatMessageReactionsModal from "../ChatMessageReactionsModal/ChatMessageReactionsModal.vue";
import BackdropModal from "@/components/modals/BackdropModal/BackdropModal.vue";
import { LayoutTypes } from "@/store/app/state";
import { useI18n } from "vue-i18n";
import { getRestrictedMessagesRef } from "@/firebase/ref";
import firebase from "@/firebase/firebase";
import ImageAttachment from "../ImageAttachment/ImageAttachment.vue";

interface ChatMessageContentProps {
  message: Message;
  index: number;
  visibleMessages: Message[];
}

interface Emits {
  (e: "replyToMessage", message: Message): void;
}

const emit = defineEmits<Emits>();

const { commit } = useStore();
const props = defineProps<ChatMessageContentProps>();
const isBlockingTextShown = getIsBlockingTextShown();
const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);
const messageReactionId = useComputedValue<string | null>(
  GetterTypes.GET_MESSAGE_REACTION_ID,
);
const { t } = useI18n();
const positionObject = reactive({
  top: 0,
  left: 0,
  width: 0,
});
const selectedMessageIds = useComputedValue<string[] | undefined>(
  GetterTypes.GET_SELECTED_MESSAGE_IDS,
);
const messageElementId = computed(() => `message_${props.message.id}`);
const messageContentElementId = computed(() =>
  getMessageContentElementId(props.message.id),
);
const hasShadow = ref(false);
const isReactionsModalOpen = ref(false);
const isMessageAutomatic = computed(() =>
  getIsAutomaticMessageType(props.message),
);

const textMessagePreviewText = computed(() => {
  if (getIsAutomaticMessageType(props.message)) {
    return;
  }
  const { replyToMessage } = props.message;
  if (!replyToMessage) {
    return;
  }

  if (getIsMessageTextType(replyToMessage)) {
    return replyToMessage.displayMessageText;
  }

  return t("chat.chatContainer.messages.audioMessage");
});

const isActive = computed(
  () =>
    !isMessageAutomatic.value && messageReactionId.value === props.message.id,
);
const isControlsActive = computed(
  () => isActive.value && !isReactionsModalOpen.value,
);
const reactionsModalId = computed(() => `reactions-modal-${props.message.id}`);
const layoutType = useComputedValue<LayoutTypes>(GetterTypes.GET_LAYOUT_TYPE);
const isMobile = computed(() => layoutType.value === LayoutTypes.MOBILE);
const isTouchEndEnabled = ref(false);
const isMine = computed(() => props.message.senderId === authId.value);
const chat = useComputedValue<ChatState | undefined>(
  GetterTypes.GET_SELECTED_CHAT_STATE,
);
const firstMessage = computed(() => {
  const firstMessageId = chat.value?.messageIds[0];
  if (!firstMessageId) {
    return null;
  }
  return chat.value.messages[firstMessageId];
});

const isMessageContentShown = computed<boolean>(() => {
  if (!getIsMessageTextType(props.message)) {
    return true;
  }

  return Boolean(
    props.message.messageText ||
      props.message?.othersAttachments ||
      props.message?.imageAttachments?.length === 1,
  );
});

const handlePosition = () => {
  const messageContentEl = document.getElementById(
    messageContentElementId.value,
  );
  if (!messageContentEl) {
    return;
  }

  const { top, left, width } = getSafeElementPosition(messageContentEl);

  positionObject.left = left;
  positionObject.top = top;
  positionObject.width = width;
  hasShadow.value = true;
};

const onLongPress = (event: CustomClickEvent) => {
  // if the user is in deleting messages mode, or if the message is automatic, reactions are not allowed
  if (selectedMessageIds.value || isMessageAutomatic.value || !isMobile.value) {
    return;
  }
  const target = event.target;
  commit(ChatsMutationTypes.SET_MESSAGE_REACTION_ID, {
    messageId: props.message.id,
  });

  const wrapper = findParentNodeById(target, messageElementId.value);
  if (!wrapper) {
    return;
  }

  disableScroll();
  handlePosition();
  disableTouchEnd();
};

const handleEnableTouchEnd = () => {
  isTouchEndEnabled.value = true;
};

const disableTouchEnd = () => {
  isTouchEndEnabled.value = false;
  window.addEventListener("touchend", handleEnableTouchEnd);
};

const clearDisableTouchEnd = () => {
  isTouchEndEnabled.value = true;
  window.removeEventListener("touchend", handleEnableTouchEnd);
};

onUnmounted(() => {
  clearDisableTouchEnd();
});

const disableScroll = () => {
  document.body.style.overflow = "hidden";
};

const hideShadow = () => {
  positionObject.left = 0;
  positionObject.top = 0;
  hasShadow.value = false;
  clearDisableTouchEnd();
};

const handlers = useLongPress({ onLongPress });

const openReactionsModal = () => {
  commit(ChatsMutationTypes.SET_MESSAGE_REACTION_ID, {
    messageId: props.message.id,
  });
  isReactionsModalOpen.value = true;
};

const closeReactionsModal = () => {
  commit(ChatsMutationTypes.SET_MESSAGE_REACTION_ID, {
    messageId: null,
  });
  isReactionsModalOpen.value = false;
};

const replyToMessage = (message: Message) => {
  emit("replyToMessage", message);
};

const fetchPreviousMessages = async () => {
  const message = props.message;
  if (getIsAutomaticMessageType(message) || !message.replyToMessage) {
    return;
  }

  if (!chat.value) {
    return;
  }

  const { chatId, messageIds } = chat.value;

  if (messageIds.includes(message.replyToMessage.id)) {
    skipToReplyMessage(message.replyToMessage);
    return;
  }

  if (!firstMessage.value) {
    return;
  }

  const messageSentAt = firstMessage.value.sentAt;
  const { sentAt } = message.replyToMessage;
  const replyToMessageSentAt = firebase.firestore.Timestamp.fromMillis(sentAt);
  const restrictedMessageRef = getRestrictedMessagesRef(
    chatId,
    messageSentAt,
  ).where("sentAt", ">=", replyToMessageSentAt);
  const messagesRef = await restrictedMessageRef.get();
  const messages = messagesRef.docs.map((mess) => mess.data());

  if (messages.length === 0) {
    return;
  }
  commit(MutationTypes.PREPEND_MESSAGES, { messages, chatId });
  await nextTick();
  skipToReplyMessage(message.replyToMessage);
};
</script>

<style lang="scss">
@import "./ChatMessageContent.scss";
</style>
