<template>
  <div class="loader-container">
    <svg class="loader" viewBox="0 0 20 20">
      <circle class="bg" cx="10" cy="10" r="8" />
      <circle
        class="progress"
        cx="10"
        cy="10"
        r="8"
        :style="{
          strokeDashoffset: computeProgress(8),
        }"
      />
    </svg>
    <button v-if="props.cancellable" @click="handleAbortUpload">
      &#10006;
    </button>
    <div v-if="overlay" class="loading-overlay" />
  </div>
</template>

<script setup lang="ts">
interface Emits {
  (e: "abort"): void;
}

interface Props {
  overlay?: boolean;
  progress?: number;
  cancellable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  overlay: false,
  progress: 0,
  cancellable: false,
});
const emit = defineEmits<Emits>();
const computeProgress = (radius: number) => {
  const circumference = Math.ceil(2 * Math.PI * radius);
  return circumference - (props.progress * circumference) / 100;
};
const handleAbortUpload = () => {
  emit("abort");
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatContainer/loader.scss";
</style>
