import store from "@/store";
import { PhoneContact } from "@/store/users/state";
import { IUserPhoneContactUserResponseObject } from "@/types/users";
import { getPhoneContactPhoneNumberProperties } from "./getPhoneContactPhoneNumberProperties";

export const getFilteredPhoneContacts = (
  contactUsers: IUserPhoneContactUserResponseObject[],
  filteredPhoneContacts: PhoneContact[],
) => {
  const regionCode: string = store.getters.GET_APP_DETAILS_REGION_CODE;
  const filPhoneContacts = filteredPhoneContacts
    .map((phoneContact) => {
      const { countryCode, phoneNumber } = getPhoneContactPhoneNumberProperties(
        phoneContact.phone,
        regionCode,
      );

      const isPhoneContactUser = contactUsers.some(
        ({ country_code, phone_number }) => {
          return countryCode === country_code && phoneNumber === phone_number;
        },
      );
      if (!isPhoneContactUser) {
        return phoneContact;
      }
    })
    .filter(Boolean) as PhoneContact[];

  return filPhoneContacts;
};
