import app from "@/store/app";
import auth from "@/store/auth";
import chats from "@/store/chats";
import users from "@/store/users";
import menu from "@/store/menu";

export const modules = {
  app,
  auth,
  chats,
  users,
  menu,
};
