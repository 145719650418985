import { IListItem, ListItemTypeEnum } from "@/types/list";
import { LocaleTypeEnum } from "@/types/localization";
import { isAppEnvironmentContainer } from "../app";
import { getChangeLanguageHandler } from "./getChangeLanguageHandler";

export const getSettingsChangeLanguageMenuItems = (): IListItem[] => [
  {
    text: "settings.changeLanguageContent.czech",
    type: ListItemTypeEnum.Lang,
    isHidden: !isAppEnvironmentContainer,
    lang: LocaleTypeEnum.CS,
    onClick: getChangeLanguageHandler(LocaleTypeEnum.CS),
  },
  {
    text: "settings.changeLanguageContent.english",
    type: ListItemTypeEnum.Lang,
    isHidden: !isAppEnvironmentContainer,
    lang: LocaleTypeEnum.EN,
    onClick: getChangeLanguageHandler(LocaleTypeEnum.EN),
  },
  {
    text: "settings.changeLanguageContent.slovak",
    type: ListItemTypeEnum.Lang,
    isHidden: !isAppEnvironmentContainer,
    lang: LocaleTypeEnum.SK,
    onClick: getChangeLanguageHandler(LocaleTypeEnum.SK),
  },
  {
    text: "settings.changeLanguageContent.ukrainian",
    type: ListItemTypeEnum.Lang,
    isHidden: !isAppEnvironmentContainer,
    lang: LocaleTypeEnum.UA,
    onClick: getChangeLanguageHandler(LocaleTypeEnum.UA),
  },
];
