import { config } from "@/config";
import { chatApiAxios } from "@/plugins";
import { checkAccessTokenAsync } from "@/services";
import { HttpRequestMethodEnum } from "@/types/api";
import { IUpdateAttachmentSourcesArgs } from "@/types/chat";

export const updateAttachmentSourcesBeAsync = async (
  data: IUpdateAttachmentSourcesArgs,
) => {
  const accessToken = await checkAccessTokenAsync();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  const res = await chatApiAxios(
    config.api.chatApi.endpoints.message.updateAttachmentSources,
    {
      method: HttpRequestMethodEnum.POST,
      headers,
      data,
    },
  );

  return res.data;
};
