import { Attachment, AttachmentTypes } from "@/store/chats/types";
import { uploadFileBeAsync } from "./uploadFileBeAsync";
import { uploadVideoFileBeAsync } from "./uploadVideoFileBeAsync";
import { compressAttachmentAsync } from "@/utils/attachment";

interface UploadAllFilesArgs {
  attachments?: Attachment[];
  chatId: string;
  messageId: string;
  firebaseIdToken: string;
}

export const uploadAllFilesAsync = async ({
  attachments,
  chatId,
  messageId,
  firebaseIdToken,
}: UploadAllFilesArgs): Promise<Attachment[]> => {
  if (!attachments?.length) {
    return [];
  }

  const compressedAttachments = await compressAttachmentAsync(attachments);
  const uploadPromises = compressedAttachments.map(
    async (attachment, idx): Promise<Attachment> => {
      if (attachment.type === AttachmentTypes.VIDEO) {
        return uploadVideoFileBeAsync({ chatId, messageId, attachment }, idx);
      }
      return uploadFileBeAsync(
        { chatId, messageId, firebaseIdToken, attachment },
        idx,
      );
    },
  );

  return await Promise.all(uploadPromises);
};
