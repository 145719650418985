import randomstring from "randomstring";
import firebase from "@/firebase/firebase";

export const createOneToOneChatId = (
  userId: number,
  partnerId: number,
): string => {
  if (userId < partnerId) return userId + "_" + partnerId;

  return partnerId + "_" + userId;
};

export const getPartnerIdFromChatId = (
  userId: number,
  chatId: string,
): number => {
  const chatUserIds = chatId.split("_");

  const partnerId =
    chatUserIds[0] === userId.toString() ? chatUserIds[1] : chatUserIds[0];
  return parseInt(partnerId);
};

export const convertFirebaseDateToLocal = (
  firebaseDate?: firebase.firestore.Timestamp,
): number => {
  firebaseDate;
  let appliedDate: Date = new Date();

  if (firebaseDate?.toDate) {
    appliedDate = firebaseDate?.toDate();
  }

  if (firebaseDate?.seconds && firebaseDate?.nanoseconds) {
    appliedDate = new firebase.firestore.Timestamp(
      firebaseDate.seconds,
      firebaseDate.nanoseconds,
    ).toDate();
  }

  return appliedDate.getTime();
};

export const isOneToOneChat = (chatId: string): boolean => {
  return chatId.includes("_");
};

export const createMessageId = (chatId: string, senderId: number): string => {
  const randomString = randomstring.generate(12);
  return `${chatId}_${senderId}_${randomString}`;
};
