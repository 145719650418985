<template>
  <div class="container" :style="`gap:${gap}px`">
    <div class="top">
      <MobileNavigationBar v-bind="props" />
      <div v-if="!isDividerHidden" class="divider" />
    </div>
    <div
      class="content"
      :class="{
        'wide-content': !!isContentWide,
      }"
    >
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts">
import MobileNavigationBar from "@/components/layouts/MobileNavigationBar/MobileNavigationBar.vue";
import { MobileNavigationProps } from "@/types/app";
interface SettingsDetailsProps extends MobileNavigationProps {
  title: string;
  isContentWide?: boolean;
  gap?: number;
  isDividerHidden?: boolean;
  handleRedirect?: () => void;
}

const props = defineProps<SettingsDetailsProps>();
</script>
<style lang="scss" scoped>
@import "src/assets/scss/settings/settingsDetails";
</style>
