import firebase from "@/firebase/firebase";
import {
  Attachment,
  FirestoreChatType,
  GroupRawChat,
  MessageType,
  OneToOneRawChat,
  RawChat,
} from "@/store/chats/types";
import randomString from "randomstring";
import { createCachedMessage } from "../message";

interface CreateCachedChatArgs {
  creatorId: string;
  memberIds: string[];
  messageText?: string;
  attachments?: Attachment[];
  messageType: MessageType;
}

export const createCachedRawChat = ({
  creatorId,
  memberIds,
  ...args
}: CreateCachedChatArgs): RawChat => {
  let uniqueMemberIds = [...memberIds];
  if (!uniqueMemberIds.includes(creatorId)) {
    uniqueMemberIds.push(creatorId);
  }
  uniqueMemberIds = [...new Set(uniqueMemberIds)];

  if (uniqueMemberIds.length === 2) {
    return createCachedOneToOneRawChat({
      creatorId,
      memberIds: uniqueMemberIds,
      ...args,
    });
  }
  return createCachedGroupRawChat({
    creatorId,
    memberIds: uniqueMemberIds,
    ...args,
  });
};

export const createCachedOneToOneRawChat = ({
  creatorId,
  memberIds,
  messageText = "",
  attachments = [],
  messageType,
}: CreateCachedChatArgs): OneToOneRawChat => {
  const chatId = createOneToOneChatId(creatorId, memberIds);
  const lastMessage = createCachedMessage(memberIds, {
    messageText,
    attachments,
    chatId,
    messageType,
  });
  return {
    id: chatId,
    createdAt: firebase.firestore.Timestamp.now(),
    hide: false,
    activeCall: null,
    lastMessage,
    type: FirestoreChatType.OneToOne,
  };
};

export const createCachedGroupRawChat = ({
  creatorId,
  memberIds,
  messageText = "",
  attachments = [],
  messageType,
}: CreateCachedChatArgs): GroupRawChat => {
  const chatId = createGroupChatId();
  const timestamp = firebase.firestore.Timestamp.now();
  const lastMessage = createCachedMessage(memberIds, {
    messageText,
    attachments,
    chatId,
    messageType,
  });
  return {
    id: chatId,
    members: memberIds.map((m) => parseInt(m)),
    createdBy: parseInt(creatorId),
    createdAt: timestamp,
    modifiedAt: timestamp,
    hide: false,
    activeCall: null,
    lastMessage,
    type: FirestoreChatType.Group,
  };
};

export const createChatId = (
  creatorId: string,
  memberIds: string[],
): string => {
  if (memberIds.length === 2) {
    return createOneToOneChatId(creatorId, memberIds);
  }
  return createGroupChatId();
};

export const createOneToOneChatId = (
  creatorId: string,
  memberIds: string[],
): string => {
  const partnerId = memberIds.find((m) => m !== creatorId) || "";

  if (parseInt(creatorId) < parseInt(partnerId)) {
    return creatorId + "_" + partnerId;
  }
  return partnerId + "_" + creatorId;
};

export const createGroupChatId = (): string => randomString.generate(12);
