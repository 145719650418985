import { LocalParticipant } from "twilio-video";
import { unpublishAudioTrack } from "./unpublishAudioTrack";
import { publishAudioTrack } from "./publishAudioTrack";

export const toggleMicrophone = async (
  localParticipant: LocalParticipant,
  hasAudio: boolean,
) => {
  if (!hasAudio) {
    unpublishAudioTrack(localParticipant);
    return;
  }

  publishAudioTrack(localParticipant);
};
