import { IconType } from "@/types/icons";

interface GetControlIconArgs {
  isMine: boolean;
  isPaused: boolean;
}

export const getAudioPlayerIcon = ({
  isMine,
  isPaused,
}: GetControlIconArgs) => {
  if (isMine && isPaused) {
    return IconType.PLAY_WHITE;
  }

  if (isPaused) {
    return IconType.PLAY_BLACK;
  }

  if (isMine) {
    return IconType.PAUSE_WHITE;
  }

  return IconType.PAUSE_BLACK;
};
