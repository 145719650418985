import { BridgeActionEnum } from "@/types/webview";
import { getLangFromRefreshToken, postWebviewMessage } from "@/utils/api";
import Cookies from "js-cookie";

interface StoreAuthDataArgs {
  refreshToken: string;
  userId: number;
  userName: string;
  userPictureUrl: string;
}

export const storeRefreshToken = ({
  refreshToken,
  userId,
  userName,
  userPictureUrl,
}: StoreAuthDataArgs) => {
  if (!refreshToken) {
    return;
  }
  const lang = getLangFromRefreshToken(refreshToken);
  Cookies.set("refresh_token", refreshToken);
  Cookies.set("lang", lang);
  postWebviewMessage({
    action: BridgeActionEnum.Login,
    refreshToken,
    lang,
    userId: String(userId),
    userName,
    userPictureUrl,
  });
};
