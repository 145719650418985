<template>
  <div v-click-outside="hideEmoji" class="emojis-and-giphs">
    <div class="emojis-and-giphs-content">
      <div class="top">
        <div class="handle-container">
          <div class="handle" />
        </div>
        <div class="switch-container">
          <div class="switch">
            <div class="switch-inner">
              <EmojisAndGiphsSwitch
                v-for="switchType in Object.values(SwitchType)"
                :key="switchType"
                :active-switch-type="activeSwitchType"
                :switch-type="switchType"
                @toggle-switch-type="toggleSwitchType"
              />
            </div>
          </div>
        </div>
      </div>
      <EmojisSelection
        v-if="activeSwitchType === SwitchType.Emoji"
        @insert-emoji="insertEmoji"
      />
      <GifsSelection v-else @insert-gif="insertGif" />
    </div>
  </div>
</template>

<script setup lang="ts">
import EmojisSelection from "../EmojisSelection/EmojisSelection.vue";
import GifsSelection from "../GifsSelection/GifsSelection.vue";
import { ref } from "vue";
import { SwitchType } from "@/types/cards";
import EmojisAndGiphsSwitch from "../EmojisAndGiphsSwitch/EmojisAndGiphsSwitch.vue";

interface Emits {
  (e: "insert-emoji", emoji: string): void;
  (e: "toggle-emoji-list-visibility"): void;
  (e: "insert-gif", url: string): void;
}

const emit = defineEmits<Emits>();

const hideEmoji = (e: MouseEvent) => {
  const target = e.target as HTMLElement;
  if (target.classList.contains("emoji-btn")) {
    return;
  }
  emit("toggle-emoji-list-visibility");
};

const insertEmoji = (emoji: string) => {
  emit("insert-emoji", emoji);
};

const insertGif = (url: string) => {
  emit("insert-gif", url);
};

const toggleSwitchType = (switchType: SwitchType) => {
  activeSwitchType.value = switchType;
};

const activeSwitchType = ref<SwitchType>(SwitchType.Emoji);
</script>

<style lang="scss" scoped>
@import "./EmojisAndGiphs.scss";
</style>
