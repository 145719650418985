import { DeleteAttachmentArgs, TextMessage } from "@/store/chats/types";
import { getIsMessageTextType } from "./getIsMessageTextType";
import { getIsAttachmentForDeletion } from "./getIsAttachmentForDeletion";

interface GetAttachmentsForDeletionArgs {
  chatId: string;
  messages: TextMessage[];
}

export const getAttachmentsForDeletionArgs = ({
  chatId,
  messages,
}: GetAttachmentsForDeletionArgs) => {
  const arr: DeleteAttachmentArgs[] = [];
  for (const message of messages) {
    if (!getIsMessageTextType(message)) {
      continue;
    }
    message.allAttachments?.forEach((att, idx) => {
      if (getIsAttachmentForDeletion(att)) {
        arr.push({
          messageId: message.id,
          chatId,
          attachmentIdx: idx,
        });
      }
    });
  }
  return arr;
};
