import { Module } from "vuex";
import { RootState } from "@/store/state";
import { chatsActions } from "./actions";
import { chatsMutations } from "./mutations";
import { ChatsState, chatsState } from "./state";
import { chatsGetters } from "./getters";

export const chats: Module<ChatsState, RootState> = {
  state: chatsState,
  actions: chatsActions,
  getters: chatsGetters,
  mutations: chatsMutations,
};
