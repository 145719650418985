import { HttpRequestContentTypeEnum, HttpRequestMethodEnum } from "@/types/api";
import { request } from "../api";
import {
  IUsersInfoRequestItemObject,
  IUsersInfoResponseItemObject,
} from "@/types/users";
import { User } from "@/store/users/state";
import { config } from "@/config";

export const getUsersByIdsAsync = async (
  accessToken: string,
  users: IUsersInfoRequestItemObject[],
): Promise<User[]> => {
  if (!users.length) {
    return [];
  }

  try {
    const data = await request<IUsersInfoResponseItemObject>(
      config.api.maitrejaApi.endpoints.users,
      HttpRequestMethodEnum.POST,
      { users },
      accessToken,
      HttpRequestContentTypeEnum.JSON,
    );
    const usersData: User[] = data.users.map(
      ({ id, name, picture, url, blocking_status, isDeleted }) => ({
        id,
        name,
        picture,
        url,
        blockingStatus: blocking_status,
        isDeleted,
      }),
    );

    return usersData;
  } catch (error) {
    return [];
  }
};
