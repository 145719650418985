import { JointParticipant } from "@/types/call";
import { Ref } from "vue";
import { getIsAudioOrVideoTrack } from "./getIsAudioOrVideoTrack";
import { getIsRemoteTrackPublication } from "./getIsRemoteTrackPublication";
import { getMediaElement } from "./getMediaElement";
import { handleTrackSubscribed } from "./handleTrackSubscribed";

export const addSubscribedTracks = (
  participant: JointParticipant | null,
  ref: Ref<HTMLDivElement | null>,
) => {
  if (!participant) {
    return;
  }
  const div = getMediaElement(participant.sid);
  for (const [_, publication] of participant.tracks) {
    if (getIsRemoteTrackPublication(publication) && !publication.isSubscribed) {
      continue;
    }
    if (!publication.track || !getIsAudioOrVideoTrack(publication.track)) {
      continue;
    }

    handleTrackSubscribed(publication.track, participant);
  }
  if (!div) {
    return;
  }
  ref.value?.appendChild(div);
};
