interface GetPixelsArgs {
  size: number;
  isShrinked: boolean;
}

const MULTIPLIER = 0.5;

export const getImagePixels = ({ size, isShrinked }: GetPixelsArgs) => {
  let appliedSize = size;
  if (isShrinked) {
    appliedSize = appliedSize * MULTIPLIER;
  }

  return `${appliedSize || ""}px`;
};
