import { ICountryCodeItem } from "@/types/countryCodes";

export const countryCodes: ICountryCodeItem[] = [
  {
    countryName: "afghanistan",
    countryCode: "93",
  },
  {
    countryName: "albania",
    countryCode: "355",
  },
  {
    countryName: "algeria",
    countryCode: "213",
  },
  {
    countryName: "american samoa",
    countryCode: "1684",
  },
  {
    countryName: "samoa",
    countryCode: "685",
  },
  {
    countryName: "the american virgin islands",
    countryCode: "1340",
  },
  {
    countryName: "andorra",
    countryCode: "376",
  },
  {
    countryName: "angola",
    countryCode: "244",
  },
  {
    countryName: "anguilla",
    countryCode: "1264",
  },
  {
    countryName: "antigua and barbuda",
    countryCode: "1268",
  },
  {
    countryName: "argentina",
    countryCode: "54",
  },
  {
    countryName: "armenia",
    countryCode: "374",
  },
  {
    countryName: "aruba",
    countryCode: "297",
  },
  {
    countryName: "ascension",
    countryCode: "247",
  },
  {
    countryName: "australia",
    countryCode: "61",
  },
  {
    countryName: "australian territories",
    countryCode: "672",
  },
  {
    countryName: "azerbaijan",
    countryCode: "994",
  },
  {
    countryName: "bahamas",
    countryCode: "1242",
  },
  {
    countryName: "bahrain",
    countryCode: "973",
  },
  {
    countryName: "bangladesh",
    countryCode: "880",
  },
  {
    countryName: "barbados",
    countryCode: "1246",
  },
  {
    countryName: "belgium",
    countryCode: "32",
  },
  {
    countryName: "belize",
    countryCode: "501",
  },
  {
    countryName: "belarus",
    countryCode: "375",
  },
  {
    countryName: "benin",
    countryCode: "229",
  },
  {
    countryName: "bermuda",
    countryCode: "1441",
  },
  {
    countryName: "bhutan",
    countryCode: "975",
  },
  {
    countryName: "bolivia",
    countryCode: "591",
  },
  {
    countryName: "bosnia and herzegovina",
    countryCode: "387",
  },
  {
    countryName: "botswana",
    countryCode: "267",
  },
  {
    countryName: "brazil",
    countryCode: "55",
  },
  {
    countryName: "virgin islands",
    countryCode: "1284",
  },
  {
    countryName: "brune",
    countryCode: "673",
  },
  {
    countryName: "bulgaria",
    countryCode: "359",
  },
  {
    countryName: "burkina faso",
    countryCode: "226",
  },
  {
    countryName: "burundi",
    countryCode: "257",
  },
  {
    countryName: "cook islands",
    countryCode: "682",
  },
  {
    countryName: "cad",
    countryCode: "235",
  },
  {
    countryName: "montenegro",
    countryCode: "382",
  },
  {
    countryName: "christmas island",
    countryCode: "61",
  },
  {
    countryName: "cocos island",
    countryCode: "61",
  },
  {
    countryName: "czech republic",
    countryCode: "420",
  },
  {
    countryName: "china",
    countryCode: "86",
  },
  {
    countryName: "denmark",
    countryCode: "45",
  },
  {
    countryName: "democratic republic of congo",
    countryCode: "243",
  },
  {
    countryName: "diego garcia",
    countryCode: "246",
  },
  {
    countryName: "british indian ocean territory",
    countryCode: "246",
  },
  {
    countryName: "dominica",
    countryCode: "1767",
  },
  {
    countryName: "dominican republic",
    countryCode: "1809",
  },
  {
    countryName: "dominican republic",
    countryCode: "1829",
  },
  {
    countryName: "dominican republic",
    countryCode: "1849",
  },
  {
    countryName: "djibout",
    countryCode: "253",
  },
  {
    countryName: "egypt",
    countryCode: "20",
  },
  {
    countryName: "ecuador",
    countryCode: "593",
  },
  {
    countryName: "eritrea",
    countryCode: "291",
  },
  {
    countryName: "estonia",
    countryCode: "372",
  },
  {
    countryName: "ethiopia",
    countryCode: "251",
  },
  {
    countryName: "faroe islands",
    countryCode: "298",
  },
  {
    countryName: "falkland islands",
    countryCode: "500",
  },
  {
    countryName: "fiji",
    countryCode: "679",
  },
  {
    countryName: "philippines",
    countryCode: "63",
  },
  {
    countryName: "finland",
    countryCode: "358",
  },
  {
    countryName: "france",
    countryCode: "33",
  },
  {
    countryName: "french guiana",
    countryCode: "594",
  },
  {
    countryName: "french polynesia",
    countryCode: "689",
  },
  {
    countryName: "gabon",
    countryCode: "241",
  },
  {
    countryName: "gambia",
    countryCode: "220",
  },
  {
    countryName: "ghana",
    countryCode: "233",
  },
  {
    countryName: "gibraltar",
    countryCode: "350",
  },
  {
    countryName: "grenada",
    countryCode: "1473",
  },
  {
    countryName: "greenland",
    countryCode: "299",
  },
  {
    countryName: "georgia",
    countryCode: "995",
  },
  {
    countryName: "guadeloupe",
    countryCode: "590",
  },
  {
    countryName: "saint barthelemy",
    countryCode: "590",
  },
  {
    countryName: "saint martin",
    countryCode: "590",
  },
  {
    countryName: "saint martin",
    countryCode: "1721",
  },
  {
    countryName: "guam",
    countryCode: "1671",
  },
  {
    countryName: "guatemala",
    countryCode: "502",
  },
  {
    countryName: "guernsey",
    countryCode: "441481",
  },
  {
    countryName: "guinea",
    countryCode: "224",
  },
  {
    countryName: "guinea-bissau",
    countryCode: "245",
  },
  {
    countryName: "guyana",
    countryCode: "592",
  },
  {
    countryName: "haiti",
    countryCode: "509",
  },
  {
    countryName: "honduras",
    countryCode: "504",
  },
  {
    countryName: "hong kong",
    countryCode: "852",
  },
  {
    countryName: "chile",
    countryCode: "56",
  },
  {
    countryName: "croatia",
    countryCode: "385",
  },
  {
    countryName: "india",
    countryCode: "91",
  },
  {
    countryName: "indonesia",
    countryCode: "62",
  },
  {
    countryName: "iraq",
    countryCode: "964",
  },
  {
    countryName: "iran",
    countryCode: "98",
  },
  {
    countryName: "ireland",
    countryCode: "353",
  },
  {
    countryName: "iceland",
    countryCode: "354",
  },
  {
    countryName: "italy",
    countryCode: "39",
  },
  {
    countryName: "isle of man",
    countryCode: "441624",
  },
  {
    countryName: "israel",
    countryCode: "972",
  },
  {
    countryName: "jamaica",
    countryCode: "1876",
  },
  {
    countryName: "japan",
    countryCode: "81",
  },
  {
    countryName: "jersey",
    countryCode: "441534",
  },
  {
    countryName: "yemen",
    countryCode: "967",
  },
  {
    countryName: "south african republic",
    countryCode: "27",
  },
  {
    countryName: "south korea",
    countryCode: "82",
  },
  {
    countryName: "jordan",
    countryCode: "962",
  },
  {
    countryName: "cayman islands",
    countryCode: "1345",
  },
  {
    countryName: "cambodia",
    countryCode: "855",
  },
  {
    countryName: "cameroon",
    countryCode: "237",
  },
  {
    countryName: "canada",
    countryCode: "1",
  },
  {
    countryName: "cape verde",
    countryCode: "238",
  },
  {
    countryName: "qatar",
    countryCode: "974",
  },
  {
    countryName: "kazakhstan",
    countryCode: "7",
  },
  {
    countryName: "kenya",
    countryCode: "254",
  },
  {
    countryName: "kiribati",
    countryCode: "686",
  },
  {
    countryName: "kosovo",
    countryCode: "383",
  },
  {
    countryName: "colombia",
    countryCode: "57",
  },
  {
    countryName: "comoros",
    countryCode: "269",
  },
  {
    countryName: "congo",
    countryCode: "242",
  },
  {
    countryName: "costa rica",
    countryCode: "506",
  },
  {
    countryName: "cuba",
    countryCode: "53",
  },
  {
    countryName: "kuwait",
    countryCode: "965",
  },
  {
    countryName: "cyprus",
    countryCode: "357",
  },
  {
    countryName: "kyrgyzstan",
    countryCode: "996",
  },
  {
    countryName: "laos",
    countryCode: "856",
  },
  {
    countryName: "lesotho",
    countryCode: "266",
  },
  {
    countryName: "lebanon",
    countryCode: "961",
  },
  {
    countryName: "liberia",
    countryCode: "231",
  },
  {
    countryName: "libya",
    countryCode: "218",
  },
  {
    countryName: "lichtenstein",
    countryCode: "423",
  },
  {
    countryName: "lithuanian",
    countryCode: "370",
  },
  {
    countryName: "latvia",
    countryCode: "371",
  },
  {
    countryName: "luxembourg",
    countryCode: "352",
  },
  {
    countryName: "macau",
    countryCode: "853",
  },
  {
    countryName: "madagascar",
    countryCode: "261",
  },
  {
    countryName: "hungary",
    countryCode: "36",
  },
  {
    countryName: "macedonia",
    countryCode: "389",
  },
  {
    countryName: "malaysia",
    countryCode: "60",
  },
  {
    countryName: "malawi",
    countryCode: "265",
  },
  {
    countryName: "maldives",
    countryCode: "960",
  },
  {
    countryName: "mali",
    countryCode: "223",
  },
  {
    countryName: "malta",
    countryCode: "356",
  },
  {
    countryName: "morocco",
    countryCode: "212",
  },
  {
    countryName: "western sahara",
    countryCode: "212",
  },
  {
    countryName: "marshall islands",
    countryCode: "692",
  },
  {
    countryName: "martinik",
    countryCode: "596",
  },
  {
    countryName: "mauritius",
    countryCode: "230",
  },
  {
    countryName: "mauritania",
    countryCode: "222",
  },
  {
    countryName: "mayotte",
    countryCode: "262",
  },
  {
    countryName: "mexico",
    countryCode: "52",
  },
  {
    countryName: "micronésia",
    countryCode: "691",
  },
  {
    countryName: "moldova",
    countryCode: "373",
  },
  {
    countryName: "monaco",
    countryCode: "377",
  },
  {
    countryName: "mongolia",
    countryCode: "976",
  },
  {
    countryName: "montserrat",
    countryCode: "1664",
  },
  {
    countryName: "mosambique",
    countryCode: "258",
  },
  {
    countryName: "myanmar",
    countryCode: "95",
  },
  {
    countryName: "namibia",
    countryCode: "264",
  },
  {
    countryName: "nura",
    countryCode: "674",
  },
  {
    countryName: "germany",
    countryCode: "49",
  },
  {
    countryName: "nepal",
    countryCode: "977",
  },
  {
    countryName: "niger",
    countryCode: "227",
  },
  {
    countryName: "nigeria",
    countryCode: "234",
  },
  {
    countryName: "nicaragua",
    countryCode: "505",
  },
  {
    countryName: "niue",
    countryCode: "683",
  },
  {
    countryName: "netherlands antilles",
    countryCode: "599",
  },
  {
    countryName: "netherlands",
    countryCode: "31",
  },
  {
    countryName: "norway",
    countryCode: "47",
  },
  {
    countryName: "svalbard and jan mayen",
    countryCode: "47",
  },
  {
    countryName: "new caledonia",
    countryCode: "687",
  },
  {
    countryName: "new zealand",
    countryCode: "64",
  },
  {
    countryName: "oman",
    countryCode: "968",
  },
  {
    countryName: "pakistan",
    countryCode: "92",
  },
  {
    countryName: "palau",
    countryCode: "680",
  },
  {
    countryName: "palestine",
    countryCode: "970",
  },
  {
    countryName: "panama",
    countryCode: "507",
  },
  {
    countryName: "papua new guinea",
    countryCode: "675",
  },
  {
    countryName: "paraguay",
    countryCode: "595",
  },
  {
    countryName: "peru",
    countryCode: "51",
  },
  {
    countryName: "pitcairn",
    countryCode: "64",
  },
  {
    countryName: "ivory coast",
    countryCode: "225",
  },
  {
    countryName: "poland",
    countryCode: "48",
  },
  {
    countryName: "puerto rico",
    countryCode: "1939",
  },
  {
    countryName: "puerto rico",
    countryCode: "1787",
  },
  {
    countryName: "portugal",
    countryCode: "351",
  },
  {
    countryName: "austria",
    countryCode: "43",
  },
  {
    countryName: "reunion",
    countryCode: "262",
  },
  {
    countryName: "equatorial guinea",
    countryCode: "240",
  },
  {
    countryName: "romania",
    countryCode: "40",
  },
  {
    countryName: "russia",
    countryCode: "7",
  },
  {
    countryName: "united states of america",
    countryCode: "1",
  },
  {
    countryName: "rwanda",
    countryCode: "250",
  },
  {
    countryName: "greece",
    countryCode: "30",
  },
  {
    countryName: "saint pierre and miquelon",
    countryCode: "508",
  },
  {
    countryName: "salvador",
    countryCode: "503",
  },
  {
    countryName: "self",
    countryCode: "685",
  },
  {
    countryName: "san marino",
    countryCode: "378",
  },
  {
    countryName: "saudi arabia",
    countryCode: "966",
  },
  {
    countryName: "senegal",
    countryCode: "221",
  },
  {
    countryName: "north korea",
    countryCode: "850",
  },
  {
    countryName: "northern mariana islands",
    countryCode: "1670",
  },
  {
    countryName: "seychelles",
    countryCode: "248",
  },
  {
    countryName: "sierra leone",
    countryCode: "232",
  },
  {
    countryName: "singapore",
    countryCode: "65",
  },
  {
    countryName: "slovakia",
    countryCode: "421",
  },
  {
    countryName: "slovenia",
    countryCode: "386",
  },
  {
    countryName: "somalia",
    countryCode: "252",
  },
  {
    countryName: "united arab emirates",
    countryCode: "971",
  },
  {
    countryName: "united kingdom",
    countryCode: "44",
  },
  {
    countryName: "serbia",
    countryCode: "381",
  },
  {
    countryName: "sri lanka",
    countryCode: "94",
  },
  {
    countryName: "central african republic",
    countryCode: "236",
  },
  {
    countryName: "south sudan",
    countryCode: "211",
  },
  {
    countryName: "sudan",
    countryCode: "249",
  },
  {
    countryName: "suriname",
    countryCode: "597",
  },
  {
    countryName: "saint helena",
    countryCode: "290",
  },
  {
    countryName: "saint lucia",
    countryCode: "1758",
  },
  {
    countryName: "saint kitts and nevis",
    countryCode: "1869",
  },
  {
    countryName: "saint thomas and prince",
    countryCode: "239",
  },
  {
    countryName: "saint vincent and the grenadines",
    countryCode: "1784",
  },
  {
    countryName: "swaziland",
    countryCode: "268",
  },
  {
    countryName: "syria",
    countryCode: "963",
  },
  {
    countryName: "solomon islands",
    countryCode: "677",
  },
  {
    countryName: "spain",
    countryCode: "34",
  },
  {
    countryName: "sweden",
    countryCode: "46",
  },
  {
    countryName: "switzerland",
    countryCode: "41",
  },
  {
    countryName: "tajikistan",
    countryCode: "992",
  },
  {
    countryName: "tanzania",
    countryCode: "255",
  },
  {
    countryName: "thailand",
    countryCode: "66",
  },
  {
    countryName: "taiwan",
    countryCode: "886",
  },
  {
    countryName: "togo",
    countryCode: "228",
  },
  {
    countryName: "tokelau",
    countryCode: "690",
  },
  {
    countryName: "tonga",
    countryCode: "676",
  },
  {
    countryName: "trinidad and tobago",
    countryCode: "1868",
  },
  {
    countryName: "tunisia",
    countryCode: "216",
  },
  {
    countryName: "turkey",
    countryCode: "90",
  },
  {
    countryName: "turkmenistan",
    countryCode: "993",
  },
  {
    countryName: "turks and caicos islands",
    countryCode: "1649",
  },
  {
    countryName: "tuvalu",
    countryCode: "688",
  },
  {
    countryName: "uganda",
    countryCode: "256",
  },
  {
    countryName: "ukraine",
    countryCode: "380",
  },
  {
    countryName: "uruguay",
    countryCode: "598",
  },
  {
    countryName: "uzbekistan",
    countryCode: "998",
  },
  {
    countryName: "vanuatu",
    countryCode: "678",
  },
  {
    countryName: "vatican",
    countryCode: "379",
  },
  {
    countryName: "venezuela",
    countryCode: "58",
  },
  {
    countryName: "vietnam",
    countryCode: "84",
  },
  {
    countryName: "east timor",
    countryCode: "670",
  },
  {
    countryName: "wallis and futuna",
    countryCode: "681",
  },
  {
    countryName: "zambia",
    countryCode: "260",
  },
  {
    countryName: "zimbabwe",
    countryCode: "263",
  },
];
