<template>
  <div class="no-chat-container">
    <img
      src="/images/landingIllustration.png"
      alt="landing-illustration"
      class="landing-image"
    />
    <h2>{{ $t("chat.chatContainer.NoChatContainer.title") }}</h2>
    <p>{{ $t("chat.chatContainer.NoChatContainer.label") }}</p>
  </div>
</template>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatContainer/noChatContainer.scss";
</style>
