import { Module } from "vuex";
import { RootState } from "@/store/state";
import { usersState, UsersState } from "./state";
import { usersActions } from "./actions";
import { usersMutations } from "./mutations";
import { usersGetters } from "./getters";

export const users: Module<UsersState, RootState> = {
  state: usersState,
  actions: usersActions,
  getters: usersGetters,
  mutations: usersMutations,
};
