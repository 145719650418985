<template>
  <div class="screen-cover" @click.stop="closeWindow">
    <img class="image" :src="src" @click.stop />
  </div>
</template>

<script setup lang="ts">
import { useStore } from "vuex";
import { GetterTypes, MutationTypes } from "@/store";
import { useComputedValue } from "@/composables";

const { commit } = useStore();
const src = useComputedValue<string | undefined>(
  GetterTypes.GET_FULL_SIZE_IMAGE,
);

const closeWindow = (e: MouseEvent) => {
  commit(MutationTypes.SET_FULL_SIZE_IMAGE, null);
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/images/fullSizeImage.scss";
</style>
