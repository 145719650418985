import { getPartnerIdFromChatId, isOneToOneChat } from "@/firebase/utils";
import store, { GetterTypes } from "@/store";
import { findUserById } from "./findUserById";

export const getPartnerFromSelectedChat = () => {
  const authId = store.getters[GetterTypes.GET_AUTH_ID];
  const selectedChatId = store.getters[GetterTypes.GET_SELECTED_CHAT_ID];
  if (!selectedChatId || !isOneToOneChat(selectedChatId)) {
    throw new Error(
      "selected chat either does not exist or it is not of type One to One",
    );
  }
  const partnerId = getPartnerIdFromChatId(authId, selectedChatId);
  const partner = findUserById(partnerId);
  return partner;
};
