<template>
  <div v-click-outside="onClickOutside" class="options">
    <Icon
      :icon="IconType.DOTS"
      class="hover"
      alt="dots"
      @click="toggleOptions"
    />
    <div v-if="showCard" class="options-wrapper">
      <OptionsCardSelect
        text="cards.OneToOneChatOptionsCard.optionCloseChat"
        :on-click="closeChat"
      />
      <OptionsCardSelect
        v-if="isAppEnvironmentContainer"
        text="cards.OneToOneChatOptionsCard.optionReportUser"
        :on-click="reportUser"
      />
      <OptionsCardSelect
        v-if="isAppEnvironmentContainer && isBlockingShown"
        text="cards.OneToOneChatOptionsCard.optionBlockUser"
        :on-click="blockUser"
      />
      <OptionsCardSelect
        v-if="isAppEnvironmentContainer && !isBlockingShown"
        text="cards.OneToOneChatOptionsCard.optionUnblockUser"
        :on-click="unblockUser"
      />
      <OptionsCardSelect
        text="cards.OneToOneChatOptionsCard.optionDeleteMessages"
        :on-click="deleteMessages"
      />
      <OptionsCardSelect
        text="cards.OneToOneChatOptionsCard.optionDeleteChat"
        :on-click="deleteChat"
      />
    </div>
  </div>
</template>

<script>
import OptionsCardSelect from "@/components/cards/OptionsCardSelect.vue";
import { GetterTypes, MutationTypes, store } from "@/store";
import { LayoutBackgroundColorTypes, PopupTypes } from "@/store/app/state";
import { isAppEnvironmentContainer } from "@/utils/app";
import { mapGetters } from "vuex";
import { ChatBlockingStatusEnum } from "@/store/chats/types";
import Icon from "../icons/Icon/Icon.vue";
import { IconType } from "@/types/icons";

export default {
  components: { OptionsCardSelect, Icon },
  data() {
    return {
      showCard: false,
      isAppEnvironmentContainer,
      IconType,
    };
  },
  computed: {
    ...mapGetters({
      blockingStatus: GetterTypes.GET_SELECTED_CHAT_BLOCKING_STATUS,
    }),
    isBlockingShown() {
      return this.blockingStatus !== ChatBlockingStatusEnum.Me;
    },
  },
  methods: {
    onClickOutside() {
      this.showCard = false;
    },
    toggleOptions() {
      this.showCard = !this.showCard;
    },
    closeChat() {
      this.$router.push("/");
      this.toggleOptions();
    },
    muteChat() {
      store.commit(MutationTypes.SHOW_POPUP, {
        popupType: PopupTypes.INFO_MUTE_CHAT,
      });
      this.toggleOptions();
    },
    deleteChat() {
      store.commit(MutationTypes.SHOW_POPUP, {
        popupType: PopupTypes.INFO_DELETE_CHAT,
      });
      this.toggleOptions();
    },
    deleteMessages() {
      store.commit(MutationTypes.SHOW_POPUP, {
        popupType: PopupTypes.INFO_DELETE_MESSAGES,
      });
      this.toggleOptions();
    },
    reportUser() {
      store.commit(MutationTypes.SHOW_POPUP, {
        popupType: PopupTypes.INFO_REPORT_USER,
      });
      this.toggleOptions();
    },
    blockUser() {
      store.commit(MutationTypes.SHOW_POPUP, {
        popupType: PopupTypes.INFO_BLOCK_USER,
        successBackgroundColorObject: {
          top: LayoutBackgroundColorTypes.WHITE,
          bottom: LayoutBackgroundColorTypes.ORANGE,
        },
      });
      this.toggleOptions();
    },
    unblockUser() {
      store.commit(MutationTypes.SHOW_POPUP, {
        popupType: PopupTypes.INFO_UNBLOCK_USER,
        processBackgroundColorObject: {
          top: LayoutBackgroundColorTypes.DARK_GRAY,
          bottom: LayoutBackgroundColorTypes.ORANGE_OVERLAY,
        },
      });
      this.toggleOptions();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/cards/optionsCard.scss";
</style>
