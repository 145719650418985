<template>
  <div ref="partialInputsContainer" class="partial-inputs-container">
    <PartialInput
      v-for="(partialInput, i) in stringsArray"
      :key="i"
      :index="i"
      :value="partialInput"
      @update-partial-input="updatePartialInputsContainer"
      @handle-partial-input-key-down="handleFocusPreviousInput"
      @paste-to-partial-inputs="pasteToPartialInputs"
    />
  </div>
</template>

<script setup lang="ts">
import PartialInput from "@/components/forms/PartialInput/PartialInput.vue";
import { RestrictedInputKeyEnum } from "@/types/form";
import { ref } from "vue";
import {
  IPartialInputEmitType,
  IPartialKeyDownEmitType,
} from "../PartialInput";

interface Props {
  stringsArray: string[];
}

interface Emits {
  (e: "updatePartialInputsContainer", arg: IPartialInputEmitType): void;
  (e: "pasteToPartialInputs", arg: ClipboardEvent): void;
}

const partialInputsContainer = ref<HTMLElement>();
const emit = defineEmits<Emits>();
const props = defineProps<Props>();

const updatePartialInputsContainer = (eventObj: IPartialInputEmitType) => {
  emit("updatePartialInputsContainer", eventObj);
  const eventData = eventObj.event.data ?? "";
  if (eventData.length > 1) {
    handleFocusLastInput();
    return;
  }
  handleFocusNextInput(eventObj);
};

const pasteToPartialInputs = async (e: ClipboardEvent) => {
  e.preventDefault();
  emit("pasteToPartialInputs", e);
  handleFocusLastInput();
};

const handleFocusLastInput = () => {
  const allInputs = getAllInputsArray();
  const inputsCount = allInputs?.length ?? 0;
  if (!allInputs) {
    return;
  }
  const lastInput = allInputs[inputsCount - 1] as HTMLInputElement;
  lastInput.focus({ preventScroll: true });
};

const handleFocusPreviousInput = (eventObj: IPartialKeyDownEmitType) => {
  const { event, index } = eventObj;
  const isBackspace = event.key === RestrictedInputKeyEnum.Backspace;
  const currentValue = props.stringsArray[index];

  if (!isBackspace || currentValue) {
    return;
  }
  const previousIndex = index - 1;
  const allInputs = getAllInputsArray();
  if (previousIndex >= 0) {
    // @ts-expect-error
    const previousInput = allInputs[previousIndex] as HTMLInputElement;
    previousInput.focus({ preventScroll: true });
  }
};

const handleFocusNextInput = (eventObj: IPartialInputEmitType) => {
  const { event, index } = eventObj;

  if (!event.data) {
    return;
  }

  const allInputs = getAllInputsArray();
  const inputsLength = allInputs?.length ?? 0;
  const nextIndex = index + 1;
  // @ts-expect-error
  const currentInput = allInputs[index] as HTMLInputElement;
  //   this is so the behavior is not broken for the same digit entered multiple times
  currentInput.value = currentInput.value.slice(0, 1);
  if (nextIndex < inputsLength) {
    // @ts-expect-error
    const nextInput = allInputs[nextIndex] as HTMLInputElement;
    nextInput.focus({ preventScroll: true });
    return;
  }
  currentInput.blur();
};

const getAllInputsArray = () => {
  const containerNodeValue = partialInputsContainer?.value;
  const allInputs = containerNodeValue?.querySelectorAll(".partial-input");

  return allInputs;
};
</script>

<style lang="scss" scoped>
@import "./PartialInputsContainer.scss";
</style>
