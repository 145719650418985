<template>
  <ChatMessageContent
    :index="index"
    :message="message"
    :visible-messages="visibleMessages"
    @reply-to-message="replyToMessage"
  >
    <ChatMessageSender v-bind="props" />
    <AudioPlayer v-bind="audioPlayerProps" />
    <template #checkboxSelectMessage>
      <CheckboxSelectMessage :message="message" />
    </template>
  </ChatMessageContent>
</template>

<script setup lang="ts">
import CheckboxSelectMessage from "@/components/checkbox/CheckboxSelectMessage.vue";
import ChatMessageContent from "@/components/chat/chatContainer/ChatMessageContent/ChatMessageContent.vue";
import ChatMessageSender from "@/components/chat/chatContainer/ChatMessageSender/ChatMessageSender.vue";
import { AudioMessage, Message } from "@/store/chats/types";
import AudioPlayer from "../AudioPlayer/AudioPlayer.vue";
import { useAudioPlayer } from "../AudioPlayer";
import { computed } from "vue";

interface ChatMessageProps {
  message: AudioMessage;
  index: number;
  visibleMessages: Message[];
}

interface Emits {
  (e: "replyToMessage", message: Message): void;
}

const props = defineProps<ChatMessageProps>();
const emit = defineEmits<Emits>();
const audioAttachment = computed(() => props.message.attachments?.[0]);

const { audioPlayerProps } = useAudioPlayer({
  isRecorder: false,
  isMine: props.message.isMine,
  containerId: `player_${props.message.id}`,
  url: audioAttachment.value?.url,
  duration: audioAttachment.value?.duration,
});

const replyToMessage = (message: Message) => {
  emit("replyToMessage", message);
};
</script>
