<template>
  <MainLayout v-if="layoutType === LayoutTypes.DESKTOP">
    <MobileLayout>
      <ChatSidebar />
    </MobileLayout>
    <NewChatContainer />
  </MainLayout>
  <MobileLayout v-else>
    <NewChatMobileOneToOneChat />
  </MobileLayout>
</template>
<script setup lang="ts">
import MobileLayout from "@/components/layouts/MobileLayout.vue";
import MainLayout from "@/components/layouts/MainLayout.vue";
import NewChatContainer from "@/components/chat/chatContainer/NewChatContainer/NewChatContainer.vue";
import NewChatMobileOneToOneChat from "@/components/chat/chatContainer/NewChatMobileOneToOneChat.vue";
import ChatSidebar from "@/components/chat/chatSidebar/ChatSidebar.vue";
import { useComputedValue } from "@/composables";
import { GetterTypes } from "@/store";
import { LayoutTypes } from "@/store/app/state";

const layoutType = useComputedValue<LayoutTypes>(GetterTypes.GET_LAYOUT_TYPE);
</script>
