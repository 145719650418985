import _ from "lodash";
import { maitrejaApiAxios } from "@/plugins";
import qs from "qs";
import { HttpRequestMethodEnum, HttpRequestContentTypeEnum } from "@/types/api";

export const request = async <ResponseDataObject = any>(
  url: string,
  method: HttpRequestMethodEnum,
  data?: object,
  auth?: string,
  contentType: HttpRequestContentTypeEnum = HttpRequestContentTypeEnum.WWWFORM,
) => {
  try {
    let headers: any = {};

    if (auth) {
      headers.Authorization = `Bearer ${auth}`;
    }
    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    const requestObj: any = {
      method,
      headers,
    };

    requestObj.data = data;

    if (data && contentType === HttpRequestContentTypeEnum.WWWFORM) {
      requestObj.data = qs.stringify(data);
    }

    const query = await maitrejaApiAxios(url, requestObj);

    const queryData: ResponseDataObject = query.data;
    return queryData;
  } catch (error) {
    console.log({ error });

    throw error;
  }
};
