import { Track } from "twilio-video";
import { getIsAudioOrVideoTrack } from "./getIsAudioOrVideoTrack";
import { getMediaElement } from "./getMediaElement";

export const attachTrack = (
  track: Track,
  elementId: string,
  isLocal = false,
) => {
  const div = getMediaElement(elementId);
  if (!div) {
    return;
  }
  if (!getIsAudioOrVideoTrack(track)) {
    return;
  }
  const video = track.attach();
  if (isLocal) {
    video.style.transform = "scale(-1, 1)";
  }
  div.appendChild(video);
};
