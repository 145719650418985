import { BridgeActionEnum } from "@/types/webview";
import { postWebviewMessage } from "./postWebviewMessage";

export const handleSendContainerEmail = (
  recipient: string,
  subject: string,
  messageBodyHtml: string,
): void => {
  postWebviewMessage({
    action: BridgeActionEnum.SendEmail,
    recipient,
    subject,
    messageBody: messageBodyHtml,
  });
};
