import { config } from "@/config";

const {
  constants: { SAFE_AREA_HEIGHT },
} = config;

export const getSafeElementPosition = (el: HTMLElement) => {
  const { top, bottom, left, width } = el.getBoundingClientRect();
  const viewportHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const bottomSafeArea = viewportHeight - SAFE_AREA_HEIGHT;
  const bottomDifference = bottom - bottomSafeArea;
  const isAboveViewport = top < SAFE_AREA_HEIGHT;
  const isBelowViewport = bottomDifference > 0;
  let resultTop = top;

  if (isAboveViewport) {
    resultTop = SAFE_AREA_HEIGHT;
  }

  if (isBelowViewport) {
    resultTop = top - bottomDifference;
  }

  return { top: resultTop, bottom, left, width };
};
