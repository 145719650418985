<template>
  <ListItemsSelectNavigationContainer v-bind="chatSettingsAllProperties" />
</template>
<script setup lang="ts">
import { useGetChatSettingsAllProperties } from "./useGetChatSettingsAllProperties";
import ListItemsSelectNavigationContainer from "@/components/layouts/ListItemsSelectNavigationContainer/ListItemsSelectNavigationContainer.vue";

const { chatSettingsAllProperties } = useGetChatSettingsAllProperties();
</script>
<style lang="scss" scoped>
@import "src/assets/scss/chat/chatSettings/chatSettingsAll";
</style>
