import { Profile } from "@/store/auth/state";
import {
  OneToOneRawAudioMessage,
  OneToOneRawTextMessage,
} from "@/store/chats/types";
import { IconType } from "@/types/icons";

export const getStatusIcon = (
  message: OneToOneRawTextMessage | OneToOneRawAudioMessage,
  auth: Profile,
): IconType | undefined => {
  if (message.senderId !== auth.id) return;
  if (message.read) return IconType.SEEN;
  return IconType.DELIVERED;
};
