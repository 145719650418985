<template>
  <PopupInfo
    title="popup.PopupInfoDeleteMessages.title"
    label="popup.PopupInfoDeleteMessages.label"
    btn-text="popup.PopupInfoDeleteMessages.btnText"
    :action="clickAction"
  />
</template>

<script setup lang="ts">
import PopupInfo from "@/components/popup/PopupInfo.vue";
import { MutationTypes, store } from "@/store";

const clickAction = () => {
  store.commit(MutationTypes.HIDE_POPUP);
  store.commit(MutationTypes.SET_SELECTING_MESSAGES, {
    selecting: true,
  });
};
</script>
