<template>
  <div
    v-if="isPreviewVisible"
    class="chat-preview"
    :class="{
      selected: isChatSelected,
      notStandalone: !isStandaloneIosAppEnvironmentContainer,
    }"
    @click="openChat"
  >
    <div class="image-wrapper">
      <ProfileImage
        :id="chat.id"
        :image="props.chat.picture"
        :type="props.chat.type"
        :size="54"
        :disable-redirect="true"
      />
    </div>
    <div class="chat">
      <div class="chat-name">
        <p>
          {{ appliedChatName }}
        </p>
      </div>
      <div class="message-wrapper">
        <Icon
          v-if="
            !getIsAutomaticMessage(latestMessage) && latestMessage?.statusIcon
          "
          :icon="latestMessage.statusIcon"
          class="seen-icon"
        />
        <div
          class="message"
          :class="{
            notRead: getIsTextMessage(latestMessage) && !latestMessage?.read,
          }"
        >
          <div v-if="isOngoingCall" class="call-message-preview">
            <Icon :icon="IconType.ONGOING_CALL_PREVIEW" class="arrow" />
            <span class="ongoingCall">{{ t("call.status.ongoing") }}</span>
          </div>
          <span v-else-if="getIsTextMessage(latestMessage)">
            {{ chatMessage }}
          </span>
          <span v-else-if="getIsAudioMessage(latestMessage)">
            {{ t("chat.chatContainer.messages.audioMessage") }}
          </span>
          <div
            v-else-if="getIsCallMessage(latestMessage) && statusProperties"
            class="call-message-preview"
          >
            <Icon :icon="statusProperties.arrowIcon" class="arrow" />
            <span
              class="message-text"
              :class="[{ incoming: !isOutgoing }, latestMessage.status]"
              >{{ t(statusProperties.text) + statusProperties.duration }}</span
            >
          </div>
          <span v-else>
            {{ t("chat.chatContainer.messages.friendRequest.request") }}
          </span>
        </div>
      </div>
    </div>
    <div class="information">
      <div class="date">{{ getMessageStringDate() }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ProfileImage from "@/components/images/ProfileImage.vue";
import { GetterTypes } from "@/store";
import { convertDateToStringDate } from "@/utils/date";
import { isStandaloneIosAppEnvironmentContainer } from "@/utils/app";
import {
  AudioMessage,
  CallMessage,
  Chat,
  FriendRequestMessage,
  Message,
  TextMessage,
} from "@/store/chats/types";
import { useComputedValue } from "@/composables/useComputedValue";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { getFullChatName, getIsChatPreviewVisible } from "@/utils/chat";
import { useRouter } from "vue-router";
import {
  getIsMessageTextType,
  getIsMessageCallType,
  getCallStatusProperties,
  getIsMessageHidden,
  getIsMessageAudioType,
  getIsMessageFriendRequestType,
} from "@/utils/message";
import { IconType } from "@/types/icons";
import Icon from "@/components/icons/Icon/Icon.vue";

interface Props {
  chat: Chat;
}

const props = defineProps<Props>();
const { t } = useI18n();
const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);
const selectedChatId = useComputedValue<string>(
  GetterTypes.GET_SELECTED_CHAT_ID,
);
const router = useRouter();
const isChatSelected = computed<boolean>(
  () => selectedChatId.value === props.chat.id,
);
const latestMessage = computed(() => props.chat.latestMessage);
const isOngoingCall = computed(() => {
  return !!props.chat.activeCall;
});

const getIsTextMessage = (
  message: Message | undefined,
): message is TextMessage => {
  if (!message) return false;
  return getIsMessageTextType(message);
};

const getIsCallMessage = (
  message: Message | undefined,
): message is CallMessage => {
  if (!message) return false;
  return getIsMessageCallType(message);
};

const getIsAutomaticMessage = (
  message: Message | undefined,
): message is CallMessage | FriendRequestMessage => {
  if (!message) return false;
  return (
    getIsMessageCallType(message) || getIsMessageFriendRequestType(message)
  );
};

const getIsAudioMessage = (
  message: Message | undefined,
): message is AudioMessage => {
  if (!message) return false;
  return getIsMessageAudioType(message);
};

const chatMessage = computed(() => {
  const lastMessageValue = latestMessage.value;
  if (!lastMessageValue || !getIsMessageTextType(lastMessageValue)) {
    return "";
  }
  const { messageText, attachments } = lastMessageValue;

  const isEmpty = getIsMessageHidden(messageText, attachments);

  if (lastMessageValue?.hidden && !isEmpty) {
    return t("chat.chatSidebar.ChatPreview.removeMessageLabel");
  }
  if (getIsTextMessage(lastMessageValue)) {
    return lastMessageValue?.displayMessageText;
  }
  return "";
});

const isOutgoing = computed(() => {
  if (!latestMessage.value) return false;
  return authId.value === latestMessage.value.senderId;
});

const statusProperties = computed(() => {
  if (!getIsCallMessage(latestMessage.value)) return;
  return getCallStatusProperties({
    isOutgoing: isOutgoing.value,
    isPreview: true,
    status: latestMessage.value.status,
    duration: latestMessage.value.duration,
  });
});

const isPreviewVisible = computed<boolean>(() => {
  return getIsChatPreviewVisible(props.chat);
});

const openChat = () => {
  const chatId = props.chat.id;
  router.push(`/${chatId}`);
};
const getMessageStringDate = () => {
  let date = props.chat.latestMessage?.sentAt || props.chat?.createdAt;
  if (!date) {
    return "";
  }
  return convertDateToStringDate(date, t);
};

const appliedChatName = computed<string>(() => {
  return getFullChatName(props.chat);
});
</script>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatSidebar/chatPreview.scss";
</style>
