import { getFirebaseAuthTokenBeAsync } from "@/services/chatApi/auth";
import { auth } from "./firebase";

export const signInWithCustomTokenAsync = async (token: string) => {
  const res = await auth.signInWithCustomToken(token);
  return res;
};

export const signToFirebaseAsync = async (refreshToken: string) => {
  const authRes = await getFirebaseAuthTokenBeAsync({
    refreshToken,
  });

  const { firebaseAuthToken } = authRes;
  await signInWithCustomTokenAsync(firebaseAuthToken);

  return authRes;
};
