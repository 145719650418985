<template>
  <div class="callRoom">
    <CallRoomBackground />
    <InitialCallScreen
      v-if="!data.participants.length && !data.dominantParticipant?.participant"
    />
    <CallDominantSpeaker
      v-else-if="!!data.dominantParticipant"
      :key="data.dominantParticipant.participant?.identity"
      :room-participant="data.dominantParticipant"
    />
    <CallParticipantsPanel
      :local-participant="data.localParticipant"
      :participants="data.participants"
    />
    <CallControlPanel
      :room="data.room"
      :display-name="data.dominantParticipant?.user?.name ?? ''"
    />
  </div>
</template>

<script setup lang="ts">
import { useGetCallRoomProperties } from "@/composables/useGetCallRoomProperties";
import CallRoomBackground from "../CallRoomBackground/CallRoomBackground.vue";
import CallDominantSpeaker from "../CallDominantSpeaker/CallDominantSpeaker.vue";
import CallParticipantsPanel from "../CallParticipantsPanel/CallParticipantsPanel.vue";
import InitialCallScreen from "../InitialCallScreen/InitialCallScreen.vue";
import CallControlPanel from "../CallControlPanel/CallControlPanel.vue";

const { data } = useGetCallRoomProperties();
</script>

<style lang="scss" scoped>
@import "./CallRoom.scss";
</style>
