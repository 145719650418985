export default {
  "call": {
    "status": {
      "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершений виклик"])},
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропущений виклик"])},
      "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дзвінок триває…"])}
    },
    "type": {
      "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вихідний дзвінок"])}
    },
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приєднуватись"])},
    "isCallingYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["телефонує вам"])},
    "establishingCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["встановлення дзвінка з"])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ви"])}
  },
  "popup": {
    "PopupCancelButton": {
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])}
    },
    "PopupEditGroup": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва та зображення групи"])},
      "optionPicture": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малюнок групи"])},
        "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити"])},
        "inputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малюнок групи"])}
      },
      "optionName": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва групи"])}
      },
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти зміни"])}
    },
    "PopupEditGroupMembers": {
      "headerMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Члени групи"])},
      "headerAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати"])},
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти зміни"])}
    },
    "PopupInfoDeleteChat": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити чат"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви впевнені, що хочете видалити цей чат? Повідомлення з чату буде остаточно видалено, а також будь-які вкладення, які містяться в чаті. Інші учасники чату зможуть його переглядати."])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити остаточно"])}
    },
    "PopupInfoDeleteMessages": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити вибрані повідомлення"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо ви вирішите видалити вибрані повідомлення, їх буде остаточно видалено з вашого пристрою. Але інші учасники чату все одно їх бачитимуть."])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть повідомлення для видалення"])}
    },
    "PopupInfoLeaveGroup": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покинути групу"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви впевнені, що бажаєте залишити групу? Ця акція не повертається. Знову ж таки, ви можете потрапити в групу, лише запросивши її учасників."])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покинути групу"])}
    },
    "PopupMuteChat": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вимкнути сповіщення"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви більше не отримуватимете сповіщень із цього чату. Ви можете знову ввімкнути їх у контекстному меню. Ви хочете продовжити?"])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вимкнути сповіщення"])}
    },
    "PopupNotificationsSetting": {
      "optionSound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звуки"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повідомлення"])},
      "optionNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сповіщення на робочому столі"])},
      "optionPreviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показати превью"])},
      "optionNoNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не показувати жодних сповіщень на робочому столі"])},
      "optionRing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рінгтон для вхідного дзвінка"])},
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти налаштування"])}
    },
    "PopupSelectFriendToChat": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибір друзів"])},
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти "])}
    },
    "PopupChatDesign": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вигляд чату"])},
      "optionColorful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кольоровий"])},
      "optionClassic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Класичний"])},
      "optionDarkMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Темний режим"])},
      "optionMinimalist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мінімальний"])},
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти налаштування"])}
    },
    "PopupInfoDeleteUserAccount": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити обліковий запис"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви впевнені, що бажаєте скасувати свій обліковий запис? Ця дія не підлягає поверненню, і ви більше не зможете увійти у свій обліковий запис."])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Так, я хочу скасувати свій обліковий запис."])},
      "cancelBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ні, я хочу зберегти обліковий запис."])}
    },
    "PopupInfoReportUser": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поскаржитися на користувача"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Справді повідомити про користувача?"])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звіт"])}
    },
    "PopupInfoBlockUser": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокувати користувача"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Справді повідомити про користувача?"])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокувати назавжди"])}
    },
    "PopupInfoUnblockUser": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розблокувати користувача"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Справді повідомити про користувача?"])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розблокувати"])}
    },
    "PopupChatAppearance": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вигляд чату"])},
      "optionColorful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кольоровий"])},
      "optionClassic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Класичний"])},
      "optionDarkMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Темний режим"])},
      "optionMinimalist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мінімальний"])},
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти налаштування"])}
    }
  },
  "cards": {
    "SidebarOptionsCard": {
      "optionArchives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архівовано"])},
      "optionNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повідомлення"])},
      "optionSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безпека"])}
    },
    "OneToOneChatOptionsCard": {
      "optionCloseChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрийте чат"])},
      "optionMute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вимкнути сповіщення"])},
      "optionDeleteMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити повідомлення"])},
      "optionDeleteChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити чат"])},
      "optionchatDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вигляд чату"])},
      "optionReportUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поскаржитися на користувача"])},
      "optionBlockUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокувати користувача"])},
      "optionUnblockUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розблокувати користувача"])},
      "optionChatAppearance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вигляд чату"])}
    },
    "GroupChatOptionsCard": {
      "optionChatSetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування чату"])},
      "optionGroupMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Члени групи"])},
      "optionLeaveGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покинути групу"])},
      "optionMute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вимкнути сповіщення"])},
      "optionCloseChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрийте чат"])},
      "optionDeleteMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити повідомлення"])},
      "optionDeleteChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити чат"])},
      "optionchatDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вигляд чату"])},
      "optionChatAppearance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вигляд чату"])}
    }
  },
  "chat": {
    "chatContainer": {
      "NoChatContainer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ласкаво просимо до чату Маitreja."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почніть спілкуватися з друзями або створіть груповий чат."])}
      },
      "InputBar": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть повідомлення..."])}
      },
      "ChatTopBarNewChat": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кому:"])}
      },
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Читати"])},
      "VideoPlayer": {
        "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відео обробляється"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час обробки відео сталася помилка"])}
      },
      "NewChatListContainer": {
        "yourContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваші контакти, які використовують MAIT Chat"])},
        "otherPeople": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інші люди в MAIT"])},
        "recommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекомендуйте MAIT Chat"])}
      },
      "messages": {
        "audioMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["📣 Голосове повідомлення"])},
        "friendRequest": {
          "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запит на дружбу"])},
          "requestSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запит на дружбу відправлено"])},
          "invitationFromUser": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Користувач ", _interpolate(_named("userName")), " пропонує вам дружбу"])},
          "acceptInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви хочете прийняти пропозицію дружби?"])},
          "acceptButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прийняти"])},
          "declineButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відхилити"])},
          "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прохання про дружбу було прийнято."])},
          "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У проханні про дружбу було відмовлено. Заявник не буде повідомлений про видалення."])}
        },
        "controls": {
          "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відповідай"])},
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопіювати"])}
        },
        "reactions": {
          "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реакції"])},
          "removeWithClick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Натисніть, щоб видалити"])}
        }
      }
    },
    "chatSidebar": {
      "ChatSidebarTopBar": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої розмови"])}
      },
      "SearchBar": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шукайте друзів або групу"])}
      },
      "NewChatPreview": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова розмова"])}
      },
      "ChatPreview": {
        "removeMessageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувач видалив повідомлення."])}
      },
      "searchBar": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шукайте друзів або групу"])}
      }
    },
    "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додаток"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувач"])},
    "blockingStatus": {
      "hasBlockedYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["заблокував вас"])},
      "youHaveBlockedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви заблокували користувача"])},
      "unblockToContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щоб продовжити розмову, розблокуйте користувача."])},
      "youCannotContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цю розмову неможливо відстежити."])}
    },
    "hasDeletedAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["видалив обліковий запис."])}
  },
  "select": {
    "UsersSelectBox": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук контакту"])}
    },
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросити"])},
    "inviteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Давайте підключатися через MAIT Chat! Це швидка, проста та безпечна програма з безкоштовними повідомленнями та дзвінками. Доступний для завантаження на https://mait.me/download."])}
  },
  "date": {
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сьогодні"])},
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bчора"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вівторок"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["понеділок"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Середа"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["четвер"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["П'ятниця"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Субота"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["неділя"])}
  },
  "user": {
    "i": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I"])},
    "deletedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалений користувач"])},
    "anonymousUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анонімний користувач"])}
  },
  "buttons": {
    "TopBarDeletingMessagesButtons": {
      "cancelDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відновити видалення"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити вибране"])}
    }
  },
  "auth": {
    "buttons": {
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійти"])},
      "loginWithPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійдіть за номером телефону"])},
      "loginWithEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареєструватися електронною поштою"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєструвати"])},
      "continueToApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейдіть до програми"])},
      "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад до входу"])},
      "sendLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надішліть електронний лист із посиланням"])},
      "restorePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скинути пароль"])},
      "sendVerificationSms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надіслати SMS підтвердження"])},
      "resendVerificationSms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторно надішліть SMS із підтвердженням"])}
    },
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логін"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["або"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефону"])},
    "restoreOrCreate": {
      "cantLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не можете увійти? Спробуй це"])},
      "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["скинути пароль"])},
      "orCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["або створити"])},
      "newAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["новий обліковий запис"])}
    },
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна пошта"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрація"])},
    "welcomeToMAIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ласкаво просимо в чат MAIT!"])},
    "enterRequirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для входу потрібна робоча адреса електронної пошти та пароль."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я"])},
    "alreadyHaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви вже маєте обліковий запис у нас?"])},
    "thankYouForRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дякуємо за реєстрацію!"])},
    "thankYouForRegistrationContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тепер ви можете без вагань почати спілкуватися з друзями з будь-якого місця та без побоювання. Чат повністю безпечний, і ми зберігаємо ваші повідомлення приватними."])},
    "restorePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відновлення паролю"])},
    "restorePasswordInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть обліковий запис електронної пошти, на який ми надішлемо вам посилання для створення нового пароля."])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий пароль"])},
    "newPasswordAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знову новий пароль"])},
    "businessConditions": {
      "iAgreeWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я згоден з"])},
      "gdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Політика обробки персональних даних"])},
      "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Умови експлуатації"])},
      "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["і"])}
    },
    "errors": {
      "wrongCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильні дані для входу."])},
      "userAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цей акаунт вже існує."])},
      "agreeToTermsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви повинні погодитися з умовами та положеннями."])},
      "thisFieldIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Це поле є обов'язковим для заповнення."])},
      "invalidPasswordLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль має бути не менше 8 символів"])},
      "invalidEmailFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недійсний формат електронної пошти."])}
    }
  },
  "snackbar": {
    "errors": {
      "userAlreadyExists": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цей обліковий запис уже існує."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейдіть до екрана входу."])}
      },
      "agreeToTermsAndConditions": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відсутня згода."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На жаль, ви не можете зареєструватися, не погодившись із Політикою обробки персональних даних і Умовами надання послуг."])}
      },
      "wrongCredentials": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка входу."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я користувача або пароль введено неправильно. Спробуйте ще раз або змініть пароль."])}
      },
      "accountWasClosedLogin": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок закрито"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обліковий запис, під яким ви намагаєтеся увійти, було закрито. Ви можете подати запит на його відновлення, надіславши листа на адресу support", "@", "mait.me. Дякуємо за ваше розуміння."])}
      },
      "accountWasClosedRegister": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок закрито"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цей обліковий запис уже існує, але його закрито. Ви можете подати запит на його поновлення електронною поштою на сторінці support", "@", "mait.me. Дякую за розуміння."])}
      },
      "invalidSmsCode": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код SMS не збігається."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спробуйте ввести код безпеки ще раз."])}
      },
      "invalidPhoneNumber": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недійсний номер телефону."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спробуйте ввести номер телефону ще раз."])}
      },
      "defaultError": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спробуйте ще раз."])}
      },
      "tooManyAttempts": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забагато спроб"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наразі неможливо надіслати додатковий код підтвердження. Будь-ласка спробуйте пізніше."])}
      },
      "phoneNumberAlreadyInUse": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неможливо підключити номер."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цей номер телефону вже використовується іншим користувачем."])}
      },
      "videoWasNotUploaded": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити відео."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будь ласка, спробуйте ще раз."])}
      }
    },
    "notifications": {
      "thankYouForReportingUser": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дякуємо, що повідомили про користувача."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зараз наша команда розгляне звіт."])}
      },
      "yourAccountWasDeleted": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш обліковий запис видалено."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бажаємо всього найкращого."])}
      },
      "theUserWasBlocked": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувача заблоковано."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви більше не отримуватимете жодних повідомлень."])}
      },
      "theUserWasUnblocked": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувача розблоковано."])}
      },
      "theChatWasDeleted": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бесіду видалено."])}
      },
      "youHaveLeftTheGroupConversation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розмова була залишена."])}
      }
    }
  },
  "welcome": {
    "welcomeInMait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ласкаво просимо в чат MAIT!"])},
    "loginWithMaitAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійдіть за допомогою свого облікового запису MAIT"])},
    "loginWithPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійдіть за номером телефону"])},
    "noAccountYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас ще немає облікового запису?"])},
    "inApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["до програми."])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареєструватися"])},
    "loginWithCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для входу в додаток використовуйте дані свого облікового запису MAIT."])},
    "unsuccessfulLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не можете увійти? Спробуй це "])},
    "restorePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["скинути пароль."])},
    "loginWithPhoneCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використовуйте свій номер телефону для входу. Після цього ми надішлемо вам код автентифікації."])},
    "confirmLoginWithPhoneCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Після введення коду підтвердження, який ми щойно надіслали вам у текстовому повідомленні, ви можете увійти в систему."])},
    "sendCodeToPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надішліть SMS з підтвердженням"])}
  },
  "forms": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук"])},
    "searchTenor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["пошук в базі даних Tenor"])}
  },
  "settings": {
    "personalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Особисті дані"])},
    "personalDetailsContent": {
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прізвище"])},
      "saveDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти дані"])},
      "deleteAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати обліковий запис"])},
      "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я та прізвище"])}
    },
    "connectPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підключити номер телефону"])},
    "connectPhoneNumberContent": {
      "yourPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш номер телефону"])},
      "savePhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти номер телефону"])},
      "deletePhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити номер телефону"])},
      "confirmation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевірте"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для підтвердження використовуйте код підтвердження, який ми щойно надіслали вам у SMS."])}
      },
      "connectNewPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підключіть новий номер телефону"])}
    },
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повідомлення"])},
    "notificationsContent": {
      "sounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звуки"])},
      "hideNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не показувати сповіщень"])},
      "notificationBarNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сповіщення на панелі сповіщень"])},
      "incomingCallRing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рінгтон для вхідного дзвінка"])},
      "switch": {
        "unused": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щоб ви нічого не пропустили..."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увімкніть сповіщення. Без них додаток не зможе надсилати вам повідомлення про те, що в ньому нового."])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увімкнути сповіщення"])},
          "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити без дозволу"])}
        },
        "enabled": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сповіщення вже ввімкнено."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тепер ви нічого не пропустите. Без страху! Якщо сповіщення вас турбують, ви можете легко вимкнути їх у налаштуваннях телефону."])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад до налаштувань"])}
        },
        "disabled": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сповіщення заборонені."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щоб домогтися відображення нових сповіщень, зайдіть в налаштування системи. У розділі окремих програм знайдіть MAIT Chat і ввімкніть сповіщення."])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад до налаштувань"])}
        }
      }
    },
    "transferContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перенести контакти"])},
    "transferContactsContent": {
      "switch": {
        "unused": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зв'яжіть свої телефонні контакти"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIT Chat отримує доступ до вашого списку контактів і надсилає телефонні номери на сайт mait.me для пошуку існуючих контактів у мережі MAIT. Список контактів доступний щоразу, коли відкривається програма. Номери телефонів не зберігаються на mait.me. Чи згодні ви надати доступ?"])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Згоден"])},
          "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не зараз"])}
        },
        "enabled": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваші контакти вже пов’язані."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Схоже, ви передали свої контакти раніше. Ви можете знайти всі зв’язані контакти під час створення нової розмови."])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад до налаштувань"])}
        },
        "disabled": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваші контакти не пов'язані."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щоб зв'язати контакти, перейдіть в налаштування системи. У розділі індивідуальної програми знайдіть MAIT Chat і ввімкніть передачу контактів."])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад до налаштувань"])}
        }
      }
    },
    "profilePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зображення профілю"])},
    "profilePictureContent": {
      "changeProfilePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити зображення профілю"])},
      "deleteProfilePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити зображення профілю"])}
    },
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вийти"])},
    "activateAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активуйте свій обліковий запис MAIT"])},
    "activateAccountContent": {
      "activationOfAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активація облікового запису MAIT"])}
    },
    "deleteAccountContent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нам дуже шкода, що ви йдете."])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви впевнені, що бажаєте скасувати свій обліковий запис? Ця дія не підлягає поверненню, і ви більше не зможете увійти у свій обліковий запис."])},
      "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ні, я хочу зберегти обліковий запис."])},
      "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Так, я хочу скасувати свій обліковий запис."])}
    },
    "changeLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміна мови"])},
    "changeLanguageContent": {
      "czech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Česky"])},
      "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "slovak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovensky"])},
      "ukrainian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["український"])}
    },
    "reportError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повідомити про помилку"])},
    "reportErrorContent": {
      "messageFromApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повідомлення з програми"])}
    },
    "termsAndConditions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Умови надання послуг"])},
      "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Політика конфіденційності"])},
      "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Умови використання"])}
    },
    "chat": {
      "blockUser": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокувати користувачів"])},
        "blockingMessages": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокування повідомлень"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви більше не зможете писати або отримувати повідомлення з цього чату. Але ви все ще можете спілкуватися один з одним у групових розмовах."])}
        },
        "blockCanBeReverted": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете будь-коли розблокувати"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете будь-коли розблокувати в налаштуваннях розмови."])}
        }
      },
      "createGroupChat": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створіть груповий чат"])},
        "contentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова група"])},
        "usersRecommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропозиції користувачів"])},
        "createGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити"])}
      },
      "unblockUser": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розблокувати користувача"])},
        "unblockingMessages": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розблокувати повідомлення"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Після розблокування ви знову зможете надсилати повідомлення, фотографії чи файли один одному."])}
        },
        "secondaryButton": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити блокування"])}
        }
      },
      "reportUser": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повідомити про розмову"])},
        "foundInappropriateContent": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви стикалися з неприйнятним контентом?"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо ви впевнені, що повідомлення користувача є образливими, недоречними або небезпечними, будь ласка, повідомте нам про це."])}
        },
        "weValueYourSafety": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми поважаємо вашу безпеку"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувач не дізнається, хто на нього повідомив. Тому не варто турбуватися про наслідки."])}
        }
      },
      "deleteChat": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити чат"])},
        "deleteAllMessages": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити всі повідомлення"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви остаточно видалите всі повідомлення. Тому добре подумайте, чи справді ви хочете вдатися до цього."])}
        },
        "photosAndFiles": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фотографії та файли"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Майте на увазі, що разом із повідомленнями ви також надсилатимете зображення, відео чи документи."])}
        }
      },
      "leaveGroupConversation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покинути групу"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Залиште розмову"])},
        "leavingConversation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вихід з розмови"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коли ви залишаєте розмову, ви можете повернутися тільки за запрошенням учасників групи."])}
        },
        "yourMessagesRemain": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваші повідомлення продовжують залишатися"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повідомлення, які ви відправили до того, як покинули групу, залишаться в групі після того, як ви її покинете."])}
        }
      },
      "renameGroup": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити назву чату"])},
        "groupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва чату"])}
      },
      "showUsers": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учасники шоу"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Члени групи"])},
        "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати"])},
        "options": {
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти зміни"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])}
        },
        "redirectModal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ти справді хочеш піти?"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви маєте незбережені зміни у списку учасників групи, які буде втрачено."])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Збережіть зміни та вийдіть"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Піти без заощаджень"])}
        }
      },
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
      "with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["з"])}
    }
  },
  "onboarding": {
    "welcome": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ласкаво просимо в чат MAIT!"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перш ніж почати, нам потрібен ваш дозвіл на деякі служби, щоб програма працювала належним чином."])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Давайте почнемо"])}
    }
  },
  "countryCodes": {
    "afghanistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Афганістан"])},
    "albania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Албанія"])},
    "algeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алжир"])},
    "samoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Американське Самоа"])},
    "the american virgin islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Американські Віргінські острови"])},
    "andorra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Андорра"])},
    "angola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анґола"])},
    "antigua and barbuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антигуа і Барбуда"])},
    "anguilla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ангілья"])},
    "argentina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аргентина"])},
    "armenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вірменія"])},
    "aruba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аруба"])},
    "ascension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Острів Вознесіння"])},
    "australia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Австралія"])},
    "australian territories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Австралійські території"])},
    "azerbaijan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Азербайжан"])},
    "bahrain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бахрейн"])},
    "bahamas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Багами"])},
    "bangladesh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бангладеш"])},
    "barbados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барбадос"])},
    "belgium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бельгія"])},
    "belize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беліз"])},
    "belarus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Білорусь"])},
    "benin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бенін"])},
    "bermuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бермудські Острови"])},
    "bhutan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бутан"])},
    "bolivia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болівія"])},
    "bosnia and herzegovina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Боснія і Герцеговина"])},
    "botswana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ботсвана"])},
    "brazil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бразилія"])},
    "virgin islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Британські Віргінські острови"])},
    "brune": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бруней"])},
    "bulgaria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болгарія"])},
    "burkina faso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Буркіна Фасо"])},
    "burundi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бурунді"])},
    "cook islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Острови Кука"])},
    "cad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чад"])},
    "montenegro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чорногорія"])},
    "czech republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чеська Республіка"])},
    "china": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Китай"])},
    "denmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данія"])},
    "democratic republic of congo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Демократична Республіка Конго"])},
    "diego garcia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дієго Гарсія"])},
    "dominica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Домініка"])},
    "dominican republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Домініканська Республіка"])},
    "djibout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Джибуті"])},
    "egypt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Єгипет"])},
    "ecuador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еквадор"])},
    "eritrea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еритрея"])},
    "estonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Естонія"])},
    "ethiopia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ефіопія"])},
    "faroe islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фарерські острови"])},
    "fiji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фіджі"])},
    "philippines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Філіппіни"])},
    "finland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фінляндія"])},
    "france": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Франція"])},
    "french guiana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Французька Гвіана"])},
    "french polynesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Французька Полінезія"])},
    "gabon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Габон"])},
    "gambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гамбія"])},
    "ghana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гана"])},
    "gibraltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гібралтар"])},
    "grenada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гренада"])},
    "greenland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гренландія"])},
    "georgia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грузія"])},
    "guadeloupe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гваделупа"])},
    "guam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гуам"])},
    "guatemala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гватемала"])},
    "guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гвінея"])},
    "guinea-bissau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гвінея-Бісау"])},
    "guyana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ґаяна"])},
    "haiti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гаїті"])},
    "honduras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гондурас"])},
    "hong kong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гонконг"])},
    "chile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чилі"])},
    "croatia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хорватія"])},
    "india": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Індія"])},
    "indonesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Індонезія"])},
    "iraq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ірак"])},
    "iran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іран"])},
    "ireland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ірландія"])},
    "iceland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ісландія"])},
    "italy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Італія"])},
    "israel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ізраїль"])},
    "jamaica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ямайка"])},
    "japan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Японія"])},
    "yemen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ємен"])},
    "south african republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Південно-Африканська Республіка"])},
    "south korea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Південна Корея"])},
    "cayman islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кайманові острови"])},
    "jordan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Йорданія"])},
    "cambodia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камбоджа"])},
    "cameroon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камерун"])},
    "canada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канада"])},
    "kazakhstan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Казахстан"])},
    "kenya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кенія"])},
    "kiribati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кірибаті"])},
    "colombia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колумбія"])},
    "congo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конго"])},
    "costa rica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коста-Ріка"])},
    "cuba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Куба"])},
    "kuwait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кувейт"])},
    "cyprus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіпр"])},
    "kyrgyzstan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Киргизтан"])},
    "laos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лаоська народна Демократична Республіка"])},
    "lesotho": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лесото"])},
    "lebanon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліван"])},
    "liberia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліберія"])},
    "libya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лівія"])},
    "lichtenstein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліхтенштейн"])},
    "lithuanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Литва"])},
    "latvia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Латвія"])},
    "luxembourg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Люксембург"])},
    "madagascar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мадаґаскар"])},
    "hungary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угорщина"])},
    "macedonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Македонія Колишня Югославська Республіка"])},
    "malaysia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малайзія"])},
    "malawi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малаві"])},
    "maldives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мальдіви"])},
    "mali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малі"])},
    "malta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мальта"])},
    "morocco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мороко"])},
    "marshall islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маршаллові острови"])},
    "martinik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мартініка"])},
    "mauritius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маврикій"])},
    "mauritania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мавританія"])},
    "mexico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мексика"])},
    "moldova": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Молдова"])},
    "micronésia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мікронезія"])},
    "monaco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монако"])},
    "mongolia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монголія"])},
    "montserrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монтсеррат"])},
    "mosambique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мозамбік"])},
    "myanmar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["М'янма"])},
    "namibia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Намібія"])},
    "nura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Науру"])},
    "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Німеччина"])},
    "nepal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непал"])},
    "niger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нігер"])},
    "nicaragua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нікарагуа"])},
    "nigeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нігерія"])},
    "niue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ніуе"])},
    "netherlands antilles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нідерландські Антильські острови"])},
    "netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нідерланди"])},
    "norway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Норвегія"])},
    "new caledonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова Каледонія"])},
    "oman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оман"])},
    "new zealand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова Зеландія"])},
    "pakistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакистан"])},
    "palau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Палау"])},
    "palestine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Палестина"])},
    "panama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Панама"])},
    "paraguay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парагвай"])},
    "peru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перу"])},
    "ivory coast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кот-д'Івуар"])},
    "poland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Польща"])},
    "puerto rico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пуерто-Ріко"])},
    "portugal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Португалія"])},
    "austria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Австрія"])},
    "equatorial guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Екваторіальна Гвінея"])},
    "romania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Румунія"])},
    "rwanda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Руанда"])},
    "saint pierre and miquelon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сен-П'єр і Мікелон"])},
    "greece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Греція"])},
    "salvador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сальвадор"])},
    "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самоа"])},
    "san marino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сан-Марино"])},
    "saudi arabia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саудівська Аравія"])},
    "senegal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сенеґал"])},
    "north korea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Північна Корея"])},
    "seychelles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сейшельські острови"])},
    "sierra leone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сьєрра-Леоне"])},
    "singapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сингапур"])},
    "slovakia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Словакія"])},
    "slovenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Словенія"])},
    "somalia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сомалі"])},
    "united arab emirates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Об'єднані Арабські Емірати"])},
    "united kingdom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Велика Британія"])},
    "serbia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сербія"])},
    "sri lanka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шрі Ланка"])},
    "central african republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Центральноафриканська Республіка"])},
    "sudan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судан"])},
    "saint helena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Святої Єлени"])},
    "saint lucia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Люсія"])},
    "saint kitts and nevis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Кітс і Невіс"])},
    "saint thomas and prince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сан-Томе і Принсіпі"])},
    "saint vincent and the grenadines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Вінсент та Гренадіни"])},
    "syria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сирія"])},
    "solomon islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соломонові острови"])},
    "spain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іспанія"])},
    "sweden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Швеція"])},
    "switzerland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Швейцарія"])},
    "tajikistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таджикістан"])},
    "tanzania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Танзанія"])},
    "thailand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таїланд"])},
    "taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тайвань"])},
    "togo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Того"])},
    "tonga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тонга"])},
    "trinidad and tobago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тринідад і Тобаго"])},
    "tunisia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Туніс"])},
    "turkey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Туреччина"])},
    "turkmenistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Туркменістан"])},
    "tuvalu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тувалу"])},
    "uganda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уганда"])},
    "ukraine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Україна"])},
    "uruguay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уругвай"])},
    "uzbekistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Узбекистан"])},
    "vanuatu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вануату"])},
    "venezuela": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Венесуела"])},
    "vietnam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В'єтнам"])},
    "east timor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тимор-Лешті"])},
    "wallis and futuna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Острови Уоліс і Футуна"])},
    "zambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замбія"])},
    "zimbabwe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зімбабве"])},
    "american samoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Американське Самоа"])},
    "british indian ocean territory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Британська територія Індійського океану"])},
    "falkland islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фолклендські острови"])},
    "guernsey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гернсі"])},
    "isle of man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Острів Мен"])},
    "jersey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Джерсі"])},
    "cape verde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кабо-Верде"])},
    "qatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Катар"])},
    "christmas island": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Острів Різдва"])},
    "cocos island": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Острів Кокос"])},
    "kosovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Косово"])},
    "comoros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коморські острови"])},
    "macau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Макао"])},
    "mayotte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Майотта"])},
    "papua new guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Папуа-Нова Гвінея"])},
    "pitcairn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Піткерн"])},
    "reunion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реюньйон"])},
    "russia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Росія"])},
    "northern mariana islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Північні Маріанські острови"])},
    "south sudan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Південний Судан"])},
    "suriname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сурінам"])},
    "saint barthelemy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Святий Варфоломій"])},
    "saint martin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сен-Мартін"])},
    "swaziland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свазіленд"])},
    "svalbard and jan mayen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шпіцберген і Ян-Майєн"])},
    "tokelau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токелау"])},
    "turks and caicos islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Острови Теркс і Кайкос"])},
    "united states of america": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сполучені Штати Америки"])},
    "vatican": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ватикан"])},
    "western sahara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Західна Сахара"])},
    "falkland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фолклендські острови"])},
    "capsule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кабо-Верде"])},
    "catarrh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Катар"])},
    "chamber and mayotte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коморські острови і Майотта"])},
    "macao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Макао"])},
    "papua-nova guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Папуа-Нова Гвінея"])},
    "réunion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реюньйон"])},
    "northern marian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Північні Маріанські острови"])},
    "surinam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сурінам"])},
    "savilia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свазіленд"])},
    "tokelu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токелау"])},
    "turks and caicos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Острови Теркс і Кайкос"])}
  },
  "versions": {
    "whatIsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Що нового в MAIT Chat"])},
    "registrationOverPhone": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрація за номером тел"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Швидка реєстрація без зайвих зволікань. Знову для реєстрації в MAIT достатньо лише номера телефону."])}
    },
    "communicationWithPhoneContacts": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зв'язок з мобільними контактами"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтримуйте зв’язок із друзями, яких ви зберегли на своєму телефоні, але не маєте облікового запису MAIT."])}
    },
    "improvedVideoDisplaying": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покращено відображення відео"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зручно переглядати записані відео. Ми додали повноекранний режим як для пейзажних, так і для портретних відео."])}
    },
    "callWithYourFriends": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дзвони друзям"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIT Chat тепер підтримує аудіо- та відеодзвінки з друзями в мережі MAIT."])}
    },
    "groupCalls": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групові дзвінки та відеодзвінки"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тепер ви також можете здійснювати дзвінки в MAIT Chat в рамках групових розмов."])}
    },
    "improvementsAndFixes": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покращення та ремонт"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під капотом ми виправили низку помилок і додали різноманітні покращення для кращого користування."])}
    }
  },
  "message-left-sidebar": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розмови"])},
    "search-bar-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кому"])}
  },
  "message-header": {
    "header-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кому"])}
  },
  "user-list": {
    "multiselect-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть"])}
  }
}