<template>
  <div class="popup edit">
    <div class="popup-header spacer">
      <span>{{ $t("popup.PopupEditGroupMembers.headerMain") }}</span>
      <span class="highlight hover" @click="toggleAddMemberShow">{{
        $t("popup.PopupEditGroupMembers.headerAdd")
      }}</span>
    </div>
    <div class="divider"></div>
    <div class="popup-content small-padding">
      <div class="members">
        <div v-if="addMemberShow" class="member">
          <Icon :icon="IconType.DEFAULT_SINGLE_GRAY" class="picture" />
          <UsersSelectBox @set-selected-users="setSelectedUsers" />
        </div>
        <div v-for="member in members" :key="member.id" class="member">
          <ProfileImage :id="member.id" :image="member.picture" :size="54" />
          <span class="name">{{ member.name }}</span>
          <Icon
            :icon="IconType.TRASH"
            class="icon"
            @click="removeMember(member)"
          />
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="popup-footer">
      <PopupCancelButton />
      <button class="btn btn-primary" @click="saveChanges">
        {{ $t("popup.PopupEditGroupMembers.btnSave") }}
      </button>
    </div>
  </div>
</template>

<script>
import PopupCancelButton from "@/components/popup/PopupCancelButton.vue";
import UsersSelectBox from "@/components/select/UsersSelectBox.vue";
import ProfileImage from "@/components/images/ProfileImage.vue";
import { mapGetters } from "vuex";
import { store, GetterTypes } from "@/store";
import { AllMutationTypes } from "@/store/mutationTypes";
import { AllActionTypes } from "@/store/actionTypes";
import Icon from "../icons/Icon/Icon.vue";
import { IconType } from "@/types/icons";

export default {
  components: {
    PopupCancelButton,
    ProfileImage,
    UsersSelectBox,
    Icon,
    Icon,
  },
  data() {
    return {
      removedMembers: [],
      addedMembers: [],
      members: [],
      addMemberShow: false,
      IconType,
    };
  },
  computed: {
    ...mapGetters({
      chatId: GetterTypes.GET_SELECTED_CHAT_ID,
    }),
  },
  created() {
    const chat = store.getters.GET_SELECTED_CHAT;
    this.members = chat.members.filter(
      (member) => member.id !== chat.createdBy,
    );
  },
  methods: {
    addMember(member) {
      if (this.members.find((m) => m.id === member.id)) return;
      this.addedMembers.push(member);
      this.members.push(member);
    },
    removeMember(member) {
      this.removedMembers.push(member);
      this.members = this.members.filter((m) => m.id !== member.id);
    },
    toggleAddMemberShow() {
      this.addMemberShow = !this.addMemberShow;
    },
    hideAddMember() {
      this.addMemberShow = false;
    },
    setSelectedUsers(member) {
      if (this.members.find((m) => m.id === member.id)) return;
      this.addedMembers.push(member);
      this.members.push(member);
      this.hideAddMember();
    },
    saveChanges() {
      const addMemberIds = this.addedMembers.map((m) => m.id);
      const removeMemberIds = this.removedMembers.map((m) => m.id);

      store.dispatch(AllActionTypes.UPDATE_GROUP_CHAT_MEMBERS, {
        chatId: this.chatId,
        addMemberIds,
        removeMemberIds,
      });

      store.commit(AllMutationTypes.HIDE_POPUP);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/popup/popup.scss";
@import "src/assets/scss/popup/popupDefault.scss";
</style>
