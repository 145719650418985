import { getImageNaturalSize } from "./getImageNaturalSize";
import { getImageSize } from "./getImageSize";

interface GetImageDimensionsArgs {
  url?: string;
  galleryMode?: boolean;
}

export const getImageDimensions = async ({
  url,
  galleryMode,
}: GetImageDimensionsArgs) => {
  const { width, height } = await getImageNaturalSize(url);
  const imageSize = getImageSize({
    width,
    height,
    galleryMode,
  });
  return imageSize;
};
