import { IdPrefixedResultObject } from "@/types/chat";
import { IUserPhoneContactUser } from "@/types/users";
import { ChatBlockingStatusEnum } from "../chats/types";

export interface User {
  id: number;
  name: string;
  picture: string;
  url: string;
  blockingStatus?: ChatBlockingStatusEnum;
  isDeleted: boolean;
}

export interface FriendUser extends User {
  alias?: string;
}

export interface UserState {
  [userId: string]: User;
}

export type IUserPhoneContactUsersState =
  IdPrefixedResultObject<IUserPhoneContactUser>;

export interface PhoneContact {
  email: string;
  name: string;
  phone: string;
}

export interface UsersState {
  users: UserState;
  usersFilter: string;
  friends: FriendUser[];
  phoneContacts: PhoneContact[];
  phoneContactsUsers: IUserPhoneContactUsersState;
}

export const usersState: UsersState = {
  users: {},
  usersFilter: "",
  friends: [],
  phoneContacts: [],
  phoneContactsUsers: {},
};
