<template>
  <textarea
    ref="root"
    :style="{
      height: `${messageInputHeight - 24 - attachmentsHeightAddition}px`,
    }"
    :placeholder="$t('chat.chatContainer.InputBar.placeholder')"
    :value="text"
    @input="updateText"
    @keydown.enter="handleEnter"
  />
</template>

<script setup lang="ts">
import { LayoutTypes } from "@/store/app/state";
import { Attachment } from "@/store/chats/types";
import { ref } from "vue";
import { useHandleModifyMessageInputHeight } from "./useHandleModifyMessageInputHeight";

interface Props {
  attachments: Attachment[];
  text: string;
}

interface Emits {
  (e: "update-text", text: string): void;
  (e: "send-message"): void;
}
const root = ref<HTMLElement>();
const props = withDefaults(defineProps<Props>(), {
  attachments: () => [],
  text: "",
});
const emit = defineEmits<Emits>();
const { layoutType, messageInputHeight, attachmentsHeightAddition } =
  useHandleModifyMessageInputHeight(props, root);

const updateText = (e: Event) => {
  const target = e.target as HTMLInputElement;
  emit("update-text", target.value);
};
const handleEnter = (e: KeyboardEvent) => {
  if (e.shiftKey || layoutType.value === LayoutTypes.MOBILE) return;
  emit("send-message");
  e.preventDefault();
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatContainer/inputText.scss";
</style>
