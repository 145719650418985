import { IPartialInputEmitType } from "@/components/forms/PartialInput";
import { handleValidatePhoneNumber } from "@/components/login/LoginPhone/handleValidatePhoneNumber";
import { useComputedValue } from "@/composables";
import { app } from "@/firebase/firebase";
import { getIsAuthenticated } from "@/services/maitrejaApi";
import { ActionTypes, GetterTypes } from "@/store";
import { ErrorHandlerSnackbarRequestObject } from "@/store/app/state";
import { Profile } from "@/store/auth/state";
import { ConnectPhoneHandler, IConnectPhoneData } from "@/types/auth";
import {
  LoginPhoneFormDataObjectType,
  FormFieldDataObject,
  FormType,
} from "@/types/form";
import { handleMountErrorMessageSnackbar } from "@/utils/app";
import {
  getInitialFormData,
  getFullPhoneNumberString,
  getIsFormValid,
  getIsAllowedNumberString,
} from "@/utils/form";
import { loginPhoneFormFields } from "@/utils/form/loginPhoneFormFields";
import { waitForMs } from "@/utils/modifiers";
import {
  getAuth,
  signOut,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import { reactive, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { loginPhoneSnackbarErrorObject } from "../utils/auth/loginPhoneSnackbarErrorObject";
import { config } from "@/config";

export const useGetConnectPhoneProperties = (
  handleSubmit: ConnectPhoneHandler,
  errorObject:
    | ErrorHandlerSnackbarRequestObject
    | undefined = loginPhoneSnackbarErrorObject,
) => {
  const initialFormData =
    getInitialFormData<LoginPhoneFormDataObjectType>(loginPhoneFormFields);
  const initialCodesArray: string[] = new Array(6).fill("");
  const isAuthenticated = getIsAuthenticated();
  const profile = useComputedValue<Profile>(GetterTypes.GET_AUTH_PROFILE);
  const router = useRouter();
  const initialData: IConnectPhoneData &
    FormFieldDataObject<LoginPhoneFormDataObjectType> = {
    ...initialFormData,
    countryCode: profile?.value.countryCode,
    phoneNumber: profile?.value.phoneNumber,
    isVerificationStarted: false,
    codesArray: [...initialCodesArray],
    isLoading: false,
    isPopupOpen: false,
    isRedirectBlocked: false,
    isRecaptchaSolved: false,
  };
  const data = reactive<
    IConnectPhoneData & FormFieldDataObject<LoginPhoneFormDataObjectType>
  >({
    ...initialData,
  });
  const codeString = computed<string>(() => data.codesArray.join(""));
  const phoneString = computed<string>(() =>
    getFullPhoneNumberString(data.countryCode, data.phoneNumber),
  );

  const { dispatch } = useStore();
  onMounted(() => {
    initializeVerifier();
  });

  const handleUpdateCountryCode = (countryCode: string): void => {
    data.countryCode = countryCode;
  };

  const handleRedirectBack = (): void => {
    if (data.isRedirectBlocked) {
      return;
    }
    if (data.isVerificationStarted) {
      data.isVerificationStarted = false;
      data.isRecaptchaSolved = false;
      data.codesArray = [...initialCodesArray];
      data.isLoading = false;
      return;
    }
    router.back();
  };

  const handleConfirmCode = async () => {
    if (!codeString.value) {
      return;
    }
    if (isAuthenticated) {
      data.isLoading = true;
    }
    try {
      // @ts-expect-error
      const result = await window.confirmationResult.confirm(codeString.value);
      const idToken: string = result.user.accessToken;
      await handleSubmit(data, idToken);
    } catch (error) {
      data.codesArray = [...initialCodesArray];
      handleMountErrorMessageSnackbar(error, errorObject);
    }
    data.isLoading = false;
  };

  const updatePartialInput = (eventObject: IPartialInputEmitType): void => {
    const { event, index } = eventObject;
    const value = event.data ?? "";
    if (value.length > 1) {
      handleUpdateAllPartialInputs(value);
      return;
    }
    data.codesArray[index] = value;
  };

  const handlePasteToPartialInputs = (pasteEvent: ClipboardEvent) => {
    const text = pasteEvent.clipboardData?.getData("text") ?? "";
    handleUpdateAllPartialInputs(text);
  };

  const handleUpdateAllPartialInputs = (inputString: string) => {
    for (
      let i = 0;
      i < config.constants.PHONE_VERIFICATION_CODE_INPUTS_COUNT;
      i++
    ) {
      const rawValue = inputString[i];
      const value =
        rawValue && getIsAllowedNumberString(rawValue) ? rawValue : "";
      data.codesArray[i] = value;
    }
  };

  const handleStartVerification = async () => {
    const auth = getAuth();

    try {
      await signOut(auth);
    } catch (error) {
      console.log({ error });
    }

    const isFormValid = getIsFormValid(
      data,
      loginPhoneFormFields,
      FormType.Login,
    );

    if (!isFormValid) {
      return;
    }

    const isPhoneNumberValid = handleValidatePhoneNumber(
      data.countryCode,
      data.phoneNumber,
    );

    if (!isPhoneNumberValid) {
      return;
    }

    // @ts-expect-error
    const appVerifier = window.recaptchaVerifier;
    data.isLoading = true;

    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneString.value,
        appVerifier,
      );
      data.isVerificationStarted = true;

      // @ts-expect-error
      window.confirmationResult = confirmationResult;
    } catch (error) {
      dispatch(ActionTypes.MOUNT_SNACKBAR, {
        title: "snackbar.errors.tooManyAttempts.title",
        text: "snackbar.errors.tooManyAttempts.text",
      });
      throw error;
    } finally {
      data.isLoading = false;
    }
  };

  const updatePhoneNumber = (value: string) => {
    data.phoneNumber = value;
  };

  const initializeVerifier = (): void => {
    const auth = getAuth(app);
    // @ts-expect-error
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "sign-in", {
      size: "invisible",
      callback: () => {
        // reCAPTCHA solved, allow entering verification code.
        if (data.isVerificationStarted) {
          data.isRecaptchaSolved = true;
        }
      },
      inherit: true,
    });
  };

  const handleTogglePopup = async (isPopupOpen: boolean) => {
    data.isPopupOpen = isPopupOpen;
    data.isRedirectBlocked = !isPopupOpen;
    await waitForMs(500);
    data.isRedirectBlocked = isPopupOpen;
  };

  return {
    data,
    handleRedirectBack,
    handleUpdateCountryCode,
    handleStartVerification,
    handleConfirmCode,
    updatePartialInput,
    updatePhoneNumber,
    handleTogglePopup,
    handlePasteToPartialInputs,
  };
};
