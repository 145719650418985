import store from "@/store";
import { getReportErrorParagraphString } from "./getReportErrorParagraphString";

export const getReportErrorMessageBody = (): string => {
  const { currentAppVersion, deviceModelName, osVersion } =
    store.getters.GET_APP_DETAILS;

  const messageBody = `<br><br><br><p>-----</p>
    ${getReportErrorParagraphString("Device", deviceModelName)}
    ${getReportErrorParagraphString("OS", osVersion)}
    ${getReportErrorParagraphString("Version", currentAppVersion)}`;

  return messageBody;
};
