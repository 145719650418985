import store, { MutationTypes, ActionTypes } from "@/store";
import {
  ErrorHandlerSnackbarRequestObject,
  SnackbarRequestObject,
} from "@/store/app/state";
import { AxiosError } from "axios";
import { defaultPhoneSnackbarErrorObjectItem } from "../auth";

export const handleMountErrorMessageSnackbar = (
  error: any,
  snackbarErrorObject: ErrorHandlerSnackbarRequestObject,
) => {
  store.commit(MutationTypes.SET_IS_LOADING, false);
  const err = error as AxiosError;
  const status = err?.response?.status?.toString();

  let snackbarObjectItem: SnackbarRequestObject =
    defaultPhoneSnackbarErrorObjectItem;
  if (snackbarErrorObject.default) {
    snackbarObjectItem = snackbarErrorObject.default;
  }
  if (status && snackbarErrorObject[status]) {
    snackbarObjectItem = snackbarErrorObject[status];
  }

  store.dispatch(ActionTypes.MOUNT_SNACKBAR, snackbarObjectItem);
};
