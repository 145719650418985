import { GetterTypes, MutationTypes } from "@/store";
import { computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useComputedValue } from "./useComputedValue";

export const useHandleRouteParamsToStore = () => {
  const route = useRoute();
  const router = useRouter();
  const { commit } = useStore();
  const paramsChatId = computed(() => route.params.chat_id);
  const selectedChatId = useComputedValue<string | undefined>(
    GetterTypes.GET_SELECTED_CHAT_ID,
  );

  watch(paramsChatId, () => {
    if (!paramsChatId.value) {
      commit(MutationTypes.REMOVE_SELECTED_CHAT);
      return;
    }

    commit(MutationTypes.SET_SELECTED_CHAT, {
      chatId: paramsChatId.value,
    });
  });

  watch(selectedChatId, (newValue, oldValue) => {
    const isDeleted = Boolean(oldValue && !newValue);
    const wasRouteSelected = oldValue ? route.path.includes(oldValue) : false;
    if (!isDeleted || !wasRouteSelected) {
      return;
    }

    router.push("/");
  });
};
