import store, { MutationTypes } from "@/store";
import { FirestoreDocumentChangeType, RawMessage } from "@/store/chats/types";

interface OptimisticUpdateReactionsArgs {
  rawMessage: RawMessage;
  chatId: string;
}

export const optimisticUpdateReactions = ({
  rawMessage,
  chatId,
}: OptimisticUpdateReactionsArgs) => {
  store.commit(MutationTypes.SET_MESSAGE_DATA, {
    changeType: FirestoreDocumentChangeType.Modified,
    rawMessage,
    chatId,
  });
};
