import {
  ConvertRawMessageArgs,
  NonAutomaticMessage,
} from "@/store/chats/types";
import { convertRawMessage } from "./convertRawMessage";
import { getIsAutomaticMessageType } from "./getIsAutomaticMessageType";

export const getNonAutomaticReplyMessage = ({
  ignoreReplies,
  ...args
}: ConvertRawMessageArgs) => {
  if (ignoreReplies) {
    return;
  }
  const convertedReplyToMessage = convertRawMessage({
    ...args,
    ignoreReplies: true,
  });
  if (
    convertedReplyToMessage &&
    getIsAutomaticMessageType(convertedReplyToMessage)
  ) {
    return;
  }
  //   NOTE: this cast is necessary to stop compiler crashing (reason unknown)
  return convertedReplyToMessage as NonAutomaticMessage;
};
