import i18n from "@/i18n";
import { ICountryCodeItem } from "@/types/countryCodes";
import { countryCodes } from "../constants/countryCodes";

export const getTranslatedCountryCodesArray = (): ICountryCodeItem[] => {
  const translatedSortedCountryCodes: ICountryCodeItem[] = countryCodes
    .map(({ countryName, countryCode }) => ({
      countryName: i18n.global.t(`countryCodes.${countryName}`),
      countryCode,
    }))
    .sort((a, b) => String(a.countryName).localeCompare(String(b.countryName)));

  return translatedSortedCountryCodes;
};
