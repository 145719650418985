import store, { ActionTypes, MutationTypes } from "@/store";
import { getRefreshToken } from "@/utils/api";
import { getAccessTokenAsync } from "./getAccessTokenAsync";
import { getIsAuthenticated } from "./getIsAuthenticated";
import { storeAuthenticationData } from "./storeAuthenticationData";

export const getIsAuthenticatedAsync = async (
  isLoadingShown: boolean = false,
): Promise<boolean> => {
  const refreshToken = getRefreshToken();

  const isAuthenticated = getIsAuthenticated();
  if (isAuthenticated) {
    return true;
  }

  if (!refreshToken) {
    return false;
  }

  try {
    if (isLoadingShown) {
      store.commit(MutationTypes.SET_IS_LOADING, true);
    }
    const authData = await getAccessTokenAsync(refreshToken);
    storeAuthenticationData({ ...authData, refresh_token: refreshToken });
    await store.dispatch(ActionTypes.LOGIN_AUTH);
    return !!authData.access_token;
  } catch (error) {
    throw error;
  } finally {
    store.commit(MutationTypes.SET_IS_LOADING, false);
  }
};
