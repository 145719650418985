import { config } from "@/config";
import { ConnectOptions } from "twilio-video";

export const getRoomOptions = (
  chatId: string,
  hasVideo: boolean,
): ConnectOptions => {
  return {
    name: chatId,
    audio: true,
    video: hasVideo ? config.constants.DEFAULT_VIDEO_SETTINGS : false,
    bandwidthProfile: {
      video: {
        mode: "collaboration",
        dominantSpeakerPriority: "standard",
        maxSubscriptionBitrate: 2400000,
      },
    },
    dominantSpeaker: true,
    maxAudioBitrate: 16000,
    preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
    networkQuality: { local: 1, remote: 1 },
  };
};
