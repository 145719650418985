import store from "@/store";
import { AuthGetterTypes } from "@/store/auth";
import { Chat, ChatTypes } from "@/store/chats/types";
import { getIsChatEmptyFromId } from "./getIsChatEmptyFromId";

export const getIsChatPreviewVisible = (chat: Chat) => {
  const authId = store.getters[AuthGetterTypes.GET_AUTH_ID];
  const isChatEmpty = getIsChatEmptyFromId(chat.id);
  const isGroupChatCreatedByCurrentUser =
    chat?.type === ChatTypes.GROUP_CHAT && chat.createdBy === authId;

  const isPreviewVisible = Boolean(
    chat && (isGroupChatCreatedByCurrentUser || !isChatEmpty),
  );

  return isPreviewVisible;
};
