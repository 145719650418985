<template>
  <div
    ref="root"
    class="chat-wrapper"
    :class="{ isBlocking: isBlockingTextShown }"
    :style="style"
  >
    <ChatTopBar />
    <ChatContent :messages="messages" @reply-to-message="replyToMessage" />
    <ChatInputBar
      v-if="!isBlockingTextShown"
      :members="members"
      :reply-message="replyMessage"
      @clear-reply-message="clearReplyMessage"
    />
  </div>
</template>

<script setup lang="ts">
import ChatTopBar from "@/components/chat/chatContainer/ChatTopBar/ChatTopBar.vue";
import ChatInputBar from "@/components/chat/chatContainer/ChatInputBar/ChatInputBar.vue";
import ChatContent from "@/components/chat/chatContainer/ChatContent/ChatContent.vue";
import { useGetChatContentContainerProperties } from "./useGetChatContentContainerProperties";
import { ref } from "vue";
import { Message, NonAutomaticMessage } from "@/store/chats/types";
import { getIsAutomaticMessageType } from "@/utils/message";
import { replyMessageInjectionKey } from "@/utils/chat";
import { provide } from "vue";

const replyMessage = ref<NonAutomaticMessage | null>(null);

const replyToMessage = (message: Message) => {
  if (getIsAutomaticMessageType(message)) {
    return;
  }
  replyMessage.value = message;
};

const clearReplyMessage = () => {
  replyMessage.value = null;
};

provide(replyMessageInjectionKey, replyMessage);

const { root, style, isBlockingTextShown, messages, members } =
  useGetChatContentContainerProperties();
</script>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatContainer/chatContainer.scss";
</style>
