import { IListItem, ListItemTypeEnum } from "@/types/list";
import { BridgeActionEnum } from "@/types/webview";
import { postWebviewMessage } from "@/utils/api";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { getIsCurrentLang } from "./getIsCurrentLang";
import { ListItemsSelectEmitsObject } from "./ListItemsSelectTypes";

interface CustomClassesObject {
  [key: string]: boolean;
}

interface Props {
  listItemsArray: IListItem[];
}

const textItemTypes = [
  ListItemTypeEnum.Chat,
  ListItemTypeEnum.Checkmark,
  ListItemTypeEnum.Checkmarked,
  ListItemTypeEnum.CheckmarkedDisabled,
];

export const useGetListItemsSelectProperties = (
  props: Props,
  emit: ListItemsSelectEmitsObject,
) => {
  const router = useRouter();
  const { t, locale } = useI18n();

  const visibleListItemsArray = computed<IListItem[]>(() => {
    return props.listItemsArray.filter((item) => !item.isHidden);
  });

  const handleClick = async (item: IListItem) => {
    const { link, onClick, itemId, type, isNotInvited } = item;
    const shouldBeAdded =
      (type === ListItemTypeEnum.Chat || type === ListItemTypeEnum.Checkmark) &&
      itemId;

    if (item.type === ListItemTypeEnum.Checkmarked) {
      emit("remove-member", { ...item, type: ListItemTypeEnum.Checkmark });
    }

    if (isNotInvited && shouldBeAdded) {
      return;
    }

    if (shouldBeAdded) {
      emit("add-member", { ...item, type: ListItemTypeEnum.Checkmarked });
    }

    if (link) {
      router.push(link);
      return;
    }

    if (!onClick) {
      return;
    }

    onClick();
  };

  const handleInviteToApp = ({ phoneNumber }: IListItem) => {
    postWebviewMessage({
      action: BridgeActionEnum.ComposePhoneMessage,
      phoneNumber,
      messageBody: t("select.inviteMessage"),
    });
  };

  const getIsTextBold = (item: IListItem): boolean => {
    const isCurrentLang = getIsCurrentLang(item, locale.value);

    if (isCurrentLang || item.type === ListItemTypeEnum.Chat) {
      return true;
    }

    return false;
  };

  const getTextAppliedClassesObject = (
    item: IListItem,
  ): CustomClassesObject => {
    const stylesObj: CustomClassesObject = {
      isBold: getIsTextBold(item),
      [item.type.toLowerCase()]: true,
    };
    if (item.textColorType) {
      stylesObj[item.textColorType?.toLowerCase()] = true;
    }
    if (item.backgroundColorType) {
      stylesObj[item.backgroundColorType?.toLowerCase()] = true;
    }

    return stylesObj;
  };

  const getAppliedText = (item: IListItem): string => {
    if (textItemTypes.includes(item.type)) {
      return item.text;
    }

    return t(item.text);
  };
  const getAppliedSubtext = (item: IListItem): string => {
    return `~ ${item.subtext}`;
  };

  return {
    visibleListItemsArray,
    handleClick,
    getTextAppliedClassesObject,
    getAppliedText,
    handleInviteToApp,
    getIsTextBold,
    getAppliedSubtext,
  };
};
