import { UsersMutationTypes } from "./mutationTypes";
import { UsersMutationType } from "./mutationState";
import { getIdPrefixedObjectFromArray } from "@/utils/modifiers";
import { IUserPhoneContactUsersState, User, UserState } from "./state";
import { IUserPhoneContactUser } from "@/types/users";

export const usersMutations: UsersMutationType = {
  [UsersMutationTypes.ADD_USER](state, user) {
    if (!user) return;
    state.users[user.id] = user;
  },
  [UsersMutationTypes.SET_USERS](state, users) {
    const usersObject: UserState = getIdPrefixedObjectFromArray<User>(users);
    state.users = usersObject;
  },
  [UsersMutationTypes.SET_AUTH_FRIENDS](state, friends) {
    state.friends = friends;
  },
  [UsersMutationTypes.SET_PHONE_CONTACTS](state, phoneContacts) {
    state.phoneContacts = phoneContacts;
  },
  [UsersMutationTypes.SET_PHONE_CONTACTS_USERS](state, phoneContactsUsers) {
    const phoneContactsUsersObject: IUserPhoneContactUsersState =
      getIdPrefixedObjectFromArray<IUserPhoneContactUser>(phoneContactsUsers);
    state.phoneContactsUsers = phoneContactsUsersObject;
  },
  [UsersMutationTypes.UPDATE_USER](state, user) {
    state.users[user.id] = { ...state.users[user.id], ...user };
  },
};
