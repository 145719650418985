<template>
  <ChatSettingsDetails v-bind="reportUserProperties" />
</template>
<script setup lang="ts">
import { useGetChatSettingsReportUserProperties } from "./useGetChatSettingsReportUserProperties";
import ChatSettingsDetails from "@/components/chat/chatSettings/ChatSettingsDetails/ChatSettingsDetails.vue";

const { reportUserProperties } = useGetChatSettingsReportUserProperties();
</script>
<style lang="scss" scoped></style>
