import { Module } from "vuex";
import { RootState } from "@/store/state";
import { authState, AuthState } from "./state";
import { authActions } from "./actions";
import { authMutations } from "./mutations";
import { authGetters } from "./getters";

export const auth: Module<AuthState, RootState> = {
  state: authState,
  actions: authActions,
  getters: authGetters,
  mutations: authMutations,
};
