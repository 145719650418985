<template>
  <div v-if="isListShown" class="list-container">
    <h5 v-if="title">{{ $t(title) }}</h5>
    <ListItemsSelect
      :list-items-array="filteredArray"
      @add-member="addMember"
      @remove-member="removeMember"
    />
  </div>
</template>
<script setup lang="ts">
import ListItemsSelect from "@/components/select/ListItemsSelect/ListItemsSelect.vue";
import { GetterTypes } from "@/store";
import { computed } from "vue";
import { IListItem } from "@/types/list";
import { useComputedValue } from "@/composables/useComputedValue";

interface Props {
  listItemsArray: IListItem[];
  title?: string;
}

interface ListItemsSelectEmits {
  (e: "add-member", member: IListItem): void;
  (e: "remove-member", member: IListItem): void;
}

const emit = defineEmits<ListItemsSelectEmits>();
const props = defineProps<Props>();
const filter = useComputedValue<string>(GetterTypes.GET_CHATS_FILTER);
const filteredArray = computed(() => {
  return props.listItemsArray.filter((item) =>
    item.text.toLowerCase().includes(filter.value.toLowerCase().trim()),
  );
});
const isListShown = computed(() => {
  return !!filteredArray.value.length;
});

const addMember = (item: IListItem) => {
  emit("add-member", item);
};
const removeMember = (item: IListItem) => {
  emit("remove-member", item);
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/chat/chatContainer/newChatListContainer.scss";
</style>
