export const findParentNodeById = (
  currNode: HTMLElement | null,
  parentNodeId: string,
) => {
  let node = currNode;
  while (node && node.id !== parentNodeId) {
    node = node.parentElement;
  }
  return node;
};
