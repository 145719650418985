import { ChatSettingsScreenEnum } from "@/types/chat";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { ChatSettingsDetailsPropsObject } from "./ChatSettingsDetailsTypes";

export const useGetChatSettingsDetailsProperties = (
  props: ChatSettingsDetailsPropsObject,
) => {
  const { t } = useI18n();
  const router = useRouter();

  const appliedImageSrc = computed<string>(() => {
    return props.imageSrc ?? `/images/${props.imageName}`;
  });
  const title = computed<string>(() => {
    return `settings.chat.${props.type}.title`;
  });

  const primaryButtonText = computed<string>(() => {
    let text = t(title.value);

    return text;
  });
  const secondaryButtonText = computed<string>(() => {
    let text = "settings.chat.cancel";
    if (props.type === ChatSettingsScreenEnum.UnblockUser) {
      text = "settings.chat.unblockUser.secondaryButton.text";
    }

    return text;
  });

  const redirectBack = (): void => {
    router.back();
  };

  return {
    title,
    appliedImageSrc,
    primaryButtonText,
    secondaryButtonText,
    redirectBack,
  };
};
