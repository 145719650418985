<template>
  <div v-click-outside="onClickOutside" class="options">
    <Icon
      :icon="IconType.DOTS"
      class="hover"
      alt="dots"
      @click="toggleOptions"
    />
    <div v-if="showCard" class="options-wrapper">
      <OptionsCardSelect
        v-if="isUserGroupOwner"
        text="cards.GroupChatOptionsCard.optionChatSetting"
        :on-click="chatSettings"
      />
      <OptionsCardSelect
        v-if="isUserGroupOwner"
        text="cards.GroupChatOptionsCard.optionGroupMembers"
        :on-click="editMembers"
      />
      <OptionsCardSelect
        v-if="!isUserGroupOwner"
        text="cards.GroupChatOptionsCard.optionLeaveGroup"
        :on-click="leaveGroup"
      />
      <OptionsCardSelect
        text="cards.GroupChatOptionsCard.optionCloseChat"
        :on-click="closeChat"
      />
      <OptionsCardSelect
        text="cards.GroupChatOptionsCard.optionDeleteMessages"
        :on-click="deleteMessages"
      />
      <OptionsCardSelect
        v-if="isUserGroupOwner"
        text="cards.GroupChatOptionsCard.optionDeleteChat"
        :on-click="deleteChat"
      />
    </div>
  </div>
</template>

<script>
import OptionsCardSelect from "@/components/cards/OptionsCardSelect.vue";
import { GetterTypes, MutationTypes, store } from "@/store";
import { PopupTypes } from "@/store/app/state";
import { mapGetters } from "vuex";
import Icon from "../icons/Icon/Icon.vue";
import { IconType } from "@/types/icons";

export default {
  components: { OptionsCardSelect, Icon },
  data() {
    return {
      showCard: false,
      IconType,
    };
  },
  computed: {
    ...mapGetters({
      chat: GetterTypes.GET_SELECTED_CHAT,
      authId: GetterTypes.GET_AUTH_ID,
    }),
    isUserGroupOwner() {
      return this.chat.createdBy === this.authId;
    },
  },
  methods: {
    onClickOutside() {
      this.showCard = false;
    },
    toggleOptions() {
      this.showCard = !this.showCard;
    },
    closeChat() {
      this.$router.push("/");
      this.toggleOptions();
    },
    chatSettings() {
      store.commit(MutationTypes.SHOW_POPUP, {
        popupType: PopupTypes.EDIT_GROUP,
      });
      this.toggleOptions();
    },
    muteChat() {
      store.commit(MutationTypes.SHOW_POPUP, {
        popupType: PopupTypes.INFO_MUTE_CHAT,
      });
      this.toggleOptions();
    },
    deleteChat() {
      store.commit(MutationTypes.SHOW_POPUP, {
        popupType: PopupTypes.INFO_DELETE_CHAT,
      });
      this.toggleOptions();
    },
    deleteMessages() {
      store.commit(MutationTypes.SHOW_POPUP, {
        popupType: PopupTypes.INFO_DELETE_MESSAGES,
      });
      this.toggleOptions();
    },
    leaveGroup() {
      store.commit(MutationTypes.SHOW_POPUP, {
        popupType: PopupTypes.INFO_LEAVE_GROUP,
      });
      this.toggleOptions();
    },
    editMembers() {
      store.commit(MutationTypes.SHOW_POPUP, {
        popupType: PopupTypes.EDIT_GROUP_MEMBERS,
      });
      this.toggleOptions();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/cards/optionsCard.scss";
</style>
