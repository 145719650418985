import { RawChat } from "@/store/chats/types";
import firebase, { firestore } from "./firebase";
import { CollectionTypes } from "./types";
import { isOneToOneChat } from "./utils";
import { config } from "@/config";
import _ from "lodash";
import { getChatQuerySnapshotCallback } from "@/utils/chat";

export const getFutureUpdatesQuery = (
  ref: firebase.firestore.Query<firebase.firestore.DocumentData>,
): firebase.firestore.Query<firebase.firestore.DocumentData> =>
  ref.where(
    "sentAt",
    ">=",
    firebase.firestore.Timestamp.fromMillis(Date.now()),
  );

export const getUserRef = (userId: number) => {
  return firestore.collection(CollectionTypes.USERS).doc(userId.toString());
};

export const getChatRef = (
  chatId: string,
): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> =>
  getChatCollectionRef().doc(chatId);

export const getChatCollectionRef =
  (): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> => {
    return firestore.collection(CollectionTypes.CHATS);
  };

interface LastChatsByDocsIdsArgs {
  chatIds: string[];
  startAfter?: firebase.firestore.Timestamp;
  limit?: number;
}

export const getLastChatsByDocIds = async ({
  chatIds,
  startAfter,
  limit = config.constants.CHATS_PAGE_COUNT,
}: LastChatsByDocsIdsArgs) => {
  const documentId = firebase.firestore.FieldPath.documentId();
  let limitedChatIds = chatIds.slice(-limit);
  if (chatIds.length > limit) {
    limitedChatIds = chatIds.slice(-limit);
  }
  if (!limitedChatIds.length) {
    return [];
  }
  let queryRef = getChatCollectionRef().where(documentId, "in", limitedChatIds);
  if (startAfter) {
    queryRef = queryRef.startAfter(startAfter);
  }
  const queryRes = await queryRef.get();
  const chats = queryRes.docs.map((doc) => doc.data()) as RawChat[];
  return chats;
};

export const getLastChatsByDocIdsQueryUnsubscribe = ({
  chatIds,
}: LastChatsByDocsIdsArgs) => {
  const documentId = firebase.firestore.FieldPath.documentId();
  const queryRef = getChatCollectionRef()
    .orderBy("lastMessage.sentAt", "desc")
    .where(documentId, "in", chatIds);

  return queryRef.limit(1).onSnapshot(getChatQuerySnapshotCallback);
};

export const getMessagesRef = (
  chatId: string,
): firebase.firestore.Query<firebase.firestore.DocumentData> => {
  const messageCollectionRef = getMessagesCollectionRef(chatId);

  const query = messageCollectionRef
    .where("hide", "==", false)
    // .where("status", "!=", "waiting-for-attachments")
    .orderBy("sentAt", "asc");

  return query;
};
export const getRestrictedMessagesRef = (
  chatId: string,
  sentAt: firebase.firestore.Timestamp,
): firebase.firestore.Query<firebase.firestore.DocumentData> => {
  const messageCollectionRef = getMessagesCollectionRef(chatId);

  const query = messageCollectionRef
    .where("hide", "==", false)
    .where("sentAt", "<", sentAt)
    .orderBy("sentAt", "asc");
  return query;
};

export const getMessageRef = (chatId: string, messageId: string) =>
  getMessagesCollectionRef(chatId).doc(messageId);

export const getMessagesCollectionRef = (
  chatId: string,
): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> => {
  const messageCollection = getMessageCollectionFromChatId(chatId);
  return firestore
    .collection(messageCollection)
    .doc(chatId)
    .collection(CollectionTypes.MESSAGES);
};

const getMessageCollectionFromChatId = (chatId: string): CollectionTypes => {
  if (isOneToOneChat(chatId)) {
    return CollectionTypes.ONE_TO_ONE_CHATS_MESSAGES;
  }
  return CollectionTypes.GROUP_CHATS_MESSAGES;
};
