<template>
  <SwitchIconButton
    :active-icon="IconType.CONTROL_CAMERA_ACTIVE"
    :inactive-icon="IconType.CONTROL_CAMERA_INACTIVE"
    :is-active="hasVideo"
    @click="toggleHasVideo"
  />
</template>

<script setup lang="ts">
import SwitchIconButton from "@/components/buttons/SwitchIconButton/SwitchIconButton.vue";
import { useComputedValue } from "@/composables";
import { GetterTypes, MutationTypes } from "@/store";
import { IOngoingCall } from "@/store/auth/types";
import { IconType } from "@/types/icons";
import { useStore } from "vuex";

const { commit } = useStore();
const hasVideo = useComputedValue<boolean>(
  GetterTypes.GET_ONGOING_CALL_HAS_VIDEO,
);
const currentCall = useComputedValue<IOngoingCall>(
  GetterTypes.GET_CURRENT_ONGOING_CALL,
);

const toggleHasVideo = () => {
  commit(MutationTypes.TOGGLE_ONGOING_CALL_VIDEO, {
    value: !hasVideo.value,
    callId: currentCall.value.chatId,
  });
};
</script>
