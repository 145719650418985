import {
  MessageType,
  GroupRawTextMessage,
  CreateCachedTextMessageArgs,
} from "@/store/chats/types";
import { getIsMessageHidden } from ".";
import { getCommonCachedMessageProperties } from "./getCommonCachedMessageProperties";

export const createCachedGroupTextMessage = ({
  chatId,
  senderId,
  attachments,
  messageText,
}: CreateCachedTextMessageArgs): GroupRawTextMessage => {
  return {
    ...getCommonCachedMessageProperties({ chatId, senderId, attachments }),
    messageText,
    readBy: [senderId],
    hide: getIsMessageHidden(messageText, attachments),
    messageType: MessageType.Text,
  };
};
