import {
  IListItem,
  ListItemTextColorTypeEnum,
  ListItemTypeEnum,
} from "@/types/list";
import { getIsChatOwner } from "./getIsChatOwner";
import { IconType } from "@/types/icons";

export const getChatSettingsGroupChatMenuItems = (
  chatId: string,
): IListItem[] => [
  {
    text: "settings.chat.renameGroup.title",
    iconName: IconType.RENAME,
    type: ListItemTypeEnum.Link,
    link: `rename_group/${chatId}`,
    isHidden: !getIsChatOwner(chatId),
  },
  {
    text: "settings.chat.showUsers.title",
    iconName: IconType.GROUP,
    type: ListItemTypeEnum.Link,
    link: `show_group_users/${chatId}`,
  },
  {
    text: "settings.chat.showUsers.addUsersToGroup",
    iconName: IconType.ADD_USER,
    type: ListItemTypeEnum.Link,
    link: `add_users_to_group/${chatId}`,
  },
  {
    text: "settings.chat.leaveGroupConversation.title",
    iconName: IconType.LEAVE_RED,
    type: ListItemTypeEnum.Link,
    link: `leave_group_conversation/${chatId}`,
    textColorType: ListItemTextColorTypeEnum.RED,
    isHidden: getIsChatOwner(chatId),
  },
];
