<template>
  <SettingsDetails
    title="settings.connectPhoneNumber"
    :handle-redirect="handleRedirectBack"
  >
    <PhoneVerificationForm
      v-bind="otherProps"
      @toggle-popup="handleTogglePopup"
    />
  </SettingsDetails>
</template>
<script setup lang="ts">
import PhoneVerificationForm from "@/components/forms/PhoneVerificationForm/PhoneVerificationForm.vue";
import SettingsDetails from "@/components/settings/SettingsDetails/SettingsDetails.vue";
import { useComputedValue } from "@/composables";
import { useGetConnectPhoneProperties } from "@/composables";
import { updateUserPhoneAsync } from "@/services/maitrejaApi/auth";
import { GetterTypes, MutationTypes } from "@/store";
import { Profile } from "@/store/auth/state";
import { ConnectPhoneHandler } from "@/types/auth";
import { settingsConnectPhoneNumberSnackbarErrorObject } from "@/utils/auth";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const { commit } = useStore();
const router = useRouter();
const profile = useComputedValue<Profile>(GetterTypes.GET_AUTH_PROFILE);

const handleSubmit: ConnectPhoneHandler = async (data, idToken) => {
  const parsedPhoneNumber = data.phoneNumber.replaceAll(" ", "");
  try {
    await updateUserPhoneAsync(idToken, data.countryCode, parsedPhoneNumber);

    const newProfile: Profile = {
      ...profile.value,
      countryCode: data.countryCode,
      phoneNumber: parsedPhoneNumber,
    };

    commit(MutationTypes.SET_AUTH_PROFILE, newProfile);
    router.back();
  } catch (err) {
    throw err;
  }
};

const { handleRedirectBack, handleTogglePopup, ...otherProps } =
  useGetConnectPhoneProperties(
    handleSubmit,
    settingsConnectPhoneNumberSnackbarErrorObject,
  );
</script>
<style lang="scss" scoped>
@import "src/assets/scss/settings/settingsConnectPhoneNumber";
</style>
