import { Attachment, AttachmentTypes } from "@/store/chats/types";

export const getIsAttachmentForDeletion = (attachment: Attachment) => {
  return (
    !attachment.isUploading &&
    attachment.type === AttachmentTypes.VIDEO &&
    Number(attachment.uploadProgress) >= 0 &&
    Number(attachment.uploadProgress) < 100
  );
};
