import { CallMessageStatus } from "@/store/chats/types";
import { IconType } from "@/types/icons";

interface GetCallStatusArgs {
  isOutgoing: boolean;
  isPreview?: boolean;
  status: CallMessageStatus;
  duration?: string | null;
}

export const getCallStatusProperties = ({
  isOutgoing,
  isPreview = false,
  status,
  duration,
}: GetCallStatusArgs) => {
  const durationString = duration ? ` (${duration})` : "";
  const isOutgoingCancelled =
    isOutgoing && status === CallMessageStatus.Cancelled;

  if (isOutgoingCancelled && isPreview) {
    return {
      arrowIcon: IconType.OUTGOING_CANCELLED_CALL_PREVIEW,
      text: "call.type.outgoing",
      duration: "",
    };
  }
  if (isOutgoingCancelled) {
    return {
      arrowIcon: IconType.OUTGOING_CANCELLED_CALL,
      text: "call.type.outgoing",
      duration: "",
    };
  }
  if (status === CallMessageStatus.Cancelled) {
    return {
      arrowIcon: IconType.INCOMING_CANCELLED_CALL,
      text: `call.status.${status}`,
      duration: "",
    };
  }

  if (isOutgoing && isPreview) {
    return {
      arrowIcon: IconType.OUTGOING_COMPLETED_CALL_PREVIEW,
      text: "call.type.outgoing",
      duration: durationString,
    };
  }

  if (isOutgoing) {
    return {
      arrowIcon: IconType.OUTGOING_COMPLETED_CALL,
      text: "call.type.outgoing",
      duration: durationString,
    };
  }

  return {
    text: `call.status.${status}`,
    arrowIcon: IconType.INCOMING_COMPLETED_CALL,
    duration: durationString,
  };
};
