<template>
  <div class="select">
    <div
      v-for="item in visibleListItemsArray"
      :key="item.itemId"
      class="item"
      :class="getTextAppliedClassesObject(item)"
      @click="handleClick(item)"
    >
      <ProfileAvatar
        v-if="
          (item.type === ListItemTypeEnum.Chat && item.pictureSrc) ||
          item.isPictureDisplayed
        "
        :src="item.pictureSrc"
        :is-online="item.isOnline"
      />
      <div v-else-if="item.iconName" class="left">
        <Icon :icon="item.iconName" />
      </div>
      <div class="right">
        <div class="text-container">
          <p class="title">
            {{ getAppliedText(item) }}
          </p>
          <p v-if="item.subtext" class="subtext">
            {{ getAppliedSubtext(item) }}
          </p>
        </div>
        <Icon
          v-if="item.type === ListItemTypeEnum.Link"
          :icon="getIconName(item.textColorType)"
        />
        <Icon
          v-if="item.type === ListItemTypeEnum.Checkmark"
          :icon="IconType.CHECKBOX_OFF"
          class="checkbox-img"
        />
        <Icon
          v-if="item.type === ListItemTypeEnum.Checkmarked"
          :icon="IconType.CHECKBOX_ON"
          class="checkbox-img"
        />
        <Icon
          v-if="item.type === ListItemTypeEnum.CheckmarkedDisabled"
          :icon="IconType.CHECKBOX_ON_GRAY"
          class="checkbox-img"
        />
        <Icon
          v-if="item.type === ListItemTypeEnum.Lang && getIsTextBold(item)"
          :icon="IconType.CHECKMARK"
        />
        <p
          v-if="item.type === ListItemTypeEnum.Chat && item.isNotInvited"
          class="isNotInvited"
          @click="handleInviteToApp(item)"
        >
          {{ t("select.invite") }}
        </p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import ProfileAvatar from "@/components/images/ProfileAvatar.vue";
import { IListItem, ListItemTypeEnum } from "../../../types/list";
import { withDefaults } from "vue";
import { useI18n } from "vue-i18n";
import { useGetListItemsSelectProperties } from "./useGetListItemsSelectProperties";
import { getIconName } from "./getIconName";
import { IconType } from "@/types/icons";
import Icon from "@/components/icons/Icon/Icon.vue";

interface Props {
  listItemsArray: IListItem[];
}

interface ListItemsSelectEmits {
  (e: "add-member", member: IListItem): void;
  (e: "remove-member", member: IListItem): void;
}

const emit = defineEmits<ListItemsSelectEmits>();

const props = withDefaults(defineProps<Props>(), {
  listItemsArray: () => [],
});
const { t } = useI18n();

const {
  visibleListItemsArray,
  handleClick,
  getTextAppliedClassesObject,
  getAppliedText,
  getAppliedSubtext,
  handleInviteToApp,
  getIsTextBold,
} = useGetListItemsSelectProperties(props, emit);
</script>
<style lang="scss" scoped>
@import "./ListItemsSelect.scss";
</style>
