type SimpleFunction = () => void;

export const getDebouncedFunction = (
  func: SimpleFunction,
  delay = 1000,
): SimpleFunction => {
  let timeoutId: number;

  const debouncedFunction: SimpleFunction = () => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      func();
    }, delay);
  };

  return debouncedFunction;
};
