export default {
  "call": {
    "status": {
      "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokončený hovor"])},
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmeškaný hovor"])},
      "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prebiehajúci hovor…"])}
    },
    "type": {
      "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odchádzajúci hovor"])}
    },
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pripojiť sa"])},
    "isCallingYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volá vám"])},
    "establishingCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadväzovanie hovoru s"])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vy"])}
  },
  "popup": {
    "PopupCancelButton": {
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušiť"])}
    },
    "PopupEditGroup": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov a obrázok skupiny"])},
      "optionPicture": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrázok skupiny"])},
        "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmeniť"])},
        "inputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrázok skupiny"])}
      },
      "optionName": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov skupiny"])}
      },
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložiť zmeny"])}
    },
    "PopupEditGroupMembers": {
      "headerMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Členovia skupiny"])},
      "headerAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pridať"])},
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložiť zmeny"])}
    },
    "PopupInfoDeleteChat": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmazať chat"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naozaj chcete zmazať tento chat? Správy z chatu budú nenávratne odstránené, rovnako ako všetky prílohy, ktoré chat obsahuje. Ostatné osoby v chate ho budú môcť naďalej zobraziť."])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trvalo zmazať"])}
    },
    "PopupInfoDeleteMessages": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmazať vybrané správy"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak sa rozhodnete zmazať vybrané správy, nenávratne ich zmažete zo svojho zariadenia. Ostatné osoby v chate ich ale stále uvidia."])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrať správy na zmazanie"])}
    },
    "PopupInfoLeaveGroup": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opustiť skupinu"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naozaj chcete opustiť skupinu? Táto akcia je nevratná. Do skupiny sa možno znova dostať len pozvánkou ich členov."])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opustiť skupinu"])}
    },
    "PopupMuteChat": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stlmiť oznámenia"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z tohto chatu Vám už nebudú prichádzať žiadne oznámenia. V kontextovej ponuke si ich môžete opäť zapnúť. Chcete pokračovať?"])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stlmiť oznámenia"])}
    },
    "PopupNotificationsSetting": {
      "optionSound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvuky"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámenie"])},
      "optionNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámenie na ploche"])},
      "optionPreviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť ukážky"])},
      "optionNoNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezobrazovať žiadne oznámenia na ploche"])},
      "optionRing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyzváňanie pre prichádzajúci hovor"])},
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložiť nastavenie"])}
    },
    "PopupSelectFriendToChat": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výber priateľov"])},
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložiť "])}
    },
    "PopupChatDesign": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzhľad chatu"])},
      "optionColorful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farebný"])},
      "optionClassic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klasický"])},
      "optionDarkMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tmavý režim"])},
      "optionMinimalist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalistický"])},
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložiť nastavenie"])}
    },
    "PopupInfoDeleteUserAccount": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmazať účet"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naozaj zmazať účet?"])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trvalo zmazať"])},
      "cancelBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie, chcem účet zachovať."])}
    },
    "PopupInfoReportUser": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlásiť používateľa"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naozaj nahlásiť používateľa?"])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlásiť"])}
    },
    "PopupInfoBlockUser": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zablokovať používateľa"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naozaj zablokovať používateľa?"])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trvalo zablokovať"])}
    },
    "PopupInfoUnblockUser": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odblokovať používateľa"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naozaj odblokovať používateľa?"])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odblokovať"])}
    },
    "PopupChatAppearance": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzhľad chatu"])},
      "optionColorful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farebný"])},
      "optionClassic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klasický"])},
      "optionDarkMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tmavý režim"])},
      "optionMinimalist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalistický"])},
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložiť nastavenie"])}
    }
  },
  "cards": {
    "SidebarOptionsCard": {
      "optionArchives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivované"])},
      "optionNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámenie"])},
      "optionSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zabezpečenie"])}
    },
    "OneToOneChatOptionsCard": {
      "optionCloseChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavrieť chat"])},
      "optionMute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stlmiť oznámenia"])},
      "optionDeleteMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať správy"])},
      "optionDeleteChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstrániť chat"])},
      "optionchatDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzhľad chatu"])},
      "optionReportUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlásiť používateľa"])},
      "optionBlockUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zablokovať používateľa"])},
      "optionUnblockUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odblokovať používateľa"])},
      "optionChatAppearance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzhľad chatu"])}
    },
    "GroupChatOptionsCard": {
      "optionChatSetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavenie chatu"])},
      "optionGroupMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Členovia skupiny"])},
      "optionLeaveGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opustiť skupinu"])},
      "optionMute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stlmiť oznámenia"])},
      "optionCloseChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavrieť chat"])},
      "optionDeleteMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať správy"])},
      "optionDeleteChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstrániť chat"])},
      "optionchatDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzhľad chatu"])},
      "optionChatAppearance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzhľad chatu"])}
    }
  },
  "chat": {
    "chatContainer": {
      "NoChatContainer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitajte v chate MAIT."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začnite si písať so svojimi priateľmi alebo vytvorte skupinový chat."])}
      },
      "InputBar": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte správu..."])}
      },
      "ChatTopBarNewChat": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komu:"])}
      },
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prečítané"])},
      "VideoPlayer": {
        "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prebieha spracovanie videa"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pri spracovaní videa došlo k chybe"])}
      },
      "NewChatListContainer": {
        "yourContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše kontakty, ktoré používajú MAIT Chat"])},
        "otherPeople": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďalší ľudia na MAIT"])},
        "recommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odporučiť MAIT Chat"])}
      },
      "messages": {
        "audioMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["📣 Hlasová správa"])},
        "friendRequest": {
          "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadosť o priateľstvo"])},
          "requestSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadosť o priateľstvo odoslaná"])},
          "invitationFromUser": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Používateľ ", _interpolate(_named("userName")), " vám ponúka priateľstvo"])},
          "acceptInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete prijať ponuku na priateľstvo?"])},
          "acceptButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijať"])},
          "declineButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmietnuť"])},
          "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadosť o priateľstvo bola prijatá."])},
          "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadosť o priateľstvo bola zamietnutá. Žiadateľ nebude o vyradení informovaný."])}
        },
        "controls": {
          "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpoveď"])},
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopírovať"])}
        },
        "reactions": {
          "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reakcie"])},
          "removeWithClick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ťuknutím odstráňte"])}
        }
      }
    },
    "chatSidebar": {
      "ChatSidebarTopBar": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje konverzácie"])}
      },
      "SearchBar": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhľadajte priateľov alebo skupinu"])}
      },
      "NewChatPreview": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová konverzácia"])}
      },
      "ChatPreview": {
        "removeMessageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užívateľ správu odstránil."])}
      },
      "searchBar": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhľadajte priateľov alebo skupinu"])}
      }
    },
    "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Príloha"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užívateľ"])},
    "blockingStatus": {
      "hasBlockedYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["si vás zablokoval"])},
      "youHaveBlockedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zablokovali ste používateľa"])},
      "unblockToContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre pokračovanie v konverzácii používateľa odblokujte."])},
      "youCannotContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na túto konverzáciu nie je možné nadviazať."])}
    },
    "hasDeletedAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zmazal účet."])}
  },
  "select": {
    "UsersSelectBox": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhľadajte Kontakt"])}
    },
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozvať"])},
    "inviteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spojme sa cez MAIT Chat! Je to rýchla, jednoduchá a bezpečná aplikácia s bezplatným posielaním sprav i volaním. Na stiahnutie na https://mait.me/download."])}
  },
  "date": {
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dnes"])},
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Včera"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utorok"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pondelok"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streda"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štvrtok"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piatok"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobota"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedeľa"])}
  },
  "user": {
    "i": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "deletedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstránený používateľ"])},
    "anonymousUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymný používateľ"])}
  },
  "buttons": {
    "TopBarDeletingMessagesButtons": {
      "cancelDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušiť mazanie"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmazať vybrané"])}
    }
  },
  "auth": {
    "buttons": {
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihlásiť sa"])},
      "loginWithPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihlásiť sa telefónnym číslom"])},
      "loginWithEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihlásiť sa emailom"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrovať sa"])},
      "continueToApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovať do aplikácie"])},
      "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Späť na prihlásenie"])},
      "sendLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaslať e-mail s odkazom"])},
      "restorePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnoviť heslo"])},
      "sendVerificationSms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslanie overovacej SMS"])},
      "resendVerificationSms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opätovné odoslanie overovacej SMS"])}
    },
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihlásenie"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alebo"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefónne číslo"])},
    "restoreOrCreate": {
      "cantLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejde sa prihlásiť? Skús"])},
      "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obnoviť heslo"])},
      "orCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alebo si vytvoriť"])},
      "newAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nový účet"])}
    },
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrácia"])},
    "welcomeToMAIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitaj v chate MAIT!"])},
    "enterRequirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na vstup potrebuješ funkčnú emailovú adresu a heslo."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno"])},
    "alreadyHaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Už máš u nás účet?"])},
    "thankYouForRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďakujeme za registráciu!"])},
    "thankYouForRegistrationContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teraz môžeš bez váhania začať chatovať so svojimi priateľmi odkiaľkoľvek a bez strachu. Chat je plne zabezpečený a vaše správy si nechávame pre seba."])},
    "restorePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovenie hesla"])},
    "restorePasswordInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadaj emailový účet, na ktorý ti pošleme odkaz na vytvorenie nového hesla."])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo"])},
    "newPasswordAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo znova"])},
    "businessConditions": {
      "iAgreeWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súhlasím so"])},
      "gdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zásadami spracovania osobných údajov"])},
      "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prevádzkovými podmienkami"])},
      "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])}
    },
    "errors": {
      "wrongCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zlé prihlasovacie údaje."])},
      "userAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento účet už existuje."])},
      "agreeToTermsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je potrebné súhlasiť s podmienkami."])},
      "thisFieldIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto pole je povinné."])},
      "invalidPasswordLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo musí mať aspoň 8 znakov"])},
      "invalidEmailFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávny formát e-mailu."])}
    }
  },
  "snackbar": {
    "errors": {
      "userAlreadyExists": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento používateľ už existuje."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračujte na prihlasovacej obrazovke."])}
      },
      "agreeToTermsAndConditions": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chýbajúci súhlas."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bez súhlasu so Zásadami pre spracovanie osobných údajov a Prevádzkovými podmienkami bohužiaľ nie je možné vykonať registráciu."])}
      },
      "wrongCredentials": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba pri prihlásení."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užívateľské meno alebo heslo boli zadané zle. Skúste to znova alebo obnovte heslo."])}
      },
      "accountWasClosedLogin": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet bol uzavretý"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet, ku ktorému sa snažíte prihlásiť, bol uzavretý. O jeho obnovenie môžete požiadať emailom na adrese support", "@", "mait.me. Ďakujeme za pochopenie."])}
      },
      "accountWasClosedRegister": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet bol uzavretý"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento účet už existuje, ale bol uzavretý. O jeho obnovenie môžete požiadať emailom na adrese support", "@", "mait.me. Ďakujeme za pochopenie."])}
      },
      "invalidSmsCode": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS kód sa nezhoduje."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skúste zadať bezpečnostný kód ešte raz."])}
      },
      "invalidPhoneNumber": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatné telefónne číslo."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skúste zadať telefónne číslo ešte raz."])}
      },
      "defaultError": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Došlo k chybe"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skúste to znova."])}
      },
      "tooManyAttempts": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Príliš veľa pokusov"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďalší overovací kód nie je možné v tomto okamihu odoslať. Skúste to prosím neskôr."])}
      },
      "phoneNumberAlreadyInUse": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo nie je možné pripojiť."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto telefónne číslo už používa iný používateľ."])}
      },
      "videoWasNotUploaded": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahranie videa zlyhalo."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím, skúste to znova."])}
      }
    },
    "notifications": {
      "thankYouForReportingUser": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďakujeme za nahlásenie používateľa."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náš tím teraz nahlásenie preverí."])}
      },
      "yourAccountWasDeleted": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš účet bol zmazaný."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prajeme všetko dobré."])}
      },
      "theUserWasBlocked": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užívateľ bol zablokovaný."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadne ďalšie správy vám neprídu."])}
      },
      "theUserWasUnblocked": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užívateľ bol odblokovaný."])}
      },
      "theChatWasDeleted": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzácia bola zmazaná."])}
      },
      "youHaveLeftTheGroupConversation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzácia bola opustená."])}
      }
    }
  },
  "welcome": {
    "welcomeInMait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitaj v chate MAIT!"])},
    "loginWithMaitAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihlásiť sa MAIT účtom"])},
    "loginWithPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihlásiť sa telefónnym číslom"])},
    "noAccountYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ešte nemáš účet?"])},
    "inApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do aplikácie."])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registruj sa"])},
    "loginWithCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre prihlásenie do aplikácie použi údaje k svojmu účtu MAIT."])},
    "unsuccessfulLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedarí sa prihlásiť? Skúste "])},
    "restorePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obnoviť heslo."])},
    "loginWithPhoneCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na prihlásenie použi svoje telefónne číslo. Následne ti naň zašleme overovací kód."])},
    "confirmLoginWithPhoneCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po zadaní overovacieho kódu, ktorý sme ti práve zaslali v SMS, sa môžeš prihlásiť."])},
    "sendCodeToPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaslať overovaciu SMS"])}
  },
  "forms": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hľadať"])},
    "searchTenor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hľadajte v databáze Tenor"])}
  },
  "settings": {
    "personalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobné údaje"])},
    "personalDetailsContent": {
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priezvisko"])},
      "saveDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložiť údaje"])},
      "deleteAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušiť účet"])},
      "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno a priezvisko"])}
    },
    "connectPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napojiť telefónne číslo"])},
    "connectPhoneNumberContent": {
      "yourPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše telefónne číslo"])},
      "savePhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložiť telefónne číslo"])},
      "deletePhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstrániť telefónne číslo"])},
      "confirmation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overenie"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na overenie použi overovací kód, ktorý sme ti práve zaslali v SMS."])}
      },
      "connectNewPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napojiť nové telefónne číslo"])}
    },
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upozornenie"])},
    "notificationsContent": {
      "sounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvuky"])},
      "hideNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezobrazovať žiadne oznámenia"])},
      "notificationBarNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámenie v notifikačnej lište"])},
      "incomingCallRing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyzváňanie pre prichádzajúci hovor"])},
      "switch": {
        "unused": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby vám nič neuniklo..."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosíme, povoľte notifikácie. Bez nich sa vám aplikácia nebude môcť posielať oznámenie o tom, čo sa v nej udialo nového."])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povoliť notifikácie"])},
          "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovať bez povolenia"])}
        },
        "enabled": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikácie už sú povolené."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teraz vám už nič neunikne. Žiadny strach! Ak by vás notifikácie rušili, zakážete ich ľahko v nastavení telefónu."])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Späť do nastavenia"])}
        },
        "disabled": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikácie nie sú povolené."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby ste dosiahli zobrazovanie nových notifikácií, choďte do systémového nastavenia. V sekcii jednotlivých aplikácií nájdite MAIT Chat a povoľte notifikácie."])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Späť do nastavenia"])}
        }
      }
    },
    "transferContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preniesť kontakty"])},
    "transferContactsContent": {
      "switch": {
        "unused": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepojte kontakty z telefónu."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIT Chat získa prístup k vášmu zoznamu kontaktov a odošle telefónne čísla na stránku mait.me, aby vyhľadal existujúce kontakty v sieti MAIT. Zoznam kontaktov je prístupný vždy po otvorení aplikácie. Telefónne čísla nie sú uložené na stránke mait.me. Súhlasíte s udelením prístupu?"])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súhlasím"])},
          "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie teraz"])}
        },
        "enabled": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše kontakty sú už prepojené."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyzerá to, že ste si kontakty preniesli už skôr. Všetky svoje prepojené kontakty nájdete pri vytváraní novej konverzácie."])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Späť do nastavenia"])}
        },
        "disabled": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše kontakty nie sú prepojené."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby ste dosiahli prepojenie kontaktov, prejdite do systémového nastavenia. V sekcii jednotlivých aplikácií nájdite MAIT Chat a povoľte prenos kontaktov."])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Späť do nastavenia"])}
        }
      }
    },
    "profilePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilový obrázok"])},
    "profilePictureContent": {
      "changeProfilePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmeniť profilový obrázok"])},
      "deleteProfilePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstrániť profilový obrázok"])}
    },
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásiť sa"])},
    "activateAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivovať účet MAIT"])},
    "activateAccountContent": {
      "activationOfAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivácia účtu MAIT"])}
    },
    "deleteAccountContent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veľmi nás mrzí, že odchádzate."])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naozaj chcete zrušiť účet? Táto akcia je nevratná a k svojmu účtu sa už nebude môcť prihlásiť."])},
      "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie, chcem účet zachovať."])},
      "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áno, chcem zrušiť účet."])}
    },
    "changeLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmena jazyka"])},
    "changeLanguageContent": {
      "czech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Česky"])},
      "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "slovak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovensky"])},
      "ukrainian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["украинский"])}
    },
    "reportError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlásiť chybu"])},
    "reportErrorContent": {
      "messageFromApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správa z aplikácie"])}
    },
    "termsAndConditions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podmienky služby"])},
      "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zásady ochrany osobných údajov"])},
      "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podmienky používania"])}
    },
    "chat": {
      "blockUser": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokovať používateľa"])},
        "blockingMessages": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokovanie správ"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z tohto chatu už nebudete môcť písať ani prijímať správy. V skupinových konverzáciách ale spolu stále môžete komunikovať."])}
        },
        "blockCanBeReverted": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokovanie môžete kedykoľvek zrušiť"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V nastavení konverzácie môžete blokovanie kedykoľvek zrušiť."])}
        }
      },
      "createGroupChat": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť skupinový chat"])},
        "contentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová skupina"])},
        "usersRecommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Návrhy používateľov"])},
        "createGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť"])}
      },
      "unblockUser": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odblokovať používateľa"])},
        "unblockingMessages": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odblokovanie správ"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po odblokovaní budete medzi sebou opäť môcť posielať správy, fotografie alebo súbory."])}
        },
        "secondaryButton": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovať v blokovaní"])}
        }
      },
      "reportUser": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlásiť konverzáciu"])},
        "foundInappropriateContent": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Narazili ste na nevhodný obsah?"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak ste si istí, že správy od používateľa sú obťažujúce, nevhodné alebo nebezpečné, dajte nám týmto vedieť."])}
        },
        "weValueYourSafety": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ctíme vašu bezpečnosť"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užívateľ sa nedozvie, kto ho nahlásil. Nemusíte sa teda báť následkov."])}
        }
      },
      "deleteChat": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstrániť konverzáciu"])},
        "deleteAllMessages": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstránenie všetkých správ"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevratne zmažete všetky správy. Preto si dobre rozmyslite, či túto akciu naozaj chcete vykonať."])}
        },
        "photosAndFiles": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotografie a súbory"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majte na pamäti, že spolu so správami prídete aj o obrázky, videá alebo dokumenty."])}
        }
      },
      "leaveGroupConversation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opustiť skupinu"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opustiť konverzáciu"])},
        "leavingConversation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opustenie konverzácie"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keď opustíte konverzáciu, môžete sa vrátiť len na základe pozvánkou od členov skupiny."])}
        },
        "yourMessagesRemain": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše správy naďalej zostávajú"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správy, ktoré ste odoslali pred opustením skupiny v nej naďalej zostanú aj po vašom odchode."])}
        }
      },
      "renameGroup": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmeniť názov chatu"])},
        "groupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov chatu"])}
      },
      "showUsers": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť členov"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Členovia skupiny"])},
        "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pridať"])},
        "options": {
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uloženie zmien"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušiť"])}
        },
        "redirectModal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naozaj chcete odísť?"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V zozname členov skupiny máte neuložené zmeny, ktoré sa stratia."])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložte zmeny a odíďte"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odísť bez uloženia"])}
        }
      },
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušiť"])},
      "with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s"])}
    }
  },
  "onboarding": {
    "welcome": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitajte v MAIT chate!"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Než začneme, potrebovali by sme od Vás povolenie k niektorým službám, aby aplikácia fungovala správne."])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poďme začať"])}
    }
  },
  "countryCodes": {
    "afghanistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afganistan"])},
    "albania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albánsko"])},
    "algeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alžírsko"])},
    "samoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Americká Samoa"])},
    "the american virgin islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Americké Panenské ostrovy"])},
    "andorra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
    "angola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "antigua and barbuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua a Barbuda"])},
    "anguilla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguilla"])},
    "argentina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentína"])},
    "armenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arménsko"])},
    "aruba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
    "ascension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascension"])},
    "australia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austrália"])},
    "australian territories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austrálske teritóriá"])},
    "azerbaijan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbajdžan"])},
    "bahrain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrajn"])},
    "bahamas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamy"])},
    "bangladesh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladéš"])},
    "barbados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
    "belgium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgicko"])},
    "belize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
    "belarus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bielorusko"])},
    "benin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
    "bermuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermudy"])},
    "bhutan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhután"])},
    "bolivia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolívia"])},
    "bosnia and herzegovina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosna a Hercegovina"])},
    "botswana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botswana"])},
    "brazil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brazília"])},
    "virgin islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Britské Panenské ostrovy"])},
    "brune": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunej"])},
    "bulgaria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulharsko"])},
    "burkina faso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
    "burundi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "cook islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookove ostrovy"])},
    "cad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čad"])},
    "montenegro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čierna Hora"])},
    "czech republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Česká republika"])},
    "china": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čína"])},
    "denmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dánsko"])},
    "democratic republic of congo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demokratická republika Kongo"])},
    "diego garcia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diego García"])},
    "dominica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominika"])},
    "dominican republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominikánska republika"])},
    "djibout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Džibutsko"])},
    "egypt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egypt"])},
    "ecuador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekvádor"])},
    "eritrea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritrea"])},
    "estonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estónsko"])},
    "ethiopia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiópia"])},
    "faroe islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faerské ostrovy"])},
    "fiji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fidži"])},
    "philippines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filipíny"])},
    "finland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fínsko"])},
    "france": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francúzsko"])},
    "french guiana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francúzska Guyana"])},
    "french polynesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francúzska Polynézia"])},
    "gabon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
    "gambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambia"])},
    "ghana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
    "gibraltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibraltár"])},
    "grenada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenada"])},
    "greenland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grónsko"])},
    "georgia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruzínsko"])},
    "guadeloupe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadeloupe"])},
    "guam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
    "guatemala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea"])},
    "guinea-bissau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea-Bissau"])},
    "guyana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
    "haiti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haiti"])},
    "honduras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "hong kong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hongkong"])},
    "chile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čile"])},
    "croatia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chorvátsko"])},
    "india": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["India"])},
    "indonesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonézia"])},
    "iraq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irak"])},
    "iran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irán"])},
    "ireland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írsko"])},
    "iceland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Island"])},
    "italy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taliansko"])},
    "israel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izrael"])},
    "jamaica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamajka"])},
    "japan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japonsko"])},
    "yemen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jemen"])},
    "south african republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juhoafrická republika"])},
    "south korea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Južná Kórea"])},
    "cayman islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kajmanie ostrovy"])},
    "jordan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordánsko"])},
    "cambodia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kambodža"])},
    "cameroon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamerun"])},
    "canada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanada"])},
    "kazakhstan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazachstan"])},
    "kenya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keňa"])},
    "kiribati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "colombia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolumbia"])},
    "congo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kongo"])},
    "costa rica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostarika"])},
    "cuba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuba"])},
    "kuwait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuvajt"])},
    "cyprus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyprus"])},
    "kyrgyzstan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirgizsko"])},
    "laos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "lesotho": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
    "lebanon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libanon"])},
    "liberia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libéria"])},
    "libya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Líbya"])},
    "lichtenstein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lichtenštajnsko"])},
    "lithuanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litva"])},
    "latvia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lotyšsko"])},
    "luxembourg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembursko"])},
    "madagascar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagaskar"])},
    "hungary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maďarsko"])},
    "macedonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macedónsko"])},
    "malaysia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malajzia"])},
    "malawi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
    "maldives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maledivy"])},
    "mali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "malta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "morocco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maroko"])},
    "marshall islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marshallove ostrovy"])},
    "martinik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinik"])},
    "mauritius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maurícius"])},
    "mauritania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritánia"])},
    "mexico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexiko"])},
    "moldova": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldavsko"])},
    "micronésia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mikronézia"])},
    "monaco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monako"])},
    "mongolia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolsko"])},
    "montserrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
    "mosambique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambik"])},
    "myanmar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mjanmarsko"])},
    "namibia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namíbia"])},
    "nura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemecko"])},
    "nepal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepál"])},
    "niger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
    "nicaragua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nikaragua"])},
    "nigeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigéria"])},
    "niue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
    "netherlands antilles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holandské Antily"])},
    "netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holandsko"])},
    "norway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nórsko"])},
    "new caledonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová Kaledónia"])},
    "oman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omán"])},
    "new zealand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nový Zéland"])},
    "pakistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
    "palau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
    "palestine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palestína"])},
    "panama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
    "paraguay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguaj"])},
    "peru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
    "ivory coast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobrežie Slonoviny"])},
    "poland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poľsko"])},
    "puerto rico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portoriko"])},
    "portugal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugalsko"])},
    "austria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakúsko"])},
    "equatorial guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rovníková Guinea"])},
    "romania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rumunsko"])},
    "rwanda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rwanda"])},
    "saint pierre and miquelon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svätý Peter a Michal"])},
    "greece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grécko"])},
    "salvador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvádor"])},
    "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "san marino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Maríno"])},
    "saudi arabia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudská Arábia"])},
    "senegal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
    "north korea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Severná Kórea"])},
    "seychelles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychely"])},
    "sierra leone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leone"])},
    "singapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapur"])},
    "slovakia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovensko"])},
    "slovenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovinsko"])},
    "somalia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somálsko"])},
    "united arab emirates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spojené arabské emiráty"])},
    "united kingdom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spojené kráľovstvo"])},
    "serbia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Srbsko"])},
    "sri lanka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Srí Lanka"])},
    "central african republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stredoafrická republika"])},
    "sudan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudán"])},
    "saint helena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svätá Helena"])},
    "saint lucia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svätá Lucia"])},
    "saint kitts and nevis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svätý Krištof a Nevis"])},
    "saint thomas and prince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svätý Tomáš a Princov ostrov"])},
    "saint vincent and the grenadines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svätý Vincent a Grenadíny"])},
    "syria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sýria"])},
    "solomon islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šalamúnove ostrovy"])},
    "spain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Španielsko"])},
    "sweden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Švédsko"])},
    "switzerland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Švajčiarsko"])},
    "tajikistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tadžikistan"])},
    "tanzania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzánia"])},
    "thailand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thajsko"])},
    "taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan"])},
    "togo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "tonga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "trinidad and tobago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad a Tobago"])},
    "tunisia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisko"])},
    "turkey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turecko"])},
    "turkmenistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkménsko"])},
    "tuvalu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "uganda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
    "ukraine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrajina"])},
    "uruguay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguaj"])},
    "uzbekistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzbekistan"])},
    "vanuatu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "venezuela": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
    "vietnam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
    "east timor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Východný Timor"])},
    "wallis and futuna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis a Futuna"])},
    "zambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambia"])},
    "zimbabwe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabwe"])},
    "american samoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Americká Samoa"])},
    "british indian ocean territory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Britské indickooceánske územie"])},
    "falkland islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falklandy"])},
    "guernsey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guernsey"])},
    "isle of man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostrov Man"])},
    "jersey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersey"])},
    "cape verde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapverdy"])},
    "qatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katar"])},
    "christmas island": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vianočný ostrov"])},
    "cocos island": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokosový ostrov"])},
    "kosovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosovo"])},
    "comoros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komorské ostrovy"])},
    "macau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macao"])},
    "mayotte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotte"])},
    "papua new guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua Nová Guinea"])},
    "pitcairn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitcairn"])},
    "reunion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réunion"])},
    "russia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rusko"])},
    "northern mariana islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Severné Mariany"])},
    "south sudan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Južný Sudán"])},
    "suriname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surinam"])},
    "saint barthelemy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svätý Bartolomej"])},
    "saint martin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svätý Martin"])},
    "swaziland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svazijsko"])},
    "svalbard and jan mayen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Špicbergy a Jan Mayen"])},
    "tokelau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "turks and caicos islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turks a Caicos"])},
    "united states of america": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spojené štáty"])},
    "vatican": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vatikán"])},
    "western sahara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Západná Sahara"])},
    "falkland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falklandy"])},
    "capsule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapverdy"])},
    "catarrh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katar"])},
    "chamber and mayotte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komory a Mayotte"])},
    "macao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macao"])},
    "papua-nova guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua-Nová Guinea"])},
    "réunion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réunion"])},
    "northern marian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Severné Mariany"])},
    "surinam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surinam"])},
    "savilia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svazijsko"])},
    "tokelu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "turks and caicos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turks a Caicos"])}
  },
  "versions": {
    "whatIsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čo je nové v MAIT Chate"])},
    "registrationOverPhone": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrácia cez telefónne číslo"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rýchla registrácia bez zbytočných prieťahov. Novo stačí na registráciu do MAIT len telefónne číslo."])}
    },
    "communicationWithPhoneContacts": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komunikácia s mobilnými kontaktmi"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buďte v kontakte aj s priateľmi, ktoré máte uložené v telefóne, ale nemajú MAIT účet."])}
    },
    "improvedVideoDisplaying": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vylepšené zobrazovanie videí"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sledujte pohodlne nahrané videá. Pridali sme fullscreen režim pre videá na šírku aj na výšku."])}
    },
    "callWithYourFriends": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavolajte svojim priateľom"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIT Chat teraz podporuje audio a video hovory s vašimi priateľmi v sieti MAIT."])}
    },
    "groupCalls": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupinové volania a videohovory"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V aplikácii MAIT Chat môžete teraz uskutočňovať hovory aj v rámci skupinových konverzácií."])}
    },
    "improvementsAndFixes": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vylepšenia a opravy"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pod kapotou sme opravili niekoľko chýb a pridali rôzne vylepšenia pre lepší zážitok."])}
    }
  },
  "message-left-sidebar": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzácie"])},
    "search-bar-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komu"])}
  },
  "message-header": {
    "header-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komu"])}
  },
  "user-list": {
    "multiselect-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte"])}
  }
}