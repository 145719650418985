<template>
  <GenericButton :variant="variant" :text="text" :on-click="handleRedirect">
    <slot />
  </GenericButton>
</template>

<script setup lang="ts">
import GenericButton from "@/components/buttons/GenericButton.vue";
import { GenericButtonVariantEnum } from "@/types/button";
import { useRouter } from "vue-router";

interface Props {
  variant: GenericButtonVariantEnum;
  text?: string;
  to?: string;
}

const props = withDefaults(defineProps<Props>(), {
  variant: GenericButtonVariantEnum.Primary,
  text: "",
  to: "",
});
const router = useRouter();

const handleRedirect = () => {
  router.push(props.to);
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/buttons/linkButton.scss";
</style>
