import { RawMessage } from "@/store/chats/types";
import { safeParseAttachment } from "./safeParseAttachment";
import { getIsRawMessageTextType } from "./getIsRawMessageTextType";

// this is an utility to fix problem with not displaying images stored in firebase storage, but with incorrectly generated stable source url
export const safeParseRawMessageWithAttachments = (
  rawMessage: RawMessage,
): RawMessage => {
  if (!getIsRawMessageTextType(rawMessage)) {
    return rawMessage;
  }

  const attachments = rawMessage.attachments;
  if (!attachments?.length) {
    return rawMessage;
  }

  const parsedAttachments = attachments.map(safeParseAttachment);

  return { ...rawMessage, attachments: parsedAttachments };
};
