import store, { MutationTypes } from "@/store";
import { getAppliedLayoutMessageHeight } from "./getAppliedLayoutMessageHeight";

export const setLayoutMessageHeight = () => {
  const layoutType = store.getters.GET_LAYOUT_TYPE;
  const blockingStatus = store.getters.GET_SELECTED_CHAT_BLOCKING_STATUS;
  const isDeleted = store.getters.GET_SELECTED_CHAT_IS_DELETED;

  const messageInputHeight = getAppliedLayoutMessageHeight(
    layoutType,
    blockingStatus,
    isDeleted,
  );

  store.commit(
    MutationTypes.SET_LAYOUT_MESSAGE_INPUT_HEIGHT,
    messageInputHeight,
  );
};
