<template>
  <div class="what-is-new-container">
    <div class="background" />
    <div class="content">
      <div class="inner">
        <div class="top">
          <h3 class="heading">{{ t("versions.whatIsNew") }}</h3>
          <div class="points">
            <div v-for="point in pointsArray" :key="point.key" class="point">
              <Icon :icon="point.key as IconType" />
              <div class="right">
                <h4 class="title">
                  {{ getTranslatedString(point.key, "title") }}
                </h4>
                <p class="text">
                  {{ getTranslatedString(point.key, "text") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <GenericButton
            :variant="GenericButtonVariantEnum.Primary"
            text="auth.buttons.continueToApp"
            :on-click="handleContinue"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import GenericButton from "@/components/buttons/GenericButton.vue";
import Icon from "@/components/icons/Icon/Icon.vue";
import { MutationTypes } from "@/store";
import { GenericButtonVariantEnum } from "@/types/button";
import { IconType } from "@/types/icons";
import { handleSetLastSeenVersion } from "@/utils/api";
import {
  getNewestAppVersionPointsArray,
  getNewestAppVersionString,
} from "@/utils/app";
import { getIconPath } from "@/utils/app/getIconPath";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
const { t } = useI18n();
const { commit } = useStore();

const getTranslatedString = (pointKeyString: string, property: string) => {
  return t(`versions.${pointKeyString}.${property}`);
};

const pointsArray = getNewestAppVersionPointsArray();
const versionString = getNewestAppVersionString();

const handleContinue = () => {
  handleSetLastSeenVersion(versionString);
  commit(MutationTypes.HIDE_POPUP);
};
</script>
<style lang="scss" scoped>
@import "./PopupWhatIsNew.scss";
</style>
