import { HttpRequestMethodEnum } from "@/types/api";
import { IUpdateUserRequestData, IUpdateUserResponseData } from "@/types/auth";
import { request } from "../api";
import { postWebviewMessage } from "@/utils/api";
import { BridgeActionEnum } from "@/types/webview";
import { config } from "@/config";

export const updateUserAsync = async (
  accessToken: string,
  requestData: IUpdateUserRequestData,
) => {
  try {
    const query = await request<IUpdateUserResponseData>(
      config.api.maitrejaApi.endpoints.updateUser,
      HttpRequestMethodEnum.POST,
      requestData,
      accessToken,
    );

    const { name, photo } = requestData;

    postWebviewMessage({
      action: BridgeActionEnum.UpdateUser,
      userName: name,
      userPictureUrl: photo,
    });
    return query;
  } catch (e) {
    console.log(e);
  }
};
