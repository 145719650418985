import { PhoneContact } from "@/store/users/state";
import {
  IUserPhoneContactUser,
  IUserPhoneContactUserResponseObject,
} from "@/types/users";
import { getExtendedFriends } from "./getExtendedFriends";
import { getFilteredPhoneContacts } from "./getFilteredPhoneContacts";
import { getPhoneContactUsers } from "./getPhoneContactUsers";

export const getUpdatePhoneContactsProperties = (
  contactUsers: IUserPhoneContactUserResponseObject[],
  filteredPhoneContacts: PhoneContact[],
) => {
  const phoneContactUsersInitial = getPhoneContactUsers(
    contactUsers,
    filteredPhoneContacts,
  );
  const filPhoneContacts = getFilteredPhoneContacts(
    contactUsers,
    filteredPhoneContacts,
  );
  const extendedFriends = getExtendedFriends(contactUsers);
  const filteredPhoneContactUsers = phoneContactUsersInitial
    .map((contactUser) => {
      const isFriend = extendedFriends.some(
        (friend) => friend.id === contactUser.id,
      );
      if (!isFriend) {
        return contactUser;
      }
    })
    .filter(Boolean) as IUserPhoneContactUser[];

  return {
    updatedPhoneContactUsers: filteredPhoneContactUsers,
    updatedFriends: extendedFriends,
    updatedPhoneContacts: filPhoneContacts,
  };
};
