import {
  OnboardingRouteItem,
  OnboardingRouteItemNameEnum,
} from "@/types/onboarding";

export const onboardingRoutesArray: OnboardingRouteItem[] = [
  {
    name: OnboardingRouteItemNameEnum.Welcome,
    path: "/register/welcome",
  },
  {
    name: OnboardingRouteItemNameEnum.Notifications,
    path: "/register/notifications",
  },
  {
    name: OnboardingRouteItemNameEnum.TransferContacts,
    path: "/register/transfer-contacts",
  },
];
