<template>
  <PopupInfo
    title="popup.PopupMuteChat.title"
    label="popup.PopupMuteChat.label"
    btn-text="popup.PopupMuteChat.btnText"
    :action="clickAction"
  />
</template>

<script>
import PopupInfo from "@/components/popup/PopupInfo.vue";
export default {
  methods: {
    clickAction() {},
  },
};
</script>
