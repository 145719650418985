import {
  getPhoneStringPropertiesFromLocaleRegionCode,
  getParsedPhoneNumberString,
  getPhoneNumberPropertiesFromFullString,
} from "../modifiers";

export const getPhoneContactPhoneNumberProperties = (
  phone: string,
  regionCode: string,
) => {
  let { countryCode, phoneNumber } =
    getPhoneStringPropertiesFromLocaleRegionCode(phone, regionCode);

  if (phone.startsWith("+")) {
    const phoneWithCountryCode = `+${getParsedPhoneNumberString(phone)}`;
    const { countryCode: countryCodeFull, phoneNumber: phoneNumberFull } =
      getPhoneNumberPropertiesFromFullString(phoneWithCountryCode);
    countryCode = countryCodeFull;
    phoneNumber = phoneNumberFull;
  }

  return { countryCode, phoneNumber };
};
