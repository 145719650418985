<template>
  <EditChatMobileContainer />
</template>
<script setup lang="ts">
import EditChatMobileContainer from "@/components/chat/chatContainer/EditChatMobileContainer/EditChatMobileContainer.vue";
import { ChatTypes } from "@/store/chats/types";
import { chatTypeInjectionKey } from "@/utils/chat/chatTypeInjectionKey";
import { provide } from "vue";

provide(chatTypeInjectionKey, {
  type: ChatTypes.GROUP_CHAT,
});
</script>
