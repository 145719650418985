import { IconType } from "@/types/icons";
import { IListItem, ListItemTypeEnum } from "@/types/list";

export const settingsNotificationsItems: IListItem[] = [
  {
    text: "settings.notificationsContent.sounds",
    iconName: IconType.RING_BLACK,
    type: ListItemTypeEnum.Checkmark,
    onClick: () => {},
  },
  {
    text: "settings.notificationsContent.notificationBarNotifications",
    iconName: IconType.CARD,
    type: ListItemTypeEnum.Checkmark,
    onClick: () => {},
  },
  {
    text: "settings.notificationsContent.hideNotifications",
    iconName: IconType.NO_NOTIFY_BLACK,
    type: ListItemTypeEnum.Checkmark,
    onClick: () => {},
  },
  {
    text: "settings.notificationsContent.incomingCallRing",
    iconName: IconType.CALLS_BLACK,
    type: ListItemTypeEnum.Checkmark,
    onClick: () => {},
  },
];
