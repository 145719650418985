<template>
  <span class="emoji-item" :style="styleObj" @click="insertEmoji(emoji)">
    {{ emoji }}
  </span>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Emits {
  (e: "insert-emoji", emoji: string): void;
}
interface Props {
  emoji: string;
  size?: number;
}

const emit = defineEmits<Emits>();
const props = defineProps<Props>();

const insertEmoji = (emoji: string) => {
  emit("insert-emoji", emoji);
};

const sizeString = computed(() => `${props.size}px`);
const styleObj = computed(() => {
  if (!props.size) return {};
  return {
    fontSize: sizeString.value,
    height: sizeString.value,
    width: sizeString.value,
    lineHeight: sizeString.value,
  };
});
</script>

<style lang="scss" scoped>
@import "./EmojiItem.scss";
</style>
