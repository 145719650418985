import { config } from "@/config";
import { chatApiAxios } from "@/plugins";
import { HttpRequestMethodEnum } from "@/types/api";
import { ISendMessageReactionArgs } from "@/types/chat";

interface RemoveMessageReactionArgs
  extends Omit<ISendMessageReactionArgs, "reaction"> {
  accessToken: string;
}

export const removeMessageReaction = async ({
  chatId,
  messageId,
  accessToken,
}: RemoveMessageReactionArgs) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  const res = await chatApiAxios(
    config.api.chatApi.endpoints.message.removeReaction,
    {
      method: HttpRequestMethodEnum.POST,
      headers,
      data: {
        chatId,
        messageId,
      },
    },
  );

  return res.data;
};
