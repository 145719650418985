<template>
  <OnboardingContainer
    title="onboarding.welcome.title"
    text="onboarding.welcome.text"
    :primary-button="primaryButton"
    image-name="welcome.jpg"
    component-name="welcome"
  />
</template>
<script setup lang="ts">
import OnboardingContainer from "@/components/onboarding/OnboardingContainer/OnboardingContainer.vue";
import {
  OnboardingContainerTypeEnum,
  OnboardingRouteItemNameEnum,
} from "@/types/onboarding";

import { provide, computed } from "vue";
import { onboardingInjectionKey } from "@/utils/onboarding/onboardingInjectionKey";
import { ButtonObject } from "@/types/button";
import { handleOnboardingContinue } from "@/utils/settings";

provide(onboardingInjectionKey, {
  type: OnboardingContainerTypeEnum.Onboarding,
  routeName: OnboardingRouteItemNameEnum.Welcome,
});

const primaryButton = computed<ButtonObject>(() => {
  return {
    handleClick: handleContinue,
    text: "onboarding.welcome.continue",
  };
});

const handleContinue = () => {
  handleOnboardingContinue(OnboardingRouteItemNameEnum.Welcome);
};
</script>
<style lang="scss" scoped></style>
