import store, { GetterTypes } from "@/store";
import { JointParticipant, RoomParticipant } from "@/types/call";

export const convertParticipantToRoomParticipant = (
  participant: JointParticipant,
): RoomParticipant => {
  const user = store.getters[GetterTypes.GET_USER](
    Number(participant.identity),
  );
  if (!user) {
    throw new Error(`Participant ${participant.identity} not found`);
  }

  return {
    participant,
    user,
    isConnected: true,
  };
};
