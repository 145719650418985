<template>
  <div class="option-card-select" @click="onClick">
    {{ $t(text) }}
  </div>
</template>

<script>
export default {
  props: {
    text: {
      required: true,
      type: String,
    },
    onClick: {
      required: true,
      default: () => {},
      type: Function,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/cards/optionsCardSelect.scss";
</style>
