import { LayoutBackgroundColorTypes } from "@/store/app/state";

export const getBackgroundColorHexCode = (
  backgroundColorType: LayoutBackgroundColorTypes,
): string => {
  let backgroundColorHexCode: string = "#ffffff";

  switch (backgroundColorType) {
    case LayoutBackgroundColorTypes.GRAY:
      backgroundColorHexCode = "#f4f3f3";
      break;
    case LayoutBackgroundColorTypes.WHITE:
      backgroundColorHexCode = "#ffffff";
      break;
    case LayoutBackgroundColorTypes.ORANGE:
      backgroundColorHexCode = "#fdf3ec";
      break;
    case LayoutBackgroundColorTypes.ORANGE_OVERLAY:
      backgroundColorHexCode = "#7e7976";
      break;
    case LayoutBackgroundColorTypes.DARK_GRAY:
      backgroundColorHexCode = "#7f7f7f";
      break;
    case LayoutBackgroundColorTypes.BLACK_NON_TRANSPARENT:
      backgroundColorHexCode = "#221d1a";
      break;
    case LayoutBackgroundColorTypes.BLACK:
      backgroundColorHexCode = "#1e292f";
      break;
    case LayoutBackgroundColorTypes.DARKER_GRAY:
      backgroundColorHexCode = "#424448";
      break;
  }

  return backgroundColorHexCode;
};
