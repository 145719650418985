import { Message, AttachmentTypes } from "@/store/chats/types";
import { showMessageDate } from "./showMessageDate";
import { getIsMessageTextType } from "./getIsMessageTextType";

export const getMessageClass = (
  messages: Message[],
  currentMessageIndex: number,
  authId: number,
): string => {
  let classes = "";

  const messageSenderId = messages[currentMessageIndex].senderId;
  const previousMessageSenderId = messages?.[currentMessageIndex - 1]?.senderId;
  const nextMessageSenderId = messages?.[currentMessageIndex + 1]?.senderId;
  const currentMessage = messages[currentMessageIndex];

  classes += messageSenderId === authId ? "my-message " : "not-my-message ";

  if (messages.length === 1) return classes;

  const showDateCurrent = showMessageDate(messages, currentMessageIndex);

  if (
    getIsMessageTextType(currentMessage) &&
    Number(currentMessage?.attachments?.length) > 1 &&
    currentMessage?.attachments?.[0].type === AttachmentTypes.IMAGE
  ) {
    return classes + "center";
  }

  if (
    currentMessageIndex === messages.length - 1 &&
    !showDateCurrent &&
    messageSenderId === previousMessageSenderId
  )
    return (classes += " bottom");

  if (currentMessageIndex === messages.length - 1) return classes;

  const showDateNext = showMessageDate(messages, currentMessageIndex + 1);

  if (
    (showDateCurrent || messageSenderId !== previousMessageSenderId) &&
    (showDateNext || messageSenderId !== nextMessageSenderId)
  )
    return classes;

  if (
    !showDateCurrent &&
    !showDateNext &&
    messageSenderId === previousMessageSenderId &&
    messageSenderId === nextMessageSenderId
  )
    return classes + "center";

  if (showDateCurrent || messageSenderId !== previousMessageSenderId)
    return classes + "top";

  if (showDateNext || messageSenderId !== nextMessageSenderId)
    return classes + "bottom";

  return classes;
};
