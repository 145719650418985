<template>
  <div class="onboarding-container" :class="injected.type">
    <div class="onboarding-content">
      <div v-if="!isOnboarding" class="top-bar">
        <Icon
          :icon="IconType.BACK_CIRCLE"
          class="icon"
          @click="handleRedirectBack"
        />
      </div>
      <div class="top">
        <div class="image-container">
          <img :src="imageSrc" :alt="imageSrc" />
          <Icon
            v-if="isBadgeShown"
            :icon="appliedBadgeName"
            :alt="appliedBadgeName"
            class="img-success"
          />
        </div>
        <div v-if="title && text" class="text-container">
          <h3>
            {{ $t(title) }}
          </h3>
          <p>
            {{ $t(text) }}
          </p>
        </div>
      </div>
      <div v-if="isOnboarding" class="slider">
        <div class="slider-content">
          <div
            v-for="(item, i) in onboardingRoutes"
            :key="item.name"
            class="slider-item"
            :class="{ 'is-current': i === currentIndex }"
          />
        </div>
      </div>
    </div>
    <div class="buttons">
      <GenericButton
        :variant="GenericButtonVariantEnum.Primary"
        :text="primaryButton?.text"
        :on-click="primaryButton?.handleClick"
      />
      <GenericButton
        v-if="secondaryButton && !isSettings"
        :variant="GenericButtonVariantEnum.Terciary"
        :text="secondaryButton.text"
        :on-click="secondaryButton.handleClick"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import GenericButton from "@/components/buttons/GenericButton.vue";
import {
  OnboardingContainerTypeEnum,
  OnboardingProvideObject,
  OnboardingRouteItem,
  OnboardingRouteItemNameEnum,
} from "@/types/onboarding";

import { BridgeStatusEnum } from "@/types/webview";
import { GetterTypes } from "@/store";
import { onboardingInjectionKey } from "@/utils/onboarding/onboardingInjectionKey";
import { computed, inject } from "vue";
import { useRouter } from "vue-router";
import { useComputedValue } from "@/composables/useComputedValue";
import { ButtonObject, GenericButtonVariantEnum } from "@/types/button";
import { IconType } from "@/types/icons";
import Icon from "@/components/icons/Icon/Icon.vue";

interface Props {
  title: string;
  text: string;
  primaryButton?: ButtonObject;
  secondaryButton?: ButtonObject;
  imageName: string;
  componentName: string;
}

const injected = inject<OnboardingProvideObject>(onboardingInjectionKey, {
  type: OnboardingContainerTypeEnum.Settings,
  routeName: OnboardingRouteItemNameEnum.Welcome,
});

const router = useRouter();
const onboardingRoutes = useComputedValue<readonly OnboardingRouteItem[]>(
  GetterTypes.GET_ONBOARDING_ROUTES,
);

const currentIndex = onboardingRoutes.value.findIndex(
  (item) => item.name === injected.routeName,
);

const props = withDefaults(defineProps<Props>(), {
  title: "",
  text: "",
  primaryButton: undefined,
  secondaryButton: undefined,
  imageName: "",
  componentName: "",
});

const contactsStatus = useComputedValue<BridgeStatusEnum>(
  GetterTypes.GET_PHONE_CONTACTS_STATUS,
);
const notificationsStatus = useComputedValue<BridgeStatusEnum>(
  GetterTypes.GET_NOTIFICATIONS_STATUS,
);

const imageSrc = computed<string>(() => {
  return `/images/${props.imageName}`;
});
const isSettings = computed<boolean>(() => {
  return injected.type === OnboardingContainerTypeEnum.Settings;
});
const isOnboarding = computed<boolean>(() => {
  return injected.type === OnboardingContainerTypeEnum.Onboarding;
});
const isBadgeShown = computed<boolean>(() => {
  return (
    isSettings.value &&
    currentStatus.value &&
    currentStatus.value !== BridgeStatusEnum.Unused
  );
});
const currentStatus = computed<BridgeStatusEnum>(() => {
  if (props.componentName.includes("phoneTransfer")) {
    return contactsStatus.value;
  }

  return notificationsStatus.value;
});

const appliedBadgeName = computed<IconType>(() => {
  return IconType[
    `BADGE_${currentStatus.value.toUpperCase()}` as keyof typeof IconType
  ];
});

const handleRedirectBack = () => {
  router.back();
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/onboarding/onboardingContainer";
</style>
