export enum FormFieldType {
  Password = "password",
  Text = "text",
  Email = "email",
  Number = "number",
  Tel = "tel",
}

export interface FormField {
  field: FormFieldNameEnum;
  type: FormFieldType;
}

export enum FormType {
  Login = "login",
  Register = "register",
}

export enum FormFieldNameEnum {
  Password = "password",
  Email = "email",
  Name = "name",
  PhoneNumber = "phoneNumber",
  PhoneString = "phoneString",
}

export type FormFieldObjectType<T = string> = {
  [key in FormFieldNameEnum]?: T;
};

export type ErrorsFieldObject<
  T = FormFieldObjectType<FormInputErrorMessageTypeEnum>,
> = {
  errors: T;
};

export type FormFieldDataObject<T = FormFieldObjectType> = T &
  ErrorsFieldObject<T>;

export interface LoginFormDataObjectType {
  [FormFieldNameEnum.Password]: string;
  [FormFieldNameEnum.Email]: string;
}
export interface RegisterFormDataObjectType {
  [FormFieldNameEnum.Name]: string;
  [FormFieldNameEnum.Password]: string;
  [FormFieldNameEnum.Email]: string;
}

export interface LoginPhoneFormDataObjectType {
  [FormFieldNameEnum.PhoneNumber]: string;
}

export enum RestrictedInputKeyEnum {
  Backspace = "Backspace",
  Delete = "Delete",
  ArrowLeft = "ArrowLeft",
  ArrowRight = "ArrowRight",
}

export enum FormInputErrorMessageTypeEnum {
  InvalidPasswordLength = "invalidPasswordLength",
  InvalidEmailFormat = "invalidEmailFormat",
  InvalidPhoneNumber = "invalidPhoneNumber",
  ThisFieldIsRequired = "thisFieldIsRequired",
  None = "",
}
