<template>
  <div
    class="attachment-video"
    :class="{ vertical: isVertical }"
    :style="{
      aspectRatio,
    }"
  >
    <div ref="videoRef" class="videoContainer">
      <video-js :id="videoId" class="videoJs" />
      <div
        v-if="attachment.displayStatus === VideoDisplayStatus.Done"
        class="playBtn"
        @click="handleClick"
      >
        <div class="playBtnInner" />
      </div>
    </div>
    <div v-if="isLoading" class="dialog"></div>
    <div
      v-else-if="
        attachment.displayStatus === undefined ||
        attachment.displayStatus === VideoDisplayStatus.Error
      "
      class="dialog"
    >
      {{ $t("chat.chatContainer.VideoPlayer.error") }}
    </div>

    <Loader
      v-if="attachment.displayStatus === VideoDisplayStatus.Uploading"
      :progress="attachment.uploadProgress"
      :cancellable="attachment.isUploading"
      @abort="handleAbortUpload"
    />
    <EncodingLoader
      v-if="attachment.displayStatus === VideoDisplayStatus.Processing"
    />
  </div>
</template>

<script setup lang="ts">
import "video.js/dist/video-js.css";
import Loader from "@/components/chat/chatContainer/Loader.vue";
import { useStore } from "vuex";
import { GetterTypes } from "@/store";
import { ChatsActionTypes, ChatsMutationTypes } from "@/store/chats";
import { Attachment, TextMessage } from "@/store/chats/types";
import { computed, watch, onMounted } from "vue";
import { useComputedValue } from "@/composables";
import { VideoDisplayStatus } from "@/types/chat";
import { useVideoJsPlayerProperties } from "./useVideoJsPlayerProperties";
import EncodingLoader from "@/components/chat/chatContainer/EncodingLoader.vue";

interface VideoPlayerProps {
  message: TextMessage;
  attachment: Attachment;
}

const props = defineProps<VideoPlayerProps>();
const { commit, dispatch } = useStore();
const accessToken = useComputedValue<string>(GetterTypes.GET_AUTH_ACCESS_TOKEN);
const chatId = useComputedValue<string>(GetterTypes.GET_SELECTED_CHAT_ID);
const aspectRatio = computed(() => {
  return getAspectRatio();
});
const isVertical = computed(() => Number(aspectRatio.value) < 1);
const isLoading = computed(
  () =>
    (props.attachment.isUploading &&
      props.attachment.displayStatus === VideoDisplayStatus.Uploading) ||
    props.attachment.displayStatus === VideoDisplayStatus.Processing,
);
const videoId = computed(() => `player_${props.attachment.cdnId}`);
const attachmentIdx = computed(
  () =>
    props.message.allAttachments?.findIndex(
      (att) => att.cdnId === props.attachment.cdnId,
    ),
);
const displayStatus = computed(() => props.attachment.displayStatus);

const { videoRef, handleClick, initializePlayer } =
  useVideoJsPlayerProperties();

watch(displayStatus, () => {
  updateMetadata();
});

onMounted(() => {
  updateMetadata();
});

const updateMetadata = () => {
  const { url, thumbnailUrl } = props.attachment;
  if (displayStatus.value !== VideoDisplayStatus.Done) {
    return;
  }

  if (url && thumbnailUrl) {
    handleInitPlayer(url, thumbnailUrl);
    return;
  }
  addVideoAttachmentSources();
};

const addVideoAttachmentSources = async () => {
  const { cdnId } = props.attachment;
  if (!cdnId || attachmentIdx.value === undefined) {
    return;
  }
  const { url, thumbnailUrl } = await dispatch(
    ChatsActionTypes.UPDATE_ATTACHMENT_SOURCES,
    {
      videoCdnId: cdnId,
      accessToken: accessToken.value,
      chatId: chatId.value,
      messageId: props.message.id,
      attachmentIdx: attachmentIdx.value,
    },
  );

  handleInitPlayer(url, thumbnailUrl);
};

const handleInitPlayer = (url: string, thumbnailUrl: string) => {
  initializePlayer({
    posterSrc: thumbnailUrl,
    hlsSrc: url,
    elementId: videoId.value,
  });
};

const getAspectRatio = () => {
  if (props.attachment.dimensions) {
    const { width, height } = props.attachment.dimensions;
    const ratio = width / height;
    return ratio + "/1";
  }
  return "16/9";
};

const handleAbortUpload = () => {
  if (!props.attachment.isUploading || attachmentIdx.value === undefined) {
    return;
  }

  const args = {
    chatId: chatId.value,
    messageId: props.message.id,
    attachmentIdx: attachmentIdx.value,
  };

  dispatch(ChatsActionTypes.DELETE_ATTACHMENT, args);
  commit(ChatsMutationTypes.UPDATE_ATTACHMENT_IS_UPLOADING, {
    ...args,
    isUploading: false,
  });
};
</script>

<style lang="scss">
@import "./VideoPlayer.scss";
</style>
