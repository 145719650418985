import { getPartnerIdFromChatId } from "@/firebase/utils";
import { getUsersByIdsAsync } from "@/services/maitrejaApi";
import store from "@/store";
import { User } from "@/store/users/state";
import { IUsersInfoRequestItemObject } from "@/types/users";

export const getOneToOneChatMembersAsync = async (
  oneToOneChatIds: string[],
  accessToken: string,
) => {
  const authId = store.getters.GET_AUTH_ID;
  const userIdsArray: IUsersInfoRequestItemObject[] = oneToOneChatIds.map(
    (chatId) => {
      return {
        id: getPartnerIdFromChatId(authId, chatId),
      };
    },
  );

  const oneToOneChatMembers: User[] = await getUsersByIdsAsync(
    accessToken,
    userIdsArray,
  );
  const chatsToBeDeleted = oneToOneChatIds
    .map((chatId) => {
      const partnerId = getPartnerIdFromChatId(authId, chatId);
      const isChatExisting = oneToOneChatMembers.find(
        (member) => String(member.id) === String(partnerId),
      );

      if (isChatExisting) {
        return;
      }
      return chatId;
    })
    .filter(Boolean) as string[];

  return { oneToOneChatMembers, chatsToBeDeleted };
};
