import { store, MutationTypes, GetterTypes } from "@/store";
import { LayoutType, LayoutTypes } from "@/store/app/state";
import { Attachment } from "@/store/chats/types";
import { useComputedValue } from "@/composables/useComputedValue";
import { computed, Ref, watch } from "vue";
import { setLayoutMessageHeight } from "@/utils/chat";

interface Props {
  attachments: Attachment[];
  text: string;
}

export const useHandleModifyMessageInputHeight = (
  props: Props,
  rootRef: Ref<HTMLElement | undefined>,
) => {
  const layoutType = useComputedValue<LayoutType>(GetterTypes.GET_LAYOUT_TYPE);
  const messageInputHeight = useComputedValue<number>(
    GetterTypes.GET_LAYOUT_MESSAGE_INPUT_HEIGHT,
  );

  watch([() => props.text, () => props.attachments], () => {
    modifyHeightByRows();
  });

  const attachmentsHeightAddition = computed(() => {
    let attachmentsAddition = 56;
    if (layoutType.value === LayoutTypes.MOBILE) {
      attachmentsAddition = 62;
    }
    if (!props.attachments.length) {
      attachmentsAddition = 0;
    }

    return attachmentsAddition;
  });

  const modifyHeightByRows = () => {
    if (!props.text && !props.attachments.length) {
      setLayoutMessageHeight();
      return;
    }

    if (!rootRef.value) {
      return;
    }

    rootRef.value.style.height = "0";
    let maxHeight = 82;
    if (layoutType.value === LayoutTypes.MOBILE) {
      maxHeight = 102;
    }

    const scrollHeight = rootRef.value.scrollHeight;
    let textAreaHeight = scrollHeight;
    if (scrollHeight > maxHeight) {
      textAreaHeight = maxHeight;
    }
    const addition = layoutType.value === LayoutTypes.MOBILE ? 22 : 38;
    const messageInputHeight =
      textAreaHeight + addition + attachmentsHeightAddition.value;

    store.commit(
      MutationTypes.SET_LAYOUT_MESSAGE_INPUT_HEIGHT,
      messageInputHeight,
    );
  };

  return { layoutType, messageInputHeight, attachmentsHeightAddition };
};
