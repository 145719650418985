<template>
  <button
    :id="id"
    class="generic-button"
    :class="{ [variant]: true, 'hidden-content': isLoading }"
    :type="onClick ? 'button' : 'submit'"
    @click="onClick"
  >
    <slot v-if="isVisible" />
    <p v-if="text">{{ appliedText }}</p>
    <Icon v-if="isLoading" :icon="IconType.SPINNER" class="loading-spinner" />
  </button>
</template>

<script setup lang="ts">
import { GenericButtonVariantEnum } from "@/types/button";
import { IconType } from "@/types/icons";
import { Comment, computed, useSlots } from "vue";
import { useI18n } from "vue-i18n";
import Icon from "@/components/icons/Icon/Icon.vue";

interface Props {
  variant?: GenericButtonVariantEnum;
  text?: string;
  onClick?: () => void;
  disableTranslation?: boolean;
  id?: string;
  isLoading?: boolean;
}

const slots = useSlots();
const { t } = useI18n();

const props = withDefaults(defineProps<Props>(), {
  variant: GenericButtonVariantEnum.Primary,
  text: "",
  onClick: undefined,
  disableTranslation: false,
  id: undefined,
  isLoading: false,
});

const isVisible = computed<boolean>(() => {
  return Boolean(
    slots.default &&
      slots.default().findIndex((o) => o.type !== Comment) !== -1,
  );
});

const appliedText = computed<string>(() => {
  return props.disableTranslation ? props.text : t(props.text);
});
</script>

<style lang="scss" scoped>
@import "src/assets/scss/buttons/genericButton.scss";
</style>
