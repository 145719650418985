import i18n from "@/i18n";
import { getLanguageChangeUpdatedRefreshTokenAsync } from "@/services/maitrejaApi/auth";
import store, { ActionTypes, GetterTypes } from "@/store";
import { LocaleTypeEnum } from "@/types/localization";

export const getChangeLanguageHandler = (lang: LocaleTypeEnum) => {
  const accessToken = store.getters[GetterTypes.GET_AUTH_ACCESS_TOKEN];

  return async () => {
    try {
      if (!accessToken) {
        return;
      }
      i18n.global.locale.value = lang;
      await getLanguageChangeUpdatedRefreshTokenAsync(lang, accessToken);
      store.dispatch(ActionTypes.LOGIN_AUTH);
    } catch (err) {
      console.log(err);
    }
  };
};
