import { MessageReactions, MessageReactionsList } from "@/store/chats/types";
import { removeDuplicatesFromArray } from "../modifiers";

const MAX_VISIBLE_REACTIONS = 4;

export const getUpdatedReactionItems = (
  reactionItems: MessageReactionsList,
): MessageReactions => {
  const allItems = Object.values(reactionItems);
  const uniqueItems = removeDuplicatesFromArray(allItems);

  return {
    items: reactionItems,
    visibleItems: uniqueItems.slice(0, MAX_VISIBLE_REACTIONS),
    isCountVisible:
      allItems.length !== uniqueItems.length ||
      allItems.length > MAX_VISIBLE_REACTIONS,
    count: allItems.length,
  };
};
