import { IconType } from "./icons";
import { LocaleTypeEnum } from "./localization";

export enum ListItemTypeEnum {
  Checkmark = "Checkmark",
  Checkmarked = "Checkmarked",
  CheckmarkedDisabled = "CheckmarkedDisabled",
  Link = "Link",
  Chat = "Chat",
  Share = "Share",
  Lang = "Lang",
}

export interface IListItem {
  pictureSrc?: string;
  text: string;
  iconName?: IconType;
  isPictureDisplayed?: boolean;
  type: ListItemTypeEnum;
  link?: string;
  onClick?: () => void;
  itemId?: number;
  isOnline?: boolean;
  isHidden?: boolean;
  textColorType?: ListItemTextColorTypeEnum;
  backgroundColorType?: ListItemTextBackgroundTypeEnum;
  lang?: LocaleTypeEnum;
  isNotInvited?: boolean;
  phoneNumber?: string;
  subtext?: string;
}

export type IListItemsArray = IListItem[];

export enum ListItemTextColorTypeEnum {
  BLACK = "BLACK",
  RED = "RED",
  WHITE = "WHITE",
}
export enum ListItemTextBackgroundTypeEnum {
  ORANGE = "ORANGE",
}
