const defaultType = "image/jpeg";
export const getFileFromUrl = async (
  url: string,
  name: string | undefined = "file",
) => {
  const response = await fetch(url);
  const data = await response.blob();

  const type = data.type || defaultType;
  const extension = type.replace(/(.*)\//g, "");
  const fileName = `${name}.${extension}`;

  return new File([data], fileName, {
    type,
  });
};
