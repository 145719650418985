import { ErrorHandlerSnackbarRequestObject } from "@/store/app/state";

export const settingsConnectPhoneNumberSnackbarErrorObject: ErrorHandlerSnackbarRequestObject =
  {
    default: {
      title: "snackbar.errors.invalidSmsCode.title",
      text: "snackbar.errors.invalidSmsCode.text",
    },
    "401": {
      title: "snackbar.errors.invalidSmsCode.title",
      text: "snackbar.errors.invalidSmsCode.text",
    },
    "406": {
      title: "snackbar.errors.phoneNumberAlreadyInUse.title",
      text: "snackbar.errors.phoneNumberAlreadyInUse.text",
      timeoutSeconds: 10,
    },
  };
