import { LayoutTypes } from "@/store/app/state";
import { ChatBlockingStatusEnum } from "@/store/chats/types";

export const getAppliedLayoutMessageHeight = (
  layoutType: LayoutTypes,
  blockingStatus: ChatBlockingStatusEnum | undefined,
  isUserDeleted: boolean,
): number => {
  const hasNoBlocking =
    blockingStatus && blockingStatus !== ChatBlockingStatusEnum.None;
  let messageInputHeight = 68;
  if (layoutType === LayoutTypes.MOBILE) {
    messageInputHeight = 52;
  }
  if (hasNoBlocking || isUserDeleted) {
    messageInputHeight = 0;
  }

  return messageInputHeight;
};
