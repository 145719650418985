export enum ChatsActionTypes {
  ADD_AUTH_CHATS_LISTENER = "ADD_AUTH_CHATS_LISTENER",
  ADD_CHAT_LISTENER = "ADD_CHAT_LISTENER",
  ADD_CHAT_DATA = "ADD_CHAT_DATA",
  ADD_MESSAGES_LISTENER = "ADD_MESSAGES_LISTENER",

  CREATE_CHAT_WITH_MESSAGE = "CREATE_CHAT_WITH_MESSAGE",
  DELETE_CHAT = "DELETE_CHAT",
  UPDATE_GROUP_CHAT = "UPDATE_GROUP_CHAT",
  UPDATE_GROUP_CHAT_MEMBERS = "UPDATE_GROUP_CHAT_MEMBERS",
  LEAVE_GROUP_CHAT = "LEAVE_GROUP_CHAT",

  SEND_MESSAGE = "SEND_MESSAGE",
  MARK_MESSAGE_AS_READ = "MARK_MESSAGE_AS_READ",
  DELETE_MESSAGES = "DELETE_MESSAGES",
  UPDATE_MESSAGE_DATA = "UPDATE_MESSAGE_DATA",
  UPDATE_CHAT_DATA = "UPDATE_CHAT_DATA",
  ADD_CHAT_DATA_FROM_QUERY = "ADD_CHAT_DATA_FROM_QUERY",
  UPDATE_ATTACHMENT_UPLOAD_PROGRESS = "UPDATE_ATTACHMENT_UPLOAD_PROGRESS",
  DELETE_NOT_UPLOADED_ATTACHMENTS = "DELETE_NOT_UPLOADED_ATTACHMENTS",
  DELETE_ATTACHMENT = "DELETE_ATTACHMENT",
  UPDATE_ATTACHMENT_SOURCES = "UPDATE_ATTACHMENT_SOURCES",

  SEND_MESSAGE_REACTION = "SEND_MESSAGE_REACTION",
  REMOVE_MESSAGE_REACTION = "REMOVE_MESSAGE_REACTION",
}
