import { config } from "@/config";
import { getChatRef } from "@/firebase/ref";
import { getChatUnsubscribeSnapshotCallback } from "./getChatUnsubscribeSnapshotCallback";
import _ from "lodash";

export const getChatUnsubscribeById = (
  chatId: string,
  delayFirstFiring: boolean = false,
) => {
  const chatRef = getChatRef(chatId);
  const rawCallback = getChatUnsubscribeSnapshotCallback(chatId);
  if (delayFirstFiring) {
    const snapshotCallback = _.after(
      config.constants.AFTER_FUNCTION_COUNT,
      rawCallback,
    );

    return chatRef.onSnapshot(snapshotCallback);
  }

  return chatRef.onSnapshot(rawCallback);
};
