import { Room } from "twilio-video";
import { convertParticipantsMapToArray } from "./convertParticipantsMapToArray";
import { getLocalParticipant } from "./getLocalParticipant";
import { getRoomParticipantsArray } from "./getRoomParticipantsArray";
import { setDominantParticipant } from "./setDominantParticipant";
import { RoomData } from "@/types/call";
import { ShallowReactive } from "vue";

export const setInitialParticipantsData = (
  room: Room,
  data: ShallowReactive<RoomData>,
) => {
  const transformedParticipants = convertParticipantsMapToArray(
    room.participants,
  );
  const roomParticipants = getRoomParticipantsArray(transformedParticipants);
  const firstParticipant = roomParticipants[0];
  if (firstParticipant) {
    data.localParticipant = getLocalParticipant(room);
    setDominantParticipant(firstParticipant.participant, data);
    data.participants = roomParticipants.filter(
      (part) =>
        part.participant?.identity !== firstParticipant.participant?.identity,
    );
  }
};
