<template>
  <span style="cursor: pointer" @click="redirectToProfile">
    <img
      class="profile-image"
      :src="getChatPictureSrc(image, type)"
      :width="size"
      :height="size"
      alt="profile image"
      style="border-radius: 50%; object-fit: cover"
    />
  </span>
</template>

<script setup lang="ts">
import { getChatPictureSrc } from "@/utils/chat";
import { getPartnerIdFromChatId, isOneToOneChat } from "@/firebase/utils";
import { GetterTypes } from "@/store";
import { ChatTypes } from "@/store/chats/types";
import { useComputedValue } from "@/composables";
import { useRoute, useRouter } from "vue-router";
import { config } from "@/config";

const MAITREJA_PROFILE_URL = `${config.api.maitrejaApi.appUrl}/profil/`;

interface Props {
  image: string;
  id: string | number;
  type?: ChatTypes;
  size?: number;
  disableRedirect?: boolean;
  redirectLink?: string;
}

const props = withDefaults(defineProps<Props>(), {
  image: "",
  id: "",
  type: ChatTypes.ONE_TO_ONE_CHAT,
  size: 54,
  disableRedirect: false,
  redirectLink: "",
});

const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);
const route = useRoute();
const router = useRouter();

const getProfileLink = (): string => {
  if (props.disableRedirect) return "#";

  if (typeof props.id === "number") return MAITREJA_PROFILE_URL + props.id;

  if (isOneToOneChat(props.id)) {
    const partnerId = getPartnerIdFromChatId(authId.value, props.id);
    return MAITREJA_PROFILE_URL + partnerId;
  }

  if (props.id.length < 10) return MAITREJA_PROFILE_URL + props.id;

  return "#";
};
const redirectToProfile = () => {
  const link = getProfileLink();
  if (props.disableRedirect || link === "#") return;

  if (props.redirectLink) {
    const query = route.query;
    router.push({ path: props.redirectLink, query });
    return;
  }

  if (!window.top) {
    return;
  }
  window.top.location = link;
};
</script>
