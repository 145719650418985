<template>
  <Form @submit-form="handleSubmit">
    <Input
      v-model="email"
      name="email"
      type="email"
      placeholder="auth.email"
      required
    />
    <GenericButton
      :variant="GenericButtonVariantEnum.Primary"
      text="auth.buttons.sendLink"
    />
  </Form>
</template>

<script>
import Form from "@/components/layouts/Form.vue";
import GenericButton from "@/components/buttons/GenericButton.vue";
import { GenericButtonVariantEnum } from "@/types/button";
export default {
  components: { GenericButton },
  data() {
    return {
      email: "",
      GenericButtonVariantEnum,
    };
  },
  methods: {
    async handleSubmit() {},
  },
};
</script>

<style lang="scss" scoped></style>
