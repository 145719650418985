import { Attachment, AttachmentTypes } from "@/store/chats/types";
import { VideoDisplayStatus } from "@/types/chat";

export const getVisibleAttachments = (attachments: Attachment[] | undefined) =>
  attachments?.filter(({ type, isUploading, displayStatus }) => {
    if (type !== AttachmentTypes.VIDEO) {
      return true;
    }
    if (!isUploading && displayStatus === VideoDisplayStatus.Uploading) {
      return false;
    }

    return true;
  });
