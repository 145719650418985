import { Profile } from "@/store/auth/state";
import {
  OneToOneRawAudioMessage,
  OneToOneRawTextMessage,
} from "@/store/chats/types";

export const getReadOneToOneMessage = (
  message: OneToOneRawTextMessage | OneToOneRawAudioMessage,
  auth: Profile,
): boolean => {
  if (message.senderId === auth.id) return true;

  return !!message.read;
};
