import { WaveSurferOptions } from "wavesurfer.js";
import { getWaveSurferColors } from "./getWaveSurferColors";

interface GetWaveSurferOptions {
  isRecorder?: boolean;
  url?: string;
  isMine?: boolean;
  containerId: string;
}

const defaultWaveSurferOptions: Partial<WaveSurferOptions> = {
  height: 23,
  width: 160,
  splitChannels: [{ overlay: false }],
  normalize: false,
  cursorWidth: 0,
  barWidth: 3,
  barGap: 2,
  barRadius: 3,
  barHeight: 2.3,
  minPxPerSec: 3,
  fillParent: true,
  mediaControls: false,
  autoplay: false,
  interact: true,
  dragToSeek: true,
  hideScrollbar: true,
  audioRate: 1,
  autoScroll: true,
  autoCenter: true,
  sampleRate: 8000,
  backend: "WebAudio",
};

export const getWaveSurferOptions = ({
  containerId,
  isRecorder = false,
  url,
  isMine = false,
}: GetWaveSurferOptions): WaveSurferOptions => {
  const options: WaveSurferOptions = {
    ...defaultWaveSurferOptions,
    ...getWaveSurferColors({ isRecorder, isMine }),
    container: `#${containerId}`,
    url,
  };

  return options;
};
