<template>
  <div class="dominantSpeaker">
    <div class="avatar">
      <CallAvatar
        :size="ProfileAvatarSizeEnum.ExtraBig"
        :src="roomParticipant?.user.picture"
        :is-connected="!!props.roomParticipant?.isConnected"
      />
      <span class="participantName">{{ participantText }}</span>
    </div>
    <MediaDisplayControl
      v-if="roomParticipant?.participant?.identity"
      :key="roomParticipant?.participant?.identity"
      :room-participant="roomParticipant"
      class="dominantSpeakerRef"
    />
  </div>
</template>

<script setup lang="ts">
import { ProfileAvatarSizeEnum } from "@/types/avatar";
import { RoomParticipant } from "@/types/call";
import CallAvatar from "../CallAvatar/CallAvatar.vue";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import MediaDisplayControl from "../MediaOutputControl/MediaDisplayControl.vue";
interface Props {
  roomParticipant: RoomParticipant | null;
}
const props = withDefaults(defineProps<Props>(), {
  roomParticipant: null,
});

const { t } = useI18n();
const participantText = computed(() => {
  let text = "";
  if (!props.roomParticipant?.isConnected) {
    text += `${t("call.establishingCall")} `;
  }
  text += props.roomParticipant?.user.name;
  return text;
});
</script>

<style lang="scss">
@import "./CallDominantSpeaker.scss";
</style>
