import imageCompression from "browser-image-compression";

const OPTIMIZE_IMAGE_OPTIONS = {
  maxSizeMB: 0.25,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

export const compressImage = async (image: File): Promise<File> => {
  const blob = await imageCompression(image, OPTIMIZE_IMAGE_OPTIONS);
  return new File([blob], image.name);
};
