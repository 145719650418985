<template>
  <NoChatContainer
    v-if="!selectedChatId && layoutType !== LayoutTypes.MOBILE"
  />
  <ChatContentContainer v-if="selectedChatId && selectedChat" />
</template>

<script setup lang="ts">
import ChatContentContainer from "@/components/chat/chatContainer/ChatContentContainer/ChatContentContainer.vue";
import NoChatContainer from "@/components/chat/chatContainer/NoChatContainer.vue";
import { LayoutTypes } from "@/store/app/state";
import { GetterTypes } from "@/store";
import { Chat, ChatBlockingStatusEnum } from "@/store/chats/types";
import { useComputedValue } from "@/composables/useComputedValue";
import { onMounted, watch } from "vue";
import { setLayoutMessageHeight } from "@/utils/chat";

const selectedChatId = useComputedValue<string | undefined>(
  GetterTypes.GET_SELECTED_CHAT_ID,
);
const selectedChat = useComputedValue<Chat | undefined>(
  GetterTypes.GET_SELECTED_CHAT,
);
const layoutType = useComputedValue<LayoutTypes>(GetterTypes.GET_LAYOUT_TYPE);
const blockingStatus = useComputedValue<ChatBlockingStatusEnum>(
  GetterTypes.GET_SELECTED_CHAT_BLOCKING_STATUS,
);

watch(blockingStatus, () => {
  setLayoutMessageHeight();
});

onMounted(() => {
  setLayoutMessageHeight();
});
</script>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatContainer/chatContainer.scss";
</style>
