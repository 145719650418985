<template>
  <PopupInfo
    title="popup.PopupInfoLeaveGroup.title"
    label="popup.PopupInfoLeaveGroup.label"
    btn-text="popup.PopupInfoLeaveGroup.btnText"
    :action="clickAction"
  />
</template>

<script setup lang="ts">
import PopupInfo from "@/components/popup/PopupInfo.vue";
import { store, ActionTypes, MutationTypes } from "@/store";

const clickAction = () => {
  const chatId = store.getters.GET_SELECTED_CHAT_ID;
  // @ts-expect-error
  store.dispatch(ActionTypes.LEAVE_GROUP_CHAT, { chatId });
  store.commit(MutationTypes.HIDE_POPUP);
};
</script>
