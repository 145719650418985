import { Module } from "vuex";
import { RootState } from "@/store/state";
import { menuState, MenuState } from "./state";
import { menuActions } from "./actions";
import { menuMutations } from "./mutations";
import { menuGetters } from "./getters";

export const menu: Module<MenuState, RootState> = {
  state: menuState,
  actions: menuActions,
  getters: menuGetters,
  mutations: menuMutations,
};
