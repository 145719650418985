import { AuthMutationTypes } from "@/store/auth";
import { RoomData } from "@/types/call";
import { ShallowReactive } from "vue";
import { stopAllMediaTracks } from "./stopAllMediaTracks";
import store from "@/store";
import { removeVideoSrc } from "./removeVideoSrc";

export const disconnectFromCurrentRoom = (data: ShallowReactive<RoomData>) => {
  if (!data.room) {
    return;
  }
  const { localParticipant } = data.room;
  removeVideoSrc(localParticipant.sid);
  stopAllMediaTracks(localParticipant);
  // @ts-expect-error
  store.commit(AuthMutationTypes.REMOVE_ONGOING_CALL, data.room.name);
  data.room = null;
};
