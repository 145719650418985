export const getExistingChatIds = (
  oneToOneChatIds: string[],
  chatsToBeDeleted: string[],
) => {
  const remainingOneToOneChatIds = oneToOneChatIds
    .map((chatId) => {
      if (chatsToBeDeleted.includes(chatId)) {
        return;
      }
      return chatId;
    })
    .filter(Boolean) as string[];
  return { remainingOneToOneChatIds };
};
