import { useComputedValue } from "@/composables";
import { ActionTypes, GetterTypes } from "@/store";
import { ChatState } from "@/store/chats/types";
import { createSingleChatId } from "@/utils/chat";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useGetChangeChatMobileContainerProperties } from "../EditChatMobileContainer";

export const useGetNewChatMobileContainerProperties = () => {
  const {
    listsContainerHeight,
    friendsListItemsArray,
    otherPeopleListItemsArray,
    phoneContactsRecommendListItemsArray,
    phoneContactsUsingAppListItemsArray,
    handleRedirect,
    injected,
    data,
    handleAddGroupMember,
    handleRemoveGroupMember,
  } = useGetChangeChatMobileContainerProperties(true);

  const router = useRouter();
  const { getters, dispatch } = useStore();

  const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);

  const handleCreateChat = async () => {
    const chatGroupLength = data.selectedGroupMembers.length;
    if (chatGroupLength === 0) {
      return;
    }

    // it is a group chat
    if (chatGroupLength > 1) {
      const memberIds = data.selectedGroupMembers.map((m) =>
        m.itemId!.toString(),
      );
      const members = data.selectedGroupMembers.map((member) => {
        data.otherPeople.find((p) => p.id === member.itemId);
      });
      const chatId: string = await dispatch(
        ActionTypes.CREATE_CHAT_WITH_MESSAGE,
        {
          messageText: "",
          memberIds,
          members,
        },
      );

      router.push(`/${chatId}`);
      return;
    }
    // it is a oneToOne chat
    const member = data.selectedGroupMembers[0];
    const partner = data.otherPeople.find((p) => p.id === member.itemId);
    if (!partner) {
      return;
    }
    const partnerId = partner.id.toString();
    const chatId = createSingleChatId(authId.value.toString(), partnerId!);
    const chat: ChatState = getters.GET_CHAT(chatId);
    if (chat) {
      router.push(`/${chat.chatId}`);
      return;
    }

    const newChatId: string = await dispatch(
      ActionTypes.CREATE_CHAT_WITH_MESSAGE,
      {
        messageText: "",
        memberIds: [partnerId],
        members: [partner],
      },
    );
    router.push(`/${newChatId}`);
  };

  return {
    listsContainerHeight,
    friendsListItemsArray,
    otherPeopleListItemsArray,
    phoneContactsRecommendListItemsArray,
    phoneContactsUsingAppListItemsArray,
    handleRedirect,
    injected,
    data,
    handleAddGroupMember,
    handleRemoveGroupMember,
    handleCreateChat,
  };
};
