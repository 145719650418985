import { User } from "@/store/users/state";
import { HttpRequestMethodEnum } from "@/types/api";
import { request } from "../api/request";
import { config } from "@/config";

export const getUserByIdAsync = async (
  accessToken: string,
  userId: number,
): Promise<User | undefined> => {
  try {
    const user = await request<User | undefined>(
      config.api.maitrejaApi.endpoints.searchUserById,
      HttpRequestMethodEnum.POST,
      { search: userId },
      accessToken,
    );

    return user;
  } catch (error) {
    throw error;
  }
};
