import { IChatSettingsWarningPoint } from "@/types/chat";
import { IconType } from "@/types/icons";

export const unblockUserWarningPoints: IChatSettingsWarningPoint[] = [
  {
    title: "settings.chat.unblockUser.unblockingMessages.title",
    text: "settings.chat.unblockUser.unblockingMessages.text",
    iconName: IconType.BLOCK_GRAY,
  },
];
