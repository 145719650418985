import { config } from "@/config";
import { chatApiAxios } from "@/plugins";
import { HttpRequestMethodEnum } from "@/types/api";
import { ISendMessageReactionArgs } from "@/types/chat";

interface SendMessageReactionArgs extends ISendMessageReactionArgs {
  accessToken: string;
}

export const sendMessageReaction = async ({
  chatId,
  messageId,
  accessToken,
  reaction,
}: SendMessageReactionArgs) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  const res = await chatApiAxios(
    config.api.chatApi.endpoints.message.addReaction,
    {
      method: HttpRequestMethodEnum.POST,
      headers,
      data: {
        chatId,
        messageId,
        reaction,
      },
    },
  );

  return res.data;
};
