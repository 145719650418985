import { JointParticipant } from "@/types/call";
import { handleTrackUnsubscribed } from "./handleTrackUnsubscribed";
import { handleTrackSubscribed } from "./handleTrackSubscribed";
import { getMediaElement } from "./getMediaElement";
import { Ref } from "vue";

export const addParticipantListeners = (
  participant: JointParticipant | null,
  ref: Ref<HTMLDivElement | null>,
) => {
  if (!participant) {
    return;
  }
  const div = getMediaElement(participant.sid);
  if (!div) {
    return;
  }
  participant.on("trackSubscribed", (track) => {
    handleTrackSubscribed(track, participant);
  });
  participant.on("trackUnsubscribed", (track) => {
    handleTrackUnsubscribed(track);
  });
  ref?.value?.appendChild(div);
};
