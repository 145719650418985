<template>
  <ListItemsSelectNavigationContainer
    :navigation-title="appliedNavigationTitle"
    disable-title-translation
    :lists-array="[
      activateAccountArray,
      filteredSettingsMenuItems,
      deleteUserAccountArray,
    ]"
  />
</template>
<script setup lang="ts">
import ListItemsSelectNavigationContainer from "@/components/layouts/ListItemsSelectNavigationContainer/ListItemsSelectNavigationContainer.vue";
import { GetterTypes } from "@/store";
import { getSettingsMenuItems, deleteUserAccountArray } from "@/utils/settings";
import { useComputedValue } from "@/composables";
import { Profile } from "@/store/auth/state";
import { IListItem } from "@/types/list";
import { getActivateAccountArray } from "@/utils/settings";
import { computed } from "vue";
import { getFullPhoneNumberString } from "@/utils/form";
import { getPhoneNumberStringWithSpaces } from "@/utils/modifiers";

const filteredSettingsMenuItems: IListItem[] = getSettingsMenuItems().filter(
  // some items to be hidden outside of webview container:
  (item) => {
    // but must be visible in local dev environment:
    if (process.env.VUE_APP_ENVIRONMENT) {
      return true;
    }
    return !item.isHidden;
  },
);

const profile = useComputedValue<Profile>(GetterTypes.GET_AUTH_PROFILE);
const appliedNavigationTitle = computed<string>(() => {
  const { name, phoneNumber, countryCode, email } = profile.value;
  if (email) {
    return name;
  }
  const fullString = getFullPhoneNumberString(countryCode, phoneNumber);
  const phoneNumberStringWithSpaces =
    getPhoneNumberStringWithSpaces(fullString);
  return phoneNumberStringWithSpaces;
});
const activateAccountArray = computed<IListItem[]>(() =>
  getActivateAccountArray(),
);
</script>
<style lang="scss" scoped>
@import "src/assets/scss/settings/settingsAll";
</style>
