import { config } from "@/config";
import { chatApiAxios } from "@/plugins";
import { HttpRequestMethodEnum } from "@/types/api";
import { IGetVideoDetailsArgs, IVideoCdnDetail } from "@/types/chat";

export const getVideoDetailsBeAsync = async ({
  videoCdnId,
  accessToken,
}: IGetVideoDetailsArgs): Promise<IVideoCdnDetail> => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const res = await chatApiAxios<IVideoCdnDetail>(
    config.api.chatApi.endpoints.video.detail(videoCdnId),
    {
      method: HttpRequestMethodEnum.GET,
      headers,
    },
  );
  return res.data;
};
