<template>
  <div class="participant">
    <CallAvatar
      :size="ProfileAvatarSizeEnum.Large"
      :src="roomParticipant?.user.picture"
      :is-connected="!!props.roomParticipant?.isConnected"
    />
    <MediaDisplayControl
      v-if="roomParticipant?.participant?.identity"
      :key="roomParticipant?.participant?.identity"
      :room-participant="roomParticipant"
      class="participantRef"
    />
    <span class="participantName">{{ participantText }}</span>
  </div>
</template>

<script setup lang="ts">
import { useComputedValue } from "@/composables";
import { RoomParticipant } from "@/types/call";
import CallAvatar from "../CallAvatar/CallAvatar.vue";
import { ProfileAvatarSizeEnum } from "@/types/avatar";
import { GetterTypes } from "@/store";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import MediaDisplayControl from "../MediaOutputControl/MediaDisplayControl.vue";
interface Props {
  roomParticipant: RoomParticipant | null;
}
const props = defineProps<Props>();

const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);
const { t } = useI18n();

const participantText = computed(() => {
  let text = props.roomParticipant?.user.name;
  if (props.roomParticipant?.user.id === authId.value) {
    text += ` (${t("call.you")})`;
  }
  return text;
});
</script>

<style lang="scss">
@import "./CallParticipant.scss";
</style>
