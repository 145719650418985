export const getMediaElement = (participantSid: string | null) => {
  if (!participantSid) {
    return null;
  }
  const existingDiv = document.getElementById(participantSid) as HTMLDivElement;
  if (existingDiv) {
    return existingDiv;
  }
  const div = document.createElement("div");
  div.id = participantSid;
  div.classList.add("tracks");

  return div;
};
