<template>
  <MainLayout v-if="accessToken || layoutType === LayoutTypes.MOBILE">
    <MobileLayout v-if="isMobileLayoutDisplayed">
      <ChatSidebar />
    </MobileLayout>
    <ChatContainer />
    <FullSizeImage v-if="src" />
  </MainLayout>
  <NoChatContainer v-else-if="layoutType === LayoutTypes.DESKTOP" />
</template>

<script setup lang="ts">
import { GetterTypes } from "@/store";
import { LayoutTypes } from "@/store/app/state";
import { getIsMobileLayoutDisplayed } from "@/utils/chat";
import { useComputedValue } from "@/composables";
import { computed } from "vue";
import MobileLayout from "@/components/layouts/MobileLayout.vue";
import MainLayout from "@/components/layouts/MainLayout.vue";
import ChatContainer from "@/components/chat/chatContainer/ChatContainer/ChatContainer.vue";
import NoChatContainer from "@/components/chat/chatContainer/NoChatContainer.vue";
import FullSizeImage from "@/components/images/FullSizeImage.vue";
import ChatSidebar from "@/components/chat/chatSidebar/ChatSidebar.vue";

const selectedChatId = useComputedValue<string | undefined>(
  GetterTypes.GET_SELECTED_CHAT_ID,
);

const accessToken = useComputedValue<string | undefined>(
  GetterTypes.GET_AUTH_ACCESS_TOKEN,
);
const layoutType = useComputedValue<LayoutTypes>(GetterTypes.GET_LAYOUT_TYPE);
const src = useComputedValue<string | undefined>(
  GetterTypes.GET_FULL_SIZE_IMAGE,
);
const isMobileLayoutDisplayed = computed(() => {
  return getIsMobileLayoutDisplayed(selectedChatId.value, layoutType.value);
});
</script>

<style lang="scss" scoped>
@import "src/assets/scss/layout/mainLayout";
</style>
