import { LayoutBackgroundColorTypes } from "@/store/app/state";
import { BridgeDisplayModeEnum } from "@/types/webview";
import { isStandaloneIosAppEnvironmentContainer } from "../app";
import { handleChangeDisplayMode } from "./handleChangeDisplayMode";

export const handleSetIosContainerSafeAreaBg = (
  backgroundColorTypeTop: LayoutBackgroundColorTypes,
  backgroundColorTypeBottom: LayoutBackgroundColorTypes,
) => {
  if (isStandaloneIosAppEnvironmentContainer) {
    handleChangeDisplayMode(
      BridgeDisplayModeEnum.Container,
      backgroundColorTypeTop,
      backgroundColorTypeBottom,
    );
  }
};
