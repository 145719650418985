import { config } from "@/config";
import { chatApiAxios } from "@/plugins";
import { HttpRequestMethodEnum } from "@/types/api";
import { IDeleteMessagesObject } from "@/types/chat";

export const deleteMessagesBeAsync = async ({
  messageIds,
  firebaseIdToken,
}: IDeleteMessagesObject) => {
  const headers = {
    Authorization: `Bearer ${firebaseIdToken}`,
  };
  const res = await chatApiAxios(config.api.chatApi.endpoints.message.index, {
    method: HttpRequestMethodEnum.DELETE,
    headers,
    data: {
      messageIds,
    },
  });
  return res.data;
};
