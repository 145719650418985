import { waitForMs } from "@/utils/modifiers";
import { MutationTypes } from "..";
import { AppActionType } from "./actionState";
import { AppActionTypes } from "./actionTypes";

export const appActions: AppActionType = {
  async [AppActionTypes.MOUNT_SNACKBAR]({ commit, getters }, obj) {
    const isMounted = getters.GET_SNACKBAR_IS_MOUNTED;
    if (isMounted) {
      return;
    }

    commit(MutationTypes.SET_SNACKBAR_MOUNTED, obj);
    await waitForMs(100);
    commit(MutationTypes.SHOW_SNACKBAR);
  },
  async [AppActionTypes.UNMOUNT_SNACKBAR]({ commit }) {
    commit(MutationTypes.HIDE_SNACKBAR);
    await waitForMs(301);
    commit(MutationTypes.SET_SNACKBAR_UNMOUNTED);
  },
};
