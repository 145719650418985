<template>
  <span class="input-attachment" @click="startRecording">
    <Icon :icon="IconType.MIC" />
  </span>
</template>

<script setup lang="ts">
import Icon from "@/components/icons/Icon/Icon.vue";
import { IconType } from "@/types/icons";

interface Emits {
  (e: "start-recording"): void;
}

const emit = defineEmits<Emits>();

const startRecording = () => {
  emit("start-recording");
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatContainer/inputAttachment.scss";
</style>
