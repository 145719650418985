<template>
  <div class="country-code-search-bar">
    <div class="input-container">
      <Icon :icon="IconType.MAGNIFY" class="icon" />
      <input v-model="filter" :placeholder="$t('forms.search')" />
    </div>
  </div>
</template>

<script>
import { IconType } from "@/types/icons";
import Icon from "../icons/Icon/Icon.vue";

export default {
  components: { Icon },
  emits: ["updateFilter"],
  data() {
    return {
      filter: "",
      IconType,
    };
  },
  watch: {
    filter() {
      this.$emit("updateFilter", this.filter);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/form/countryCodeSearchBar.scss";
</style>
