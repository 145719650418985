import { AppMutationTypes } from "./app";
import { AuthMutationTypes } from "./auth";
import { ChatsMutationTypes } from "./chats";
import { UsersMutationTypes } from "./users";
import { MenuMutationTypes } from "./menu";

export const AllMutationTypes = {
  ...AppMutationTypes,
  ...AuthMutationTypes,
  ...ChatsMutationTypes,
  ...UsersMutationTypes,
  ...MenuMutationTypes,
};
