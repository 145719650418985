<template>
  <div class="country-code-select" @click="toggleDropdown">
    <div class="inner-container">
      <p>+ {{ data.selectedCountryCode }}</p>
      <Icon :icon="IconType.DROPDOWN_ARROW" alt="dropdown" />
    </div>
  </div>
  <div v-if="data.isPopupOpen" class="country-code-popup">
    <div class="country-code-popup-top" />
    <div v-click-outside="toggleDropdown" class="country-code-popup-inner">
      <div class="inner-content">
        <CountryCodeSearchBar @update-filter="handleUpdateFilter" />
        <div class="items-container">
          <div
            v-for="item in data.filteredCountryCodesArray"
            :key="item.countryName + item.countryCode"
            class="country-code-item"
            @click="handleClick(item)"
          >
            <h5>{{ item.countryName }}</h5>
            <p>{{ item.countryCode }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import CountryCodeSearchBar from "@/components/forms/CountryCodeSearchBar.vue";

import { useGetCountryCodeSelectProperties } from "./useGetCountryCodeSelectProperties";
import Icon from "@/components/icons/Icon/Icon.vue";
import { IconType } from "@/types/icons";

interface CountryCodeSelectEmits {
  (e: "updateCountryCode", arg: string): void;
  (e: "togglePopup", arg: boolean): void;
}

const emit = defineEmits<CountryCodeSelectEmits>();

const { data, toggleDropdown, handleUpdateFilter, handleClick } =
  useGetCountryCodeSelectProperties(emit);
</script>
<style lang="scss" scoped>
@import "./CountryCodeSelect.scss";
</style>
