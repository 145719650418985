import store, { ActionTypes } from "@/store";
import { getIsPhoneStringValid } from "@/utils/form";

export const handleValidatePhoneNumber = (
  countryCode: string,
  phoneNumber: string,
) => {
  const isPhoneStringValid = getIsPhoneStringValid(countryCode, phoneNumber);
  if (!isPhoneStringValid) {
    store.dispatch(ActionTypes.MOUNT_SNACKBAR, {
      title: "snackbar.errors.invalidPhoneNumber.title",
      text: "snackbar.errors.invalidPhoneNumber.text",
    });
  }

  return isPhoneStringValid;
};
