import { RawChat, RawMessage } from "@/store/chats/types";
import { getIdPrefixedObjectFromArray } from "../modifiers";
import { safeParseRawMessageWithAttachments } from "./safeParseRawMessageWithAttachments";

export const getRawMessagesDetails = ({ lastMessage }: RawChat) => {
  const rawMessages = lastMessage
    ? [safeParseRawMessageWithAttachments(lastMessage)]
    : [];
  const messagesObject = getIdPrefixedObjectFromArray<RawMessage>(rawMessages);

  return {
    messages: messagesObject,
    messageIds: rawMessages.map((mess) => mess.id),
    rawMessages,
  };
};
