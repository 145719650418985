import { convertFirebaseDateToLocal } from "@/firebase/utils";
import { ChatState } from "@/store/chats/types";

export const getSentByChatState = (chat: ChatState) => {
  const lastMessageSentAt = chat.rawChat?.lastMessage?.sentAt;
  if (lastMessageSentAt) {
    return convertFirebaseDateToLocal(lastMessageSentAt);
  }
  return convertFirebaseDateToLocal(chat.rawChat?.createdAt);
};
