<template>
  <span class="input-attachment" @click="focusFileInput">
    <Icon :icon="IconType.CLIP" />
  </span>
  <input
    ref="fileInput"
    class="fileInput"
    type="file"
    multiple
    @change="addAttachment"
  />
</template>

<script setup lang="ts">
import Icon from "@/components/icons/Icon/Icon.vue";
import { IconType } from "@/types/icons";
import { ref } from "vue";

interface Emits {
  (e: "add-attachment", files: FileList): void;
}

const emit = defineEmits<Emits>();
const fileInput = ref<HTMLInputElement>();

const focusFileInput = () => {
  if (!fileInput.value) {
    return;
  }
  fileInput.value.value = "";
  fileInput.value.click();
};
const addAttachment = (e: Event) => {
  const target = e.target as HTMLInputElement;
  const files = target.files;
  if (!files) {
    return;
  }
  emit("add-attachment", files);
};

</script>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatContainer/inputAttachment.scss";
</style>
