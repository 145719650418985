<template>
  <EmojiItem
    :emoji="emoji"
    :size="size"
    :class="{ reactionsPanelItem: true, isEnlarged }"
    @insert-emoji="insertEmoji"
    @mouseenter="enlarge"
    @mouseleave="shrink"
  />
</template>

<script setup lang="ts">
import EmojiItem from "@/components/cards/EmojiItem/EmojiItem.vue";
import { ref } from "vue";

interface Props {
  emoji: string;
  size?: number;
}
interface Emits {
  (e: "insert-emoji", emoji: string): void;
}

defineProps<Props>();

const emit = defineEmits<Emits>();
const isEnlarged = ref(false);

const insertEmoji = (emoji: string) => {
  emit("insert-emoji", emoji);
};

const enlarge = () => {
  isEnlarged.value = true;
};

const shrink = () => {
  isEnlarged.value = false;
};
</script>

<style lang="scss">
@import "./MessageEmojiReactionsPanelItem.scss";
</style>
