import { PhoneNumber, PhoneNumberUtil } from "google-libphonenumber";
import { getFullPhoneNumberString } from "./getFullPhoneNumberString";

export const getIsPhoneStringValid = (
  countryCode: string,
  phoneNumber: string,
) => {
  if (phoneNumber.length < 2) {
    return false;
  }

  const phoneString: string = getFullPhoneNumberString(
    countryCode,
    phoneNumber,
  );
  const phoneNumberUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
  const value: PhoneNumber = phoneNumberUtil.parseAndKeepRawInput(phoneString);
  const regionCode = phoneNumberUtil.getRegionCodeForNumber(value);
  const isPossibleNumber: boolean = phoneNumberUtil.isPossibleNumber(value);
  const isValidNumber: boolean = phoneNumberUtil.isValidNumber(value);
  const isValidNumberForRegion: boolean =
    phoneNumberUtil.isValidNumberForRegion(value, regionCode);
  const isPhoneStringValid: boolean =
    isPossibleNumber && isValidNumber && isValidNumberForRegion;

  return isPhoneStringValid;
};
