import i18n from "@/i18n";
import { User } from "@/store/users/state";

export const createChatNameFromMembers = (members: User[], authId: number) => {
  let name = i18n.global.t("user.i");
  members
    .filter((m) => m?.id !== authId)
    .forEach((m) => {
      let userName = m?.name?.split(" ")[0];
      if (m?.isDeleted) {
        userName = i18n.global.t("user.deletedUser");
      }

      name += ", " + userName;
    });

  return name;
};
