import * as tus from "tus-js-client";
import { IUploadVideoFile } from "@/types/chat";
import { getVideoFileDuration } from "./getVideoFileDuration";
import store, { ActionTypes, MutationTypes } from "@/store";
import { ref } from "vue";
import { config } from "@/config";
import { getVideoChunkSize } from "./getVideoChunkSize";
import { checkAccessTokenAsync } from "@/services";
import { Attachment } from "@/store/chats/types";

const retryDelays = [...Array(20).keys()].map((i) => i * 1000);
const calculateProgress = (bytesAccepted: number, bytesTotal: number) =>
  Math.floor((bytesAccepted / bytesTotal) * 100);

export const uploadVideoFileBeAsync = async (
  { chatId, messageId, attachment }: IUploadVideoFile,
  attachmentIdx: number,
): Promise<Attachment> => {
  const file = attachment.compressedFile ?? attachment.file;
  const authId = store.getters.GET_AUTH_ID as number;
  const isErrorMessageSent = ref<boolean>(false);

  try {
    const duration = await getVideoFileDuration(file);
    const accessToken = await checkAccessTokenAsync();

    const getCdnId = () =>
      new Promise<string>((resolve) => {
        const options: tus.UploadOptions = {
          endpoint: `${config.api.chatApi.url}${config.api.chatApi.endpoints.video.upload}`,
          chunkSize: getVideoChunkSize(file.size), // Required a minimum chunk size of 5MB, here we use 50MB.
          retryDelays, // Indicates to tus-js-client the delays after which it will retry if the upload fails
          metadata: {
            filename: file.name,
            filetype: file.type,
            maxDurationSeconds: String(duration + 1),
            bearerToken: accessToken,
            uploadCreator: String(authId),
            tusResumable: "1.0.0",
            // the following metadata is used to update the display status via cloudflare webhook
            appName: "chat",
            chatId,
            messageId,
            attachmentIdx: String(attachmentIdx),
          },
          uploadSize: file.size,
          onError: () => {
            if (isErrorMessageSent.value) {
              return;
            }
            isErrorMessageSent.value = true;
            // TODO: add video deletion
            // TODO: add snackbar
            throw new Error("upload failed");
          },
          onAfterResponse: (_, res) => {
            const cdnId = res.getHeader("Stream-Media-Id");
            if (!cdnId) {
              return;
            }

            resolve(cdnId);
          },
          onChunkComplete: (_, bytesAccepted, bytesTotal) => {
            store.dispatch(ActionTypes.UPDATE_ATTACHMENT_UPLOAD_PROGRESS, {
              chatId,
              messageId,
              attachmentIdx,
              progress: calculateProgress(bytesAccepted, bytesTotal),
            });
          },

          onShouldRetry: (err) => {
            var status = err.originalResponse
              ? err.originalResponse.getStatus()
              : 0;
            // If the status is a 403, we do not want to retry.
            if (status === 403) {
              return false;
            }

            // For any other status code, tus-js-client should retry.
            return true;
          },
        };

        const upload = new tus.Upload(file, options);
        upload.start();
      });

    const cdnId = await getCdnId();
    store.commit(MutationTypes.UPDATE_ATTACHMENT_IS_UPLOADING, {
      chatId,
      messageId,
      attachmentIdx,
      isUploading: true,
    });

    return { ...attachment, cdnId };
  } catch (error) {
    console.log({ error });

    throw error;
  }
};
