<template>
  <ChatSettingsDetails v-bind="blockUserProperties" />
</template>
<script setup lang="ts">
import { useGetChatSettingsBlockUserProperties } from "./useGetChatSettingsBlockUserProperties";
import ChatSettingsDetails from "@/components/chat/chatSettings/ChatSettingsDetails/ChatSettingsDetails.vue";

const { blockUserProperties } = useGetChatSettingsBlockUserProperties();
</script>
<style lang="scss" scoped></style>
